export default {
  path: 'settlement',
  name: '结算',
  icon: 'PurseFilled',
  accessKey: '/settlement',
  routes: [
    {
      path: 'labor-settlement',
      component: '@/pages/settlement/labor-settlement',
      name: '用工结算',
      accessKey: '/settlement/labor-settlement',
    },
    // 用工结算页面下的三个tab
    {
      path: 'labor-settlement?tab=settlement',
      component: '@/pages/settlement/labor-settlement',
      name: '用工结算',
      hideInMenu: true,
      accessKey: '/settlement/labor-settlement?tab=settlement',
    },
    {
      path: 'labor-settlement?tab=payRecord',
      component: '@/pages/settlement/labor-settlement',
      name: '发薪记录',
      hideInMenu: true,
      accessKey: '/settlement/labor-settlement?tab=payRecord',
    },
    {
      path: 'labor-settlement?tab=bridgingFinance',
      component: '@/pages/settlement/labor-settlement',
      name: '垫资记录',
      hideInMenu: true,
      accessKey: '/settlement/labor-settlement?tab=bridgingFinance',
    },

    // 开发票两个tab, 与发薪记录用同样的权限
    {
      path: 'invoice',
      component: '@/pages/settlement/invoice',
      name: '待开发票',
      hideInMenu: true,
      accessKey: '/settlement/labor-settlement?tab=payRecord',
      selectedKey: '/settlement/labor-settlement',
    },
    {
      path: 'invoice-history',
      component: '@/pages/settlement/invoice-history',
      name: '开票历史',
      hideInMenu: true,
      accessKey: '/settlement/labor-settlement?tab=payRecord',
      selectedKey: '/settlement/labor-settlement',
    },

    {
      path: 'payroll',
      component: '@/pages/settlement/payroll',
      name: '发薪',
      hideInMenu: true,
      accessKey: '/settlement/labor-settlement',
      selectedKey: '/settlement/labor-settlement',
    },
    {
      path: 'salary-record',
      component: '@/pages/settlement/salary-record',
      name: '薪资记录',
      accessKey: '/settlement/salary-record',
    },
    {
      path: 'salary-record-detail',
      component: '@/pages/settlement/salary-record-detail',
      name: '薪资详情',
      hideInMenu: true,
      accessKey: '/settlement/salary-record',
      selectedKey: '/settlement/salary-record',
    },
    {
      path: 'customer-settlement',
      component: '@/pages/settlement/customer-settlement',
      name: '客户结算',
      accessKey: '/settlement/customer-settlement',
    },
    {
      path: 'customer-settlement-detail',
      component: '@/pages/settlement/customer-settlement-detail',
      name: '客户结算详情',
      hideInMenu: true,
      accessKey: '/settlement/customer-settlement',
      selectedKey: '/settlement/customer-settlement',
    },
  ],
};
