import { findFirstLeafNode, listToTree } from '@pangu/utils';
import {
  Navigate,
  Outlet,
  useLocation,
  useModel,
  useRouteProps,
} from '@umijs/max';
import { useMemo } from 'react';

import { GetBasicMenuAccountListResponse } from '@/api';
import { MenuItem } from '@/utils';

const RedirectWrapper = () => {
  const { pathname } = useLocation();
  const routeProps = useRouteProps();

  const { initialState } = useModel('@@initialState');
  const routeMap = initialState?.routeMap;

  const { access } = useModel('user');

  const firstMenu = useMemo(() => {
    const treeData = listToTree(
      (access as GetBasicMenuAccountListResponse) || [],
    );
    const first = findFirstLeafNode(treeData, treeData[0]?.id);

    const route = Array.from(routeMap?.values() || []).find(
      (item) => (item as MenuItem).accessKey === first?.url,
    );

    return (route as MenuItem)?.path ?? '/user/login';
  }, [routeMap, access]);

  // 如果有权限，或者是下级页面（例如详情页面），就渲染
  let outlet = null;

  if (
    !routeProps.accessKey ||
    access?.find((el: { url: string }) => el.url === routeProps.accessKey)
  ) {
    outlet = <Outlet />;
  } else {
    outlet = <Navigate to="/403" replace />;
  }

  return pathname === '/' ? <Navigate to={firstMenu} replace /> : outlet;
};
export default RedirectWrapper;
