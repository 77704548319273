import dayjs, { type Dayjs } from 'dayjs';

import {
  DATE_FORMAT,
  DATE_TIME_FORMAT,
  TIME_FORMAT,
} from '@/assets/data/constants';

type FormatType = 'date' | 'time' | 'datetime';

class DayjsUtils {
  private static formatMap = {
    date: DATE_FORMAT,
    time: TIME_FORMAT,
    datetime: DATE_TIME_FORMAT,
  };
  /**
   * 将日期范围转换成时间戳
   * @param range Dayjs 日期范围
   * @param format 格式化类型
   * @returns 时间戳
   */
  static getTimestampGroup(
    range: [Dayjs, Dayjs],
    format: FormatType = 'datetime',
  ): [string, string] {
    const [startTime, endTime] = range;
    const _format = this.formatMap[format];
    return [startTime.format(_format), endTime.format(_format)];
  }

  /**
   * 将时间转成 Dayjs
   * @param range 时间范围
   * @returns Dayjs 范围
   */
  static getDayjsGroup(
    range: [string | number, string | number],
  ): [Dayjs, Dayjs] {
    const [startTime, endTime] = range;
    return [dayjs(startTime), dayjs(endTime)];
  }

  /**
   * 格式化时间范围，将时间范围的开始时间设置为当天的 00:00:00，结束时间设置为当天的 23:59:59
   * @param range Dayjs 日期范围
   * @returns Dayjs 日期范围
   */
  static formatRange = (range: [Dayjs, Dayjs]): [Dayjs, Dayjs] => {
    const [start, end] = range;
    return [dayjs(start).startOf('day'), dayjs(end).endOf('day')];
  };

  /**
   * 格式化时间戳
   * @param t 时间戳
   * @param format 格式化类型
   * @returns 格式化后的时间
   */
  static formatTimestamp(t: string | number, format: FormatType = 'datetime') {
    if (!t) return '';
    const _format = this.formatMap[format];
    return dayjs(t).format(_format);
  }

  /**
   * 将时间字符串转成 Dayjs 格式
   * @param time 时间字符串，如 12:00:00
   * @returns Dayjs 时间
   */
  static timeToDayjs(time: string) {
    const [hour = 0, minute = 0, second = 0] = time.split(':');
    return dayjs().hour(+hour).minute(+minute).second(+second);
  }

  /**
   * 将时间范围字符串转成 Dayjs 格式
   * @param group 时间字符串范围
   * @returns Dayjs 时间范围
   */
  static timeToDayjsRange(group: [string | undefined, string | undefined]) {
    if (!Array.isArray(group)) return;
    const [startTime, endTime] = group;
    if (!startTime || !endTime) return;
    return [this.timeToDayjs(startTime), this.timeToDayjs(endTime)];
  }
}

export default DayjsUtils;
