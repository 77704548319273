import { history, Navigate, Outlet, useModel } from '@umijs/max';
import { Skeleton } from 'antd';
import { useEffect, useState } from 'react';

import { CookieKeyEnum, StorageUtils } from '@/utils';

const SecurityWrapper = () => {
  const {
    fetchUserInfo,
    fetchUserAccess,
    fetchLaborInfo,
    isFirstLogin,
    fetchUserIsFirstLogin,
  } = useModel('user');
  const [loading, setLoading] = useState<boolean>(true);
  // 从 cookie 中获取 token
  const token = StorageUtils.getCookie(CookieKeyEnum.Token);

  // 获取用户信息
  const fetchInfo = async () => {
    try {
      const res = await fetchUserInfo();
      const { mobile } = res ?? {};
      await fetchUserIsFirstLogin({ phone: mobile });
      await fetchUserAccess();
      await fetchLaborInfo();
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  // 获取用户信息
  useEffect(() => {
    if (token) {
      fetchInfo();
    } else {
      history.push('/user/login');
    }
  }, []);

  if (loading) {
    return <Skeleton active />;
  }

  if (!token) {
    return <Navigate to="/user/login" replace />;
  }

  if (isFirstLogin) {
    return <Navigate to="/user/login" replace />;
  }

  return <Outlet />;
};
export default SecurityWrapper;
