export default {
  path: 'statistics',
  name: '报表统计',
  icon: 'PurseFilled',
  accessKey: '/statistics',

  routes: [
    {
      path: 'day',
      component: '@/pages/statistics/day',
      name: '日统计',
      accessKey: '/statistics/day',
    },
    {
      path: 'month',
      component: '@/pages/statistics/month',
      name: '月统计',
      accessKey: '/statistics/month',
    },
    {
      path: 'team',
      component: '@/pages/statistics/team',
      name: '班组统计',
      accessKey: '/statistics/team',
    },
  ],
};
