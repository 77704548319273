import {
  ConstructionSiteEquipmentFilled,
  CustomFilled,
  MeetingFilled,
  PangZhanFilled,
  PurseFilled,
  SettingsFilled,
  ShieldFilled,
  TaskFilled,
  WorkingHoursFilled,
} from '@pangu/icons-react';

export const iconMap = new Map([
  ['MeetingFilled', <MeetingFilled />],
  ['PangZhanFilled', <PangZhanFilled />],
  ['TaskFilled', <TaskFilled />],
  ['PurseFilled', <PurseFilled />],
  ['ShieldFilled', <ShieldFilled />],
  ['CustomFilled', <CustomFilled />],
  ['SettingsFilled', <SettingsFilled />],
  ['WorkingHoursFilled', <WorkingHoursFilled />],
  ['ConstructionSiteEquipmentFilled', <ConstructionSiteEquipmentFilled />],
]);
