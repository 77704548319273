import pkg from '../../../package.json';

export const DATE_FORMAT = 'YYYY-MM-DD';

export const TIME_FORMAT = 'HH:mm:ss';

export const DATE_TIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;

export enum STATUS_ENUM {
  '待接单' = 1,
  '接单中' = 2,
  '待客户确认考勤' = 3,
  '待考勤确认' = 4,
  '已完成' = 5,
  '已拒绝' = 6,
  '酒店已取消' = 7,
  '已取消' = 8,
  '已取消（未接单）' = 9,
  '待结算' = 10,
  '进行中' = 11,
}
export enum DISPATCH_STATUS_ENUM {
  '待接单' = 10,
  '接单中' = 20,
  '进行中' = 30,
  '待结算' = 60,
  '已拒绝' = 70,
  '已完成' = 80,
  '已取消' = 90,
}

export const STATUS_COLOR_MAP = new Map([
  [STATUS_ENUM['待接单'], 'blue'],
  [STATUS_ENUM['接单中'], 'blue'],
  [STATUS_ENUM['进行中'], 'green'],
  [STATUS_ENUM['待客户确认考勤'], 'cyan'],
  [STATUS_ENUM['待考勤确认'], 'gold'],
  [STATUS_ENUM['待结算'], 'purple'],
  [STATUS_ENUM['已完成'], 'default'],
  [STATUS_ENUM['已拒绝'], 'red'],
]);

export const DISPATCH_ORDER_STATUS_COLOR_MAP = new Map([
  [DISPATCH_STATUS_ENUM['待接单'], 'default'],
  [DISPATCH_STATUS_ENUM['接单中'], 'blue'],
  [DISPATCH_STATUS_ENUM['进行中'], 'green'],
  [DISPATCH_STATUS_ENUM['已完成'], 'default'],
  [DISPATCH_STATUS_ENUM['已拒绝'], 'red'],
  [DISPATCH_STATUS_ENUM['已取消'], 'yellow'],
]);

export enum VALUATION_TYPE_ENUM {
  '按小时' = 0,
  '按天' = 1,
  '按件' = 2,
}

export enum VALUATION_TYPE_UNIT_ENUM {
  '时' = 0,
  '天' = 1,
  '件' = 2,
}

export enum CANCEL_ENUM {
  '申请中' = 1,
  '已拒绝' = 2,
}

export enum TEAM_STATUS_ENUM {
  '待开始' = 1,
  '进行中' = 2,
  '已结束' = 3,
  '已作废' = 4,
}

export enum APPLY_STATUS_ENUM {
  '待录用' = 1,
  '已录用' = 2,
  '已拒绝' = 3,
}

export enum WORK_STATUS_ENUM {
  '在职' = 1,
  '离职' = 2,
  '黑名单' = 3,
}

export enum WORK_SCHEDULING_STATUS_ENUM {
  '待入场' = 0,
  '在职' = 1,
  '离职' = 2,
  '已移除' = 3,
}

export enum ATTENDANCE_STATUS_ENUM {
  '正常' = 1,
  '迟到' = 2,
  '早退' = 3,
  '缺卡' = 4,
  '旷工' = 5,
  '迟到/缺卡' = 6,
  '早退/缺卡' = 7,
  '迟到/早退' = 8,
  '未打卡' = 9,
}

export const mapAttendanceStatus = new Map([
  [
    ATTENDANCE_STATUS_ENUM['正常'],
    { text: '正常', status: 'success' as const },
  ],
  [
    ATTENDANCE_STATUS_ENUM['迟到'],
    { text: '迟到', status: 'warning' as const },
  ],
  [
    ATTENDANCE_STATUS_ENUM['早退'],
    { text: '早退', status: 'warning' as const },
  ],
  [ATTENDANCE_STATUS_ENUM['缺卡'], { text: '缺卡', status: 'error' as const }],
  [ATTENDANCE_STATUS_ENUM['旷工'], { text: '旷工', status: 'error' as const }],
  [
    ATTENDANCE_STATUS_ENUM['迟到/缺卡'],
    { text: '迟到/缺卡', status: 'error' as const },
  ],
  [
    ATTENDANCE_STATUS_ENUM['早退/缺卡'],
    { text: '早退/缺卡', status: 'error' as const },
  ],
  [
    ATTENDANCE_STATUS_ENUM['迟到/早退'],
    { text: '迟到/早退', status: 'warning' as const },
  ],
  [
    ATTENDANCE_STATUS_ENUM['未打卡'],
    { text: '未打卡', status: 'warning' as const },
  ],
]);

export enum COOPERATE_STATUS_ENUM {
  // 1正常 2被拒绝 3拒绝 4停用 5被停用 6未激活-待用工确认 7待通过-申请列表
  '正常' = 1,
  '被拒绝' = 2,
  '拒绝' = 3,
  '停用' = 4,
  '被停用' = 5,
  '未激活' = 6,
  '待通过' = 7,
}

// 日志类型枚举
export enum LogEnum {
  维护与升级,
  参数设置,
  系统设置,
  用户管理日志,
}

// 班组考勤状态
export enum TEAM_ATTENDANCE_STATUS_ENUM {
  '待确认' = 0,
  '已确认' = 1,
}

// OSS 上传文件目录
export const OSS_DIR = `business/${process.env.ENV}/${pkg.name}`;
// export const OSS_DIR = `images`;

export const EMPTY_TEXT = '-';

// 部分分页接口传一个大的 pageSize 用来查全部数据
export const MAX_PAGE_SIZE = 10000;

export enum payChannelEnum {
  微信 = 'WECHAT',
  支付宝 = 'ALIPAY',
}
