/* prettier-ignore-start */
/* tslint-disable */
/* eslint-disable */

/* 该文件由 @pangu/end-type-to-front-type 自动生成，请勿直接修改！！！ */

// @ts-ignore
// prettier-ignore
import { Method, RequestBodyType, ResponseBodyType, RequestConfig, RequestFunctionRestArgs, FileData, prepare } from '@pangu/end-type-to-front-type'
import request from '@/utils/request'
// @ts-ignore

/**
 * 接口 [1.查询顶部统计详情↗](http://fe.jiuworker.com:3000/project/29/interface/api/853)
 *
 * @分类 [APP-首页↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_67)
 * @标签 `APP-首页`
 * @请求头 `GET /app/top/bar`
 * @更新时间 `2024-08-23 16:22:04`
 */

/* **返回类型** */
export interface GetAppTopBarResponse {
  /**
   * 考勤异常人数
   */
  attendanceAbnormalNum: number
  /**
   * 进行中数量
   */
  doingNum: number
  /**
   * 接单中数量
   */
  receivingNum: number
  /**
   * 今日出勤人数
   */
  todayAttendanceNum: number
  /**
   * 今日投保
   */
  todayInsuranceNum: number
  /**
   * 今日排班人数
   */
  todayScheduleNum: number
  /**
   * 今日工时
   */
  todayWorkHours: string
  /**
   * 待接单数量
   */
  waitOrderNum: number
}

/* **请求函数** */
export async function getAppTopBar(): Promise<any> {
  return request(`/app/top/bar`, {
    method: Method.GET,
  })
}

/**
 * 接口 [attendance↗](http://fe.jiuworker.com:3000/project/29/interface/api/1119)
 *
 * @分类 [定时任务触发↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_71)
 * @标签 `定时任务触发`
 * @请求头 `POST /schedule/attendance`
 * @更新时间 `2024-08-23 16:22:11`
 */

/* **返回类型** */
export type PostScheduleAttendanceResponse = any

/* **请求函数** */
export async function postScheduleAttendance(): Promise<any> {
  return request(`/schedule/attendance`, {
    method: Method.POST,
  })
}

/**
 * 接口 [attendanceAll↗](http://fe.jiuworker.com:3000/project/29/interface/api/1121)
 *
 * @分类 [定时任务触发↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_71)
 * @标签 `定时任务触发`
 * @请求头 `POST /schedule/attendance/all`
 * @更新时间 `2024-08-23 16:22:11`
 */

/* **返回类型** */
export type PostScheduleAttendanceAllResponse = string

/* **请求函数** */
export async function postScheduleAttendanceAll(): Promise<any> {
  return request(`/schedule/attendance/all`, {
    method: Method.POST,
  })
}

/**
 * 接口 [attendanceStat↗](http://fe.jiuworker.com:3000/project/29/interface/api/1123)
 *
 * @分类 [定时任务触发↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_71)
 * @标签 `定时任务触发`
 * @请求头 `POST /schedule/attendance/stat`
 * @更新时间 `2024-08-23 16:22:11`
 */

/* **请求query类型** */
export interface PostScheduleAttendanceStatQuery {
  /**
   * localDate
   */
  localDate?: string
}

/* **返回类型** */
export type PostScheduleAttendanceStatResponse = any

/* **请求函数** */
export async function postScheduleAttendanceStat(query: PostScheduleAttendanceStatQuery): Promise<any> {
  return request(`/schedule/attendance/stat`, {
    method: Method.POST,
    params: query,
  })
}

/**
 * 接口 [updateInterest↗](http://fe.jiuworker.com:3000/project/29/interface/api/1125)
 *
 * @分类 [定时任务触发↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_71)
 * @标签 `定时任务触发`
 * @请求头 `GET /schedule/finance/interest`
 * @更新时间 `2024-08-23 16:22:11`
 */

/* **返回类型** */
export type GetScheduleFinanceInterestResponse = any

/* **请求函数** */
export async function getScheduleFinanceInterest(): Promise<any> {
  return request(`/schedule/finance/interest`, {
    method: Method.GET,
  })
}

/**
 * 接口 [healthCheck↗](http://fe.jiuworker.com:3000/project/29/interface/api/1127)
 *
 * @分类 [定时任务触发↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_71)
 * @标签 `定时任务触发`
 * @请求头 `POST /schedule/health/check`
 * @更新时间 `2024-08-23 16:22:11`
 */

/* **返回类型** */
export type PostScheduleHealthCheckResponse = string[]

/* **请求函数** */
export async function postScheduleHealthCheck(): Promise<any> {
  return request(`/schedule/health/check`, {
    method: Method.POST,
  })
}

/**
 * 接口 [serviceFee↗](http://fe.jiuworker.com:3000/project/29/interface/api/1129)
 *
 * @分类 [定时任务触发↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_71)
 * @标签 `定时任务触发`
 * @请求头 `GET /schedule/service/fee`
 * @更新时间 `2024-08-23 16:22:11`
 */

/* **返回类型** */
export type GetScheduleServiceFeeResponse = any

/* **请求函数** */
export async function getScheduleServiceFee(): Promise<any> {
  return request(`/schedule/service/fee`, {
    method: Method.GET,
  })
}

/**
 * 接口 [team↗](http://fe.jiuworker.com:3000/project/29/interface/api/1131)
 *
 * @分类 [定时任务触发↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_71)
 * @标签 `定时任务触发`
 * @请求头 `POST /schedule/team/status`
 * @更新时间 `2024-08-23 16:22:11`
 */

/* **返回类型** */
export type PostScheduleTeamStatusResponse = any

/* **请求函数** */
export async function postScheduleTeamStatus(): Promise<any> {
  return request(`/schedule/team/status`, {
    method: Method.POST,
  })
}

/**
 * 接口 [wechatPayStatus↗](http://fe.jiuworker.com:3000/project/29/interface/api/1573)
 *
 * @分类 [定时任务触发↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_71)
 * @标签 `定时任务触发`
 * @请求头 `GET /schedule/wechat/pay/status`
 * @更新时间 `2024-08-23 16:22:11`
 */

/* **返回类型** */
export type GetScheduleWechatPayStatusResponse = any

/* **请求函数** */
export async function getScheduleWechatPayStatus(): Promise<any> {
  return request(`/schedule/wechat/pay/status`, {
    method: Method.GET,
  })
}

/**
 * 接口 [insuranceAuto↗](http://fe.jiuworker.com:3000/project/29/interface/api/2553)
 *
 * @分类 [定时任务触发↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_71)
 * @标签 `定时任务触发`
 * @请求头 `POST /schedule/insurance/auto`
 * @更新时间 `2024-08-23 16:22:11`
 */

/* **返回类型** */
export type PostScheduleInsuranceAutoResponse = any

/* **请求函数** */
export async function postScheduleInsuranceAuto(): Promise<any> {
  return request(`/schedule/insurance/auto`, {
    method: Method.POST,
  })
}

/**
 * 接口 [ipRefresh↗](http://fe.jiuworker.com:3000/project/29/interface/api/2615)
 *
 * @分类 [定时任务触发↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_71)
 * @标签 `定时任务触发`
 * @请求头 `GET /schedule/ip/refresh`
 * @更新时间 `2024-08-23 16:22:11`
 */

/* **返回类型** */
export type GetScheduleIpRefreshResponse = boolean

/* **请求函数** */
export async function getScheduleIpRefresh(): Promise<any> {
  return request(`/schedule/ip/refresh`, {
    method: Method.GET,
  })
}

/**
 * 接口 [11. 保险详情↗](http://fe.jiuworker.com:3000/project/29/interface/api/785)
 *
 * @分类 [APP-保险↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_59)
 * @标签 `APP-保险`
 * @请求头 `GET /app/insurance/detail/{id}`
 * @更新时间 `2024-08-23 16:22:02`
 */

/* **请求params类型** */
export interface GetAppInsuranceDetailByIdParams {
  /**
   * id
   */
  id: string
}

/* **返回类型** */
export interface GetAppInsuranceDetailByIdResponse {
  /**
   * 描述图片
   */
  descPic: string
  /**
   * 描述
   */
  description: string
  gmtCreate: string
  gmtModified: string
  id: number
  /**
   * 保险公司名称
   */
  insuranceCompanyName: string
  /**
   * 劳务公司id
   */
  laborCompanyId: number
  /**
   * 保险名称
   */
  name: string
  /**
   * 保险价格
   */
  price: number
  /**
   * 状态 1:正常，0:禁用
   */
  status: number
}

/* **请求函数** */
export async function getAppInsuranceDetailById(params: GetAppInsuranceDetailByIdParams): Promise<any> {
  return request(`/app/insurance/detail/${params.id}`, {
    method: Method.GET,
  })
}

/**
 * 接口 [10.保险介绍列表↗](http://fe.jiuworker.com:3000/project/29/interface/api/787)
 *
 * @分类 [APP-保险↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_59)
 * @标签 `APP-保险`
 * @请求头 `GET /app/insurance/list`
 * @更新时间 `2024-08-23 16:22:02`
 */

/* **请求query类型** */
export interface GetAppInsuranceListQuery {
  /**
   * name
   */
  name?: string
  /**
   * pageNum
   */
  pageNum?: number
  /**
   * pageSize
   */
  pageSize?: number
}

/* **返回类型** */
export interface GetAppInsuranceListResponse {
  data: {
    /**
     * 描述图片
     */
    descPic: string
    /**
     * 描述
     */
    description: string
    gmtCreate: string
    gmtModified: string
    id: number
    /**
     * 保险公司名称
     */
    insuranceCompanyName: string
    /**
     * 劳务公司id
     */
    laborCompanyId: number
    /**
     * 保险名称
     */
    name: string
    /**
     * 保险价格
     */
    price: number
    /**
     * 状态 1:正常，0:禁用
     */
    status: number
  }[]
  total: number
}

/* **请求函数** */
export async function getAppInsuranceList(query: GetAppInsuranceListQuery): Promise<any> {
  return request(`/app/insurance/list`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [3.保险记录列表↗](http://fe.jiuworker.com:3000/project/29/interface/api/789)
 *
 * @分类 [APP-保险↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_59)
 * @标签 `APP-保险`
 * @请求头 `GET /app/insurance/list/record`
 * @更新时间 `2024-08-23 16:22:02`
 */

/* **请求query类型** */
export interface GetAppInsuranceListRecordQuery {
  /**
   * endDate
   */
  endDate?: string
  /**
   * keyword
   */
  keyword?: string
  /**
   * pageNum
   */
  pageNum?: number
  /**
   * pageSize
   */
  pageSize?: number
  /**
   * startDate
   */
  startDate?: string
  /**
   * 状态 1:正常，2:失败 3:投保中
   */
  status?: string
  /**
   * teamId
   */
  teamId?: string
}

/* **返回类型** */
export interface GetAppInsuranceListRecordResponse {
  data: {
    /**
     * 年龄
     */
    age: number
    /**
     * 头像
     */
    avatar: string
    /**
     * 1:男，0:女
     */
    gender: number
    id: number
    /**
     * 身份证号
     */
    idcard: string
    insuranceId: number
    /**
     * 险种
     */
    insuranceName: string
    /**
     * 保单号码
     */
    insuranceNo: string
    /**
     * 保费价格
     */
    insurancePrice: number
    /**
     * 投保时间
     */
    insuranceTime: string
    /**
     * 岗位id
     */
    jobId: number
    /**
     * 岗位名称
     */
    jobName: string
    /**
     * 手机号
     */
    mobile: string
    /**
     * 失败原因
     */
    reason: string
    /**
     * 状态 1:正常，2:失败 3:投保中
     */
    status: number
    /**
     * 班组id
     */
    teamId: number
    /**
     * 班组名称
     */
    teamName: string
    /**
     * 用户id
     */
    userId: number
    /**
     * 用户名
     */
    userName: string
  }[]
  total: number
}

/* **请求函数** */
export async function getAppInsuranceListRecord(query: GetAppInsuranceListRecordQuery): Promise<any> {
  return request(`/app/insurance/list/record`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [10.1保险-筛选列表↗](http://fe.jiuworker.com:3000/project/29/interface/api/791)
 *
 * @分类 [APP-保险↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_59)
 * @标签 `APP-保险`
 * @请求头 `GET /app/insurance/list/select`
 * @更新时间 `2024-08-23 16:22:02`
 */

/* **请求query类型** */
export interface GetAppInsuranceListSelectQuery {
  /**
   * name
   */
  name?: string
  /**
   * pageNum
   */
  pageNum?: number
  /**
   * pageSize
   */
  pageSize?: number
}

/* **返回类型** */
export interface GetAppInsuranceListSelectResponse {
  data: {
    id: number
    /**
     * 班组名称
     */
    name: string
  }[]
  total: number
}

/* **请求函数** */
export async function getAppInsuranceListSelect(query: GetAppInsuranceListSelectQuery): Promise<any> {
  return request(`/app/insurance/list/select`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [1.保险概览↗](http://fe.jiuworker.com:3000/project/29/interface/api/793)
 *
 * @分类 [APP-保险↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_59)
 * @标签 `APP-保险`
 * @请求头 `GET /app/insurance/overview`
 * @更新时间 `2024-08-23 16:22:03`
 */

/* **返回类型** */
export interface GetAppInsuranceOverviewResponse {
  /**
   * 本月投保次数
   */
  monthInsurance: number
  /**
   * 本月投保金额
   */
  monthInsuranceAmount: number
  /**
   * 今日投保次数
   */
  todayInsurance: number
  /**
   * 今日投保金额
   */
  todayInsuranceAmount: number
  /**
   * 累计投保次数
   */
  totalInsurance: number
  /**
   * 累计投保金额
   */
  totalInsuranceAmount: number
}

/* **请求函数** */
export async function getAppInsuranceOverview(): Promise<any> {
  return request(`/app/insurance/overview`, {
    method: Method.GET,
  })
}

/**
 * 接口 [4.重新投保↗](http://fe.jiuworker.com:3000/project/29/interface/api/795)
 *
 * @分类 [APP-保险↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_59)
 * @标签 `APP-保险`
 * @请求头 `POST /app/insurance/retry`
 * @更新时间 `2024-08-23 16:22:03`
 */

/* **请求body类型** */
export interface PostAppInsuranceRetryBody {
  id?: number
}

/* **返回类型** */
export type PostAppInsuranceRetryResponse = boolean

/* **请求函数** */
export async function postAppInsuranceRetry(body: PostAppInsuranceRetryBody): Promise<any> {
  return request(`/app/insurance/retry`, {
    method: Method.POST,

    data: body,
  })
}

/**
 * 接口 [13.1找回密码↗](http://fe.jiuworker.com:3000/project/29/interface/api/713)
 *
 * @分类 [APP-客户端-登陆登出↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_60)
 * @标签 `APP-客户端-登陆登出`
 * @请求头 `GET /app/account/find/password`
 * @更新时间 `2024-08-23 16:22:01`
 */

/* **请求query类型** */
export interface GetAppAccountFindPasswordQuery {
  /**
   * 短信验证码
   */
  code?: string
  /**
   * password
   */
  password: string
  /**
   * phone
   */
  phone: string
}

/* **返回类型** */
export type GetAppAccountFindPasswordResponse = boolean

/* **请求函数** */
export async function getAppAccountFindPassword(query: GetAppAccountFindPasswordQuery): Promise<any> {
  return request(`/app/account/find/password`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [23.1是否是首次登陆↗](http://fe.jiuworker.com:3000/project/29/interface/api/715)
 *
 * @分类 [APP-客户端-登陆登出↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_60)
 * @标签 `APP-客户端-登陆登出`
 * @请求头 `GET /app/account/first/login`
 * @更新时间 `2024-08-23 16:22:01`
 */

/* **请求query类型** */
export interface GetAppAccountFirstLoginQuery {
  /**
   * phone
   */
  phone?: string
}

/* **返回类型** */
export type GetAppAccountFirstLoginResponse = boolean

/* **请求函数** */
export async function getAppAccountFirstLogin(query: GetAppAccountFirstLoginQuery): Promise<any> {
  return request(`/app/account/first/login`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [20.1修改新密码↗](http://fe.jiuworker.com:3000/project/29/interface/api/717)
 *
 * @分类 [APP-客户端-登陆登出↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_60)
 * @标签 `APP-客户端-登陆登出`
 * @请求头 `GET /app/account/new/password`
 * @更新时间 `2024-08-23 16:22:01`
 */

/* **请求query类型** */
export interface GetAppAccountNewPasswordQuery {
  /**
   * password
   */
  password: string
}

/* **返回类型** */
export type GetAppAccountNewPasswordResponse = boolean

/* **请求函数** */
export async function getAppAccountNewPassword(query: GetAppAccountNewPasswordQuery): Promise<any> {
  return request(`/app/account/new/password`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [31.微信绑定手机号↗](http://fe.jiuworker.com:3000/project/29/interface/api/719)
 *
 * @分类 [APP-客户端-登陆登出↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_60)
 * @标签 `APP-客户端-登陆登出`
 * @请求头 `GET /app/account/out/auth/bind`
 * @更新时间 `2024-08-23 16:22:01`
 */

/* **请求query类型** */
export interface GetAppAccountOutAuthBindQuery {
  /**
   * 短信验证码
   */
  code?: string
  /**
   * openId
   */
  openId: string
  /**
   * phone
   */
  phone: string
  /**
   * 10:老小程序；20：新小程序
   */
  type?: string
}

/* **返回类型** */
export interface GetAppAccountOutAuthBindResponse {
  laborList: {
    companyId: number
    companyName: string
  }[]
  name: string
  openId: string
  phone: string
  token: string
}

/* **请求函数** */
export async function getAppAccountOutAuthBind(query: GetAppAccountOutAuthBindQuery): Promise<any> {
  return request(`/app/account/out/auth/bind`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [30.微信授权登陆↗](http://fe.jiuworker.com:3000/project/29/interface/api/721)
 *
 * @分类 [APP-客户端-登陆登出↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_60)
 * @标签 `APP-客户端-登陆登出`
 * @请求头 `GET /app/account/out/auth/login`
 * @更新时间 `2024-08-23 16:22:01`
 */

/* **请求query类型** */
export interface GetAppAccountOutAuthLoginQuery {
  /**
   * code
   */
  code: string
  /**
   * 10:老小程序；20：新小程序
   */
  type?: string
}

/* **返回类型** */
export interface GetAppAccountOutAuthLoginResponse {
  laborList: {
    companyId: number
    companyName: string
  }[]
  name: string
  openId: string
  phone: string
  token: string
}

/* **请求函数** */
export async function getAppAccountOutAuthLogin(query: GetAppAccountOutAuthLoginQuery): Promise<any> {
  return request(`/app/account/out/auth/login`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [1.获取随机验证码↗](http://fe.jiuworker.com:3000/project/29/interface/api/723)
 *
 * @分类 [APP-客户端-登陆登出↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_60)
 * @标签 `APP-客户端-登陆登出`
 * @请求头 `GET /app/account/out/login/code`
 * @更新时间 `2024-08-23 16:22:01`
 */

/* **返回类型** */
export type GetAppAccountOutLoginCodeResponse = string

/* **请求函数** */
export async function getAppAccountOutLoginCode(): Promise<any> {
  return request(`/app/account/out/login/code`, {
    method: Method.GET,
  })
}

/**
 * 接口 [32.微信授权获取手机号↗](http://fe.jiuworker.com:3000/project/29/interface/api/725)
 *
 * @分类 [APP-客户端-登陆登出↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_60)
 * @标签 `APP-客户端-登陆登出`
 * @请求头 `GET /app/account/out/phone`
 * @更新时间 `2024-08-23 16:22:01`
 */

/* **请求query类型** */
export interface GetAppAccountOutPhoneQuery {
  /**
   * code
   */
  code: string
  /**
   * 10:老小程序；20：新小程序
   */
  type?: string
}

/* **返回类型** */
export type GetAppAccountOutPhoneResponse = string

/* **请求函数** */
export async function getAppAccountOutPhone(query: GetAppAccountOutPhoneQuery): Promise<any> {
  return request(`/app/account/out/phone`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [21.获取当前登陆账户信息↗](http://fe.jiuworker.com:3000/project/29/interface/api/783)
 *
 * @分类 [APP-客户端-登陆登出↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_60)
 * @标签 `APP-客户端-登陆登出`
 * @请求头 `GET /app/info/account`
 * @更新时间 `2024-08-23 16:22:02`
 */

/* **返回类型** */
export interface GetAppInfoAccountResponse {
  appVersion: number
  companyUserId: number
  /**
   * 是否首次登陆
   */
  isFirstLogin: boolean
  laborCompanyId: number
  laborCompanyName: string
  mobile: string
  /**
   * 试用到期时间
   */
  probationPeriod: string
  roleId: number
  /**
   * 是否是班组长
   */
  teamLeader: boolean
  token: string
  userId: number
  userName: string
}

/* **请求函数** */
export async function getAppInfoAccount(): Promise<any> {
  return request(`/app/info/account`, {
    method: Method.GET,
  })
}

/**
 * 接口 [12.如果加入多个企业组织，登陆后在调这个接口获取token↗](http://fe.jiuworker.com:3000/project/29/interface/api/797)
 *
 * @分类 [APP-客户端-登陆登出↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_60)
 * @标签 `APP-客户端-登陆登出`
 * @请求头 `GET /app/login/choose/company`
 * @更新时间 `2024-08-23 16:22:03`
 */

/* **请求query类型** */
export interface GetAppLoginChooseCompanyQuery {
  /**
   * companyId
   */
  companyId: string
  /**
   * phone
   */
  phone: string
}

/* **返回类型** */
export interface GetAppLoginChooseCompanyResponse {
  laborList: {
    companyId: number
    companyName: string
  }[]
  name: string
  openId: string
  phone: string
  token: string
}

/* **请求函数** */
export async function getAppLoginChooseCompany(query: GetAppLoginChooseCompanyQuery): Promise<any> {
  return request(`/app/login/choose/company`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [13.1找回密码↗](http://fe.jiuworker.com:3000/project/29/interface/api/799)
 *
 * @分类 [APP-客户端-登陆登出↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_60)
 * @标签 `APP-客户端-登陆登出`
 * @请求头 `GET /app/login/find/password`
 * @更新时间 `2024-08-23 16:22:03`
 */

/* **请求query类型** */
export interface GetAppLoginFindPasswordQuery {
  /**
   * 短信验证码
   */
  code?: string
  /**
   * password
   */
  password: string
  /**
   * phone
   */
  phone: string
}

/* **返回类型** */
export type GetAppLoginFindPasswordResponse = boolean

/* **请求函数** */
export async function getAppLoginFindPassword(query: GetAppLoginFindPasswordQuery): Promise<any> {
  return request(`/app/login/find/password`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [2.获取短信验证码↗](http://fe.jiuworker.com:3000/project/29/interface/api/801)
 *
 * @分类 [APP-客户端-登陆登出↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_60)
 * @标签 `APP-客户端-登陆登出`
 * @请求头 `GET /app/login/get/sms/code`
 * @更新时间 `2024-08-23 16:22:03`
 */

/* **请求query类型** */
export interface GetAppLoginGetSmsCodeQuery {
  /**
   * code
   */
  code: string
  /**
   * phone
   */
  phone: string
}

/* **返回类型** */
export type GetAppLoginGetSmsCodeResponse = string

/* **请求函数** */
export async function getAppLoginGetSmsCode(query: GetAppLoginGetSmsCodeQuery): Promise<any> {
  return request(`/app/login/get/sms/code`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [20.1修改新密码↗](http://fe.jiuworker.com:3000/project/29/interface/api/803)
 *
 * @分类 [APP-客户端-登陆登出↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_60)
 * @标签 `APP-客户端-登陆登出`
 * @请求头 `GET /app/login/new/password`
 * @更新时间 `2024-08-23 16:22:03`
 */

/* **请求query类型** */
export interface GetAppLoginNewPasswordQuery {
  /**
   * password
   */
  password: string
}

/* **返回类型** */
export type GetAppLoginNewPasswordResponse = boolean

/* **请求函数** */
export async function getAppLoginNewPassword(query: GetAppLoginNewPasswordQuery): Promise<any> {
  return request(`/app/login/new/password`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [4.密码登陆↗](http://fe.jiuworker.com:3000/project/29/interface/api/805)
 *
 * @分类 [APP-客户端-登陆登出↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_60)
 * @标签 `APP-客户端-登陆登出`
 * @请求头 `GET /app/login/password`
 * @更新时间 `2024-08-23 16:22:03`
 */

/* **请求query类型** */
export interface GetAppLoginPasswordQuery {
  /**
   * password
   */
  password: string
  /**
   * phone
   */
  phone: string
}

/* **返回类型** */
export interface GetAppLoginPasswordResponse {
  /**
   * 失败次数
   */
  failTime: number
  laborList: {
    companyId: number
    companyName: string
  }[]
  name: string
  openId: string
  phone: string
  /**
   * 冻结时间/秒
   */
  second: number
  token: string
}

/* **请求函数** */
export async function getAppLoginPassword(query: GetAppLoginPasswordQuery): Promise<any> {
  return request(`/app/login/password`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [3.短信验证码登陆↗](http://fe.jiuworker.com:3000/project/29/interface/api/807)
 *
 * @分类 [APP-客户端-登陆登出↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_60)
 * @标签 `APP-客户端-登陆登出`
 * @请求头 `GET /app/login/sms`
 * @更新时间 `2024-08-23 16:22:03`
 */

/* **请求query类型** */
export interface GetAppLoginSmsQuery {
  /**
   * code
   */
  code: string
  /**
   * phone
   */
  phone: string
}

/* **返回类型** */
export interface GetAppLoginSmsResponse {
  laborList: {
    companyId: number
    companyName: string
  }[]
  name: string
  openId: string
  phone: string
  token: string
}

/* **请求函数** */
export async function getAppLoginSms(query: GetAppLoginSmsQuery): Promise<any> {
  return request(`/app/login/sms`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [22.获取登陆人的菜单权限↗](http://fe.jiuworker.com:3000/project/29/interface/api/809)
 *
 * @分类 [APP-客户端-登陆登出↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_60)
 * @标签 `APP-客户端-登陆登出`
 * @请求头 `GET /app/menu/account`
 * @更新时间 `2024-08-23 16:22:03`
 */

/* **返回类型** */
export type GetAppMenuAccountResponse = {
  /**
   * 编码
   */
  code: string
  id: number
  /**
   * 名字
   */
  menuName: string
  menuType: number
  menuVOList: {}[]
  parentId: number
  rootId: number
  /**
   * 链接地址
   */
  url: string
}[]

/* **请求函数** */
export async function getAppMenuAccount(): Promise<any> {
  return request(`/app/menu/account`, {
    method: Method.GET,
  })
}

/**
 * 接口 [22.1获取登陆人的菜单权限-列表↗](http://fe.jiuworker.com:3000/project/29/interface/api/811)
 *
 * @分类 [APP-客户端-登陆登出↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_60)
 * @标签 `APP-客户端-登陆登出`
 * @请求头 `GET /app/menu/account/list`
 * @更新时间 `2024-08-23 16:22:03`
 */

/* **返回类型** */
export type GetAppMenuAccountListResponse = {
  code: string
  gmtCreate: string
  gmtModified: string
  id: number
  name: string
  parentId: number
  rootId: number
  type: number
  url: string
}[]

/* **请求函数** */
export async function getAppMenuAccountList(): Promise<any> {
  return request(`/app/menu/account/list`, {
    method: Method.GET,
  })
}

/**
 * 接口 [2.详情↗](http://fe.jiuworker.com:3000/project/29/interface/api/845)
 *
 * @分类 [APP-班组信息↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_61)
 * @标签 `APP-班组信息`
 * @请求头 `GET /app/team/detail/{id}`
 * @更新时间 `2024-08-23 16:22:04`
 */

/* **请求params类型** */
export interface GetAppTeamDetailByIdParams {
  /**
   * id
   */
  id: string
}

/* **返回类型** */
export interface GetAppTeamDetailByIdResponse {
  companyId: number
  /**
   *  报名截止时间
   */
  deadline: string
  /**
   * 设备列表
   */
  deviceList: {
    deviceId: number
    deviceName: string
  }[]
  /**
   * 用工单位id
   */
  employerCompanyId: number
  /**
   * 用工单位名称
   */
  employerCompanyName: string
  endDate: string
  endTime: string
  /**
   * 超额报名比例限制
   */
  excessRatio: number
  gmtCreate: string
  gmtModified: string
  id: number
  /**
   * 订单预估收入
   */
  income: number
  /**
   * 投保险种
   */
  insuranceId: number
  /**
   * 投保险种名称
   */
  insuranceName: string
  /**
   * 保险预估支出
   */
  insurePayment: number
  /**
   * 岗位列表
   */
  jobs: {
    /**
     * 最大年龄
     */
    ageMax: number
    /**
     * 最小年龄
     */
    ageMin: number
    femaleNum: number
    id: number
    /**
     * 岗位id-用工计划中
     */
    jobId: number
    maleNum: number
    /**
     * 岗位名称
     */
    name: string
    /**
     * 用工计划id
     */
    num: number
    /**
     * 其它要求-录用确认规则引擎
     */
    other: string
    /**
     * 单价
     */
    price: number
    rooms: {
      /**
       * 房间id
       */
      id: number
      name: string
      num: number
      originalPrice: number
      price: number
    }[]
    /**
     * 班组id
     */
    teamId: number
    /**
     * 计价方式 0:按小时 1:按天 2:按次
     */
    valuationType: number
  }[]
  /**
   * 领班id
   */
  leaderId: number
  /**
   * 领班手机号
   */
  leaderName: string
  location: {
    address: string
    latitude: string
    longitude: string
    region: string[]
  }
  market: number
  /**
   * 班组名称
   */
  name: string
  /**
   * 用工计划id
   */
  orderId: number
  /**
   * 用工计划
   */
  orderName: string
  /**
   * 订单编号/工单号
   */
  orderNo: string
  /**
   * 劳务预估支出
   */
  payment: number
  planId: number
  /**
   * 利润
   */
  profit: number
  /**
   * 报名二维码
   */
  qrcode: string
  /**
   * 休息时长 小时
   */
  restTime: number
  source: string
  startDate: string
  startTime: string
  /**
   * 班组状态 1待开始 2进行中 3已结束 4已作废
   */
  status: number
  /**
   * 计价方式 0:按小时 1:按天 2:按次
   */
  valuationType: number
}

/* **请求函数** */
export async function getAppTeamDetailById(params: GetAppTeamDetailByIdParams): Promise<any> {
  return request(`/app/team/detail/${params.id}`, {
    method: Method.GET,
  })
}

/**
 * 接口 [3.列表↗](http://fe.jiuworker.com:3000/project/29/interface/api/847)
 *
 * @分类 [APP-班组信息↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_61)
 * @标签 `APP-班组信息`
 * @请求头 `GET /app/team/list`
 * @更新时间 `2024-08-23 16:22:04`
 */

/* **请求query类型** */
export interface GetAppTeamListQuery {
  /**
   * name
   */
  name?: string
  /**
   * orderId
   */
  orderId?: string
  /**
   * pageNum
   */
  pageNum?: number
  /**
   * pageSize
   */
  pageSize?: number
  /**
   * status
   */
  status?: string
}

/* **返回类型** */
export interface GetAppTeamListResponse {
  data: {
    /**
     * 报名人数
     */
    applyNum: number
    deadline: string
    endDate: string
    endTime: string
    excessRatio: number
    /**
     * 工作时长
     */
    hour: number
    id: number
    insuranceId: number
    insuranceName: string
    /**
     * 岗位信息
     */
    jobs: {
      /**
       * 岗位名称
       */
      name: string
      /**
       * 用工数量
       */
      num: number
      /**
       * 单价
       */
      price: number
      rooms: {
        /**
         * 房间id
         */
        id: number
        name: string
        num: number
        originalPrice: number
        price: number
      }[]
      /**
       * 计价方式 0按小时 1按天 2按次
       */
      valuationType: number
    }[]
    /**
     * 班组长Id
     */
    leaderAccountId: number
    location: {
      address: string
      latitude: string
      longitude: string
      region: string[]
    }
    /**
     * 班组名称
     */
    name: string
    /**
     * 岗位需求人数
     */
    needNum: number
    /**
     * 用工计划id
     */
    orderId: number
    /**
     * 用工计划名称
     */
    orderName: string
    planId: number
    /**
     * 休息时长 小时
     */
    restTime: number
    /**
     * 排班人数
     */
    scheduleCount: number
    /**
     * 排班进度
     */
    scheduleProcess: number
    source: string
    startDate: string
    startTime: string
    /**
     * 班组状态 1待开始 2进行中 3已结束 4已作废
     */
    status: number
    /**
     * 计价方式 0:按小时 1:按天 2:按次
     */
    valuationType: number
    /**
     * 工时
     */
    workTime: string
  }[]
  total: number
}

/* **请求函数** */
export async function getAppTeamList(query: GetAppTeamListQuery): Promise<any> {
  return request(`/app/team/list`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [3.列表-班组长↗](http://fe.jiuworker.com:3000/project/29/interface/api/849)
 *
 * @分类 [APP-班组信息↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_61)
 * @标签 `APP-班组信息`
 * @请求头 `GET /app/team/list/leader`
 * @更新时间 `2024-08-23 16:22:04`
 */

/* **请求query类型** */
export interface GetAppTeamListLeaderQuery {
  /**
   * name
   */
  name?: string
  /**
   * orderId
   */
  orderId?: string
  /**
   * pageNum
   */
  pageNum?: number
  /**
   * pageSize
   */
  pageSize?: number
  /**
   * status
   */
  status?: string
}

/* **返回类型** */
export interface GetAppTeamListLeaderResponse {
  data: {
    /**
     * 报名人数
     */
    applyNum: number
    deadline: string
    endDate: string
    endTime: string
    excessRatio: number
    /**
     * 工作时长
     */
    hour: number
    id: number
    insuranceId: number
    insuranceName: string
    /**
     * 岗位信息
     */
    jobs: {
      /**
       * 岗位名称
       */
      name: string
      /**
       * 用工数量
       */
      num: number
      /**
       * 单价
       */
      price: number
      rooms: {
        /**
         * 房间id
         */
        id: number
        name: string
        num: number
        originalPrice: number
        price: number
      }[]
      /**
       * 计价方式 0按小时 1按天 2按次
       */
      valuationType: number
    }[]
    /**
     * 班组长Id
     */
    leaderAccountId: number
    location: {
      address: string
      latitude: string
      longitude: string
      region: string[]
    }
    /**
     * 班组名称
     */
    name: string
    /**
     * 岗位需求人数
     */
    needNum: number
    /**
     * 用工计划id
     */
    orderId: number
    /**
     * 用工计划名称
     */
    orderName: string
    planId: number
    /**
     * 休息时长 小时
     */
    restTime: number
    /**
     * 排班人数
     */
    scheduleCount: number
    /**
     * 排班进度
     */
    scheduleProcess: number
    source: string
    startDate: string
    startTime: string
    /**
     * 班组状态 1待开始 2进行中 3已结束 4已作废
     */
    status: number
    /**
     * 计价方式 0:按小时 1:按天 2:按次
     */
    valuationType: number
    /**
     * 工时
     */
    workTime: string
  }[]
  total: number
}

/* **请求函数** */
export async function getAppTeamListLeader(query: GetAppTeamListLeaderQuery): Promise<any> {
  return request(`/app/team/list/leader`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [3.1 班组列表-用于下拉框↗](http://fe.jiuworker.com:3000/project/29/interface/api/851)
 *
 * @分类 [APP-班组信息↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_61)
 * @标签 `APP-班组信息`
 * @请求头 `GET /app/team/list/select`
 * @更新时间 `2024-08-23 16:22:04`
 */

/* **请求query类型** */
export interface GetAppTeamListSelectQuery {
  /**
   * name
   */
  name?: string
  /**
   * pageNum
   */
  pageNum?: number
  /**
   * pageSize
   */
  pageSize?: number
}

/* **返回类型** */
export interface GetAppTeamListSelectResponse {
  data: {
    id: number
    /**
     * 班组名称
     */
    name: string
  }[]
  total: number
}

/* **请求函数** */
export async function getAppTeamListSelect(query: GetAppTeamListSelectQuery): Promise<any> {
  return request(`/app/team/list/select`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [10.派单快捷创建班组↗](http://fe.jiuworker.com:3000/project/29/interface/api/2453)
 *
 * @分类 [APP-班组信息↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_61)
 * @标签 `APP-班组信息`
 * @请求头 `POST /app/team/add`
 * @更新时间 `2024-05-30 10:06:04`
 */

/* **请求body类型** */
export interface PostAppTeamAddBody {
  orderId?: number
  rooms?: {
    /**
     * 岗位名称
     */
    name?: string
    /**
     * 设置工价
     */
    price?: number
  }[]
}

/* **返回类型** */
export type PostAppTeamAddResponse = boolean

/* **请求函数** */
export async function postAppTeamAdd(body: PostAppTeamAddBody): Promise<any> {
  return request(`/app/team/add`, {
    method: Method.POST,

    data: body,
  })
}

/**
 * 接口 [10.派单快捷创建班组↗](http://fe.jiuworker.com:3000/project/29/interface/api/2479)
 *
 * @分类 [APP-班组信息↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_61)
 * @标签 `APP-班组信息`
 * @请求头 `POST /app/team/fast/add`
 * @更新时间 `2024-08-23 16:22:04`
 */

/* **请求body类型** */
export interface PostAppTeamFastAddBody {
  orderId?: number
  rooms?: {
    /**
     * 岗位名称
     */
    name?: string
    /**
     * 设置工价
     */
    price?: number
  }[]
}

/* **返回类型** */
export type PostAppTeamFastAddResponse = boolean

/* **请求函数** */
export async function postAppTeamFastAdd(body: PostAppTeamFastAddBody): Promise<any> {
  return request(`/app/team/fast/add`, {
    method: Method.POST,

    data: body,
  })
}

/**
 * 接口 [1.接受或拒绝用工单↗](http://fe.jiuworker.com:3000/project/29/interface/api/813)
 *
 * @分类 [APP-用工单↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_62)
 * @标签 `APP-用工单`
 * @请求头 `PUT /app/order/accept`
 * @更新时间 `2024-08-23 16:22:03`
 */

/* **请求body类型** */
export interface PutAppOrderAcceptBody {
  /**
   * 是否同意 1同意0拒绝
   */
  accept?: number
  id?: number
  /**
   * 拒绝原因
   */
  refuseReason?: string
}

/* **返回类型** */
export type PutAppOrderAcceptResponse = boolean

/* **请求函数** */
export async function putAppOrderAccept(body: PutAppOrderAcceptBody): Promise<any> {
  return request(`/app/order/accept`, {
    method: Method.PUT,

    data: body,
  })
}

/**
 * 接口 [6.同意用工企业取消计划↗](http://fe.jiuworker.com:3000/project/29/interface/api/815)
 *
 * @分类 [APP-用工单↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_62)
 * @标签 `APP-用工单`
 * @请求头 `PUT /app/order/accept/cancel`
 * @更新时间 `2024-08-23 16:22:03`
 */

/* **请求body类型** */
export interface PutAppOrderAcceptCancelBody {
  /**
   * 是否同意 1同意0拒绝
   */
  accept?: number
  id?: number
  /**
   * 拒绝原因
   */
  refuseReason?: string
}

/* **返回类型** */
export type PutAppOrderAcceptCancelResponse = boolean

/* **请求函数** */
export async function putAppOrderAcceptCancel(body: PutAppOrderAcceptCancelBody): Promise<any> {
  return request(`/app/order/accept/cancel`, {
    method: Method.PUT,

    data: body,
  })
}

/**
 * 接口 [5.申请取消计划↗](http://fe.jiuworker.com:3000/project/29/interface/api/817)
 *
 * @分类 [APP-用工单↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_62)
 * @标签 `APP-用工单`
 * @请求头 `PUT /app/order/cancel`
 * @更新时间 `2024-08-23 16:22:03`
 */

/* **请求body类型** */
export interface PutAppOrderCancelBody {
  id?: number
  /**
   * 拒绝原因
   */
  reason?: string
}

/* **返回类型** */
export type PutAppOrderCancelResponse = boolean

/* **请求函数** */
export async function putAppOrderCancel(body: PutAppOrderCancelBody): Promise<any> {
  return request(`/app/order/cancel`, {
    method: Method.PUT,

    data: body,
  })
}

/**
 * 接口 [2.详情↗](http://fe.jiuworker.com:3000/project/29/interface/api/819)
 *
 * @分类 [APP-用工单↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_62)
 * @标签 `APP-用工单`
 * @请求头 `GET /app/order/detail/{id}`
 * @更新时间 `2024-08-23 16:22:03`
 */

/* **请求params类型** */
export interface GetAppOrderDetailByIdParams {
  /**
   * id
   */
  id: string
}

/* **返回类型** */
export interface GetAppOrderDetailByIdResponse {
  /**
   * 计划取消原因
   */
  cancelReason: string
  /**
   * 取消状态 1正常 2用工方取消 3劳务方取消 4用工方拒绝 5劳务方拒绝
   */
  cancelStatus: number
  /**
   * 联系人
   */
  contractPerson: string
  /**
   * 联系人电话
   */
  contractPhone: string
  day: number
  /**
   * 单日费用
   */
  dayPrice: number
  /**
   * 用工企业id
   */
  employCompanyId: number
  /**
   * 用工企业名称
   */
  employCompanyName: string
  endDate: string
  endDateTime: string
  endTime: string
  gmtCreate: string
  gmtModified: string
  /**
   * 工作时长
   */
  hour: number
  id: number
  /**
   * 岗位需求json
   */
  jobs: {
    ageMax: number
    ageMin: number
    femaleNum: number
    maleNum: number
    maxAge: number
    minAge: number
    name: string
    num: number
    other: string
    price: number
    rooms: {
      /**
       * 房间id
       */
      id: number
      name: string
      num: number
      originalPrice: number
      price: number
    }[]
  }[]
  /**
   * 劳务公司id
   */
  laborCompanyId: number
  location: {
    address: string
    latitude: string
    longitude: string
    region: string[]
  }
  /**
   * 需求名称
   */
  name: string
  /**
   * 订单编号
   */
  orderNo: string
  /**
   * 用工计划id
   */
  planId: number
  /**
   * 拒绝计划取消原因
   */
  refuseCancelReason: string
  /**
   * 拒绝原因
   */
  refuseReason: string
  /**
   * 休息时长 小时
   */
  restTime: number
  /**
   * 排班进度
   */
  scheduleProgress: number
  source: string
  startDate: string
  startTime: string
  /**
   * 订单状态 1待接单 2接单中  11进行中 3待客户确认考勤 4待考勤确认 5已完成 6 已拒绝 7已取消-用工端 8已取消-劳务端 9已取消-未接单 10待结算 11进行中
   */
  status: number
  /**
   * 班组id
   */
  teamId: number
  /**
   * 班组名称
   */
  teamName: string
  /**
   * 天数汇总
   */
  totalDay: number
  /**
   * 总费用
   */
  totalPrice: number
  /**
   * 工时汇总
   */
  totalWorkHour: number
  userNum: number
  /**
   * 计价方式 0:按小时 1:按天 2:按次
   */
  valuationType: number
}

/* **请求函数** */
export async function getAppOrderDetailById(params: GetAppOrderDetailByIdParams): Promise<any> {
  return request(`/app/order/detail/${params.id}`, {
    method: Method.GET,
  })
}

/**
 * 接口 [3.列表↗](http://fe.jiuworker.com:3000/project/29/interface/api/821)
 *
 * @分类 [APP-用工单↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_62)
 * @标签 `APP-用工单`
 * @请求头 `GET /app/order/list`
 * @更新时间 `2024-08-23 16:22:03`
 */

/* **请求query类型** */
export interface GetAppOrderListQuery {
  /**
   * employCompanyId
   */
  employCompanyId?: string
  /**
   * endDate
   */
  endDate?: string
  /**
   * name
   */
  name?: string
  /**
   * orderNo
   */
  orderNo?: string
  /**
   * pageNum
   */
  pageNum?: number
  /**
   * pageSize
   */
  pageSize?: number
  /**
   * startDate
   */
  startDate?: string
  /**
   * 订单状态 1待接单 2接单中  11进行中 3待客户确认考勤 4待考勤确认 5已完成 6 已拒绝 7已取消-用工端 8已取消-劳务端 9已取消-未接单 10待结算 11进行中  20所有取消
   */
  statusList?: string
}

/* **返回类型** */
export interface GetAppOrderListResponse {
  data: {
    /**
     * 计划取消原因
     */
    cancelReason: string
    /**
     * 取消状态 1正常 2用工方取消 3劳务方取消 4用工方拒绝 5劳务方拒绝
     */
    cancelStatus: number
    /**
     * 联系人
     */
    contractPerson: string
    /**
     * 联系人电话
     */
    contractPhone: string
    day: number
    /**
     * 单日费用
     */
    dayPrice: number
    /**
     * 用工企业id
     */
    employCompanyId: number
    /**
     * 用工企业名称
     */
    employCompanyName: string
    endDate: string
    endDateTime: string
    endTime: string
    gmtCreate: string
    gmtModified: string
    /**
     * 工作时长
     */
    hour: number
    id: number
    /**
     * 岗位需求json
     */
    jobs: {
      ageMax: number
      ageMin: number
      femaleNum: number
      maleNum: number
      maxAge: number
      minAge: number
      name: string
      num: number
      other: string
      price: number
      rooms: {
        /**
         * 房间id
         */
        id: number
        name: string
        num: number
        originalPrice: number
        price: number
      }[]
    }[]
    /**
     * 劳务公司id
     */
    laborCompanyId: number
    location: {
      address: string
      latitude: string
      longitude: string
      region: string[]
    }
    /**
     * 需求名称
     */
    name: string
    /**
     * 订单编号
     */
    orderNo: string
    /**
     * 用工计划id
     */
    planId: number
    /**
     * 拒绝计划取消原因
     */
    refuseCancelReason: string
    /**
     * 拒绝原因
     */
    refuseReason: string
    /**
     * 休息时长 小时
     */
    restTime: number
    /**
     * 排班进度
     */
    scheduleProgress: number
    source: string
    startDate: string
    startTime: string
    /**
     * 订单状态 1待接单 2接单中  11进行中 3待客户确认考勤 4待考勤确认 5已完成 6 已拒绝 7已取消-用工端 8已取消-劳务端 9已取消-未接单 10待结算 11进行中
     */
    status: number
    /**
     * 班组id
     */
    teamId: number
    /**
     * 班组名称
     */
    teamName: string
    /**
     * 天数汇总
     */
    totalDay: number
    /**
     * 总费用
     */
    totalPrice: number
    /**
     * 工时汇总
     */
    totalWorkHour: number
    userNum: number
    /**
     * 计价方式 0:按小时 1:按天 2:按次
     */
    valuationType: number
  }[]
  total: number
}

/* **请求函数** */
export async function getAppOrderList(query: GetAppOrderListQuery): Promise<any> {
  return request(`/app/order/list`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [3.1 用工企业申请取消计划↗](http://fe.jiuworker.com:3000/project/29/interface/api/823)
 *
 * @分类 [APP-用工单↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_62)
 * @标签 `APP-用工单`
 * @请求头 `GET /app/order/list/cancel`
 * @更新时间 `2024-08-23 16:22:03`
 */

/* **请求query类型** */
export interface GetAppOrderListCancelQuery {
  /**
   * pageNum
   */
  pageNum?: number
  /**
   * pageSize
   */
  pageSize?: number
  /**
   * 1申请中2已拒绝
   */
  status?: string
}

/* **返回类型** */
export interface GetAppOrderListCancelResponse {
  data: {
    /**
     * 计划取消原因
     */
    cancelReason: string
    /**
     * 用工企业名称
     */
    employCompanyName: string
    id: number
    /**
     * 岗位需求json
     */
    jobs: {
      ageMax: number
      ageMin: number
      femaleNum: number
      maleNum: number
      maxAge: number
      minAge: number
      name: string
      num: number
      other: string
      price: number
      rooms: {
        /**
         * 房间id
         */
        id: number
        name: string
        num: number
        originalPrice: number
        price: number
      }[]
    }[]
    /**
     * 需求名称
     */
    name: string
    /**
     * 订单编号
     */
    orderNo: string
    /**
     * 用工计划id
     */
    planId: number
    /**
     * 拒绝计划取消原因
     */
    refuseCancelReason: string
    /**
     * 用工企业名称
     */
    refuseReason: string
    /**
     * 订单状态 1待接单 2接单中  11进行中 3待客户确认考勤 4待考勤确认 5已完成 6 已拒绝 7已取消-用工端 8已取消-劳务端 9已取消-未接单 10待结算 11进行中
     */
    status: number
    /**
     * 计价方式 0:按小时 1:按天 2:按次
     */
    valuationType: number
  }[]
  total: number
}

/* **请求函数** */
export async function getAppOrderListCancel(query: GetAppOrderListCancelQuery): Promise<any> {
  return request(`/app/order/list/cancel`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [3.2需求单-数据统计↗](http://fe.jiuworker.com:3000/project/29/interface/api/825)
 *
 * @分类 [APP-用工单↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_62)
 * @标签 `APP-用工单`
 * @请求头 `GET /app/order/list/count`
 * @更新时间 `2024-08-23 16:22:03`
 */

/* **返回类型** */
export type GetAppOrderListCountResponse = {
  count: number
  /**
   * 订单状态 10待接单 20接单中  30进行中 60待结算  80已完成 90已取消
   */
  status: number
}[]

/* **请求函数** */
export async function getAppOrderListCount(): Promise<any> {
  return request(`/app/order/list/count`, {
    method: Method.GET,
  })
}

/**
 * 接口 [2.用工企业筛选列表↗](http://fe.jiuworker.com:3000/project/29/interface/api/827)
 *
 * @分类 [APP-用工单↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_62)
 * @标签 `APP-用工单`
 * @请求头 `GET /app/order/list/employer/select`
 * @更新时间 `2024-08-23 16:22:03`
 */

/* **请求query类型** */
export interface GetAppOrderListEmployerSelectQuery {
  /**
   * name
   */
  name?: string
  /**
   * pageNum
   */
  pageNum?: number
  /**
   * pageSize
   */
  pageSize?: number
}

/* **返回类型** */
export interface GetAppOrderListEmployerSelectResponse {
  data: {
    id: number
    /**
     * 班组名称
     */
    name: string
  }[]
  total: number
}

/* **请求函数** */
export async function getAppOrderListEmployerSelect(query: GetAppOrderListEmployerSelectQuery): Promise<any> {
  return request(`/app/order/list/employer/select`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [12.付款记录详情↗](http://fe.jiuworker.com:3000/project/29/interface/api/829)
 *
 * @分类 [APP-结算信息↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_63)
 * @标签 `APP-结算信息`
 * @请求头 `GET /app/settle/detail/payRecord/{payId}`
 * @更新时间 `2024-08-23 16:22:04`
 */

/* **请求params类型** */
export interface GetAppSettleDetailPayRecordByPayIdParams {
  /**
   * payId
   */
  payId: string
}

/* **返回类型** */
export interface GetAppSettleDetailPayRecordByPayIdResponse {
  /**
   * 结算结束日期
   */
  endDate: string
  gmtCreate: string
  gmtModified: string
  id: number
  /**
   * 劳务公司id
   */
  laborCompanyId: number
  /**
   * 结算出来的待发金额
   */
  oldSalary: number
  /**
   * 付款账户
   */
  payAccount: string
  /**
   * 备注
   */
  remark: string
  /**
   * 支付的薪资
   */
  salary: number
  /**
   * 手续费
   */
  serviceFee: number
  /**
   * 结算开始日期
   */
  startDate: string
  /**
   * 异常状态  1:正常 2:确认中 3:失败
   */
  status: number
  /**
   * 到账时间
   */
  successTime: string
  /**
   * 班组id
   */
  teamId: number
  /**
   * 班组名称
   */
  teamName: string
  /**
   * 交易流水号
   */
  transactionSerialNumber: string
  /**
   * 收款账户
   */
  userAccount: string
  /**
   * 用户id
   */
  userId: number
  /**
   * 用户名称
   */
  userName: string
}

/* **请求函数** */
export async function getAppSettleDetailPayRecordByPayId(
  params: GetAppSettleDetailPayRecordByPayIdParams,
): Promise<any> {
  return request(`/app/settle/detail/payRecord/${params.payId}`, {
    method: Method.GET,
  })
}

/**
 * 接口 [10.班组人员待结算薪资↗](http://fe.jiuworker.com:3000/project/29/interface/api/831)
 *
 * @分类 [APP-结算信息↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_63)
 * @标签 `APP-结算信息`
 * @请求头 `GET /app/settle/list/employee/salary`
 * @更新时间 `2024-08-23 16:22:04`
 */

/* **请求query类型** */
export interface GetAppSettleListEmployeeSalaryQuery {
  /**
   * 认证类型 0当前发薪渠道不支持 1当前发薪渠道已认证
   */
  authType?: string
  /**
   * endDate
   */
  endDate: string
  /**
   * startDate
   */
  startDate: string
  /**
   * teamId
   */
  teamId: string
}

/* **返回类型** */
export type GetAppSettleListEmployeeSalaryResponse = {
  /**
   * 认证类型 0当前发薪渠道不支持 1当前发薪渠道已认证
   */
  authType: number
  /**
   * 用户名称
   */
  avatar: string
  /**
   * 身份证
   */
  idcard: string
  /**
   * 手机号
   */
  mobile: string
  /**
   * 计件
   */
  piece: number
  /**
   * 结算id列表
   */
  settleIds: number[]
  teamEmployeeId: number
  userId: number
  /**
   * 用户名称
   */
  userName: string
  /**
   * 计价方式 0:按小时 1:按天 2:按次
   */
  valuationType: number
  /**
   * 待发工资
   */
  waitPay: number
  /**
   * 工时
   */
  workDay: string
  /**
   * 工时
   */
  workHour: string
}[]

/* **请求函数** */
export async function getAppSettleListEmployeeSalary(query: GetAppSettleListEmployeeSalaryQuery): Promise<any> {
  return request(`/app/settle/list/employee/salary`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [12.付款记录↗](http://fe.jiuworker.com:3000/project/29/interface/api/833)
 *
 * @分类 [APP-结算信息↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_63)
 * @标签 `APP-结算信息`
 * @请求头 `GET /app/settle/list/payRecord`
 * @更新时间 `2024-08-23 16:22:04`
 */

/* **请求query类型** */
export interface GetAppSettleListPayRecordQuery {
  /**
   * accountName
   */
  accountName?: string
  /**
   * endDate
   */
  endDate?: string
  /**
   * pageNum
   */
  pageNum?: number
  /**
   * pageSize
   */
  pageSize?: number
  /**
   * startDate
   */
  startDate?: string
  /**
   * 状态  1:正常 2:确认中 3:失败
   */
  status?: string
  /**
   * teamId
   */
  teamId?: string
  /**
   * transactionSerialNumber
   */
  transactionSerialNumber?: string
  /**
   * userName
   */
  userName?: string
}

/* **返回类型** */
export interface GetAppSettleListPayRecordResponse {
  data: {
    /**
     * 结算结束日期
     */
    endDate: string
    gmtCreate: string
    gmtModified: string
    id: number
    /**
     * 劳务公司id
     */
    laborCompanyId: number
    /**
     * 结算出来的待发金额
     */
    oldSalary: number
    /**
     * 付款账户
     */
    payAccount: string
    /**
     * 备注
     */
    remark: string
    /**
     * 支付的薪资
     */
    salary: number
    /**
     * 手续费
     */
    serviceFee: number
    /**
     * 结算开始日期
     */
    startDate: string
    /**
     * 异常状态  1:正常 2:确认中 3:失败
     */
    status: number
    /**
     * 到账时间
     */
    successTime: string
    /**
     * 班组id
     */
    teamId: number
    /**
     * 班组名称
     */
    teamName: string
    /**
     * 交易流水号
     */
    transactionSerialNumber: string
    /**
     * 收款账户
     */
    userAccount: string
    /**
     * 用户id
     */
    userId: number
    /**
     * 用户名称
     */
    userName: string
  }[]
  total: number
}

/* **请求函数** */
export async function getAppSettleListPayRecord(query: GetAppSettleListPayRecordQuery): Promise<any> {
  return request(`/app/settle/list/payRecord`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [2.待结算班组↗](http://fe.jiuworker.com:3000/project/29/interface/api/835)
 *
 * @分类 [APP-结算信息↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_63)
 * @标签 `APP-结算信息`
 * @请求头 `GET /app/settle/list/team`
 * @更新时间 `2024-08-23 16:22:04`
 */

/* **请求query类型** */
export interface GetAppSettleListTeamQuery {
  /**
   * pageNum
   */
  pageNum?: number
  /**
   * pageSize
   */
  pageSize?: number
}

/* **返回类型** */
export interface GetAppSettleListTeamResponse {
  data: {
    /**
     * 实发工资
     */
    actualPay: number
    /**
     * 今日预计收入
     */
    incomeToday: number
    /**
     * 今日工资
     */
    payToday: number
    /**
     * 计件
     */
    piece: number
    /**
     * 自建班组手续费，null不展示
     */
    serviceFee: number
    /**
     * 应发工资
     */
    shouldPay: number
    teamId: number
    /**
     * 班组名称
     */
    teamName: string
    /**
     * 总天数
     */
    totalDayCount: number
    /**
     * 计价方式 0:按小时 1:按天 2:按次
     */
    valuationType: number
    /**
     * 已发工资
     */
    waitPay: number
    /**
     * 工时
     */
    workHour: string
  }[]
  total: number
}

/* **请求函数** */
export async function getAppSettleListTeam(query: GetAppSettleListTeamQuery): Promise<any> {
  return request(`/app/settle/list/team`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [20.发薪列表-班组维度↗](http://fe.jiuworker.com:3000/project/29/interface/api/837)
 *
 * @分类 [APP-结算信息↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_63)
 * @标签 `APP-结算信息`
 * @请求头 `GET /app/settle/list/team/pay`
 * @更新时间 `2024-08-23 16:22:04`
 */

/* **请求query类型** */
export interface GetAppSettleListTeamPayQuery {
  /**
   * idcard
   */
  idcard?: string
  /**
   * mobile
   */
  mobile?: string
  /**
   * pageNum
   */
  pageNum?: number
  /**
   * pageSize
   */
  pageSize?: number
  /**
   * teamId
   */
  teamId: string
  /**
   * userName
   */
  userName?: string
}

/* **返回类型** */
export interface GetAppSettleListTeamPayResponse {
  data: {
    /**
     * 实发工资
     */
    actualPay: number
    /**
     * 头像
     */
    avatar: string
    /**
     * 计件
     */
    piece: number
    /**
     * 应发工资
     */
    shouldPay: number
    /**
     * 总天数
     */
    totalDayCount: number
    userId: number
    /**
     * 用户名称
     */
    userName: string
    /**
     * 待发工资
     */
    waitPay: number
    /**
     * 工时
     */
    workHour: string
  }[]
  total: number
}

/* **请求函数** */
export async function getAppSettleListTeamPay(query: GetAppSettleListTeamPayQuery): Promise<any> {
  return request(`/app/settle/list/team/pay`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [1.用工结算概览↗](http://fe.jiuworker.com:3000/project/29/interface/api/839)
 *
 * @分类 [APP-结算信息↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_63)
 * @标签 `APP-结算信息`
 * @请求头 `GET /app/settle/overview`
 * @更新时间 `2024-08-23 16:22:04`
 */

/* **返回类型** */
export interface GetAppSettleOverviewResponse {
  /**
   * 账户余额-根据当前渠道返回
   */
  balance: string
  balanceAllVo: {
    /**
     * 支付宝账户余额
     */
    aliAmount: number
    /**
     * 支付宝签约状态 0否1是
     */
    aliSign: number
    /**
     * 支付渠道 WECHAT ALIPAY
     */
    payChannel: string
    /**
     * 微信账户余额
     */
    wechatAmount: number
    /**
     * 微工卡签约状态 0否1是
     */
    wechatSign: number
  }
  /**
   * 今日预估收入待发
   */
  exceptedIncomeToday: number
  /**
   * 全部待发
   */
  waitPay: number
  /**
   * 今日待发
   */
  waitPayToday: number
}

/* **请求函数** */
export async function getAppSettleOverview(): Promise<any> {
  return request(`/app/settle/overview`, {
    method: Method.GET,
  })
}

/**
 * 接口 [11.发薪↗](http://fe.jiuworker.com:3000/project/29/interface/api/841)
 *
 * @分类 [APP-结算信息↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_63)
 * @标签 `APP-结算信息`
 * @请求头 `POST /app/settle/pay`
 * @更新时间 `2024-08-23 16:22:04`
 */

/* **请求body类型** */
export interface PostAppSettlePayBody {
  /**
   * 是否垫资 0否1是 默认否
   */
  advanceType?: number
  /**
   * 结算员工列表
   */
  employeePays?: {
    /**
     * 付款金额
     */
    remark?: string
    /**
     * 付款金额
     */
    salary?: number
    /**
     * 结算id列表
     */
    settleIds?: number[]
    /**
     * 用户id
     */
    userId?: number
  }[]
  /**
   * 结束时间
   */
  endDate?: string
  /**
   * 支付密码
   */
  payPassword?: string
  /**
   * 开始时间
   */
  startDate?: string
  /**
   * 班组id
   */
  teamId?: number
}

/* **返回类型** */
export interface PostAppSettlePayResponse {
  /**
   * 失败次数
   */
  failTime: number
  /**
   * 冻结时间/秒
   */
  second: number
}

/* **请求函数** */
export async function postAppSettlePay(body: PostAppSettlePayBody): Promise<any> {
  return request(`/app/settle/pay`, {
    method: Method.POST,

    data: body,
  })
}

/**
 * 接口 [21.发薪列表-班组概览↗](http://fe.jiuworker.com:3000/project/29/interface/api/843)
 *
 * @分类 [APP-结算信息↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_63)
 * @标签 `APP-结算信息`
 * @请求头 `GET /app/settle/team/pay/overview`
 * @更新时间 `2024-08-23 16:22:04`
 */

/* **请求query类型** */
export interface GetAppSettleTeamPayOverviewQuery {
  /**
   * teamId
   */
  teamId: string
}

/* **返回类型** */
export interface GetAppSettleTeamPayOverviewResponse {
  /**
   * 实发工资
   */
  actualPay: number
  /**
   * 今日预计收入
   */
  incomeToday: number
  /**
   * 今日工资
   */
  payToday: number
  /**
   * 计件
   */
  piece: number
  /**
   * 自建班组手续费，null不展示
   */
  serviceFee: number
  /**
   * 应发工资
   */
  shouldPay: number
  teamId: number
  /**
   * 班组名称
   */
  teamName: string
  /**
   * 总天数
   */
  totalDayCount: number
  /**
   * 计价方式 0:按小时 1:按天 2:按次
   */
  valuationType: number
  /**
   * 已发工资
   */
  waitPay: number
  /**
   * 工时
   */
  workHour: string
}

/* **请求函数** */
export async function getAppSettleTeamPayOverview(query: GetAppSettleTeamPayOverviewQuery): Promise<any> {
  return request(`/app/settle/team/pay/overview`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [10.按人员发薪-人员维度↗](http://fe.jiuworker.com:3000/project/29/interface/api/2457)
 *
 * @分类 [APP-结算信息↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_63)
 * @标签 `APP-结算信息`
 * @请求头 `GET /app/settle/list/user`
 * @更新时间 `2024-05-29 09:56:04`
 */

/* **请求query类型** */
export interface GetAppSettleListUserQuery {
  /**
   * pageNum
   */
  pageNum?: number
  /**
   * pageSize
   */
  pageSize?: number
}

/* **返回类型** */
export interface GetAppSettleListUserResponse {
  data: {
    /**
     * 头像
     */
    avatar: string
    /**
     * 身份证
     */
    idcard: string
    /**
     * 手机号
     */
    mobile: string
    /**
     * 已发工资
     */
    payed: number
    /**
     * 今日工资
     */
    todaySalary: number
    userId: number
    /**
     * 用户名称
     */
    userName: string
    /**
     * 待发工资
     */
    waitPay: number
  }[]
  total: number
}

/* **请求函数** */
export async function getAppSettleListUser(query: GetAppSettleListUserQuery): Promise<any> {
  return request(`/app/settle/list/user`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [100.按人员发薪-人员维度↗](http://fe.jiuworker.com:3000/project/29/interface/api/2459)
 *
 * @分类 [APP-结算信息↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_63)
 * @标签 `APP-结算信息`
 * @请求头 `GET /app/settle/list/employee`
 * @更新时间 `2024-08-23 16:22:04`
 */

/* **请求query类型** */
export interface GetAppSettleListEmployeeQuery {
  /**
   * pageNum
   */
  pageNum?: number
  /**
   * pageSize
   */
  pageSize?: number
}

/* **返回类型** */
export interface GetAppSettleListEmployeeResponse {
  data: {
    /**
     * 认证类型 0当前发薪渠道不支持 1当前发薪渠道已认证
     */
    authType: number
    /**
     * 头像
     */
    avatar: string
    /**
     * 身份证
     */
    idcard: string
    /**
     * 手机号
     */
    mobile: string
    /**
     * 已发工资
     */
    payed: number
    /**
     * 今日工资
     */
    todaySalary: number
    userId: number
    /**
     * 用户名称
     */
    userName: string
    /**
     * 待发工资
     */
    waitPay: number
  }[]
  total: number
}

/* **请求函数** */
export async function getAppSettleListEmployee(query: GetAppSettleListEmployeeQuery): Promise<any> {
  return request(`/app/settle/list/employee`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [废弃101.按人员发薪-员工指定周期薪水↗](http://fe.jiuworker.com:3000/project/29/interface/api/2461)
 *
 * @分类 [APP-结算信息↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_63)
 * @标签 `APP-结算信息`
 * @请求头 `GET /app/settle/salary/employee/detail`
 * @更新时间 `2024-08-23 16:22:04`
 */

/* **请求query类型** */
export interface GetAppSettleSalaryEmployeeDetailQuery {
  /**
   * endDate
   */
  endDate: string
  /**
   * startDate
   */
  startDate: string
  /**
   * userId
   */
  userId: string
}

/* **返回类型** */
export interface GetAppSettleSalaryEmployeeDetailResponse {
  /**
   * 头像
   */
  avatar: string
  /**
   * 身份证
   */
  idcard: string
  /**
   * 手机号
   */
  mobile: string
  piece: number
  /**
   * 付款金额
   */
  salary: number
  /**
   * 结算id列表
   */
  settleIds: number[]
  userId: number
  /**
   * 用户名称
   */
  userName: string
  /**
   * 工作时长
   */
  workHour: string
}

/* **请求函数** */
export async function getAppSettleSalaryEmployeeDetail(query: GetAppSettleSalaryEmployeeDetailQuery): Promise<any> {
  return request(`/app/settle/salary/employee/detail`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [102.按人员发薪-人员月度查询↗](http://fe.jiuworker.com:3000/project/29/interface/api/2463)
 *
 * @分类 [APP-结算信息↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_63)
 * @标签 `APP-结算信息`
 * @请求头 `GET /app/settle/salary/employee/month/detail`
 * @更新时间 `2024-08-23 16:22:04`
 */

/* **请求query类型** */
export interface GetAppSettleSalaryEmployeeMonthDetailQuery {
  /**
   * 月份格式 2024-01-01
   */
  date?: string
  /**
   * pageNum
   */
  pageNum?: number
  /**
   * pageSize
   */
  pageSize?: number
  /**
   * userId
   */
  userId: string
}

/* **返回类型** */
export interface GetAppSettleSalaryEmployeeMonthDetailResponse {
  authType: number
  /**
   * 头像
   */
  avatar: string
  /**
   * 身份证
   */
  idcard: string
  /**
   * 手机号
   */
  mobile: string
  /**
   * 薪资月历
   */
  monthSalary: {
    /**
     * 日期
     */
    date: string
    /**
     * 工时
     */
    piece: string
    /**
     * 工资
     */
    salary: number
    /**
     * 状态 2未付款 3已付款
     */
    status: number
  }[]
  /**
   * 已发工资
   */
  payed: number
  /**
   * 今日工资
   */
  todaySalary: number
  userId: number
  /**
   * 用户名称
   */
  userName: string
  /**
   * 待发工资
   */
  waitPay: number
}

/* **请求函数** */
export async function getAppSettleSalaryEmployeeMonthDetail(
  query: GetAppSettleSalaryEmployeeMonthDetailQuery,
): Promise<any> {
  return request(`/app/settle/salary/employee/month/detail`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [废弃11.发薪↗](http://fe.jiuworker.com:3000/project/29/interface/api/2467)
 *
 * @分类 [APP-结算信息↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_63)
 * @标签 `APP-结算信息`
 * @请求头 `POST /app/settle/pay/user`
 * @更新时间 `2024-07-15 18:38:04`
 */

/* **请求body类型** */
export interface PostAppSettlePayUserBody {
  /**
   * 结束时间
   */
  endDate?: string
  /**
   * 支付密码-md5
   */
  payPassword?: string
  /**
   * 备注
   */
  remark?: string
  /**
   * 付款金额
   */
  salary?: number
  /**
   * 结算id列表
   */
  settleIds?: number[]
  /**
   * 开始时间
   */
  startDate?: string
  /**
   * 用户id
   */
  userId?: number
}

/* **返回类型** */
export interface PostAppSettlePayUserResponse {
  /**
   * 失败次数
   */
  failTime: number
  /**
   * 冻结时间/秒
   */
  second: number
}

/* **请求函数** */
export async function postAppSettlePayUser(body: PostAppSettlePayUserBody): Promise<any> {
  return request(`/app/settle/pay/user`, {
    method: Method.POST,

    data: body,
  })
}

/**
 * 接口 [11.发薪支付-人员多班组↗](http://fe.jiuworker.com:3000/project/29/interface/api/2521)
 *
 * @分类 [APP-结算信息↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_63)
 * @标签 `APP-结算信息`
 * @请求头 `POST /app/settle/pay/user2`
 * @更新时间 `2024-08-23 16:22:04`
 */

/* **请求body类型** */
export interface PostAppSettlePayUser2Body {
  /**
   * 结束时间
   */
  endDate?: string
  payList?: {
    /**
     * 备注
     */
    remark?: string
    /**
     * 付款金额
     */
    salary?: number
    /**
     * 结算id列表
     */
    settleIds?: number[]
    teamId?: number
  }[]
  /**
   * 支付密码-md5
   */
  payPassword?: string
  /**
   * 付款金额
   */
  salary?: number
  /**
   * 开始时间
   */
  startDate?: string
  /**
   * 用户id
   */
  userId?: number
}

/* **返回类型** */
export interface PostAppSettlePayUser2Response {
  /**
   * 失败次数
   */
  failTime: number
  /**
   * 冻结时间/秒
   */
  second: number
}

/* **请求函数** */
export async function postAppSettlePayUser2(body: PostAppSettlePayUser2Body): Promise<any> {
  return request(`/app/settle/pay/user2`, {
    method: Method.POST,

    data: body,
  })
}

/**
 * 接口 [101.按人员发薪-员工指定周期薪水-0612↗](http://fe.jiuworker.com:3000/project/29/interface/api/2523)
 *
 * @分类 [APP-结算信息↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_63)
 * @标签 `APP-结算信息`
 * @请求头 `GET /app/settle/salary/employee/detail2`
 * @更新时间 `2024-08-23 16:22:04`
 */

/* **请求query类型** */
export interface GetAppSettleSalaryEmployeeDetail2Query {
  /**
   * endDate
   */
  endDate: string
  /**
   * startDate
   */
  startDate: string
  /**
   * userId
   */
  userId: string
}

/* **返回类型** */
export interface GetAppSettleSalaryEmployeeDetail2Response {
  /**
   * 认证类型 0当前发薪渠道不支持 1当前发薪渠道已认证
   */
  authType: number
  /**
   * 头像
   */
  avatar: string
  /**
   * 身份证
   */
  idcard: string
  /**
   * 手机号
   */
  mobile: string
  /**
   * 班组详情列表
   */
  teamList: {
    piece: number
    /**
     * 付款金额
     */
    salary: number
    /**
     * 结算id列表
     */
    settleIds: number[]
    teamId: number
    /**
     * 班组id
     */
    teamName: string
    /**
     * 工作时长
     */
    workHour: string
  }[]
  userId: number
  /**
   * 用户名称
   */
  userName: string
}

/* **请求函数** */
export async function getAppSettleSalaryEmployeeDetail2(query: GetAppSettleSalaryEmployeeDetail2Query): Promise<any> {
  return request(`/app/settle/salary/employee/detail2`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [1.1添加客户-扫码添加客户 ↗](http://fe.jiuworker.com:3000/project/29/interface/api/743)
 *
 * @分类 [APP-综合接口↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_64)
 * @标签 `APP-综合接口`
 * @请求头 `POST /app/cooperate/code/add`
 * @更新时间 `2024-08-23 16:22:02`
 */

/* **请求body类型** */
export interface PostAppCooperateCodeAddBody {
  id?: number
}

/* **返回类型** */
export type PostAppCooperateCodeAddResponse = boolean

/* **请求函数** */
export async function postAppCooperateCodeAdd(body: PostAppCooperateCodeAddBody): Promise<any> {
  return request(`/app/cooperate/code/add`, {
    method: Method.POST,

    data: body,
  })
}

/**
 * 接口 [1.2获取代理商信息↗](http://fe.jiuworker.com:3000/project/29/interface/api/2413)
 *
 * @分类 [APP-综合接口↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_64)
 * @标签 `APP-综合接口`
 * @请求头 `GET /app/cooperate/list`
 * @更新时间 `2024-05-15 14:32:02`
 */

/* **返回类型** */
export interface GetAppCooperateListResponse {
  id: number
  logo: string
  name: string
  username: string
}

/* **请求函数** */
export async function getAppCooperateList(): Promise<any> {
  return request(`/app/cooperate/list`, {
    method: Method.GET,
  })
}

/**
 * 接口 [1.2获取代理商信息↗](http://fe.jiuworker.com:3000/project/29/interface/api/2417)
 *
 * @分类 [APP-综合接口↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_64)
 * @标签 `APP-综合接口`
 * @请求头 `GET /app/agent/info`
 * @更新时间 `2024-08-23 16:22:01`
 */

/* **返回类型** */
export interface GetAppAgentInfoResponse {
  id: number
  logo: string
  name: string
  username: string
}

/* **请求函数** */
export async function getAppAgentInfo(): Promise<any> {
  return request(`/app/agent/info`, {
    method: Method.GET,
  })
}

/**
 * 接口 [10.考勤每日概览↗](http://fe.jiuworker.com:3000/project/29/interface/api/727)
 *
 * @分类 [APP-考勤管理↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_65)
 * @标签 `APP-考勤管理`
 * @请求头 `GET /app/attendadnce/daily/overview`
 * @更新时间 `2024-08-23 16:22:01`
 */

/* **请求query类型** */
export interface GetAppAttendadnceDailyOverviewQuery {
  /**
   * date
   */
  date: string
  /**
   * teamId
   */
  teamId: string
}

/* **返回类型** */
export interface GetAppAttendadnceDailyOverviewResponse {
  /**
   * 异常
   */
  attendanceAbnormal: number
  /**
   * 出勤人次
   */
  attendanceCount: number
  /**
   * 未出勤人次
   */
  notAttendanceCount: number
  /**
   * 计件
   */
  piece: number
  teamId: number
  teamName: string
  /**
   * 人次-今日排班人数
   */
  userCount: number
  /**
   * 计价方式 0:按小时 1:按天 2:按次
   */
  valuationType: number
  /**
   * 工时
   */
  workHour: string
}

/* **请求函数** */
export async function getAppAttendadnceDailyOverview(query: GetAppAttendadnceDailyOverviewQuery): Promise<any> {
  return request(`/app/attendadnce/daily/overview`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [22.已结束的班组-概览↗](http://fe.jiuworker.com:3000/project/29/interface/api/729)
 *
 * @分类 [APP-考勤管理↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_65)
 * @标签 `APP-考勤管理`
 * @请求头 `GET /app/attendadnce/detail/team/{teamId}`
 * @更新时间 `2024-08-23 16:22:02`
 */

/* **请求params类型** */
export interface GetAppAttendadnceDetailTeamByTeamIdParams {
  /**
   * teamId
   */
  teamId: string
}

/* **返回类型** */
export interface GetAppAttendadnceDetailTeamByTeamIdResponse {
  /**
   * 旷工
   */
  absenteeismCount: number
  /**
   * 出勤人次
   */
  dayCount: number
  /**
   * 早退
   */
  earlyCount: number
  /**
   * 班组人员数量
   */
  employeeCount: number
  /**
   * 缺卡
   */
  lackCardCount: number
  /**
   * 迟到
   */
  lateCount: number
  /**
   * 补卡
   */
  makeUpCardCount: number
  /**
   * 计件
   */
  piece: number
  teamId: number
  /**
   * 班组名称
   */
  teamName: string
  /**
   * 总天数
   */
  totalDayCount: number
  /**
   * 工时
   */
  workHour: string
}

/* **请求函数** */
export async function getAppAttendadnceDetailTeamByTeamId(
  params: GetAppAttendadnceDetailTeamByTeamIdParams,
): Promise<any> {
  return request(`/app/attendadnce/detail/team/${params.teamId}`, {
    method: Method.GET,
  })
}

/**
 * 接口 [14.修改记件↗](http://fe.jiuworker.com:3000/project/29/interface/api/731)
 *
 * @分类 [APP-考勤管理↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_65)
 * @标签 `APP-考勤管理`
 * @请求头 `GET /app/attendadnce/employee/piece/modify`
 * @更新时间 `2024-08-23 16:22:02`
 */

/* **请求query类型** */
export interface GetAppAttendadnceEmployeePieceModifyQuery {
  /**
   * attendanceId
   */
  attendanceId: string
  /**
   * piece
   */
  piece: string
}

/* **返回类型** */
export type GetAppAttendadnceEmployeePieceModifyResponse = boolean

/* **请求函数** */
export async function getAppAttendadnceEmployeePieceModify(
  query: GetAppAttendadnceEmployeePieceModifyQuery,
): Promise<any> {
  return request(`/app/attendadnce/employee/piece/modify`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [2.已结束概览↗](http://fe.jiuworker.com:3000/project/29/interface/api/733)
 *
 * @分类 [APP-考勤管理↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_65)
 * @标签 `APP-考勤管理`
 * @请求头 `GET /app/attendadnce/finish/overview`
 * @更新时间 `2024-08-23 16:22:02`
 */

/* **请求query类型** */
export interface GetAppAttendadnceFinishOverviewQuery {
  /**
   * pageNum
   */
  pageNum?: number
  /**
   * pageSize
   */
  pageSize?: number
}

/* **返回类型** */
export interface GetAppAttendadnceFinishOverviewResponse {
  data: {
    /**
     * 出勤人次
     */
    attendanceCount: number
    /**
     * 总天数
     */
    dayCount: number
    /**
     * 计件
     */
    piece: number
    teamId: number
    teamName: string
    /**
     * 工时
     */
    workHour: string
  }[]
  total: number
}

/* **请求函数** */
export async function getAppAttendadnceFinishOverview(query: GetAppAttendadnceFinishOverviewQuery): Promise<any> {
  return request(`/app/attendadnce/finish/overview`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [13.个人考勤记录↗](http://fe.jiuworker.com:3000/project/29/interface/api/735)
 *
 * @分类 [APP-考勤管理↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_65)
 * @标签 `APP-考勤管理`
 * @请求头 `GET /app/attendadnce/list/employee/month`
 * @更新时间 `2024-08-23 16:22:02`
 */

/* **请求query类型** */
export interface GetAppAttendadnceListEmployeeMonthQuery {
  /**
   * 月份 格式 2023-05-01
   */
  month?: string
  /**
   * teamId
   */
  teamId: string
  /**
   * userId
   */
  userId: string
}

/* **返回类型** */
export interface GetAppAttendadnceListEmployeeMonthResponse {
  /**
   * 异常
   */
  attendanceAbnormal: number
  /**
   * 出勤人次
   */
  attendanceCount: number
  /**
   * 月度考勤列表
   */
  attendanceList: {
    /**
     * 是否异常 0否1是
     */
    abnormal: number
    /**
     * 下班状态 1:正常 2:迟到 3:早退 4:缺卡 5:旷工 6:补卡 7:未打卡
     */
    afterStatus: number
    /**
     * 下班时间
     */
    afterTime: string
    /**
     * 日期
     */
    dayDate: string
    /**
     * 上班状态 1:正常 2:迟到 3:早退 4:缺卡 5:旷工 6:补卡 7:未打卡
     */
    moonStatus: number
    /**
     * 上班时间
     */
    moonTime: string
    /**
     * 计件
     */
    piece: number
    /**
     * 状态 1:正常 2:迟到 3:早退 4:缺卡 5:旷工 6:迟到/缺卡 7:早退/缺卡 8:迟到/早退
     */
    status: number
    /**
     * 工时
     */
    workHour: string
  }[]
  avatar: string
  mobile: string
  /**
   * 计件
   */
  piece: number
  teamId: number
  teamName: string
  /**
   * 总天数
   */
  totalDayCount: number
  /**
   * 人次
   */
  userCount: number
  userId: number
  userName: string
  /**
   * 工时
   */
  workHour: string
}

/* **请求函数** */
export async function getAppAttendadnceListEmployeeMonth(query: GetAppAttendadnceListEmployeeMonthQuery): Promise<any> {
  return request(`/app/attendadnce/list/employee/month`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [12.每日考勤-人员列表↗](http://fe.jiuworker.com:3000/project/29/interface/api/737)
 *
 * @分类 [APP-考勤管理↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_65)
 * @标签 `APP-考勤管理`
 * @请求头 `GET /app/attendadnce/list/team/daily`
 * @更新时间 `2024-08-23 16:22:02`
 */

/* **请求query类型** */
export interface GetAppAttendadnceListTeamDailyQuery {
  /**
   * date
   */
  date: string
  /**
   * pageNum
   */
  pageNum?: number
  /**
   * pageSize
   */
  pageSize?: number
  /**
   * 1:出勤 2:未出勤 3:异常
   */
  status?: string
  /**
   * teamId
   */
  teamId: string
}

/* **返回类型** */
export interface GetAppAttendadnceListTeamDailyResponse {
  data: {
    attendanceId: number
    /**
     * 考勤状态 1:正常 2:迟到 3:早退 4:缺卡 5:旷工 6:迟到/缺卡 7:早退/缺卡 8:迟到/早退 9:未打卡
     */
    attendanceStatus: number
    /**
     * 头像
     */
    avatar: string
    /**
     * 下班打卡时间
     */
    endTime: string
    /**
     * 岗位名称
     */
    jobName: string
    /**
     * 是否补卡 1是0否
     */
    makeUpCard: number
    /**
     * 手机号
     */
    mobile: string
    /**
     * 计件
     */
    piece: number
    /**
     * 上班打卡时间
     */
    startTime: string
    /**
     * 雇员id
     */
    teamEmployeeId: number
    teamId: number
    /**
     * 用户id
     */
    userId: number
    /**
     * 用户名称
     */
    userName: string
    /**
     * 工作时长
     */
    workHour: string
  }[]
  total: number
}

/* **请求函数** */
export async function getAppAttendadnceListTeamDaily(query: GetAppAttendadnceListTeamDailyQuery): Promise<any> {
  return request(`/app/attendadnce/list/team/daily`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [20.已结束的班组-员工考勤列表↗](http://fe.jiuworker.com:3000/project/29/interface/api/739)
 *
 * @分类 [APP-考勤管理↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_65)
 * @标签 `APP-考勤管理`
 * @请求头 `GET /app/attendadnce/list/team/employee`
 * @更新时间 `2024-08-23 16:22:02`
 */

/* **请求query类型** */
export interface GetAppAttendadnceListTeamEmployeeQuery {
  /**
   * pageNum
   */
  pageNum?: number
  /**
   * pageSize
   */
  pageSize?: number
  /**
   * teamId
   */
  teamId: string
}

/* **返回类型** */
export interface GetAppAttendadnceListTeamEmployeeResponse {
  data: {
    /**
     * 旷工
     */
    absenteeismCount: number
    /**
     * 用户头像
     */
    avatar: string
    /**
     * 出勤天数
     */
    dayCount: number
    /**
     * 早退
     */
    earlyCount: number
    /**
     * teamEmployeeId
     */
    id: number
    jobName: string
    /**
     * 缺卡
     */
    lackCardCount: number
    /**
     * 迟到
     */
    lateCount: number
    /**
     * 补卡
     */
    makeUpCardCount: number
    mobile: string
    /**
     * 计件
     */
    piece: number
    teamEmployeeId: number
    /**
     * 总天数
     */
    totalDayCount: number
    userId: number
    userName: string
    /**
     * 工时
     */
    workHour: string
  }[]
  total: number
}

/* **请求函数** */
export async function getAppAttendadnceListTeamEmployee(query: GetAppAttendadnceListTeamEmployeeQuery): Promise<any> {
  return request(`/app/attendadnce/list/team/employee`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [1.进行中概览↗](http://fe.jiuworker.com:3000/project/29/interface/api/741)
 *
 * @分类 [APP-考勤管理↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_65)
 * @标签 `APP-考勤管理`
 * @请求头 `GET /app/attendadnce/processing/overview`
 * @更新时间 `2024-08-23 16:22:02`
 */

/* **请求query类型** */
export interface GetAppAttendadnceProcessingOverviewQuery {
  /**
   * pageNum
   */
  pageNum?: number
  /**
   * pageSize
   */
  pageSize?: number
}

/* **返回类型** */
export interface GetAppAttendadnceProcessingOverviewResponse {
  data: {
    /**
     * 考勤异常
     */
    attendanceAbnormal: number
    /**
     * 出勤人次
     */
    attendanceCount: number
    /**
     * 计件
     */
    piece: number
    teamId: number
    teamName: string
    /**
     * 今日排班=出勤+旷工
     */
    userCount: number
    /**
     * 计价方式 0:按小时 1:按天 2:按次
     */
    valuationType: number
    /**
     * 工时
     */
    workHour: string
  }[]
  total: number
}

/* **请求函数** */
export async function getAppAttendadnceProcessingOverview(
  query: GetAppAttendadnceProcessingOverviewQuery,
): Promise<any> {
  return request(`/app/attendadnce/processing/overview`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [10.确认考勤↗](http://fe.jiuworker.com:3000/project/29/interface/api/2643)
 *
 * @分类 [APP-考勤管理↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_65)
 * @标签 `APP-考勤管理`
 * @请求头 `PUT /app/attendadnce/confirm`
 * @更新时间 `2024-08-23 16:22:01`
 */

/* **请求body类型** */
export interface PutAppAttendadnceConfirmBody {
  id?: number
}

/* **返回类型** */
export type PutAppAttendadnceConfirmResponse = boolean

/* **请求函数** */
export async function putAppAttendadnceConfirm(body: PutAppAttendadnceConfirmBody): Promise<any> {
  return request(`/app/attendadnce/confirm`, {
    method: Method.PUT,

    data: body,
  })
}

/**
 * 接口 [3.待确认概览↗](http://fe.jiuworker.com:3000/project/29/interface/api/2645)
 *
 * @分类 [APP-考勤管理↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_65)
 * @标签 `APP-考勤管理`
 * @请求头 `GET /app/attendadnce/confirm/overview`
 * @更新时间 `2024-08-23 16:22:01`
 */

/* **请求query类型** */
export interface GetAppAttendadnceConfirmOverviewQuery {
  /**
   * pageNum
   */
  pageNum?: number
  /**
   * pageSize
   */
  pageSize?: number
}

/* **返回类型** */
export interface GetAppAttendadnceConfirmOverviewResponse {
  data: {
    /**
     * 出勤人次
     */
    attendanceCount: number
    /**
     * 总天数
     */
    dayCount: number
    /**
     * 计件
     */
    piece: number
    teamId: number
    teamName: string
    /**
     * 工时
     */
    workHour: string
  }[]
  total: number
}

/* **请求函数** */
export async function getAppAttendadnceConfirmOverview(query: GetAppAttendadnceConfirmOverviewQuery): Promise<any> {
  return request(`/app/attendadnce/confirm/overview`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [11.报名用户-录用\/拒绝↗](http://fe.jiuworker.com:3000/project/29/interface/api/745)
 *
 * @分类 [APP-雇员信息↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_66)
 * @标签 `APP-雇员信息`
 * @请求头 `POST /app/employee/apply/accept`
 * @更新时间 `2024-08-23 16:22:02`
 */

/* **请求body类型** */
export interface PostAppEmployeeApplyAcceptBody {
  /**
   * 是否同意 1同意0拒绝
   */
  accept?: number
  /**
   * 用户id集合-teamEmployeeId
   */
  ids?: number[]
  /**
   * 拒绝原因
   */
  refuseReason?: string
}

/* **返回类型** */
export type PostAppEmployeeApplyAcceptResponse = boolean

/* **请求函数** */
export async function postAppEmployeeApplyAccept(body: PostAppEmployeeApplyAcceptBody): Promise<any> {
  return request(`/app/employee/apply/accept`, {
    method: Method.POST,

    data: body,
  })
}

/**
 * 接口 [10.报名列表↗](http://fe.jiuworker.com:3000/project/29/interface/api/747)
 *
 * @分类 [APP-雇员信息↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_66)
 * @标签 `APP-雇员信息`
 * @请求头 `GET /app/employee/apply/list`
 * @更新时间 `2024-08-23 16:22:02`
 */

/* **请求query类型** */
export interface GetAppEmployeeApplyListQuery {
  /**
   * gender
   */
  gender?: string
  /**
   * keyword
   */
  keyword?: string
  /**
   * maxAge
   */
  maxAge?: string
  /**
   * minAge
   */
  minAge?: string
  /**
   * pageNum
   */
  pageNum?: number
  /**
   * pageSize
   */
  pageSize?: number
  /**
   * 报名状态 1:待录用 2:已录用 3:已拒绝
   */
  status?: string
  /**
   * teamId
   */
  teamId?: string
  /**
   * 工作状态 0:待入职 1:在职 2:离职 3:已移除 4:已删除
   */
  workStatus?: string
}

/* **返回类型** */
export interface GetAppEmployeeApplyListResponse {
  data: {
    /**
     * 年龄
     */
    age: number
    /**
     * 报名时间
     */
    applyTime: string
    /**
     * 头像
     */
    avatar: string
    /**
     * 拉黑理由
     */
    blackRemark: string
    /**
     * 拉黑状态 0:未拉黑 1:已拉黑
     */
    blackStatus: number
    /**
     * 录用时间
     */
    employTime: string
    /**
     * 1:男，0:女
     */
    gender: number
    /**
     * 创建时间
     */
    gmtCreate: string
    gmtModified: string
    id: number
    /**
     * 身份证号码
     */
    idcard: string
    /**
     * 岗位id
     */
    jobId: number
    /**
     * 岗位名称
     */
    jobName: string
    /**
     * 离职时间
     */
    leaveTime: string
    /**
     * 手机号
     */
    mobile: string
    /**
     * 总计件
     */
    piece: number
    /**
     * 拒绝理由
     */
    refuseReason: string
    /**
     * 累计薪资
     */
    salary: number
    /**
     * 报名状态 1:待录用 2:已录用 3:已拒绝
     */
    status: number
    /**
     * 班组id
     */
    teamId: number
    /**
     * 班组名称
     */
    teamName: string
    /**
     * 用户id
     */
    userId: number
    /**
     * 用户名
     */
    userName: string
    /**
     * 总天数
     */
    workDays: number
    /**
     * 总工时
     */
    workHour: string
    /**
     * 工作状态 0:待入职 1:在职 2:离职 3:已移除 4:已删除
     */
    workStatus: number
    /**
     * 累计排班
     */
    workTimes: number
  }[]
  total: number
}

/* **请求函数** */
export async function getAppEmployeeApplyList(query: GetAppEmployeeApplyListQuery): Promise<any> {
  return request(`/app/employee/apply/list`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [21.拉黑求职者-基于信息-id传userId↗](http://fe.jiuworker.com:3000/project/29/interface/api/749)
 *
 * @分类 [APP-雇员信息↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_66)
 * @标签 `APP-雇员信息`
 * @请求头 `PUT /app/employee/block`
 * @更新时间 `2024-08-23 16:22:02`
 */

/* **请求body类型** */
export interface PutAppEmployeeBlockBody {
  id?: number
  remark?: string
}

/* **返回类型** */
export type PutAppEmployeeBlockResponse = boolean

/* **请求函数** */
export async function putAppEmployeeBlock(body: PutAppEmployeeBlockBody): Promise<any> {
  return request(`/app/employee/block`, {
    method: Method.PUT,

    data: body,
  })
}

/**
 * 接口 [21.拉黑求职者-基于身份证号↗](http://fe.jiuworker.com:3000/project/29/interface/api/751)
 *
 * @分类 [APP-雇员信息↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_66)
 * @标签 `APP-雇员信息`
 * @请求头 `PUT /app/employee/block/idcard`
 * @更新时间 `2024-08-23 16:22:02`
 */

/* **请求body类型** */
export interface PutAppEmployeeBlockIdcardBody {
  idcard?: string
  name?: string
  remark?: string
}

/* **返回类型** */
export type PutAppEmployeeBlockIdcardResponse = boolean

/* **请求函数** */
export async function putAppEmployeeBlockIdcard(body: PutAppEmployeeBlockIdcardBody): Promise<any> {
  return request(`/app/employee/block/idcard`, {
    method: Method.PUT,

    data: body,
  })
}

/**
 * 接口 [12.1 用户详情-历史记录上方概览↗](http://fe.jiuworker.com:3000/project/29/interface/api/753)
 *
 * @分类 [APP-雇员信息↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_66)
 * @标签 `APP-雇员信息`
 * @请求头 `GET /app/employee/detail/company/user/{userId}`
 * @更新时间 `2024-08-23 16:22:02`
 */

/* **请求params类型** */
export interface GetAppEmployeeDetailCompanyUserByUserIdParams {
  /**
   * userId
   */
  userId: string
}

/* **返回类型** */
export interface GetAppEmployeeDetailCompanyUserByUserIdResponse {
  /**
   * 总计件
   */
  piece: number
  /**
   * 累计薪资
   */
  salary: number
  /**
   * 总天数
   */
  workDays: number
  /**
   * 总工时
   */
  workHour: string
  /**
   * 累计排班
   */
  workTimes: number
}

/* **请求函数** */
export async function getAppEmployeeDetailCompanyUserByUserId(
  params: GetAppEmployeeDetailCompanyUserByUserIdParams,
): Promise<any> {
  return request(`/app/employee/detail/company/user/${params.userId}`, {
    method: Method.GET,
  })
}

/**
 * 接口 [12.1 用户详情-人才库\/黑名单↗](http://fe.jiuworker.com:3000/project/29/interface/api/755)
 *
 * @分类 [APP-雇员信息↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_66)
 * @标签 `APP-雇员信息`
 * @请求头 `GET /app/employee/detail/user/{userId}`
 * @更新时间 `2024-08-23 16:22:02`
 */

/* **请求params类型** */
export interface GetAppEmployeeDetailUserByUserIdParams {
  /**
   * userId
   */
  userId: string
}

/* **返回类型** */
export interface GetAppEmployeeDetailUserByUserIdResponse {
  /**
   * 年龄
   */
  age: number
  /**
   * 报名时间
   */
  applyTime: string
  /**
   * 头像
   */
  avatar: string
  /**
   * 拉黑理由
   */
  blackRemark: string
  /**
   * 拉黑状态 0:未拉黑 1:已拉黑
   */
  blackStatus: number
  /**
   * 录用时间
   */
  employTime: string
  /**
   * 1:男，0:女
   */
  gender: number
  /**
   * 创建时间
   */
  gmtCreate: string
  id: number
  /**
   * 身份证号码
   */
  idcard: string
  /**
   * 离职时间
   */
  leaveTime: string
  /**
   * 手机号
   */
  mobile: string
  /**
   * 总计件
   */
  piece: number
  /**
   * 累计薪资
   */
  salary: number
  /**
   * 用户id
   */
  userId: number
  /**
   * 用户名
   */
  userName: string
  /**
   * 总天数
   */
  workDays: number
  /**
   * 总工时
   */
  workHour: string
  /**
   * 工作状态 0:待入职 1:在职 2:离职 3:已移除 4:已删除
   */
  workStatus: number
  /**
   * 累计排班
   */
  workTimes: number
}

/* **请求函数** */
export async function getAppEmployeeDetailUserByUserId(params: GetAppEmployeeDetailUserByUserIdParams): Promise<any> {
  return request(`/app/employee/detail/user/${params.userId}`, {
    method: Method.GET,
  })
}

/**
 * 接口 [12.用户详情-报名\/在职\/离职↗](http://fe.jiuworker.com:3000/project/29/interface/api/757)
 *
 * @分类 [APP-雇员信息↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_66)
 * @标签 `APP-雇员信息`
 * @请求头 `GET /app/employee/detail/{teamEmployeeId}`
 * @更新时间 `2024-08-23 16:22:02`
 */

/* **请求params类型** */
export interface GetAppEmployeeDetailByTeamEmployeeIdParams {
  /**
   * teamEmployeeId
   */
  teamEmployeeId: string
}

/* **返回类型** */
export interface GetAppEmployeeDetailByTeamEmployeeIdResponse {
  /**
   * 年龄
   */
  age: number
  /**
   * 报名时间
   */
  applyTime: string
  /**
   * 头像
   */
  avatar: string
  /**
   * 拉黑理由
   */
  blackRemark: string
  /**
   * 拉黑状态 0:未拉黑 1:已拉黑
   */
  blackStatus: number
  /**
   * 录用时间
   */
  employTime: string
  /**
   * 1:男，0:女
   */
  gender: number
  /**
   * 创建时间
   */
  gmtCreate: string
  gmtModified: string
  id: number
  /**
   * 身份证号码
   */
  idcard: string
  /**
   * 岗位id
   */
  jobId: number
  /**
   * 岗位名称
   */
  jobName: string
  /**
   * 离职时间
   */
  leaveTime: string
  /**
   * 手机号
   */
  mobile: string
  /**
   * 总计件
   */
  piece: number
  /**
   * 拒绝理由
   */
  refuseReason: string
  /**
   * 累计薪资
   */
  salary: number
  /**
   * 报名状态 1:待录用 2:已录用 3:已拒绝
   */
  status: number
  /**
   * 班组id
   */
  teamId: number
  /**
   * 班组名称
   */
  teamName: string
  /**
   * 用户id
   */
  userId: number
  /**
   * 用户名
   */
  userName: string
  /**
   * 总天数
   */
  workDays: number
  /**
   * 总工时
   */
  workHour: string
  /**
   * 工作状态 0:待入职 1:在职 2:离职 3:已移除 4:已删除
   */
  workStatus: number
  /**
   * 累计排班
   */
  workTimes: number
}

/* **请求函数** */
export async function getAppEmployeeDetailByTeamEmployeeId(
  params: GetAppEmployeeDetailByTeamEmployeeIdParams,
): Promise<any> {
  return request(`/app/employee/detail/${params.teamEmployeeId}`, {
    method: Method.GET,
  })
}

/**
 * 接口 [14.离职-排班 teamEmployeeId↗](http://fe.jiuworker.com:3000/project/29/interface/api/759)
 *
 * @分类 [APP-雇员信息↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_66)
 * @标签 `APP-雇员信息`
 * @请求头 `PUT /app/employee/dimission`
 * @更新时间 `2024-08-23 16:22:02`
 */

/* **请求body类型** */
export interface PutAppEmployeeDimissionBody {
  id?: number
  remark?: string
}

/* **返回类型** */
export type PutAppEmployeeDimissionResponse = boolean

/* **请求函数** */
export async function putAppEmployeeDimission(body: PutAppEmployeeDimissionBody): Promise<any> {
  return request(`/app/employee/dimission`, {
    method: Method.PUT,

    data: body,
  })
}

/**
 * 接口 [13.灵活用工列表-↗](http://fe.jiuworker.com:3000/project/29/interface/api/761)
 *
 * @分类 [APP-雇员信息↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_66)
 * @标签 `APP-雇员信息`
 * @请求头 `GET /app/employee/list`
 * @更新时间 `2024-08-23 16:22:02`
 */

/* **请求query类型** */
export interface GetAppEmployeeListQuery {
  /**
   * gender
   */
  gender?: string
  /**
   * keyword
   */
  keyword?: string
  /**
   * maxAge
   */
  maxAge?: string
  /**
   * minAge
   */
  minAge?: string
  /**
   * pageNum
   */
  pageNum?: number
  /**
   * pageSize
   */
  pageSize?: number
  /**
   * 状态 1:在职人员 2:离职人员 3:黑名单
   */
  status?: string
  /**
   * teamId
   */
  teamId?: string
}

/* **返回类型** */
export interface GetAppEmployeeListResponse {
  data: {
    /**
     * 年龄
     */
    age: number
    /**
     * 报名时间
     */
    applyTime: string
    /**
     * 头像
     */
    avatar: string
    /**
     * 拉黑理由
     */
    blackRemark: string
    /**
     * 拉黑状态 0:未拉黑 1:已拉黑
     */
    blackStatus: number
    /**
     * 录用时间
     */
    employTime: string
    /**
     * 1:男，0:女
     */
    gender: number
    /**
     * 创建时间
     */
    gmtCreate: string
    gmtModified: string
    id: number
    /**
     * 身份证号码
     */
    idcard: string
    /**
     * 岗位id
     */
    jobId: number
    /**
     * 岗位名称
     */
    jobName: string
    /**
     * 离职时间
     */
    leaveTime: string
    /**
     * 手机号
     */
    mobile: string
    /**
     * 总计件
     */
    piece: number
    /**
     * 拒绝理由
     */
    refuseReason: string
    /**
     * 累计薪资
     */
    salary: number
    /**
     * 报名状态 1:待录用 2:已录用 3:已拒绝
     */
    status: number
    /**
     * 班组id
     */
    teamId: number
    /**
     * 班组名称
     */
    teamName: string
    /**
     * 用户id
     */
    userId: number
    /**
     * 用户名
     */
    userName: string
    /**
     * 总天数
     */
    workDays: number
    /**
     * 总工时
     */
    workHour: string
    /**
     * 工作状态 0:待入职 1:在职 2:离职 3:已移除 4:已删除
     */
    workStatus: number
    /**
     * 累计排班
     */
    workTimes: number
  }[]
  total: number
}

/* **请求函数** */
export async function getAppEmployeeList(query: GetAppEmployeeListQuery): Promise<any> {
  return request(`/app/employee/list`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [40.下拉筛选↗](http://fe.jiuworker.com:3000/project/29/interface/api/763)
 *
 * @分类 [APP-雇员信息↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_66)
 * @标签 `APP-雇员信息`
 * @请求头 `GET /app/employee/list/select`
 * @更新时间 `2024-08-23 16:22:02`
 */

/* **请求query类型** */
export interface GetAppEmployeeListSelectQuery {
  /**
   * name
   */
  name?: string
  /**
   * pageNum
   */
  pageNum?: number
  /**
   * pageSize
   */
  pageSize?: number
  /**
   * 1:正常 2:离职 3:黑名单 4:已删除
   */
  stats?: string
  /**
   * teamId
   */
  teamId?: string
}

/* **返回类型** */
export interface GetAppEmployeeListSelectResponse {
  data: {
    id: number
    /**
     * 班组名称
     */
    name: string
  }[]
  total: number
}

/* **请求函数** */
export async function getAppEmployeeListSelect(query: GetAppEmployeeListSelectQuery): Promise<any> {
  return request(`/app/employee/list/select`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [12.用户详情-工作记录↗](http://fe.jiuworker.com:3000/project/29/interface/api/765)
 *
 * @分类 [APP-雇员信息↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_66)
 * @标签 `APP-雇员信息`
 * @请求头 `GET /app/employee/list/team/record`
 * @更新时间 `2024-08-23 16:22:02`
 */

/* **请求query类型** */
export interface GetAppEmployeeListTeamRecordQuery {
  /**
   * pageNum
   */
  pageNum?: number
  /**
   * pageSize
   */
  pageSize?: number
  /**
   * teamEmployeeId
   */
  teamEmployeeId?: string
  /**
   * userId
   */
  userId?: string
}

/* **返回类型** */
export interface GetAppEmployeeListTeamRecordResponse {
  data: {
    /**
     * 年龄
     */
    age: number
    /**
     * 头像
     */
    avatar: string
    /**
     * 结束时间
     */
    endDate: string
    /**
     * 1:男，0:女
     */
    gender: number
    /**
     * 创建（报名）时间
     */
    gmtCreate: string
    gmtModified: string
    id: number
    /**
     * 身份证号码
     */
    idcard: string
    /**
     * 岗位id
     */
    jobId: number
    /**
     * 岗位名称
     */
    jobName: string
    /**
     * 手机号
     */
    mobile: string
    /**
     * 总计件
     */
    piece: number
    /**
     * 累计薪资
     */
    salary: number
    /**
     * 开始时间
     */
    startDate: string
    /**
     * 报名状态 1:待录用 2:已录用 3:已拒绝
     */
    status: number
    teamEmployId: number
    /**
     * 班组id
     */
    teamId: number
    /**
     * 班组名称
     */
    teamName: string
    /**
     * 用户id
     */
    userId: number
    /**
     * 用户名
     */
    userName: string
    /**
     * 总天数
     */
    workDays: number
    /**
     * 总工时
     */
    workHour: number
    /**
     * 工作状态 0:待入职 1:在职 2:离职 3:已移除 4:已删除
     */
    workStatus: number
    /**
     * 累计排班
     */
    workTimes: number
  }[]
  total: number
}

/* **请求函数** */
export async function getAppEmployeeListTeamRecord(query: GetAppEmployeeListTeamRecordQuery): Promise<any> {
  return request(`/app/employee/list/team/record`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [15.移除用户-排班列表 teamEmployeeId↗](http://fe.jiuworker.com:3000/project/29/interface/api/767)
 *
 * @分类 [APP-雇员信息↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_66)
 * @标签 `APP-雇员信息`
 * @请求头 `PUT /app/employee/remove`
 * @更新时间 `2024-08-23 16:22:02`
 */

/* **请求body类型** */
export interface PutAppEmployeeRemoveBody {
  id?: number
}

/* **返回类型** */
export type PutAppEmployeeRemoveResponse = boolean

/* **请求函数** */
export async function putAppEmployeeRemove(body: PutAppEmployeeRemoveBody): Promise<any> {
  return request(`/app/employee/remove`, {
    method: Method.PUT,

    data: body,
  })
}

/**
 * 接口 [20.人才库↗](http://fe.jiuworker.com:3000/project/29/interface/api/769)
 *
 * @分类 [APP-雇员信息↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_66)
 * @标签 `APP-雇员信息`
 * @请求头 `GET /app/employee/talent/list`
 * @更新时间 `2024-08-23 16:22:02`
 */

/* **请求query类型** */
export interface GetAppEmployeeTalentListQuery {
  /**
   * gender
   */
  gender?: string
  /**
   * keyword
   */
  keyword?: string
  /**
   * maxAge
   */
  maxAge?: string
  /**
   * minAge
   */
  minAge?: string
  /**
   * pageNum
   */
  pageNum?: number
  /**
   * pageSize
   */
  pageSize?: number
  /**
   * teamId
   */
  teamId?: string
}

/* **返回类型** */
export interface GetAppEmployeeTalentListResponse {
  data: {
    /**
     * 年龄
     */
    age: number
    /**
     * 头像
     */
    avatar: string
    /**
     * 劳务公司id
     */
    companyId: number
    /**
     * 1:男，0:女
     */
    gender: number
    id: number
    /**
     * 身份证号码
     */
    idcard: string
    /**
     * 手机号
     */
    mobile: string
    /**
     * 计件
     */
    piece: string
    /**
     * 累计薪资
     */
    salary: number
    /**
     * 1:正常 2:离职 3:黑名单 4:已删除
     */
    status: number
    /**
     * 用户id
     */
    userId: number
    /**
     * 用户名
     */
    userName: string
    /**
     * 总天数
     */
    workDays: string
    /**
     * 总工时
     */
    workHour: string
    /**
     * 累计排班
     */
    workTimes: string
  }[]
  total: number
}

/* **请求函数** */
export async function getAppEmployeeTalentList(query: GetAppEmployeeTalentListQuery): Promise<any> {
  return request(`/app/employee/talent/list`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [1.排班-手动添加↗](http://fe.jiuworker.com:3000/project/29/interface/api/771)
 *
 * @分类 [APP-雇员信息↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_66)
 * @标签 `APP-雇员信息`
 * @请求头 `POST /app/employee/team/add`
 * @更新时间 `2024-08-23 16:22:02`
 */

/* **请求body类型** */
export interface PostAppEmployeeTeamAddBody {
  /**
   * 头像
   */
  avatar?: string
  /**
   * 身份证号码
   */
  idcard?: string
  /**
   * 岗位id
   */
  jobId?: number
  /**
   * 手机号
   */
  mobile?: string
  /**
   * 是否跳过风险校验 0否1是
   */
  skip?: number
  /**
   * 不再核验 0否1是
   */
  skipNotConfirm?: number
  /**
   * 班组id
   */
  teamId?: number
  /**
   * 用户名
   */
  userName?: string
}

/* **返回类型** */
export interface PostAppEmployeeTeamAddResponse {
  /**
   * 0:成功 1:账户余额不足 2:跳过风险校验
   */
  riskStatus: number
}

/* **请求函数** */
export async function postAppEmployeeTeamAdd(body: PostAppEmployeeTeamAddBody): Promise<any> {
  return request(`/app/employee/team/add`, {
    method: Method.POST,

    data: body,
  })
}

/**
 * 接口 [2.排班-人才库导入↗](http://fe.jiuworker.com:3000/project/29/interface/api/773)
 *
 * @分类 [APP-雇员信息↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_66)
 * @标签 `APP-雇员信息`
 * @请求头 `POST /app/employee/team/import`
 * @更新时间 `2024-08-23 16:22:02`
 */

/* **请求body类型** */
export interface PostAppEmployeeTeamImportBody {
  /**
   * 岗位id
   */
  jobId?: number
  /**
   * 是否跳过风险校验 0否1是
   */
  skip?: number
  /**
   * 不再核验 0否1是
   */
  skipNotConfirm?: number
  /**
   * 用户id
   */
  userIds?: number[]
}

/* **返回类型** */
export interface PostAppEmployeeTeamImportResponse {
  /**
   * 总人数
   */
  count: number
  /**
   * 失败人数
   */
  failCount: number
  /**
   * 失败列表
   */
  list: {
    /**
     * 身份证号
     */
    idcard: string
    /**
     * 姓名
     */
    name: string
    /**
     * 失败原因
     */
    reason: string
  }[]
  /**
   * 0:成功 1:账户余额不足 2:跳过风险校验
   */
  riskStatus: number
  /**
   * 成功人数
   */
  successCount: number
}

/* **请求函数** */
export async function postAppEmployeeTeamImport(body: PostAppEmployeeTeamImportBody): Promise<any> {
  return request(`/app/employee/team/import`, {
    method: Method.POST,

    data: body,
  })
}

/**
 * 接口 [4.排班-班组排班情况↗](http://fe.jiuworker.com:3000/project/29/interface/api/775)
 *
 * @分类 [APP-雇员信息↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_66)
 * @标签 `APP-雇员信息`
 * @请求头 `GET /app/employee/team/list`
 * @更新时间 `2024-08-23 16:22:02`
 */

/* **请求query类型** */
export interface GetAppEmployeeTeamListQuery {
  /**
   * pageNum
   */
  pageNum?: number
  /**
   * pageSize
   */
  pageSize?: number
  /**
   * teamId
   */
  teamId?: string
  /**
   * 工作状态 0:待入职 1:在职 2:离职 3:已移除 4:已删除
   */
  workStatus?: string
}

/* **返回类型** */
export interface GetAppEmployeeTeamListResponse {
  data: {
    /**
     * 年龄
     */
    age: number
    /**
     * 报名时间
     */
    applyTime: string
    /**
     * 头像
     */
    avatar: string
    /**
     * 拉黑理由
     */
    blackRemark: string
    /**
     * 拉黑状态 0:未拉黑 1:已拉黑
     */
    blackStatus: number
    /**
     * 录用时间
     */
    employTime: string
    /**
     * 1:男，0:女
     */
    gender: number
    /**
     * 创建时间
     */
    gmtCreate: string
    gmtModified: string
    id: number
    /**
     * 身份证号码
     */
    idcard: string
    /**
     * 岗位id
     */
    jobId: number
    /**
     * 岗位名称
     */
    jobName: string
    /**
     * 离职时间
     */
    leaveTime: string
    /**
     * 手机号
     */
    mobile: string
    /**
     * 总计件
     */
    piece: number
    /**
     * 拒绝理由
     */
    refuseReason: string
    /**
     * 累计薪资
     */
    salary: number
    /**
     * 报名状态 1:待录用 2:已录用 3:已拒绝
     */
    status: number
    /**
     * 班组id
     */
    teamId: number
    /**
     * 班组名称
     */
    teamName: string
    /**
     * 用户id
     */
    userId: number
    /**
     * 用户名
     */
    userName: string
    /**
     * 总天数
     */
    workDays: number
    /**
     * 总工时
     */
    workHour: string
    /**
     * 工作状态 0:待入职 1:在职 2:离职 3:已移除 4:已删除
     */
    workStatus: number
    /**
     * 累计排班
     */
    workTimes: number
  }[]
  total: number
}

/* **请求函数** */
export async function getAppEmployeeTeamList(query: GetAppEmployeeTeamListQuery): Promise<any> {
  return request(`/app/employee/team/list`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [30.结算-班组人员列表↗](http://fe.jiuworker.com:3000/project/29/interface/api/777)
 *
 * @分类 [APP-雇员信息↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_66)
 * @标签 `APP-雇员信息`
 * @请求头 `GET /app/employee/team/list/settle`
 * @更新时间 `2024-08-23 16:22:02`
 */

/* **请求query类型** */
export interface GetAppEmployeeTeamListSettleQuery {
  /**
   * pageNum
   */
  pageNum?: number
  /**
   * pageSize
   */
  pageSize?: number
  /**
   * teamId
   */
  teamId: string
}

/* **返回类型** */
export interface GetAppEmployeeTeamListSettleResponse {
  data: {
    /**
     * 年龄
     */
    age: number
    /**
     * 报名时间
     */
    applyTime: string
    /**
     * 头像
     */
    avatar: string
    /**
     * 拉黑理由
     */
    blackRemark: string
    /**
     * 拉黑状态 0:未拉黑 1:已拉黑
     */
    blackStatus: number
    /**
     * 录用时间
     */
    employTime: string
    /**
     * 1:男，0:女
     */
    gender: number
    /**
     * 创建时间
     */
    gmtCreate: string
    gmtModified: string
    id: number
    /**
     * 身份证号码
     */
    idcard: string
    /**
     * 岗位id
     */
    jobId: number
    /**
     * 岗位名称
     */
    jobName: string
    /**
     * 离职时间
     */
    leaveTime: string
    /**
     * 手机号
     */
    mobile: string
    /**
     * 总计件
     */
    piece: number
    /**
     * 拒绝理由
     */
    refuseReason: string
    /**
     * 累计薪资
     */
    salary: number
    /**
     * 报名状态 1:待录用 2:已录用 3:已拒绝
     */
    status: number
    /**
     * 班组id
     */
    teamId: number
    /**
     * 班组名称
     */
    teamName: string
    /**
     * 用户id
     */
    userId: number
    /**
     * 用户名
     */
    userName: string
    /**
     * 总天数
     */
    workDays: number
    /**
     * 总工时
     */
    workHour: string
    /**
     * 工作状态 0:待入职 1:在职 2:离职 3:已移除 4:已删除
     */
    workStatus: number
    /**
     * 累计排班
     */
    workTimes: number
  }[]
  total: number
}

/* **请求函数** */
export async function getAppEmployeeTeamListSettle(query: GetAppEmployeeTeamListSettleQuery): Promise<any> {
  return request(`/app/employee/team/list/settle`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [3.排班-进度↗](http://fe.jiuworker.com:3000/project/29/interface/api/779)
 *
 * @分类 [APP-雇员信息↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_66)
 * @标签 `APP-雇员信息`
 * @请求头 `GET /app/employee/team/overview/{teamId}`
 * @更新时间 `2024-08-23 16:22:02`
 */

/* **请求params类型** */
export interface GetAppEmployeeTeamOverviewByTeamIdParams {
  /**
   * teamId
   */
  teamId: string
}

/* **返回类型** */
export interface GetAppEmployeeTeamOverviewByTeamIdResponse {
  /**
   * 已排人员人数
   */
  employeeNum: number
  /**
   * 招聘进度
   */
  jobProcess: {
    /**
     * 已招人数
     */
    jobHiredNum: number
    jobId: number
    /**
     * 岗位名称
     */
    jobName: string
    /**
     * 招聘需求人数
     */
    jobNum: number
  }[]
  /**
   * 排班进度 0-100
   */
  recruitProgress: number
}

/* **请求函数** */
export async function getAppEmployeeTeamOverviewByTeamId(
  params: GetAppEmployeeTeamOverviewByTeamIdParams,
): Promise<any> {
  return request(`/app/employee/team/overview/${params.teamId}`, {
    method: Method.GET,
  })
}

/**
 * 接口 [22.从黑名单中移除-id传userId↗](http://fe.jiuworker.com:3000/project/29/interface/api/781)
 *
 * @分类 [APP-雇员信息↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_66)
 * @标签 `APP-雇员信息`
 * @请求头 `PUT /app/employee/unblock`
 * @更新时间 `2024-08-23 16:22:02`
 */

/* **请求body类型** */
export interface PutAppEmployeeUnblockBody {
  id?: number
}

/* **返回类型** */
export type PutAppEmployeeUnblockResponse = boolean

/* **请求函数** */
export async function putAppEmployeeUnblock(body: PutAppEmployeeUnblockBody): Promise<any> {
  return request(`/app/employee/unblock`, {
    method: Method.PUT,

    data: body,
  })
}

/**
 * 接口 [11. 保险详情↗](http://fe.jiuworker.com:3000/project/29/interface/api/975)
 *
 * @分类 [保险↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_68)
 * @标签 `保险`
 * @请求头 `GET /insurance/detail/{id}`
 * @更新时间 `2024-08-23 16:22:08`
 */

/* **请求params类型** */
export interface GetInsuranceDetailByIdParams {
  /**
   * id
   */
  id: string
}

/* **返回类型** */
export interface GetInsuranceDetailByIdResponse {
  /**
   * 描述图片
   */
  descPic: string
  /**
   * 描述
   */
  description: string
  gmtCreate: string
  gmtModified: string
  id: number
  /**
   * 保险公司名称
   */
  insuranceCompanyName: string
  /**
   * 劳务公司id
   */
  laborCompanyId: number
  /**
   * 保险名称
   */
  name: string
  /**
   * 保险价格
   */
  price: number
  /**
   * 状态 1:正常，0:禁用
   */
  status: number
}

/* **请求函数** */
export async function getInsuranceDetailById(params: GetInsuranceDetailByIdParams): Promise<any> {
  return request(`/insurance/detail/${params.id}`, {
    method: Method.GET,
  })
}

/**
 * 接口 [10.保险介绍列表↗](http://fe.jiuworker.com:3000/project/29/interface/api/977)
 *
 * @分类 [保险↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_68)
 * @标签 `保险`
 * @请求头 `GET /insurance/list`
 * @更新时间 `2024-08-23 16:22:08`
 */

/* **请求query类型** */
export interface GetInsuranceListQuery {
  /**
   * name
   */
  name?: string
  /**
   * pageNum
   */
  pageNum?: number
  /**
   * pageSize
   */
  pageSize?: number
}

/* **返回类型** */
export interface GetInsuranceListResponse {
  data: {
    /**
     * 描述图片
     */
    descPic: string
    /**
     * 描述
     */
    description: string
    gmtCreate: string
    gmtModified: string
    id: number
    /**
     * 保险公司名称
     */
    insuranceCompanyName: string
    /**
     * 劳务公司id
     */
    laborCompanyId: number
    /**
     * 保险名称
     */
    name: string
    /**
     * 保险价格
     */
    price: number
    /**
     * 状态 1:正常，0:禁用
     */
    status: number
  }[]
  total: number
}

/* **请求函数** */
export async function getInsuranceList(query: GetInsuranceListQuery): Promise<any> {
  return request(`/insurance/list`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [3.保险记录列表↗](http://fe.jiuworker.com:3000/project/29/interface/api/979)
 *
 * @分类 [保险↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_68)
 * @标签 `保险`
 * @请求头 `GET /insurance/list/record`
 * @更新时间 `2024-08-23 16:22:08`
 */

/* **请求query类型** */
export interface GetInsuranceListRecordQuery {
  /**
   * endDate
   */
  endDate?: string
  /**
   * keyword
   */
  keyword?: string
  /**
   * pageNum
   */
  pageNum?: number
  /**
   * pageSize
   */
  pageSize?: number
  /**
   * startDate
   */
  startDate?: string
  /**
   * 状态 1:正常，2:失败 3:投保中
   */
  status?: string
  /**
   * teamId
   */
  teamId?: string
}

/* **返回类型** */
export interface GetInsuranceListRecordResponse {
  data: {
    /**
     * 年龄
     */
    age: number
    /**
     * 头像
     */
    avatar: string
    /**
     * 1:男，0:女
     */
    gender: number
    id: number
    /**
     * 身份证号
     */
    idcard: string
    insuranceId: number
    /**
     * 险种
     */
    insuranceName: string
    /**
     * 保单号码
     */
    insuranceNo: string
    /**
     * 保费价格
     */
    insurancePrice: number
    /**
     * 投保时间
     */
    insuranceTime: string
    /**
     * 岗位id
     */
    jobId: number
    /**
     * 岗位名称
     */
    jobName: string
    /**
     * 手机号
     */
    mobile: string
    /**
     * 失败原因
     */
    reason: string
    /**
     * 状态 1:正常，2:失败 3:投保中
     */
    status: number
    /**
     * 班组id
     */
    teamId: number
    /**
     * 班组名称
     */
    teamName: string
    /**
     * 用户id
     */
    userId: number
    /**
     * 用户名
     */
    userName: string
  }[]
  total: number
}

/* **请求函数** */
export async function getInsuranceListRecord(query: GetInsuranceListRecordQuery): Promise<any> {
  return request(`/insurance/list/record`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [10.1保险-筛选列表↗](http://fe.jiuworker.com:3000/project/29/interface/api/981)
 *
 * @分类 [保险↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_68)
 * @标签 `保险`
 * @请求头 `GET /insurance/list/select`
 * @更新时间 `2024-08-23 16:22:08`
 */

/* **请求query类型** */
export interface GetInsuranceListSelectQuery {
  /**
   * name
   */
  name?: string
  /**
   * pageNum
   */
  pageNum?: number
  /**
   * pageSize
   */
  pageSize?: number
}

/* **返回类型** */
export interface GetInsuranceListSelectResponse {
  data: {
    id: number
    /**
     * 班组名称
     */
    name: string
  }[]
  total: number
}

/* **请求函数** */
export async function getInsuranceListSelect(query: GetInsuranceListSelectQuery): Promise<any> {
  return request(`/insurance/list/select`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [1.保险概览↗](http://fe.jiuworker.com:3000/project/29/interface/api/983)
 *
 * @分类 [保险↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_68)
 * @标签 `保险`
 * @请求头 `GET /insurance/overview`
 * @更新时间 `2024-08-23 16:22:08`
 */

/* **返回类型** */
export interface GetInsuranceOverviewResponse {
  /**
   * 本月投保次数
   */
  monthInsurance: number
  /**
   * 本月投保金额
   */
  monthInsuranceAmount: number
  /**
   * 今日投保次数
   */
  todayInsurance: number
  /**
   * 今日投保金额
   */
  todayInsuranceAmount: number
  /**
   * 累计投保次数
   */
  totalInsurance: number
  /**
   * 累计投保金额
   */
  totalInsuranceAmount: number
}

/* **请求函数** */
export async function getInsuranceOverview(): Promise<any> {
  return request(`/insurance/overview`, {
    method: Method.GET,
  })
}

/**
 * 接口 [4.重新投保↗](http://fe.jiuworker.com:3000/project/29/interface/api/985)
 *
 * @分类 [保险↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_68)
 * @标签 `保险`
 * @请求头 `POST /insurance/retry`
 * @更新时间 `2024-08-23 16:22:08`
 */

/* **请求body类型** */
export interface PostInsuranceRetryBody {
  id?: number
}

/* **返回类型** */
export type PostInsuranceRetryResponse = boolean

/* **请求函数** */
export async function postInsuranceRetry(body: PostInsuranceRetryBody): Promise<any> {
  return request(`/insurance/retry`, {
    method: Method.POST,

    data: body,
  })
}

/**
 * 接口 [41.补充社会统一信用编码↗](http://fe.jiuworker.com:3000/project/29/interface/api/987)
 *
 * @分类 [劳务公司信息↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_69)
 * @标签 `劳务公司信息`
 * @请求头 `PUT /labor/company/append/cert`
 * @更新时间 `2024-08-23 16:22:08`
 */

/* **请求body类型** */
export interface PutLaborCompanyAppendCertBody {
  /**
   * 企业名称
   */
  certName?: string
  /**
   * 企业统一信用代码执照
   */
  certNo?: string
  id?: number
}

/* **返回类型** */
export type PutLaborCompanyAppendCertResponse = boolean

/* **请求函数** */
export async function putLaborCompanyAppendCert(body: PutLaborCompanyAppendCertBody): Promise<any> {
  return request(`/labor/company/append/cert`, {
    method: Method.PUT,

    data: body,
  })
}

/**
 * 接口 [12.修改支付密码↗](http://fe.jiuworker.com:3000/project/29/interface/api/989)
 *
 * @分类 [劳务公司信息↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_69)
 * @标签 `劳务公司信息`
 * @请求头 `POST /labor/company/change/pay/password`
 * @更新时间 `2024-08-23 16:22:08`
 */

/* **请求body类型** */
export interface PostLaborCompanyChangePayPasswordBody {
  password?: string
  token?: string
}

/* **返回类型** */
export type PostLaborCompanyChangePayPasswordResponse = boolean

/* **请求函数** */
export async function postLaborCompanyChangePayPassword(body: PostLaborCompanyChangePayPasswordBody): Promise<any> {
  return request(`/labor/company/change/pay/password`, {
    method: Method.POST,

    data: body,
  })
}

/**
 * 接口 [10.发送修改支付密码验证码↗](http://fe.jiuworker.com:3000/project/29/interface/api/991)
 *
 * @分类 [劳务公司信息↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_69)
 * @标签 `劳务公司信息`
 * @请求头 `GET /labor/company/change/pay/password/code`
 * @更新时间 `2024-08-23 16:22:08`
 */

/* **请求query类型** */
export interface GetLaborCompanyChangePayPasswordCodeQuery {
  /**
   * code
   */
  code?: string
}

/* **返回类型** */
export type GetLaborCompanyChangePayPasswordCodeResponse = string

/* **请求函数** */
export async function getLaborCompanyChangePayPasswordCode(
  query: GetLaborCompanyChangePayPasswordCodeQuery,
): Promise<any> {
  return request(`/labor/company/change/pay/password/code`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [11.修改支付密码验证码-校验↗](http://fe.jiuworker.com:3000/project/29/interface/api/993)
 *
 * @分类 [劳务公司信息↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_69)
 * @标签 `劳务公司信息`
 * @请求头 `GET /labor/company/change/pay/password/code/valid`
 * @更新时间 `2024-08-23 16:22:08`
 */

/* **请求query类型** */
export interface GetLaborCompanyChangePayPasswordCodeValidQuery {
  /**
   * validCode
   */
  validCode: string
}

/* **返回类型** */
export interface GetLaborCompanyChangePayPasswordCodeValidResponse {
  token: string
}

/* **请求函数** */
export async function getLaborCompanyChangePayPasswordCodeValid(
  query: GetLaborCompanyChangePayPasswordCodeValidQuery,
): Promise<any> {
  return request(`/labor/company/change/pay/password/code/valid`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [5.删除劳务公司↗](http://fe.jiuworker.com:3000/project/29/interface/api/995)
 *
 * @分类 [劳务公司信息↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_69)
 * @标签 `劳务公司信息`
 * @请求头 `DELETE /labor/company/delete/{id}`
 * @更新时间 `2023-12-18 16:56:07`
 */

/* **请求params类型** */
export interface DeleteLaborCompanyDeleteByIdParams {
  /**
   * id
   */
  id: string
}

/* **返回类型** */
export type DeleteLaborCompanyDeleteByIdResponse = boolean

/* **请求函数** */
export async function deleteLaborCompanyDeleteById(params: DeleteLaborCompanyDeleteByIdParams): Promise<any> {
  return request(`/labor/company/delete/${params.id}`, {
    method: Method.DELETE,
  })
}

/**
 * 接口 [2.编辑劳务公司↗](http://fe.jiuworker.com:3000/project/29/interface/api/997)
 *
 * @分类 [劳务公司信息↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_69)
 * @标签 `劳务公司信息`
 * @请求头 `PUT /labor/company/edit`
 * @更新时间 `2024-08-23 16:22:08`
 */

/* **请求body类型** */
export interface PutLaborCompanyEditBody {
  address?: {
    address?: string
    latitude?: string
    longitude?: string
    region?: string[]
  }
  /**
   * 企业营业执照
   */
  businessLicense?: string
  /**
   * 企业营业执照
   */
  certNo?: string
  /**
   * 邮箱
   */
  email?: string
  gmtCreate?: string
  gmtModified?: string
  id?: number
  /**
   * 手机号
   */
  mobile?: string
  /**
   * 劳务公司名称
   */
  name?: string
  /**
   * 联系人
   */
  userName?: string
}

/* **返回类型** */
export type PutLaborCompanyEditResponse = boolean

/* **请求函数** */
export async function putLaborCompanyEdit(body: PutLaborCompanyEditBody): Promise<any> {
  return request(`/labor/company/edit`, {
    method: Method.PUT,

    data: body,
  })
}

/**
 * 接口 [41.获取签约结果↗](http://fe.jiuworker.com:3000/project/29/interface/api/999)
 *
 * @分类 [劳务公司信息↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_69)
 * @标签 `劳务公司信息`
 * @请求头 `GET /labor/company/insurance/sign/result`
 * @更新时间 `2024-08-23 16:22:08`
 */

/* **返回类型** */
export interface GetLaborCompanyInsuranceSignResultResponse {
  code: string
  msg: string
  result: number
  success: boolean
}

/* **请求函数** */
export async function getLaborCompanyInsuranceSignResult(): Promise<any> {
  return request(`/labor/company/insurance/sign/result`, {
    method: Method.GET,
  })
}

/**
 * 接口 [40.保险账户签约地址↗](http://fe.jiuworker.com:3000/project/29/interface/api/1001)
 *
 * @分类 [劳务公司信息↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_69)
 * @标签 `劳务公司信息`
 * @请求头 `GET /labor/company/insurance/sign/url`
 * @更新时间 `2024-08-23 16:22:08`
 */

/* **返回类型** */
export interface GetLaborCompanyInsuranceSignUrlResponse {
  /**
   * 0未签约 1已签约 2缺少社会统一信用代码
   */
  status: number
  /**
   * 签约地址
   */
  url: string
}

/* **请求函数** */
export async function getLaborCompanyInsuranceSignUrl(): Promise<any> {
  return request(`/labor/company/insurance/sign/url`, {
    method: Method.GET,
  })
}

/**
 * 接口 [4.分页查询↗](http://fe.jiuworker.com:3000/project/29/interface/api/1003)
 *
 * @分类 [劳务公司信息↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_69)
 * @标签 `劳务公司信息`
 * @请求头 `GET /labor/company/page`
 * @更新时间 `2023-12-18 16:56:07`
 */

/* **请求query类型** */
export interface GetLaborCompanyPageQuery {
  /**
   * name
   */
  name?: string
  /**
   * pageNum
   */
  pageNum?: number
  /**
   * pageSize
   */
  pageSize?: number
}

/* **返回类型** */
export interface GetLaborCompanyPageResponse {
  data: {
    address: {
      address: string
      latitude: string
      longitude: string
      region: string[]
    }
    /**
     * 企业营业执照
     */
    businessLicense: string
    /**
     * 公司名称-营业执照-保险业务
     */
    certName: string
    /**
     * 社会统一信用代码
     */
    certNo: string
    /**
     * 邮箱
     */
    email: string
    /**
     * 支付账户登录名称
     */
    externalLogonId: string
    /**
     * 支付账户名称-记账本企业名称
     */
    financeAccountName: string
    gmtCreate: string
    gmtModified: string
    id: number
    /**
     * 手机号
     */
    mobile: string
    /**
     * 劳务公司名称
     */
    name: string
    /**
     * 签约状态
     */
    payChannel: string
    /**
     * 签约状态
     */
    sign: number
    /**
     * 联系人
     */
    userName: string
  }[]
  total: number
}

/* **请求函数** */
export async function getLaborCompanyPage(query: GetLaborCompanyPageQuery): Promise<any> {
  return request(`/labor/company/page`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [支付账户获取充值链接↗](http://fe.jiuworker.com:3000/project/29/interface/api/1005)
 *
 * @分类 [劳务公司信息↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_69)
 * @标签 `劳务公司信息`
 * @请求头 `POST /labor/company/payAccount/charge`
 * @更新时间 `2024-08-23 16:22:08`
 */

/* **请求body类型** */
export interface PostLaborCompanyPayAccountChargeBody {
  accountId?: number
  outBizNo?: string
  remark?: string
  transAmount?: string
}

/* **返回类型** */
export type PostLaborCompanyPayAccountChargeResponse = string

/* **请求函数** */
export async function postLaborCompanyPayAccountCharge(body: PostLaborCompanyPayAccountChargeBody): Promise<any> {
  return request(`/labor/company/payAccount/charge`, {
    method: Method.POST,

    data: body,
  })
}

/**
 * 接口 [isSign↗](http://fe.jiuworker.com:3000/project/29/interface/api/1007)
 *
 * @分类 [劳务公司信息↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_69)
 * @标签 `劳务公司信息`
 * @请求头 `GET /labor/company/payAccount/isSign`
 * @更新时间 `2024-08-23 16:22:08`
 */

/* **返回类型** */
export type GetLaborCompanyPayAccountIsSignResponse = boolean

/* **请求函数** */
export async function getLaborCompanyPayAccountIsSign(): Promise<any> {
  return request(`/labor/company/payAccount/isSign`, {
    method: Method.GET,
  })
}

/**
 * 接口 [获取签约地址↗](http://fe.jiuworker.com:3000/project/29/interface/api/1009)
 *
 * @分类 [劳务公司信息↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_69)
 * @标签 `劳务公司信息`
 * @请求头 `POST /labor/company/payAccount/preSign`
 * @更新时间 `2024-08-23 16:22:08`
 */

/* **请求body类型** */
export interface PostLaborCompanyPayAccountPreSignBody {
  externalLogonId?: string
  financeAccountId?: number
  financeAccountName?: string
  sign?: boolean
}

/* **返回类型** */
export type PostLaborCompanyPayAccountPreSignResponse = string

/* **请求函数** */
export async function postLaborCompanyPayAccountPreSign(body: PostLaborCompanyPayAccountPreSignBody): Promise<any> {
  return request(`/labor/company/payAccount/preSign`, {
    method: Method.POST,

    data: body,
  })
}

/**
 * 接口 [是否开通记账本↗](http://fe.jiuworker.com:3000/project/29/interface/api/1011)
 *
 * @分类 [劳务公司信息↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_69)
 * @标签 `劳务公司信息`
 * @请求头 `GET /labor/company/payAccount/query`
 * @更新时间 `2024-08-23 16:22:08`
 */

/* **返回类型** */
export type GetLaborCompanyPayAccountQueryResponse = boolean

/* **请求函数** */
export async function getLaborCompanyPayAccountQuery(): Promise<any> {
  return request(`/labor/company/payAccount/query`, {
    method: Method.GET,
  })
}

/**
 * 接口 [获取余额↗](http://fe.jiuworker.com:3000/project/29/interface/api/1013)
 *
 * @分类 [劳务公司信息↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_69)
 * @标签 `劳务公司信息`
 * @请求头 `GET /labor/company/payAccount/queryBalance`
 * @更新时间 `2024-08-23 16:22:08`
 */

/* **返回类型** */
export type GetLaborCompanyPayAccountQueryBalanceResponse = string

/* **请求函数** */
export async function getLaborCompanyPayAccountQueryBalance(): Promise<any> {
  return request(`/labor/company/payAccount/queryBalance`, {
    method: Method.GET,
  })
}

/**
 * 接口 [获取批量账单↗](http://fe.jiuworker.com:3000/project/29/interface/api/1015)
 *
 * @分类 [劳务公司信息↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_69)
 * @标签 `劳务公司信息`
 * @请求头 `GET /labor/company/payAccount/queryBatchBill`
 * @更新时间 `2024-08-23 16:22:09`
 */

/* **请求query类型** */
export interface GetLaborCompanyPayAccountQueryBatchBillQuery {
  accountId?: string
  /**
   * 账单状态 SUCCESS 支付成功  WAIT_PAY 等待支付 FAIL 支付失败 CLOSED 已关闭
   */
  billStatus?: string
  /**
   * 账单类型，SALARY 薪水 PAYMENT  商务付款 ACCOUNT_BOOK_CHARGE  记账本充值
   */
  billType?: string
  endAmount?: string
  endTime?: string
  pageNum?: number
  pageSize?: number
  payChannel?: string
  /**
   *  查询标识主体类型, PAYEE 收款 PAYER 付款 ALL 全部
   */
  queryIdentityObjectType?: string
  startAmount?: string
  startTime?: string
}

/* **返回类型** */
export interface GetLaborCompanyPayAccountQueryBatchBillResponse {
  data: {
    billAmount: string
    billStatus: string
    billType: string
    originFundFlowId: string
    originOrderId: string
    originOrderTitle: string
    originOutBizNo: string
    otherAccountId: string
    otherAccountName: string
    payChannel: string
    payDate: string
    remark: string
  }[]
  total: number
}

/* **请求函数** */
export async function getLaborCompanyPayAccountQueryBatchBill(
  query: GetLaborCompanyPayAccountQueryBatchBillQuery,
): Promise<any> {
  return request(`/labor/company/payAccount/queryBatchBill`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [确认签约↗](http://fe.jiuworker.com:3000/project/29/interface/api/1017)
 *
 * @分类 [劳务公司信息↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_69)
 * @标签 `劳务公司信息`
 * @请求头 `POST /labor/company/payAccount/signConfirm`
 * @更新时间 `2024-08-23 16:22:09`
 */

/* **返回类型** */
export interface PostLaborCompanyPayAccountSignConfirmResponse {
  code: string
  msg: string
  result: number
  success: boolean
}

/* **请求函数** */
export async function postLaborCompanyPayAccountSignConfirm(): Promise<any> {
  return request(`/labor/company/payAccount/signConfirm`, {
    method: Method.POST,
  })
}

/**
 * 接口 [取消签约↗](http://fe.jiuworker.com:3000/project/29/interface/api/1019)
 *
 * @分类 [劳务公司信息↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_69)
 * @标签 `劳务公司信息`
 * @请求头 `POST /labor/company/payAccount/unSign`
 * @更新时间 `2024-08-23 16:22:09`
 */

/* **返回类型** */
export interface PostLaborCompanyPayAccountUnSignResponse {
  code: string
  msg: string
  result: number
  success: boolean
}

/* **请求函数** */
export async function postLaborCompanyPayAccountUnSign(): Promise<any> {
  return request(`/labor/company/payAccount/unSign`, {
    method: Method.POST,
  })
}

/**
 * 接口 [3.查询劳务公司-基本信息↗](http://fe.jiuworker.com:3000/project/29/interface/api/1021)
 *
 * @分类 [劳务公司信息↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_69)
 * @标签 `劳务公司信息`
 * @请求头 `GET /labor/company/query`
 * @更新时间 `2024-08-23 16:22:09`
 */

/* **返回类型** */
export interface GetLaborCompanyQueryResponse {
  address: {
    address: string
    latitude: string
    longitude: string
    region: string[]
  }
  /**
   * 企业营业执照
   */
  businessLicense: string
  /**
   * 公司名称-营业执照-保险业务
   */
  certName: string
  /**
   * 社会统一信用代码
   */
  certNo: string
  /**
   * 邮箱
   */
  email: string
  /**
   * 支付账户登录名称
   */
  externalLogonId: string
  /**
   * 支付账户名称-记账本企业名称
   */
  financeAccountName: string
  gmtCreate: string
  gmtModified: string
  id: number
  location: {
    address: string
    latitude: string
    longitude: string
    region: string[]
  }
  /**
   * 手机号
   */
  mobile: string
  /**
   * 劳务公司名称
   */
  name: string
  /**
   * 签约状态
   */
  payChannel: string
  /**
   * 签约状态
   */
  sign: number
  /**
   * 联系人
   */
  userName: string
}

/* **请求函数** */
export async function getLaborCompanyQuery(): Promise<any> {
  return request(`/labor/company/query`, {
    method: Method.GET,
  })
}

/**
 * 接口 [获取微信支付余额↗](http://fe.jiuworker.com:3000/project/29/interface/api/1433)
 *
 * @分类 [劳务公司信息↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_69)
 * @标签 `劳务公司信息`
 * @请求头 `GET /labor/company/wechat/pay/queryBalance`
 * @更新时间 `2023-12-08 14:16:05`
 */

/* **返回类型** */
export type GetLaborCompanyWechatPayQueryBalanceResponse = string

/* **请求函数** */
export async function getLaborCompanyWechatPayQueryBalance(): Promise<any> {
  return request(`/labor/company/wechat/pay/queryBalance`, {
    method: Method.GET,
  })
}

/**
 * 接口 [获取微信支付是否签约↗](http://fe.jiuworker.com:3000/project/29/interface/api/1435)
 *
 * @分类 [劳务公司信息↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_69)
 * @标签 `劳务公司信息`
 * @请求头 `POST /labor/company/wechat/pay/sign`
 * @更新时间 `2023-12-08 14:16:05`
 */

/* **返回类型** */
export type PostLaborCompanyWechatPaySignResponse = boolean

/* **请求函数** */
export async function postLaborCompanyWechatPaySign(): Promise<any> {
  return request(`/labor/company/wechat/pay/sign`, {
    method: Method.POST,
  })
}

/**
 * 接口 [获取账户余额及当前发薪渠道↗](http://fe.jiuworker.com:3000/project/29/interface/api/1469)
 *
 * @分类 [劳务公司信息↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_69)
 * @标签 `劳务公司信息`
 * @请求头 `GET /labor/company/pay/balance/all`
 * @更新时间 `2024-08-23 16:22:08`
 */

/* **返回类型** */
export interface GetLaborCompanyPayBalanceAllResponse {
  /**
   * 支付宝账户余额
   */
  aliAmount: number
  /**
   * 支付宝签约状态 0否1是
   */
  aliSign: number
  /**
   * 支付渠道 WECHAT ALIPAY
   */
  payChannel: string
  /**
   * 微信账户余额
   */
  wechatAmount: number
  /**
   * 微工卡签约状态 0否1是
   */
  wechatSign: number
}

/* **请求函数** */
export async function getLaborCompanyPayBalanceAll(): Promise<any> {
  return request(`/labor/company/pay/balance/all`, {
    method: Method.GET,
  })
}

/**
 * 接口 [4.同意合作关系↗](http://fe.jiuworker.com:3000/project/29/interface/api/905)
 *
 * @分类 [合作对象管理-用工单位↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_70)
 * @标签 `合作对象管理-用工单位`
 * @请求头 `PUT /cooperate/accept`
 * @更新时间 `2024-08-23 16:22:05`
 */

/* **请求body类型** */
export interface PutCooperateAcceptBody {
  /**
   * 是否同意 1同意0拒绝
   */
  accept?: number
  id?: number
  /**
   * 拒绝原因
   */
  refuseReason?: string
}

/* **返回类型** */
export type PutCooperateAcceptResponse = boolean

/* **请求函数** */
export async function putCooperateAccept(body: PutCooperateAcceptBody): Promise<any> {
  return request(`/cooperate/accept`, {
    method: Method.PUT,

    data: body,
  })
}

/**
 * 接口 [6.删除合作关系↗](http://fe.jiuworker.com:3000/project/29/interface/api/907)
 *
 * @分类 [合作对象管理-用工单位↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_70)
 * @标签 `合作对象管理-用工单位`
 * @请求头 `DELETE /cooperate/delete/{id}`
 * @更新时间 `2024-08-23 16:22:05`
 */

/* **请求params类型** */
export interface DeleteCooperateDeleteByIdParams {
  /**
   * id
   */
  id: string
}

/* **返回类型** */
export type DeleteCooperateDeleteByIdResponse = boolean

/* **请求函数** */
export async function deleteCooperateDeleteById(params: DeleteCooperateDeleteByIdParams): Promise<any> {
  return request(`/cooperate/delete/${params.id}`, {
    method: Method.DELETE,
  })
}

/**
 * 接口 [2.编辑账号↗](http://fe.jiuworker.com:3000/project/29/interface/api/909)
 *
 * @分类 [合作对象管理-用工单位↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_70)
 * @标签 `合作对象管理-用工单位`
 * @请求头 `PUT /cooperate/edit`
 * @更新时间 `2024-08-23 16:22:05`
 */

/* **请求body类型** */
export interface PutCooperateEditBody {
  /**
   * 详细地址
   */
  address?: string
  /**
   * 申请时间
   */
  applyTime?: string
  /**
   * 邮箱
   */
  email?: string
  /**
   * 用工单位id
   */
  employerCompanyId?: number
  id?: number
  /**
   * 手机号
   */
  mobile?: string
  /**
   * 用工单位（客户）名称
   */
  name?: string
  /**
   * 拒绝理由
   */
  refuseReason?: string
  /**
   * 状态 1正常 2被拒绝 3拒绝 4停用 5被停用 6未激活-待用工确认 7待通过-申请列表
   */
  status?: number
  /**
   * 联系人
   */
  userName?: string
}

/* **返回类型** */
export type PutCooperateEditResponse = boolean

/* **请求函数** */
export async function putCooperateEdit(body: PutCooperateEditBody): Promise<any> {
  return request(`/cooperate/edit`, {
    method: Method.PUT,

    data: body,
  })
}

/**
 * 接口 [3.启用\/禁用合作关系↗](http://fe.jiuworker.com:3000/project/29/interface/api/911)
 *
 * @分类 [合作对象管理-用工单位↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_70)
 * @标签 `合作对象管理-用工单位`
 * @请求头 `PUT /cooperate/enable`
 * @更新时间 `2024-08-23 16:22:05`
 */

/* **请求body类型** */
export interface PutCooperateEnableBody {
  /**
   * 启用状态 1启用0禁用
   */
  enable?: number
  id?: number
}

/* **返回类型** */
export type PutCooperateEnableResponse = boolean

/* **请求函数** */
export async function putCooperateEnable(body: PutCooperateEnableBody): Promise<any> {
  return request(`/cooperate/enable`, {
    method: Method.PUT,

    data: body,
  })
}

/**
 * 接口 [5.查询账号列表↗](http://fe.jiuworker.com:3000/project/29/interface/api/913)
 *
 * @分类 [合作对象管理-用工单位↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_70)
 * @标签 `合作对象管理-用工单位`
 * @请求头 `GET /cooperate/list`
 * @更新时间 `2024-08-23 16:22:05`
 */

/* **请求query类型** */
export interface GetCooperateListQuery {
  /**
   * mobile
   */
  mobile?: string
  /**
   * name
   */
  name?: string
  /**
   * pageNum
   */
  pageNum?: number
  /**
   * pageSize
   */
  pageSize?: number
  /**
   * 状态 1正常 2被拒绝 3拒绝 4停用 5被停用 6未激活-待用工确认 7待通过-申请列表
   */
  status?: string
  /**
   * userName
   */
  userName?: string
}

/* **返回类型** */
export interface GetCooperateListResponse {
  data: {
    /**
     * 详细地址
     */
    address: string
    /**
     * 申请时间
     */
    applyTime: string
    /**
     * 邮箱
     */
    email: string
    /**
     * 用工单位id
     */
    employerCompanyId: number
    id: number
    /**
     * 手机号
     */
    mobile: string
    /**
     * 用工单位（客户）名称
     */
    name: string
    /**
     * 拒绝理由
     */
    refuseReason: string
    /**
     * 状态 1正常 2被拒绝 3拒绝 4停用 5被停用 6未激活-待用工确认 7待通过-申请列表
     */
    status: number
    /**
     * 联系人
     */
    userName: string
  }[]
  total: number
}

/* **请求函数** */
export async function getCooperateList(query: GetCooperateListQuery): Promise<any> {
  return request(`/cooperate/list`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [5.1查询公司列表-筛选↗](http://fe.jiuworker.com:3000/project/29/interface/api/915)
 *
 * @分类 [合作对象管理-用工单位↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_70)
 * @标签 `合作对象管理-用工单位`
 * @请求头 `GET /cooperate/list/select`
 * @更新时间 `2024-08-23 16:22:05`
 */

/* **请求query类型** */
export interface GetCooperateListSelectQuery {
  /**
   * name
   */
  name?: string
  /**
   * pageNum
   */
  pageNum?: number
  /**
   * pageSize
   */
  pageSize?: number
}

/* **返回类型** */
export interface GetCooperateListSelectResponse {
  data: {
    id: number
    /**
     * 班组名称
     */
    name: string
  }[]
  total: number
}

/* **请求函数** */
export async function getCooperateListSelect(query: GetCooperateListSelectQuery): Promise<any> {
  return request(`/cooperate/list/select`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [4.同意合作关系-酒店id↗](http://fe.jiuworker.com:3000/project/29/interface/api/2593)
 *
 * @分类 [合作对象管理-用工单位↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_70)
 * @标签 `合作对象管理-用工单位`
 * @请求头 `PUT /cooperate/accept/enter`
 * @更新时间 `2024-08-23 16:22:05`
 */

/* **请求body类型** */
export interface PutCooperateAcceptEnterBody {
  /**
   * 是否同意 1同意0拒绝
   */
  accept?: number
  id?: number
  /**
   * 拒绝原因
   */
  refuseReason?: string
}

/* **返回类型** */
export type PutCooperateAcceptEnterResponse = boolean

/* **请求函数** */
export async function putCooperateAcceptEnter(body: PutCooperateAcceptEnterBody): Promise<any> {
  return request(`/cooperate/accept/enter`, {
    method: Method.PUT,

    data: body,
  })
}

/**
 * 接口 [1.生成二维码位置↗](http://fe.jiuworker.com:3000/project/29/interface/api/1023)
 *
 * @分类 [扫码登陆相关↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_72)
 * @标签 `扫码登陆相关`
 * @请求头 `GET /login/out/code/image`
 * @更新时间 `2024-08-23 16:22:09`
 */

/* **返回类型** */
export interface GetLoginOutCodeImageResponse {
  /**
   * secret
   */
  secret: string
  /**
   * 根据该值生成二维码
   */
  url: string
}

/* **请求函数** */
export async function getLoginOutCodeImage(): Promise<any> {
  return request(`/login/out/code/image`, {
    method: Method.GET,
  })
}

/**
 * 接口 [3.查询扫码生成的token，这个接口需要前端轮询调用↗](http://fe.jiuworker.com:3000/project/29/interface/api/1025)
 *
 * @分类 [扫码登陆相关↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_72)
 * @标签 `扫码登陆相关`
 * @请求头 `GET /login/out/code/research`
 * @更新时间 `2024-08-23 16:22:09`
 */

/* **请求query类型** */
export interface GetLoginOutCodeResearchQuery {
  /**
   * secret
   */
  secret: string
}

/* **返回类型** */
export interface GetLoginOutCodeResearchResponse {
  laborList: {
    companyId: number
    companyName: string
  }[]
  name: string
  openId: string
  phone: string
  token: string
}

/* **请求函数** */
export async function getLoginOutCodeResearch(query: GetLoginOutCodeResearchQuery): Promise<any> {
  return request(`/login/out/code/research`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [2.扫码生成token↗](http://fe.jiuworker.com:3000/project/29/interface/api/1027)
 *
 * @分类 [扫码登陆相关↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_72)
 * @标签 `扫码登陆相关`
 * @请求头 `GET /login/out/code/token`
 * @更新时间 `2024-08-23 16:22:09`
 */

/* **请求query类型** */
export interface GetLoginOutCodeTokenQuery {
  /**
   * secret
   */
  secret: string
}

/* **返回类型** */
export type GetLoginOutCodeTokenResponse = boolean

/* **请求函数** */
export async function getLoginOutCodeToken(query: GetLoginOutCodeTokenQuery): Promise<any> {
  return request(`/login/out/code/token`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [图片地址↗](http://fe.jiuworker.com:3000/project/29/interface/api/955)
 *
 * @分类 [文件管理↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_73)
 * @标签 `文件管理`
 * @请求头 `POST /file/oss/url`
 * @更新时间 `2024-08-23 16:22:08`
 */

/* **返回类型** */
export type PostFileOssUrlResponse = string

/* **请求函数** */
export async function postFileOssUrl(): Promise<any> {
  return request(`/file/oss/url`, {
    method: Method.POST,
  })
}

/**
 * 接口 [班组pdf信息↗](http://fe.jiuworker.com:3000/project/29/interface/api/957)
 *
 * @分类 [文件管理↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_73)
 * @标签 `文件管理`
 * @请求头 `GET /file/pdf/info/{teamId}`
 * @更新时间 `2024-08-23 16:22:08`
 */

/* **请求params类型** */
export interface GetFilePdfInfoByTeamIdParams {
  /**
   * teamId
   */
  teamId: string
}

/* **返回类型** */
export type GetFilePdfInfoByTeamIdResponse = any

/* **请求函数** */
export async function getFilePdfInfoByTeamId(params: GetFilePdfInfoByTeamIdParams): Promise<any> {
  return request(`/file/pdf/info/${params.teamId}`, {
    method: Method.GET,
  })
}

/**
 * 接口 [OSS签权接口↗](http://fe.jiuworker.com:3000/project/29/interface/api/959)
 *
 * @分类 [文件管理↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_73)
 * @标签 `文件管理`
 * @请求头 `GET /file/signature`
 * @更新时间 `2024-08-23 16:22:08`
 */

/* **请求query类型** */
export interface GetFileSignatureQuery {
  /**
   * superKey
   */
  superKey: string
}

/* **返回类型** */
export interface GetFileSignatureResponse {
  accessId: string
  dir: string
  expire: string
  host: string
  policy: string
  signature: string
}

/* **请求函数** */
export async function getFileSignature(query: GetFileSignatureQuery): Promise<any> {
  return request(`/file/signature`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [OSS签权接口↗](http://fe.jiuworker.com:3000/project/29/interface/api/961)
 *
 * @分类 [文件管理↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_73)
 * @标签 `文件管理`
 * @请求头 `GET /file/signature/oss`
 * @更新时间 `2024-08-23 16:22:08`
 */

/* **请求query类型** */
export interface GetFileSignatureOssQuery {
  /**
   * superKey
   */
  superKey: string
}

/* **返回类型** */
export interface GetFileSignatureOssResponse {
  accessId: string
  dir: string
  expire: string
  host: string
  policy: string
  signature: string
}

/* **请求函数** */
export async function getFileSignatureOss(query: GetFileSignatureOssQuery): Promise<any> {
  return request(`/file/signature/oss`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [1.创建班组↗](http://fe.jiuworker.com:3000/project/29/interface/api/1173)
 *
 * @分类 [班组信息↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_74)
 * @标签 `班组信息`
 * @请求头 `POST /team/add`
 * @更新时间 `2024-08-23 16:22:12`
 */

/* **请求body类型** */
export interface PostTeamAddBody {
  /**
   *  报名截止时间
   */
  deadline?: string
  deviceList?: {
    deviceId?: number
    deviceName?: string
  }[]
  endDate?: string
  endTime?: string
  id?: number
  /**
   * 投保险种
   */
  insuranceId?: number
  jobs?: {
    /**
     * 最大年龄
     */
    ageMax?: number
    /**
     * 最小年龄
     */
    ageMin?: number
    /**
     * 计划用工数量/女
     */
    femaleNum?: number
    id?: number
    /**
     * 岗位id
     */
    jobId?: number
    /**
     * 计划用工数量/男
     */
    maleNum?: number
    /**
     * 岗位名称
     */
    name?: string
    /**
     * 计划用工数量
     */
    num?: number
    /**
     * 其它要求 json
     */
    other?: string
    /**
     * 用工/单价
     */
    price?: number
    rooms?: {
      /**
       * 房间id
       */
      id?: number
      name?: string
      num?: number
      originalPrice?: number
      price?: number
    }[]
    /**
     * 班组id
     */
    teamId?: number
    /**
     * 计价方式 0:按小时 1:按天 2:按次
     */
    valuationType?: number
  }[]
  /**
   * 班组长id
   */
  leaderId?: string
  location?: {
    address?: string
    latitude?: string
    longitude?: string
    region?: string[]
  }
  /**
   * 是否发送到劳务市场 0否1是
   */
  market?: number
  /**
   * 班组名称
   */
  name?: string
  /**
   * 用工计划id
   */
  orderId?: number
  /**
   * 报名二维码
   */
  qrcode?: string
  /**
   * 休息时长 小时
   */
  restTime?: number
  startDate?: string
  startTime?: string
  /**
   * 计价方式 0:按小时 1:按天 2:按次
   */
  valuationType?: number
}

/* **返回类型** */
export type PostTeamAddResponse = boolean

/* **请求函数** */
export async function postTeamAdd(body: PostTeamAddBody): Promise<any> {
  return request(`/team/add`, {
    method: Method.POST,

    data: body,
  })
}

/**
 * 接口 [10.确认考勤↗](http://fe.jiuworker.com:3000/project/29/interface/api/1175)
 *
 * @分类 [班组信息↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_74)
 * @标签 `班组信息`
 * @请求头 `PUT /team/confirm`
 * @更新时间 `2024-08-23 16:22:12`
 */

/* **请求body类型** */
export interface PutTeamConfirmBody {
  id?: number
}

/* **返回类型** */
export type PutTeamConfirmResponse = boolean

/* **请求函数** */
export async function putTeamConfirm(body: PutTeamConfirmBody): Promise<any> {
  return request(`/team/confirm`, {
    method: Method.PUT,

    data: body,
  })
}

/**
 * 接口 [2.详情↗](http://fe.jiuworker.com:3000/project/29/interface/api/1177)
 *
 * @分类 [班组信息↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_74)
 * @标签 `班组信息`
 * @请求头 `GET /team/detail/{id}`
 * @更新时间 `2024-08-23 16:22:12`
 */

/* **请求params类型** */
export interface GetTeamDetailByIdParams {
  /**
   * id
   */
  id: string
}

/* **返回类型** */
export interface GetTeamDetailByIdResponse {
  companyId: number
  /**
   *  报名截止时间
   */
  deadline: string
  /**
   * 设备列表
   */
  deviceList: {
    deviceId: number
    deviceName: string
  }[]
  /**
   * 用工单位id
   */
  employerCompanyId: number
  /**
   * 用工单位名称
   */
  employerCompanyName: string
  endDate: string
  endTime: string
  /**
   * 超额报名比例限制
   */
  excessRatio: number
  gmtCreate: string
  gmtModified: string
  id: number
  /**
   * 订单预估收入
   */
  income: number
  /**
   * 投保险种
   */
  insuranceId: number
  /**
   * 投保险种名称
   */
  insuranceName: string
  /**
   * 保险预估支出
   */
  insurePayment: number
  /**
   * 岗位列表
   */
  jobs: {
    /**
     * 最大年龄
     */
    ageMax: number
    /**
     * 最小年龄
     */
    ageMin: number
    femaleNum: number
    id: number
    /**
     * 岗位id-用工计划中
     */
    jobId: number
    maleNum: number
    /**
     * 岗位名称
     */
    name: string
    /**
     * 用工计划id
     */
    num: number
    /**
     * 其它要求-录用确认规则引擎
     */
    other: string
    /**
     * 单价
     */
    price: number
    rooms: {
      /**
       * 房间id
       */
      id: number
      name: string
      num: number
      originalPrice: number
      price: number
    }[]
    /**
     * 班组id
     */
    teamId: number
    /**
     * 计价方式 0:按小时 1:按天 2:按次
     */
    valuationType: number
  }[]
  /**
   * 领班id
   */
  leaderId: number
  /**
   * 领班手机号
   */
  leaderName: string
  location: {
    address: string
    latitude: string
    longitude: string
    region: string[]
  }
  market: number
  /**
   * 班组名称
   */
  name: string
  /**
   * 用工计划id
   */
  orderId: number
  /**
   * 用工计划
   */
  orderName: string
  /**
   * 订单编号/工单号
   */
  orderNo: string
  /**
   * 劳务预估支出
   */
  payment: number
  planId: number
  /**
   * 利润
   */
  profit: number
  /**
   * 报名二维码
   */
  qrcode: string
  /**
   * 休息时长 小时
   */
  restTime: number
  source: string
  startDate: string
  startTime: string
  /**
   * 班组状态 1待开始 2进行中 3已结束 4已作废
   */
  status: number
  /**
   * 计价方式 0:按小时 1:按天 2:按次
   */
  valuationType: number
}

/* **请求函数** */
export async function getTeamDetailById(params: GetTeamDetailByIdParams): Promise<any> {
  return request(`/team/detail/${params.id}`, {
    method: Method.GET,
  })
}

/**
 * 接口 [7.班组作废↗](http://fe.jiuworker.com:3000/project/29/interface/api/1179)
 *
 * @分类 [班组信息↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_74)
 * @标签 `班组信息`
 * @请求头 `PUT /team/disable`
 * @更新时间 `2024-08-23 16:22:12`
 */

/* **请求body类型** */
export interface PutTeamDisableBody {
  id?: number
}

/* **返回类型** */
export type PutTeamDisableResponse = boolean

/* **请求函数** */
export async function putTeamDisable(body: PutTeamDisableBody): Promise<any> {
  return request(`/team/disable`, {
    method: Method.PUT,

    data: body,
  })
}

/**
 * 接口 [2.编辑班组↗](http://fe.jiuworker.com:3000/project/29/interface/api/1181)
 *
 * @分类 [班组信息↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_74)
 * @标签 `班组信息`
 * @请求头 `PUT /team/edit`
 * @更新时间 `2024-08-23 16:22:12`
 */

/* **请求body类型** */
export interface PutTeamEditBody {
  /**
   *  报名截止时间
   */
  deadline?: string
  deviceList?: {
    deviceId?: number
    deviceName?: string
  }[]
  endDate?: string
  endTime?: string
  id?: number
  /**
   * 投保险种
   */
  insuranceId?: number
  jobs?: {
    /**
     * 最大年龄
     */
    ageMax?: number
    /**
     * 最小年龄
     */
    ageMin?: number
    /**
     * 计划用工数量/女
     */
    femaleNum?: number
    id?: number
    /**
     * 岗位id
     */
    jobId?: number
    /**
     * 计划用工数量/男
     */
    maleNum?: number
    /**
     * 岗位名称
     */
    name?: string
    /**
     * 计划用工数量
     */
    num?: number
    /**
     * 其它要求 json
     */
    other?: string
    /**
     * 用工/单价
     */
    price?: number
    rooms?: {
      /**
       * 房间id
       */
      id?: number
      name?: string
      num?: number
      originalPrice?: number
      price?: number
    }[]
    /**
     * 班组id
     */
    teamId?: number
    /**
     * 计价方式 0:按小时 1:按天 2:按次
     */
    valuationType?: number
  }[]
  /**
   * 班组长id
   */
  leaderId?: string
  location?: {
    address?: string
    latitude?: string
    longitude?: string
    region?: string[]
  }
  /**
   * 是否发送到劳务市场 0否1是
   */
  market?: number
  /**
   * 班组名称
   */
  name?: string
  /**
   * 用工计划id
   */
  orderId?: number
  /**
   * 报名二维码
   */
  qrcode?: string
  /**
   * 休息时长 小时
   */
  restTime?: number
  startDate?: string
  startTime?: string
  /**
   * 计价方式 0:按小时 1:按天 2:按次
   */
  valuationType?: number
}

/* **返回类型** */
export type PutTeamEditResponse = boolean

/* **请求函数** */
export async function putTeamEdit(body: PutTeamEditBody): Promise<any> {
  return request(`/team/edit`, {
    method: Method.PUT,

    data: body,
  })
}

/**
 * 接口 [6.保险设置↗](http://fe.jiuworker.com:3000/project/29/interface/api/1183)
 *
 * @分类 [班组信息↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_74)
 * @标签 `班组信息`
 * @请求头 `POST /team/insurance`
 * @更新时间 `2024-08-23 16:22:12`
 */

/* **请求body类型** */
export interface PostTeamInsuranceBody {
  id?: number
  /**
   * 保险id
   */
  insuranceId?: number
  location?: {
    address?: string
    latitude?: string
    longitude?: string
    region?: string[]
  }
}

/* **返回类型** */
export type PostTeamInsuranceResponse = boolean

/* **请求函数** */
export async function postTeamInsurance(body: PostTeamInsuranceBody): Promise<any> {
  return request(`/team/insurance`, {
    method: Method.POST,

    data: body,
  })
}

/**
 * 接口 [5.班组长↗](http://fe.jiuworker.com:3000/project/29/interface/api/1185)
 *
 * @分类 [班组信息↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_74)
 * @标签 `班组信息`
 * @请求头 `POST /team/leader`
 * @更新时间 `2024-08-23 16:22:12`
 */

/* **请求body类型** */
export interface PostTeamLeaderBody {
  /**
   * 班组长联系方式
   */
  accountId?: string
  id?: number
}

/* **返回类型** */
export type PostTeamLeaderResponse = boolean

/* **请求函数** */
export async function postTeamLeader(body: PostTeamLeaderBody): Promise<any> {
  return request(`/team/leader`, {
    method: Method.POST,

    data: body,
  })
}

/**
 * 接口 [3.列表↗](http://fe.jiuworker.com:3000/project/29/interface/api/1187)
 *
 * @分类 [班组信息↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_74)
 * @标签 `班组信息`
 * @请求头 `GET /team/list`
 * @更新时间 `2024-08-23 16:22:12`
 */

/* **请求query类型** */
export interface GetTeamListQuery {
  /**
   * name
   */
  name?: string
  /**
   * orderId
   */
  orderId?: string
  /**
   * pageNum
   */
  pageNum?: number
  /**
   * pageSize
   */
  pageSize?: number
  /**
   * status
   */
  status?: string
}

/* **返回类型** */
export interface GetTeamListResponse {
  data: {
    /**
     * 报名人数
     */
    applyNum: number
    deadline: string
    endDate: string
    endTime: string
    excessRatio: number
    /**
     * 工作时长
     */
    hour: number
    id: number
    insuranceId: number
    insuranceName: string
    /**
     * 岗位信息
     */
    jobs: {
      /**
       * 岗位名称
       */
      name: string
      /**
       * 用工数量
       */
      num: number
      /**
       * 单价
       */
      price: number
      rooms: {
        /**
         * 房间id
         */
        id: number
        name: string
        num: number
        originalPrice: number
        price: number
      }[]
      /**
       * 计价方式 0按小时 1按天 2按次
       */
      valuationType: number
    }[]
    /**
     * 班组长Id
     */
    leaderAccountId: number
    location: {
      address: string
      latitude: string
      longitude: string
      region: string[]
    }
    /**
     * 班组名称
     */
    name: string
    /**
     * 岗位需求人数
     */
    needNum: number
    /**
     * 用工计划id
     */
    orderId: number
    /**
     * 用工计划名称
     */
    orderName: string
    planId: number
    /**
     * 休息时长 小时
     */
    restTime: number
    /**
     * 排班人数
     */
    scheduleCount: number
    /**
     * 排班进度
     */
    scheduleProcess: number
    source: string
    startDate: string
    startTime: string
    /**
     * 班组状态 1待开始 2进行中 3已结束 4已作废
     */
    status: number
    /**
     * 计价方式 0:按小时 1:按天 2:按次
     */
    valuationType: number
    /**
     * 工时
     */
    workTime: string
  }[]
  total: number
}

/* **请求函数** */
export async function getTeamList(query: GetTeamListQuery): Promise<any> {
  return request(`/team/list`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [3.1 班组列表-用于下拉框↗](http://fe.jiuworker.com:3000/project/29/interface/api/1189)
 *
 * @分类 [班组信息↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_74)
 * @标签 `班组信息`
 * @请求头 `GET /team/list/select`
 * @更新时间 `2024-08-23 16:22:12`
 */

/* **请求query类型** */
export interface GetTeamListSelectQuery {
  /**
   * name
   */
  name?: string
  /**
   * pageNum
   */
  pageNum?: number
  /**
   * pageSize
   */
  pageSize?: number
}

/* **返回类型** */
export interface GetTeamListSelectResponse {
  data: {
    id: number
    /**
     * 班组名称
     */
    name: string
  }[]
  total: number
}

/* **请求函数** */
export async function getTeamListSelect(query: GetTeamListSelectQuery): Promise<any> {
  return request(`/team/list/select`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [4.二维码设置↗](http://fe.jiuworker.com:3000/project/29/interface/api/1191)
 *
 * @分类 [班组信息↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_74)
 * @标签 `班组信息`
 * @请求头 `POST /team/qrcode`
 * @更新时间 `2024-08-23 16:22:12`
 */

/* **请求body类型** */
export interface PostTeamQrcodeBody {
  /**
   *  报名截止时间
   */
  deadline?: string
  /**
   * 超额报名比例
   */
  excessRatio?: number
  id?: number
}

/* **返回类型** */
export type PostTeamQrcodeResponse = boolean

/* **请求函数** */
export async function postTeamQrcode(body: PostTeamQrcodeBody): Promise<any> {
  return request(`/team/qrcode`, {
    method: Method.POST,

    data: body,
  })
}

/**
 * 接口 [8.绑定设备↗](http://fe.jiuworker.com:3000/project/29/interface/api/1587)
 *
 * @分类 [班组信息↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_74)
 * @标签 `班组信息`
 * @请求头 `PUT /team/device/bind`
 * @更新时间 `2024-08-23 16:22:12`
 */

/* **请求body类型** */
export interface PutTeamDeviceBindBody {
  deviceIds?: number[]
  teamId?: number
}

/* **返回类型** */
export type PutTeamDeviceBindResponse = boolean

/* **请求函数** */
export async function putTeamDeviceBind(body: PutTeamDeviceBindBody): Promise<any> {
  return request(`/team/device/bind`, {
    method: Method.PUT,

    data: body,
  })
}

/**
 * 接口 [1.创建班组-派单↗](http://fe.jiuworker.com:3000/project/29/interface/api/2153)
 *
 * @分类 [班组信息↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_74)
 * @标签 `班组信息`
 * @请求头 `POST /team/delivery/add`
 * @更新时间 `2024-08-23 16:22:12`
 */

/* **请求body类型** */
export interface PostTeamDeliveryAddBody {
  /**
   *  报名截止时间
   */
  deadline?: string
  endDate?: string
  endTime?: string
  id?: number
  /**
   * 投保险种
   */
  insuranceId?: number
  jobs?: {
    /**
     * 最大年龄
     */
    ageMax?: number
    /**
     * 最小年龄
     */
    ageMin?: number
    /**
     * 计划用工数量/女
     */
    femaleNum?: number
    id?: number
    /**
     * 岗位id
     */
    jobId?: number
    /**
     * 计划用工数量/男
     */
    maleNum?: number
    /**
     * 岗位名称
     */
    name?: string
    /**
     * 计划用工数量
     */
    num?: number
    /**
     * 其它要求 json
     */
    other?: string
    /**
     * 用工/单价
     */
    price?: number
    rooms?: {
      /**
       * 房间id
       */
      id?: number
      name?: string
      num?: number
      originalPrice?: number
      price?: number
    }[]
    /**
     * 班组id
     */
    teamId?: number
    /**
     * 计价方式 0:按小时 1:按天 2:按次
     */
    valuationType?: number
  }[]
  /**
   * 班组长id
   */
  leaderId?: string
  location?: {
    address?: string
    latitude?: string
    longitude?: string
    region?: string[]
  }
  /**
   * 是否发送到劳务市场 0否1是
   */
  market?: number
  /**
   * 班组名称
   */
  name?: string
  /**
   * 用工计划id
   */
  orderId?: number
  planId?: number
  /**
   * 休息时长 小时
   */
  restTime?: number
  startDate?: string
  startTime?: string
  /**
   * 计价方式 0:按小时 1:按天 2:按次
   */
  valuationType?: number
}

/* **返回类型** */
export type PostTeamDeliveryAddResponse = boolean

/* **请求函数** */
export async function postTeamDeliveryAdd(body: PostTeamDeliveryAddBody): Promise<any> {
  return request(`/team/delivery/add`, {
    method: Method.POST,

    data: body,
  })
}

/**
 * 接口 [1.接受或拒绝用工单↗](http://fe.jiuworker.com:3000/project/29/interface/api/1029)
 *
 * @分类 [用工单↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_75)
 * @标签 `用工单`
 * @请求头 `PUT /order/accept`
 * @更新时间 `2024-08-23 16:22:09`
 */

/* **请求body类型** */
export interface PutOrderAcceptBody {
  /**
   * 是否同意 1同意0拒绝
   */
  accept?: number
  id?: number
  /**
   * 拒绝原因
   */
  refuseReason?: string
}

/* **返回类型** */
export type PutOrderAcceptResponse = boolean

/* **请求函数** */
export async function putOrderAccept(body: PutOrderAcceptBody): Promise<any> {
  return request(`/order/accept`, {
    method: Method.PUT,

    data: body,
  })
}

/**
 * 接口 [6.同意用工企业取消计划↗](http://fe.jiuworker.com:3000/project/29/interface/api/1031)
 *
 * @分类 [用工单↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_75)
 * @标签 `用工单`
 * @请求头 `PUT /order/accept/cancel`
 * @更新时间 `2024-08-23 16:22:09`
 */

/* **请求body类型** */
export interface PutOrderAcceptCancelBody {
  /**
   * 是否同意 1同意0拒绝
   */
  accept?: number
  id?: number
  /**
   * 拒绝原因
   */
  refuseReason?: string
}

/* **返回类型** */
export type PutOrderAcceptCancelResponse = boolean

/* **请求函数** */
export async function putOrderAcceptCancel(body: PutOrderAcceptCancelBody): Promise<any> {
  return request(`/order/accept/cancel`, {
    method: Method.PUT,

    data: body,
  })
}

/**
 * 接口 [5.申请取消计划↗](http://fe.jiuworker.com:3000/project/29/interface/api/1033)
 *
 * @分类 [用工单↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_75)
 * @标签 `用工单`
 * @请求头 `PUT /order/cancel`
 * @更新时间 `2024-08-23 16:22:09`
 */

/* **请求body类型** */
export interface PutOrderCancelBody {
  id?: number
  /**
   * 拒绝原因
   */
  reason?: string
}

/* **返回类型** */
export type PutOrderCancelResponse = boolean

/* **请求函数** */
export async function putOrderCancel(body: PutOrderCancelBody): Promise<any> {
  return request(`/order/cancel`, {
    method: Method.PUT,

    data: body,
  })
}

/**
 * 接口 [2.详情↗](http://fe.jiuworker.com:3000/project/29/interface/api/1035)
 *
 * @分类 [用工单↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_75)
 * @标签 `用工单`
 * @请求头 `GET /order/detail/{id}`
 * @更新时间 `2024-08-23 16:22:09`
 */

/* **请求params类型** */
export interface GetOrderDetailByIdParams {
  /**
   * id
   */
  id: string
}

/* **返回类型** */
export interface GetOrderDetailByIdResponse {
  /**
   * 计划取消原因
   */
  cancelReason: string
  /**
   * 取消状态 1正常 2用工方取消 3劳务方取消 4用工方拒绝 5劳务方拒绝
   */
  cancelStatus: number
  /**
   * 联系人
   */
  contractPerson: string
  /**
   * 联系人电话
   */
  contractPhone: string
  day: number
  /**
   * 单日费用
   */
  dayPrice: number
  /**
   * 用工企业id
   */
  employCompanyId: number
  /**
   * 用工企业名称
   */
  employCompanyName: string
  endDate: string
  endDateTime: string
  endTime: string
  gmtCreate: string
  gmtModified: string
  /**
   * 工作时长
   */
  hour: number
  id: number
  /**
   * 岗位需求json
   */
  jobs: {
    ageMax: number
    ageMin: number
    femaleNum: number
    maleNum: number
    maxAge: number
    minAge: number
    name: string
    num: number
    other: string
    price: number
    rooms: {
      /**
       * 房间id
       */
      id: number
      name: string
      num: number
      originalPrice: number
      price: number
    }[]
  }[]
  /**
   * 劳务公司id
   */
  laborCompanyId: number
  location: {
    address: string
    latitude: string
    longitude: string
    region: string[]
  }
  /**
   * 需求名称
   */
  name: string
  /**
   * 订单编号
   */
  orderNo: string
  /**
   * 用工计划id
   */
  planId: number
  /**
   * 拒绝计划取消原因
   */
  refuseCancelReason: string
  /**
   * 拒绝原因
   */
  refuseReason: string
  /**
   * 休息时长 小时
   */
  restTime: number
  /**
   * 排班进度
   */
  scheduleProgress: number
  source: string
  startDate: string
  startTime: string
  /**
   * 订单状态 1待接单 2接单中  11进行中 3待客户确认考勤 4待考勤确认 5已完成 6 已拒绝 7已取消-用工端 8已取消-劳务端 9已取消-未接单 10待结算 11进行中
   */
  status: number
  /**
   * 班组id
   */
  teamId: number
  /**
   * 班组名称
   */
  teamName: string
  /**
   * 天数汇总
   */
  totalDay: number
  /**
   * 总费用
   */
  totalPrice: number
  /**
   * 工时汇总
   */
  totalWorkHour: number
  userNum: number
  /**
   * 计价方式 0:按小时 1:按天 2:按次
   */
  valuationType: number
}

/* **请求函数** */
export async function getOrderDetailById(params: GetOrderDetailByIdParams): Promise<any> {
  return request(`/order/detail/${params.id}`, {
    method: Method.GET,
  })
}

/**
 * 接口 [3.列表↗](http://fe.jiuworker.com:3000/project/29/interface/api/1037)
 *
 * @分类 [用工单↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_75)
 * @标签 `用工单`
 * @请求头 `GET /order/list`
 * @更新时间 `2024-08-23 16:22:09`
 */

/* **请求query类型** */
export interface GetOrderListQuery {
  /**
   * employCompanyId
   */
  employCompanyId?: string
  /**
   * endDate
   */
  endDate?: string
  /**
   * name
   */
  name?: string
  /**
   * orderNo
   */
  orderNo?: string
  /**
   * pageNum
   */
  pageNum?: number
  /**
   * pageSize
   */
  pageSize?: number
  /**
   * startDate
   */
  startDate?: string
  /**
   * statusList
   */
  statusList?: string
}

/* **返回类型** */
export interface GetOrderListResponse {
  data: {
    /**
     * 计划取消原因
     */
    cancelReason: string
    /**
     * 取消状态 1正常 2用工方取消 3劳务方取消 4用工方拒绝 5劳务方拒绝
     */
    cancelStatus: number
    /**
     * 联系人
     */
    contractPerson: string
    /**
     * 联系人电话
     */
    contractPhone: string
    day: number
    /**
     * 单日费用
     */
    dayPrice: number
    /**
     * 用工企业id
     */
    employCompanyId: number
    /**
     * 用工企业名称
     */
    employCompanyName: string
    endDate: string
    endDateTime: string
    endTime: string
    gmtCreate: string
    gmtModified: string
    /**
     * 工作时长
     */
    hour: number
    id: number
    /**
     * 岗位需求json
     */
    jobs: {
      ageMax: number
      ageMin: number
      femaleNum: number
      maleNum: number
      maxAge: number
      minAge: number
      name: string
      num: number
      other: string
      price: number
      rooms: {
        /**
         * 房间id
         */
        id: number
        name: string
        num: number
        originalPrice: number
        price: number
      }[]
    }[]
    /**
     * 劳务公司id
     */
    laborCompanyId: number
    location: {
      address: string
      latitude: string
      longitude: string
      region: string[]
    }
    /**
     * 需求名称
     */
    name: string
    /**
     * 订单编号
     */
    orderNo: string
    /**
     * 用工计划id
     */
    planId: number
    /**
     * 拒绝计划取消原因
     */
    refuseCancelReason: string
    /**
     * 拒绝原因
     */
    refuseReason: string
    /**
     * 休息时长 小时
     */
    restTime: number
    /**
     * 排班进度
     */
    scheduleProgress: number
    source: string
    startDate: string
    startTime: string
    /**
     * 订单状态 1待接单 2接单中  11进行中 3待客户确认考勤 4待考勤确认 5已完成 6 已拒绝 7已取消-用工端 8已取消-劳务端 9已取消-未接单 10待结算 11进行中
     */
    status: number
    /**
     * 班组id
     */
    teamId: number
    /**
     * 班组名称
     */
    teamName: string
    /**
     * 天数汇总
     */
    totalDay: number
    /**
     * 总费用
     */
    totalPrice: number
    /**
     * 工时汇总
     */
    totalWorkHour: number
    userNum: number
    /**
     * 计价方式 0:按小时 1:按天 2:按次
     */
    valuationType: number
  }[]
  total: number
}

/* **请求函数** */
export async function getOrderList(query: GetOrderListQuery): Promise<any> {
  return request(`/order/list`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [3.1 用工企业申请取消计划列表↗](http://fe.jiuworker.com:3000/project/29/interface/api/1039)
 *
 * @分类 [用工单↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_75)
 * @标签 `用工单`
 * @请求头 `GET /order/list/cancel`
 * @更新时间 `2024-08-23 16:22:09`
 */

/* **请求query类型** */
export interface GetOrderListCancelQuery {
  /**
   * pageNum
   */
  pageNum?: number
  /**
   * pageSize
   */
  pageSize?: number
  /**
   * 1申请中2已拒绝
   */
  status?: string
}

/* **返回类型** */
export interface GetOrderListCancelResponse {
  data: {
    /**
     * 计划取消原因
     */
    cancelReason: string
    /**
     * 用工企业名称
     */
    employCompanyName: string
    id: number
    /**
     * 岗位需求json
     */
    jobs: {
      ageMax: number
      ageMin: number
      femaleNum: number
      maleNum: number
      maxAge: number
      minAge: number
      name: string
      num: number
      other: string
      price: number
      rooms: {
        /**
         * 房间id
         */
        id: number
        name: string
        num: number
        originalPrice: number
        price: number
      }[]
    }[]
    /**
     * 需求名称
     */
    name: string
    /**
     * 订单编号
     */
    orderNo: string
    /**
     * 用工计划id
     */
    planId: number
    /**
     * 拒绝计划取消原因
     */
    refuseCancelReason: string
    /**
     * 用工企业名称
     */
    refuseReason: string
    /**
     * 订单状态 1待接单 2接单中  11进行中 3待客户确认考勤 4待考勤确认 5已完成 6 已拒绝 7已取消-用工端 8已取消-劳务端 9已取消-未接单 10待结算 11进行中
     */
    status: number
    /**
     * 计价方式 0:按小时 1:按天 2:按次
     */
    valuationType: number
  }[]
  total: number
}

/* **请求函数** */
export async function getOrderListCancel(query: GetOrderListCancelQuery): Promise<any> {
  return request(`/order/list/cancel`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [3.2需求单-数据统计↗](http://fe.jiuworker.com:3000/project/29/interface/api/1041)
 *
 * @分类 [用工单↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_75)
 * @标签 `用工单`
 * @请求头 `GET /order/list/count`
 * @更新时间 `2024-08-23 16:22:09`
 */

/* **返回类型** */
export type GetOrderListCountResponse = {
  count: number
  /**
   * 订单状态 10待接单 20接单中  30进行中 60待结算  80已完成 90已取消
   */
  status: number
}[]

/* **请求函数** */
export async function getOrderListCount(): Promise<any> {
  return request(`/order/list/count`, {
    method: Method.GET,
  })
}

/**
 * 接口 [3.列表-创建班组↗](http://fe.jiuworker.com:3000/project/29/interface/api/1043)
 *
 * @分类 [用工单↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_75)
 * @标签 `用工单`
 * @请求头 `GET /order/list/select`
 * @更新时间 `2024-08-23 16:22:09`
 */

/* **请求query类型** */
export interface GetOrderListSelectQuery {
  /**
   * name
   */
  name?: string
  /**
   * pageNum
   */
  pageNum?: number
  /**
   * pageSize
   */
  pageSize?: number
}

/* **返回类型** */
export interface GetOrderListSelectResponse {
  data: {
    /**
     * 计划取消原因
     */
    cancelReason: string
    /**
     * 取消状态 1正常 2用工方取消 3劳务方取消 4用工方拒绝 5劳务方拒绝
     */
    cancelStatus: number
    /**
     * 联系人
     */
    contractPerson: string
    /**
     * 联系人电话
     */
    contractPhone: string
    day: number
    /**
     * 单日费用
     */
    dayPrice: number
    /**
     * 用工企业id
     */
    employCompanyId: number
    /**
     * 用工企业名称
     */
    employCompanyName: string
    endDate: string
    endDateTime: string
    endTime: string
    gmtCreate: string
    gmtModified: string
    /**
     * 工作时长
     */
    hour: number
    id: number
    /**
     * 岗位需求json
     */
    jobs: {
      ageMax: number
      ageMin: number
      femaleNum: number
      maleNum: number
      maxAge: number
      minAge: number
      name: string
      num: number
      other: string
      price: number
      rooms: {
        /**
         * 房间id
         */
        id: number
        name: string
        num: number
        originalPrice: number
        price: number
      }[]
    }[]
    /**
     * 劳务公司id
     */
    laborCompanyId: number
    location: {
      address: string
      latitude: string
      longitude: string
      region: string[]
    }
    /**
     * 需求名称
     */
    name: string
    /**
     * 订单编号
     */
    orderNo: string
    /**
     * 用工计划id
     */
    planId: number
    /**
     * 拒绝计划取消原因
     */
    refuseCancelReason: string
    /**
     * 拒绝原因
     */
    refuseReason: string
    /**
     * 休息时长 小时
     */
    restTime: number
    /**
     * 排班进度
     */
    scheduleProgress: number
    source: string
    startDate: string
    startTime: string
    /**
     * 订单状态 1待接单 2接单中  11进行中 3待客户确认考勤 4待考勤确认 5已完成 6 已拒绝 7已取消-用工端 8已取消-劳务端 9已取消-未接单 10待结算 11进行中
     */
    status: number
    /**
     * 班组id
     */
    teamId: number
    /**
     * 班组名称
     */
    teamName: string
    /**
     * 天数汇总
     */
    totalDay: number
    /**
     * 总费用
     */
    totalPrice: number
    /**
     * 工时汇总
     */
    totalWorkHour: number
    userNum: number
    /**
     * 计价方式 0:按小时 1:按天 2:按次
     */
    valuationType: number
  }[]
  total: number
}

/* **请求函数** */
export async function getOrderListSelect(query: GetOrderListSelectQuery): Promise<any> {
  return request(`/order/list/select`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [3.列表-关联班组↗](http://fe.jiuworker.com:3000/project/29/interface/api/1603)
 *
 * @分类 [用工单↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_75)
 * @标签 `用工单`
 * @请求头 `GET /order/list/bind/team`
 * @更新时间 `2024-08-23 16:22:09`
 */

/* **请求query类型** */
export interface GetOrderListBindTeamQuery {
  /**
   * employCompanyId
   */
  employCompanyId?: string
  /**
   * endDate
   */
  endDate?: string
  /**
   * name
   */
  name?: string
  /**
   * orderNo
   */
  orderNo?: string
  /**
   * pageNum
   */
  pageNum?: number
  /**
   * pageSize
   */
  pageSize?: number
  /**
   * startDate
   */
  startDate?: string
  /**
   * statusList
   */
  statusList?: string
}

/* **返回类型** */
export interface GetOrderListBindTeamResponse {
  data: {
    /**
     * 计划取消原因
     */
    cancelReason: string
    /**
     * 取消状态 1正常 2用工方取消 3劳务方取消 4用工方拒绝 5劳务方拒绝
     */
    cancelStatus: number
    /**
     * 联系人
     */
    contractPerson: string
    /**
     * 联系人电话
     */
    contractPhone: string
    day: number
    /**
     * 单日费用
     */
    dayPrice: number
    /**
     * 用工企业id
     */
    employCompanyId: number
    /**
     * 用工企业名称
     */
    employCompanyName: string
    endDate: string
    endDateTime: string
    endTime: string
    gmtCreate: string
    gmtModified: string
    /**
     * 工作时长
     */
    hour: number
    id: number
    /**
     * 岗位需求json
     */
    jobs: {
      ageMax: number
      ageMin: number
      femaleNum: number
      maleNum: number
      maxAge: number
      minAge: number
      name: string
      num: number
      other: string
      price: number
      rooms: {
        /**
         * 房间id
         */
        id: number
        name: string
        num: number
        originalPrice: number
        price: number
      }[]
    }[]
    /**
     * 劳务公司id
     */
    laborCompanyId: number
    location: {
      address: string
      latitude: string
      longitude: string
      region: string[]
    }
    /**
     * 需求名称
     */
    name: string
    /**
     * 订单编号
     */
    orderNo: string
    /**
     * 用工计划id
     */
    planId: number
    /**
     * 拒绝计划取消原因
     */
    refuseCancelReason: string
    /**
     * 拒绝原因
     */
    refuseReason: string
    /**
     * 休息时长 小时
     */
    restTime: number
    /**
     * 排班进度
     */
    scheduleProgress: number
    source: string
    startDate: string
    startTime: string
    /**
     * 订单状态 1待接单 2接单中  11进行中 3待客户确认考勤 4待考勤确认 5已完成 6 已拒绝 7已取消-用工端 8已取消-劳务端 9已取消-未接单 10待结算 11进行中
     */
    status: number
    /**
     * 班组id
     */
    teamId: number
    /**
     * 班组名称
     */
    teamName: string
    /**
     * 天数汇总
     */
    totalDay: number
    /**
     * 总费用
     */
    totalPrice: number
    /**
     * 工时汇总
     */
    totalWorkHour: number
    userNum: number
    /**
     * 计价方式 0:按小时 1:按天 2:按次
     */
    valuationType: number
  }[]
  total: number
}

/* **请求函数** */
export async function getOrderListBindTeam(query: GetOrderListBindTeamQuery): Promise<any> {
  return request(`/order/list/bind/team`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [12.接单↗](http://fe.jiuworker.com:3000/project/29/interface/api/1961)
 *
 * @分类 [用工单↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_75)
 * @标签 `用工单`
 * @请求头 `POST /order/grab/accept`
 * @更新时间 `2024-08-23 16:22:09`
 */

/* **请求body类型** */
export interface PostOrderGrabAcceptBody {
  id?: number
}

/* **返回类型** */
export type PostOrderGrabAcceptResponse = boolean

/* **请求函数** */
export async function postOrderGrabAccept(body: PostOrderGrabAcceptBody): Promise<any> {
  return request(`/order/grab/accept`, {
    method: Method.POST,

    data: body,
  })
}

/**
 * 接口 [11.用工计划详情↗](http://fe.jiuworker.com:3000/project/29/interface/api/1963)
 *
 * @分类 [用工单↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_75)
 * @标签 `用工单`
 * @请求头 `GET /order/grab/detail/{id}`
 * @更新时间 `2024-08-23 16:22:09`
 */

/* **请求params类型** */
export interface GetOrderGrabDetailByIdParams {
  /**
   * id
   */
  id: string
}

/* **返回类型** */
export interface GetOrderGrabDetailByIdResponse {
  /**
   * 计划取消原因
   */
  cancelReason: string
  /**
   * 取消状态 1正常 2用工方取消 3劳务方取消 4用工方拒绝 5劳务方拒绝
   */
  cancelStatus: number
  /**
   * 联系人
   */
  contractPerson: string
  /**
   * 联系人电话
   */
  contractPhone: string
  day: number
  /**
   * 单日费用
   */
  dayPrice: number
  /**
   * 用工企业id
   */
  employCompanyId: number
  /**
   * 用工企业名称
   */
  employCompanyName: string
  endDate: string
  endDateTime: string
  endTime: string
  gmtCreate: string
  gmtModified: string
  /**
   * 工作时长
   */
  hour: number
  id: number
  /**
   * 岗位需求json
   */
  jobs: {
    ageMax: number
    ageMin: number
    femaleNum: number
    maleNum: number
    maxAge: number
    minAge: number
    name: string
    num: number
    other: string
    price: number
    rooms: {
      /**
       * 房间id
       */
      id: number
      name: string
      num: number
      originalPrice: number
      price: number
    }[]
  }[]
  /**
   * 劳务公司id
   */
  laborCompanyId: number
  location: {
    address: string
    latitude: string
    longitude: string
    region: string[]
  }
  /**
   * 需求名称
   */
  name: string
  /**
   * 订单编号
   */
  orderNo: string
  /**
   * 用工计划id
   */
  planId: number
  /**
   * 拒绝计划取消原因
   */
  refuseCancelReason: string
  /**
   * 拒绝原因
   */
  refuseReason: string
  /**
   * 休息时长 小时
   */
  restTime: number
  /**
   * 排班进度
   */
  scheduleProgress: number
  source: string
  startDate: string
  startTime: string
  /**
   * 订单状态 1待接单 2接单中  11进行中 3待客户确认考勤 4待考勤确认 5已完成 6 已拒绝 7已取消-用工端 8已取消-劳务端 9已取消-未接单 10待结算 11进行中
   */
  status: number
  /**
   * 班组id
   */
  teamId: number
  /**
   * 班组名称
   */
  teamName: string
  /**
   * 天数汇总
   */
  totalDay: number
  /**
   * 总费用
   */
  totalPrice: number
  /**
   * 工时汇总
   */
  totalWorkHour: number
  userNum: number
  /**
   * 计价方式 0:按小时 1:按天 2:按次
   */
  valuationType: number
}

/* **请求函数** */
export async function getOrderGrabDetailById(params: GetOrderGrabDetailByIdParams): Promise<any> {
  return request(`/order/grab/detail/${params.id}`, {
    method: Method.GET,
  })
}

/**
 * 接口 [10.抢单大厅-计划单分页查询↗](http://fe.jiuworker.com:3000/project/29/interface/api/1965)
 *
 * @分类 [用工单↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_75)
 * @标签 `用工单`
 * @请求头 `GET /order/grab/query`
 * @更新时间 `2024-08-23 16:22:09`
 */

/* **请求query类型** */
export interface GetOrderGrabQueryQuery {
  /**
   * 市
   */
  city?: string
  /**
   * 区县
   */
  country?: string
  /**
   * 用工结束时间
   */
  endDate?: string
  isFade?: string
  laborEnterIdList?: string
  /**
   * 最多工时
   */
  maxHourNum?: string
  /**
   * 最多费用
   */
  maxMoneyNum?: string
  /**
   * 最多人数
   */
  maxPeopleNum?: string
  /**
   * 最少工时
   */
  minHourNum?: string
  /**
   * 最少费用
   */
  minMoneyNum?: string
  /**
   * 最少人数
   */
  minPeopleNum?: string
  /**
   * 排序类型：10：近期发布; 20 费用优先；30 工时优先
   */
  orderType?: string
  pageNum?: number
  pageSize?: number
  /**
   * 省份
   */
  province?: string
  /**
   * 用工开始时间
   */
  startDate?: string
}

/* **返回类型** */
export interface GetOrderGrabQueryResponse {
  data: {
    /**
     * 计划取消原因
     */
    cancelReason: string
    /**
     * 取消状态 1正常 2用工方取消 3劳务方取消 4用工方拒绝 5劳务方拒绝
     */
    cancelStatus: number
    day: number
    /**
     * 单日费用
     */
    dayPrice: number
    /**
     * 用工企业id
     */
    employCompanyId: number
    /**
     * 用工企业名称
     */
    employCompanyName: string
    endDate: string
    endDateTime: string
    endTime: string
    gmtCreate: string
    gmtModified: string
    /**
     * 工作时长
     */
    hour: number
    id: number
    /**
     * 岗位需求json
     */
    jobs: {
      ageMax: number
      ageMin: number
      femaleNum: number
      maleNum: number
      maxAge: number
      minAge: number
      name: string
      num: number
      other: string
      price: number
      rooms: {
        /**
         * 房间id
         */
        id: number
        name: string
        num: number
        originalPrice: number
        price: number
      }[]
    }[]
    /**
     * 劳务公司id
     */
    laborCompanyId: number
    location: {
      address: string
      latitude: string
      longitude: string
      region: string[]
    }
    /**
     * 需求名称
     */
    name: string
    /**
     * 订单编号
     */
    orderNo: string
    /**
     * 用工计划id
     */
    planId: number
    /**
     * 拒绝计划取消原因
     */
    refuseCancelReason: string
    /**
     * 拒绝原因
     */
    refuseReason: string
    /**
     * 休息时长 小时
     */
    restTime: number
    /**
     * 排班进度
     */
    scheduleProgress: number
    source: string
    startDate: string
    startTime: string
    /**
     * 订单状态 1待接单 2接单中  11进行中 3待客户确认考勤 4待考勤确认 5已完成 6 已拒绝 7已取消-用工端 8已取消-劳务端 9已取消-未接单 10待结算 11进行中
     */
    status: number
    /**
     * 班组id
     */
    teamId: number
    /**
     * 班组名称
     */
    teamName: string
    /**
     * 天数汇总
     */
    totalDay: number
    /**
     * 总费用
     */
    totalPrice: number
    /**
     * 工时汇总
     */
    totalWorkHour: number
    userNum: number
    /**
     * 计价方式 0:按小时 1:按天 2:按次
     */
    valuationType: number
  }[]
  total: number
}

/* **请求函数** */
export async function getOrderGrabQuery(query: GetOrderGrabQueryQuery): Promise<any> {
  return request(`/order/grab/query`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [用工端结算结果通知↗](http://fe.jiuworker.com:3000/project/29/interface/api/1101)
 *
 * @分类 [用工单位结算-对外↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_76)
 * @标签 `用工单位结算-对外`
 * @请求头 `POST /out/settle/settle`
 * @更新时间 `2024-08-23 16:22:10`
 */

/* **请求body类型** */
export interface PostOutSettleSettleBody {
  amount?: number
  enterId?: number
  fileList?: {
    /**
     * 附件名称
     */
    name?: string
    /**
     * 附件地址
     */
    path?: string
    /**
     * 附件大小
     */
    size?: string
  }[]
  isOver?: boolean
  laborId?: number
  payType?: number
  planId?: number
  remark?: string
  serialNumber?: string
}

/* **返回类型** */
export type PostOutSettleSettleResponse = boolean

/* **请求函数** */
export async function postOutSettleSettle(body: PostOutSettleSettleBody): Promise<any> {
  return request(`/out/settle/settle`, {
    method: Method.POST,

    data: body,
  })
}

/**
 * 接口 [2.对方同意合作关系↗](http://fe.jiuworker.com:3000/project/29/interface/api/1047)
 *
 * @分类 [用工端调用接口-合作关系↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_78)
 * @标签 `用工端调用接口-合作关系`
 * @请求头 `POST /out/cooperate/accept`
 * @更新时间 `2024-08-23 16:22:09`
 */

/* **请求body类型** */
export interface PostOutCooperateAcceptBody {
  enterId?: number
  laborId?: number
  /**
   * 拒绝原因
   */
  refuseReason?: string
  /**
   * 是否同意 1同意；0拒绝
   */
  status?: number
}

/* **返回类型** */
export type PostOutCooperateAcceptResponse = boolean

/* **请求函数** */
export async function postOutCooperateAccept(body: PostOutCooperateAcceptBody): Promise<any> {
  return request(`/out/cooperate/accept`, {
    method: Method.POST,

    data: body,
  })
}

/**
 * 接口 [21.用工端撤回邀请↗](http://fe.jiuworker.com:3000/project/29/interface/api/1049)
 *
 * @分类 [用工端调用接口-合作关系↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_78)
 * @标签 `用工端调用接口-合作关系`
 * @请求头 `POST /out/cooperate/cancel`
 * @更新时间 `2024-08-23 16:22:09`
 */

/* **请求body类型** */
export interface PostOutCooperateCancelBody {
  enterId?: number
  laborId?: number
}

/* **返回类型** */
export type PostOutCooperateCancelResponse = boolean

/* **请求函数** */
export async function postOutCooperateCancel(body: PostOutCooperateCancelBody): Promise<any> {
  return request(`/out/cooperate/cancel`, {
    method: Method.POST,

    data: body,
  })
}

/**
 * 接口 [11.查询公司详情↗](http://fe.jiuworker.com:3000/project/29/interface/api/1051)
 *
 * @分类 [用工端调用接口-合作关系↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_78)
 * @标签 `用工端调用接口-合作关系`
 * @请求头 `GET /out/cooperate/company/detail`
 * @更新时间 `2024-08-23 16:22:09`
 */

/* **请求query类型** */
export interface GetOutCooperateCompanyDetailQuery {
  /**
   * laborId
   */
  laborId: string
}

/* **返回类型** */
export interface GetOutCooperateCompanyDetailResponse {
  address: {
    address: string
    latitude: string
    longitude: string
    region: string[]
  }
  /**
   * 企业营业执照
   */
  businessLicense: string
  /**
   * 公司名称-营业执照-保险业务
   */
  certName: string
  /**
   * 社会统一信用代码
   */
  certNo: string
  /**
   * 邮箱
   */
  email: string
  /**
   * 支付账户登录名称
   */
  externalLogonId: string
  /**
   * 支付账户名称-记账本企业名称
   */
  financeAccountName: string
  gmtCreate: string
  gmtModified: string
  id: number
  location: {
    address: string
    latitude: string
    longitude: string
    region: string[]
  }
  /**
   * 手机号
   */
  mobile: string
  /**
   * 劳务公司名称
   */
  name: string
  /**
   * 签约状态
   */
  payChannel: string
  /**
   * 签约状态
   */
  sign: number
  /**
   * 联系人
   */
  userName: string
}

/* **请求函数** */
export async function getOutCooperateCompanyDetail(query: GetOutCooperateCompanyDetailQuery): Promise<any> {
  return request(`/out/cooperate/company/detail`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [10.用户名查询公司列表↗](http://fe.jiuworker.com:3000/project/29/interface/api/1053)
 *
 * @分类 [用工端调用接口-合作关系↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_78)
 * @标签 `用工端调用接口-合作关系`
 * @请求头 `GET /out/cooperate/company/list`
 * @更新时间 `2024-08-23 16:22:09`
 */

/* **请求query类型** */
export interface GetOutCooperateCompanyListQuery {
  /**
   * name
   */
  name?: string
  /**
   * pageNum
   */
  pageNum?: number
  /**
   * pageSize
   */
  pageSize?: number
}

/* **返回类型** */
export interface GetOutCooperateCompanyListResponse {
  data: {
    address: {
      address: string
      latitude: string
      longitude: string
      region: string[]
    }
    /**
     * 企业营业执照
     */
    businessLicense: string
    /**
     * 公司名称-营业执照-保险业务
     */
    certName: string
    /**
     * 社会统一信用代码
     */
    certNo: string
    /**
     * 邮箱
     */
    email: string
    /**
     * 支付账户登录名称
     */
    externalLogonId: string
    /**
     * 支付账户名称-记账本企业名称
     */
    financeAccountName: string
    gmtCreate: string
    gmtModified: string
    id: number
    location: {
      address: string
      latitude: string
      longitude: string
      region: string[]
    }
    /**
     * 手机号
     */
    mobile: string
    /**
     * 劳务公司名称
     */
    name: string
    /**
     * 签约状态
     */
    payChannel: string
    /**
     * 签约状态
     */
    sign: number
    /**
     * 联系人
     */
    userName: string
  }[]
  total: number
}

/* **请求函数** */
export async function getOutCooperateCompanyList(query: GetOutCooperateCompanyListQuery): Promise<any> {
  return request(`/out/cooperate/company/list`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [30.查询用工企业信息↗](http://fe.jiuworker.com:3000/project/29/interface/api/1055)
 *
 * @分类 [用工端调用接口-合作关系↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_78)
 * @标签 `用工端调用接口-合作关系`
 * @请求头 `POST /out/cooperate/enter/detail/{enterId}`
 * @更新时间 `2024-08-23 16:22:09`
 */

/* **请求params类型** */
export interface PostOutCooperateEnterDetailByEnterIdParams {
  /**
   * enterId
   */
  enterId: string
}

/* **返回类型** */
export interface PostOutCooperateEnterDetailByEnterIdResponse {
  address: string
  businessLicense: string
  city: string
  contractPerson: string
  contractPhone: string
  country: string
  email: string
  externalLogonId: string
  financeAccountId: number
  financeAccountName: string
  id: number
  latitude: string
  longitude: string
  name: string
  password: string
  province: string
  sign: boolean
}

/* **请求函数** */
export async function postOutCooperateEnterDetailByEnterId(
  params: PostOutCooperateEnterDetailByEnterIdParams,
): Promise<any> {
  return request(`/out/cooperate/enter/detail/${params.enterId}`, {
    method: Method.POST,
  })
}

/**
 * 接口 [1.客户添加劳务公司↗](http://fe.jiuworker.com:3000/project/29/interface/api/1057)
 *
 * @分类 [用工端调用接口-合作关系↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_78)
 * @标签 `用工端调用接口-合作关系`
 * @请求头 `POST /out/cooperate/invite`
 * @更新时间 `2024-08-23 16:22:09`
 */

/* **请求body类型** */
export interface PostOutCooperateInviteBody {
  address?: string
  email?: string
  enterId?: number
  laborId?: number
  mobile?: string
  name?: string
  userName?: string
}

/* **返回类型** */
export type PostOutCooperateInviteResponse = boolean

/* **请求函数** */
export async function postOutCooperateInvite(body: PostOutCooperateInviteBody): Promise<any> {
  return request(`/out/cooperate/invite`, {
    method: Method.POST,

    data: body,
  })
}

/**
 * 接口 [21.获取当前登陆账户信息↗](http://fe.jiuworker.com:3000/project/29/interface/api/883)
 *
 * @分类 [登陆登出-用工端兼容酒店格式↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_79)
 * @标签 `登陆登出-用工端兼容酒店格式`
 * @请求头 `GET /basic/info/account`
 * @更新时间 `2024-08-23 16:22:05`
 */

/* **返回类型** */
export interface GetBasicInfoAccountResponse {
  appVersion: number
  companyUserId: number
  /**
   * 是否首次登陆
   */
  isFirstLogin: boolean
  laborCompanyId: number
  laborCompanyName: string
  mobile: string
  /**
   * 试用到期时间
   */
  probationPeriod: string
  roleId: number
  /**
   * 是否是班组长
   */
  teamLeader: boolean
  token: string
  userId: number
  userName: string
}

/* **请求函数** */
export async function getBasicInfoAccount(): Promise<any> {
  return request(`/basic/info/account`, {
    method: Method.GET,
  })
}

/**
 * 接口 [4.密码登陆↗](http://fe.jiuworker.com:3000/project/29/interface/api/885)
 *
 * @分类 [登陆登出-用工端兼容酒店格式↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_79)
 * @标签 `登陆登出-用工端兼容酒店格式`
 * @请求头 `GET /basic/login/account`
 * @更新时间 `2024-08-23 16:22:05`
 */

/* **请求query类型** */
export interface GetBasicLoginAccountQuery {
  /**
   * password
   */
  password: string
  /**
   * phone
   */
  phone: string
}

/* **返回类型** */
export interface GetBasicLoginAccountResponse {
  /**
   * 失败次数
   */
  failTime: number
  laborList: {
    companyId: number
    companyName: string
  }[]
  name: string
  openId: string
  phone: string
  /**
   * 冻结时间/秒
   */
  second: number
  token: string
}

/* **请求函数** */
export async function getBasicLoginAccount(query: GetBasicLoginAccountQuery): Promise<any> {
  return request(`/basic/login/account`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [13.找回密码↗](http://fe.jiuworker.com:3000/project/29/interface/api/887)
 *
 * @分类 [登陆登出-用工端兼容酒店格式↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_79)
 * @标签 `登陆登出-用工端兼容酒店格式`
 * @请求头 `GET /basic/login/find/password`
 * @更新时间 `2024-08-23 16:22:05`
 */

/* **请求query类型** */
export interface GetBasicLoginFindPasswordQuery {
  /**
   * 短信验证码
   */
  code?: string
  /**
   * password
   */
  password: string
  /**
   * phone
   */
  phone: string
}

/* **返回类型** */
export type GetBasicLoginFindPasswordResponse = boolean

/* **请求函数** */
export async function getBasicLoginFindPassword(query: GetBasicLoginFindPasswordQuery): Promise<any> {
  return request(`/basic/login/find/password`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [23.是否是首次登陆↗](http://fe.jiuworker.com:3000/project/29/interface/api/889)
 *
 * @分类 [登陆登出-用工端兼容酒店格式↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_79)
 * @标签 `登陆登出-用工端兼容酒店格式`
 * @请求头 `GET /basic/login/first`
 * @更新时间 `2024-08-23 16:22:05`
 */

/* **请求query类型** */
export interface GetBasicLoginFirstQuery {
  /**
   * phone
   */
  phone?: string
}

/* **返回类型** */
export type GetBasicLoginFirstResponse = boolean

/* **请求函数** */
export async function getBasicLoginFirst(query: GetBasicLoginFirstQuery): Promise<any> {
  return request(`/basic/login/first`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [20.修改新密码-仅当密码是初始密码的时候↗](http://fe.jiuworker.com:3000/project/29/interface/api/891)
 *
 * @分类 [登陆登出-用工端兼容酒店格式↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_79)
 * @标签 `登陆登出-用工端兼容酒店格式`
 * @请求头 `GET /basic/login/new/password`
 * @更新时间 `2024-08-23 16:22:05`
 */

/* **请求query类型** */
export interface GetBasicLoginNewPasswordQuery {
  /**
   * password
   */
  password: string
}

/* **返回类型** */
export type GetBasicLoginNewPasswordResponse = boolean

/* **请求函数** */
export async function getBasicLoginNewPassword(query: GetBasicLoginNewPasswordQuery): Promise<any> {
  return request(`/basic/login/new/password`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [22.获取登陆人的菜单权限-树↗](http://fe.jiuworker.com:3000/project/29/interface/api/893)
 *
 * @分类 [登陆登出-用工端兼容酒店格式↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_79)
 * @标签 `登陆登出-用工端兼容酒店格式`
 * @请求头 `GET /basic/menu/account`
 * @更新时间 `2024-08-23 16:22:05`
 */

/* **返回类型** */
export type GetBasicMenuAccountResponse = {
  /**
   * 编码
   */
  code: string
  id: number
  /**
   * 名字
   */
  menuName: string
  menuType: number
  menuVOList: {}[]
  parentId: number
  rootId: number
  /**
   * 链接地址
   */
  url: string
}[]

/* **请求函数** */
export async function getBasicMenuAccount(): Promise<any> {
  return request(`/basic/menu/account`, {
    method: Method.GET,
  })
}

/**
 * 接口 [22.1获取登陆人的菜单权限-列表↗](http://fe.jiuworker.com:3000/project/29/interface/api/895)
 *
 * @分类 [登陆登出-用工端兼容酒店格式↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_79)
 * @标签 `登陆登出-用工端兼容酒店格式`
 * @请求头 `GET /basic/menu/account/list`
 * @更新时间 `2024-08-23 16:22:05`
 */

/* **返回类型** */
export type GetBasicMenuAccountListResponse = {
  code: string
  gmtCreate: string
  gmtModified: string
  id: number
  name: string
  parentId: number
  rootId: number
  type: number
  url: string
}[]

/* **请求函数** */
export async function getBasicMenuAccountList(): Promise<any> {
  return request(`/basic/menu/account/list`, {
    method: Method.GET,
  })
}

/**
 * 接口 [24.是否是班组长↗](http://fe.jiuworker.com:3000/project/29/interface/api/897)
 *
 * @分类 [登陆登出-用工端兼容酒店格式↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_79)
 * @标签 `登陆登出-用工端兼容酒店格式`
 * @请求头 `GET /basic/menu/team/leader`
 * @更新时间 `2024-08-23 16:22:05`
 */

/* **返回类型** */
export type GetBasicMenuTeamLeaderResponse = boolean

/* **请求函数** */
export async function getBasicMenuTeamLeader(): Promise<any> {
  return request(`/basic/menu/team/leader`, {
    method: Method.GET,
  })
}

/**
 * 接口 [2.获取短信验证码↗](http://fe.jiuworker.com:3000/project/29/interface/api/899)
 *
 * @分类 [登陆登出-用工端兼容酒店格式↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_79)
 * @标签 `登陆登出-用工端兼容酒店格式`
 * @请求头 `GET /basic/out/code`
 * @更新时间 `2024-08-23 16:22:05`
 */

/* **请求query类型** */
export interface GetBasicOutCodeQuery {
  /**
   * code
   */
  code?: string
  /**
   * phone
   */
  phone: string
}

/* **返回类型** */
export type GetBasicOutCodeResponse = string

/* **请求函数** */
export async function getBasicOutCode(query: GetBasicOutCodeQuery): Promise<any> {
  return request(`/basic/out/code`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [3.短信验证码登陆↗](http://fe.jiuworker.com:3000/project/29/interface/api/901)
 *
 * @分类 [登陆登出-用工端兼容酒店格式↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_79)
 * @标签 `登陆登出-用工端兼容酒店格式`
 * @请求头 `GET /basic/out/code/login`
 * @更新时间 `2024-08-23 16:22:05`
 */

/* **请求query类型** */
export interface GetBasicOutCodeLoginQuery {
  /**
   * code
   */
  code: string
  /**
   * phone
   */
  phone: string
}

/* **返回类型** */
export interface GetBasicOutCodeLoginResponse {
  laborList: {
    companyId: number
    companyName: string
  }[]
  name: string
  openId: string
  phone: string
  token: string
}

/* **请求函数** */
export async function getBasicOutCodeLogin(query: GetBasicOutCodeLoginQuery): Promise<any> {
  return request(`/basic/out/code/login`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [12.如果加入多个企业组织，登陆后在调这个接口获取token↗](http://fe.jiuworker.com:3000/project/29/interface/api/903)
 *
 * @分类 [登陆登出-用工端兼容酒店格式↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_79)
 * @标签 `登陆登出-用工端兼容酒店格式`
 * @请求头 `GET /basic/token/account`
 * @更新时间 `2024-08-23 16:22:05`
 */

/* **请求query类型** */
export interface GetBasicTokenAccountQuery {
  /**
   * companyId
   */
  companyId: string
  /**
   * phone
   */
  phone?: string
}

/* **返回类型** */
export interface GetBasicTokenAccountResponse {
  laborList: {
    companyId: number
    companyName: string
  }[]
  name: string
  openId: string
  phone: string
  token: string
}

/* **请求函数** */
export async function getBasicTokenAccount(query: GetBasicTokenAccountQuery): Promise<any> {
  return request(`/basic/token/account`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [40.发薪列表-账户资金情况↗](http://fe.jiuworker.com:3000/project/29/interface/api/1133)
 *
 * @分类 [结算信息↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_80)
 * @标签 `结算信息`
 * @请求头 `GET /settle/advance/funds`
 * @更新时间 `2024-08-23 16:22:11`
 */

/* **请求query类型** */
export interface GetSettleAdvanceFundsQuery {
  /**
   * teamId
   */
  teamId?: string
}

/* **返回类型** */
export interface GetSettleAdvanceFundsResponse {
  /**
   * 可垫资额度
   */
  advanceAmount: number
  /**
   * 是否垫资 0否1是
   */
  advanceEnable: number
  /**
   * 账户余额
   */
  balance: number
  /**
   * 垫资利率-日利率 1000元每天0.51钱 返回0.51
   */
  interestDayRate: number
  /**
   * 垫资利率-年利率 10.01% 返回10.01
   */
  interestRate: number
  laborCompanyId: number
  /**
   * 总授信额度余额
   */
  totalAdvanceAmount: number
}

/* **请求函数** */
export async function getSettleAdvanceFunds(query: GetSettleAdvanceFundsQuery): Promise<any> {
  return request(`/settle/advance/funds`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [42.垫资记录-列表↗](http://fe.jiuworker.com:3000/project/29/interface/api/1135)
 *
 * @分类 [结算信息↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_80)
 * @标签 `结算信息`
 * @请求头 `GET /settle/advance/list`
 * @更新时间 `2024-08-23 16:22:11`
 */

/* **请求query类型** */
export interface GetSettleAdvanceListQuery {
  /**
   * endDate
   */
  endDate?: string
  /**
   * pageNum
   */
  pageNum?: number
  /**
   * pageSize
   */
  pageSize?: number
  /**
   * serialNumber
   */
  serialNumber?: string
  /**
   * startDate
   */
  startDate?: string
  /**
   * status
   */
  status?: string
  /**
   * teamId
   */
  teamId?: string
}

/* **返回类型** */
export interface GetSettleAdvanceListResponse {
  data: {
    advanceApplyId: number
    /**
     * 垫资时间
     */
    advanceTime: string
    /**
     * 金额
     */
    amount: number
    /**
     * 日利率
     */
    dayRate: number
    /**
     * 利息
     */
    interest: number
    interestRate: number
    /**
     * 已归还金额
     */
    paybackAmount: number
    /**
     * 已归还利息
     */
    paybackInterest: number
    /**
     * 状态 0未回款1已回款
     */
    paybackStatus: number
    /**
     * 结/还款时间
     */
    paybackTime: string
    payeeIdentity: string
    payerIdentity: string
    repayStatus: number
    repayTime: string
    /**
     * 交易号
     */
    serialNumber: string
    /**
     * 班组名称
     */
    teamName: string
  }[]
  total: number
}

/* **请求函数** */
export async function getSettleAdvanceList(query: GetSettleAdvanceListQuery): Promise<any> {
  return request(`/settle/advance/list`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [41.垫资记录-概览↗](http://fe.jiuworker.com:3000/project/29/interface/api/1137)
 *
 * @分类 [结算信息↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_80)
 * @标签 `结算信息`
 * @请求头 `GET /settle/advance/overview`
 * @更新时间 `2024-08-23 16:22:11`
 */

/* **返回类型** */
export interface GetSettleAdvanceOverviewResponse {
  /**
   * 垫资欠款
   */
  amount: number
  /**
   * 利息欠款
   */
  interest: number
  /**
   * 累计垫资
   */
  totalAmount: number
  /**
   * 累计利息
   */
  totalInterest: number
}

/* **请求函数** */
export async function getSettleAdvanceOverview(): Promise<any> {
  return request(`/settle/advance/overview`, {
    method: Method.GET,
  })
}

/**
 * 接口 [44.垫资还款查询↗](http://fe.jiuworker.com:3000/project/29/interface/api/1139)
 *
 * @分类 [结算信息↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_80)
 * @标签 `结算信息`
 * @请求头 `GET /settle/advance/query`
 * @更新时间 `2024-08-23 16:22:11`
 */

/* **请求query类型** */
export interface GetSettleAdvanceQueryQuery {
  /**
   * id
   */
  id: string
}

/* **返回类型** */
export interface GetSettleAdvanceQueryResponse {
  advanceApplyId: number
  /**
   * 须还本金
   */
  amount: number
  /**
   * 账户余额
   */
  balance: number
  /**
   * 须还利息
   */
  interest: number
}

/* **请求函数** */
export async function getSettleAdvanceQuery(query: GetSettleAdvanceQueryQuery): Promise<any> {
  return request(`/settle/advance/query`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [45.垫资还款↗](http://fe.jiuworker.com:3000/project/29/interface/api/1141)
 *
 * @分类 [结算信息↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_80)
 * @标签 `结算信息`
 * @请求头 `POST /settle/advance/repay`
 * @更新时间 `2024-08-23 16:22:11`
 */

/* **请求body类型** */
export interface PostSettleAdvanceRepayBody {
  /**
   * 垫资记录id
   */
  id?: number
  /**
   * 支付密码-md5
   */
  password?: string
}

/* **返回类型** */
export type PostSettleAdvanceRepayResponse = boolean

/* **请求函数** */
export async function postSettleAdvanceRepay(body: PostSettleAdvanceRepayBody): Promise<any> {
  return request(`/settle/advance/repay`, {
    method: Method.POST,

    data: body,
  })
}

/**
 * 接口 [43.账户余额查询↗](http://fe.jiuworker.com:3000/project/29/interface/api/1143)
 *
 * @分类 [结算信息↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_80)
 * @标签 `结算信息`
 * @请求头 `GET /settle/balance`
 * @更新时间 `2024-08-23 16:22:11`
 */

/* **返回类型** */
export type GetSettleBalanceResponse = string

/* **请求函数** */
export async function getSettleBalance(): Promise<any> {
  return request(`/settle/balance`, {
    method: Method.GET,
  })
}

/**
 * 接口 [50.提现接口↗](http://fe.jiuworker.com:3000/project/29/interface/api/1145)
 *
 * @分类 [结算信息↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_80)
 * @标签 `结算信息`
 * @请求头 `POST /settle/cash/out`
 * @更新时间 `2024-08-23 16:22:11`
 */

/* **请求body类型** */
export interface PostSettleCashOutBody {
  /**
   * 提现金额
   */
  amount?: number
  /**
   * 支付密码-md5
   */
  password?: string
}

/* **返回类型** */
export type PostSettleCashOutResponse = boolean

/* **请求函数** */
export async function postSettleCashOut(body: PostSettleCashOutBody): Promise<any> {
  return request(`/settle/cash/out`, {
    method: Method.POST,

    data: body,
  })
}

/**
 * 接口 [32.用工单位结算列表-详情概览↗](http://fe.jiuworker.com:3000/project/29/interface/api/1147)
 *
 * @分类 [结算信息↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_80)
 * @标签 `结算信息`
 * @请求头 `GET /settle/detail/employer/overview`
 * @更新时间 `2024-08-23 16:22:11`
 */

/* **请求query类型** */
export interface GetSettleDetailEmployerOverviewQuery {
  /**
   * orderId
   */
  orderId?: string
}

/* **返回类型** */
export interface GetSettleDetailEmployerOverviewResponse {
  /**
   * 实发工资
   */
  actualPay: number
  /**
   * 用工公司名称
   */
  employerCompanyName: string
  id: number
  laborCompanyId: number
  /**
   * 劳务公司名称
   */
  laborCompanyName: string
  /**
   * 用工计划单号
   */
  orderId: number
  /**
   * 用工计划名称
   */
  orderName: string
  /**
   * 计件
   */
  piece: number
  /**
   * 应发工资
   */
  shouldPay: number
  teamId: number
  /**
   * 班组名称
   */
  teamName: string
  /**
   * 总天数
   */
  totalDayCount: number
  /**
   * 工时
   */
  workHour: string
}

/* **请求函数** */
export async function getSettleDetailEmployerOverview(query: GetSettleDetailEmployerOverviewQuery): Promise<any> {
  return request(`/settle/detail/employer/overview`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [10.班组人员待结算薪资↗](http://fe.jiuworker.com:3000/project/29/interface/api/1149)
 *
 * @分类 [结算信息↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_80)
 * @标签 `结算信息`
 * @请求头 `GET /settle/list/employee/salary`
 * @更新时间 `2024-08-23 16:22:12`
 */

/* **请求query类型** */
export interface GetSettleListEmployeeSalaryQuery {
  /**
   * 0未认证 1已认证
   */
  authType?: string
  /**
   * endDate
   */
  endDate: string
  /**
   * startDate
   */
  startDate: string
  /**
   * teamId
   */
  teamId: string
  /**
   * userIds
   */
  userIds?: string
}

/* **返回类型** */
export type GetSettleListEmployeeSalaryResponse = {
  /**
   * 认证类型 0当前发薪渠道不支持 1当前发薪渠道已认证
   */
  authType: number
  /**
   * 用户名称
   */
  avatar: string
  /**
   * 身份证
   */
  idcard: string
  /**
   * 手机号
   */
  mobile: string
  /**
   * 计件
   */
  piece: number
  /**
   * 结算id列表
   */
  settleIds: number[]
  teamEmployeeId: number
  userId: number
  /**
   * 用户名称
   */
  userName: string
  /**
   * 计价方式 0:按小时 1:按天 2:按次
   */
  valuationType: number
  /**
   * 待发工资
   */
  waitPay: number
  /**
   * 工时
   */
  workDay: string
  /**
   * 工时
   */
  workHour: string
}[]

/* **请求函数** */
export async function getSettleListEmployeeSalary(query: GetSettleListEmployeeSalaryQuery): Promise<any> {
  return request(`/settle/list/employee/salary`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [30.用工单位结算列表↗](http://fe.jiuworker.com:3000/project/29/interface/api/1151)
 *
 * @分类 [结算信息↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_80)
 * @标签 `结算信息`
 * @请求头 `GET /settle/list/employer`
 * @更新时间 `2024-08-23 16:22:12`
 */

/* **请求query类型** */
export interface GetSettleListEmployerQuery {
  /**
   * name
   */
  name?: string
  /**
   * pageNum
   */
  pageNum?: number
  /**
   * pageSize
   */
  pageSize?: number
  /**
   * 状态 0待结算 1未结清(已结算) 2已结清 9已结算+已结清
   */
  status?: string
}

/* **返回类型** */
export interface GetSettleListEmployerResponse {
  data: {
    /**
     * 实发工资
     */
    actualPay: number
    employerCompanyId: number
    /**
     * 用工公司名称
     */
    employerCompanyName: string
    employerSettleId: number
    id: number
    /**
     * 发票状态 0不可开票 1可开票
     */
    invoiceStatus: number
    laborCompanyId: number
    /**
     * 用工计划单号
     */
    orderId: number
    /**
     * 用工计划名称
     */
    orderName: string
    /**
     * 计件
     */
    piece: number
    /**
     * 服务费
     */
    serviceFee: number
    /**
     * 手续费状态 0未结清1已结清
     */
    serviceFeeStatus: number
    /**
     * 应发工资
     */
    shouldPay: number
    /**
     * 状态 0待结算 1已结算 2已结清
     */
    status: number
    teamId: number
    /**
     * 班组名称
     */
    teamName: string
    /**
     * 总天数
     */
    totalDayCount: number
    /**
     * 工时
     */
    workHour: string
  }[]
  total: number
}

/* **请求函数** */
export async function getSettleListEmployer(query: GetSettleListEmployerQuery): Promise<any> {
  return request(`/settle/list/employer`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [31.用工单位结算列表-详情页面-交易记录↗](http://fe.jiuworker.com:3000/project/29/interface/api/1153)
 *
 * @分类 [结算信息↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_80)
 * @标签 `结算信息`
 * @请求头 `GET /settle/list/employer/detail`
 * @更新时间 `2024-08-23 16:22:12`
 */

/* **请求query类型** */
export interface GetSettleListEmployerDetailQuery {
  /**
   * orderId
   */
  orderId?: string
  /**
   * pageNum
   */
  pageNum?: number
  /**
   * pageSize
   */
  pageSize?: number
}

/* **返回类型** */
export interface GetSettleListEmployerDetailResponse {
  data: {
    /**
     * 付款金额
     */
    amount: string
    /**
     * 附件
     */
    attachment: {
      /**
       * 附件名称
       */
      name: string
      /**
       * 附件地址
       */
      path: string
      /**
       * 附件大小
       */
      size: string
    }[]
    id: number
    laborCompanyId: number
    /**
     * 用工计划单号
     */
    orderId: number
    /**
     * 备注
     */
    remark: string
    teamId: number
    /**
     * 时间
     */
    time: string
    /**
     * 交易流水号
     */
    transactionSerialNumber: string
  }[]
  total: number
}

/* **请求函数** */
export async function getSettleListEmployerDetail(query: GetSettleListEmployerDetailQuery): Promise<any> {
  return request(`/settle/list/employer/detail`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [12.付款记录↗](http://fe.jiuworker.com:3000/project/29/interface/api/1155)
 *
 * @分类 [结算信息↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_80)
 * @标签 `结算信息`
 * @请求头 `GET /settle/list/payRecord`
 * @更新时间 `2024-08-23 16:22:12`
 */

/* **请求query类型** */
export interface GetSettleListPayRecordQuery {
  /**
   * accountName
   */
  accountName?: string
  /**
   * endDate
   */
  endDate?: string
  /**
   * pageNum
   */
  pageNum?: number
  /**
   * pageSize
   */
  pageSize?: number
  /**
   * startDate
   */
  startDate?: string
  /**
   * 状态  1:正常 2:确认中 3:失败
   */
  status?: string
  /**
   * teamId
   */
  teamId?: string
  /**
   * transactionSerialNumber
   */
  transactionSerialNumber?: string
  /**
   * userName
   */
  userName?: string
}

/* **返回类型** */
export interface GetSettleListPayRecordResponse {
  data: {
    /**
     * 结算结束日期
     */
    endDate: string
    gmtCreate: string
    gmtModified: string
    id: number
    /**
     * 劳务公司id
     */
    laborCompanyId: number
    /**
     * 结算出来的待发金额
     */
    oldSalary: number
    /**
     * 付款账户
     */
    payAccount: string
    /**
     * 备注
     */
    remark: string
    /**
     * 支付的薪资
     */
    salary: number
    /**
     * 手续费
     */
    serviceFee: number
    /**
     * 结算开始日期
     */
    startDate: string
    /**
     * 异常状态  1:正常 2:确认中 3:失败
     */
    status: number
    /**
     * 到账时间
     */
    successTime: string
    /**
     * 班组id
     */
    teamId: number
    /**
     * 班组名称
     */
    teamName: string
    /**
     * 交易流水号
     */
    transactionSerialNumber: string
    /**
     * 收款账户
     */
    userAccount: string
    /**
     * 用户id
     */
    userId: number
    /**
     * 用户名称
     */
    userName: string
  }[]
  total: number
}

/* **请求函数** */
export async function getSettleListPayRecord(query: GetSettleListPayRecordQuery): Promise<any> {
  return request(`/settle/list/payRecord`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [2.待结算班组↗](http://fe.jiuworker.com:3000/project/29/interface/api/1157)
 *
 * @分类 [结算信息↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_80)
 * @标签 `结算信息`
 * @请求头 `GET /settle/list/team`
 * @更新时间 `2024-08-23 16:22:12`
 */

/* **请求query类型** */
export interface GetSettleListTeamQuery {
  /**
   * pageNum
   */
  pageNum?: number
  /**
   * pageSize
   */
  pageSize?: number
}

/* **返回类型** */
export interface GetSettleListTeamResponse {
  data: {
    /**
     * 实发工资
     */
    actualPay: number
    /**
     * 今日预计收入
     */
    incomeToday: number
    /**
     * 今日工资
     */
    payToday: number
    /**
     * 计件
     */
    piece: number
    /**
     * 自建班组手续费，null不展示
     */
    serviceFee: number
    /**
     * 应发工资
     */
    shouldPay: number
    teamId: number
    /**
     * 班组名称
     */
    teamName: string
    /**
     * 总天数
     */
    totalDayCount: number
    /**
     * 计价方式 0:按小时 1:按天 2:按次
     */
    valuationType: number
    /**
     * 已发工资
     */
    waitPay: number
    /**
     * 工时
     */
    workHour: string
  }[]
  total: number
}

/* **请求函数** */
export async function getSettleListTeam(query: GetSettleListTeamQuery): Promise<any> {
  return request(`/settle/list/team`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [20.发薪列表-班组维度↗](http://fe.jiuworker.com:3000/project/29/interface/api/1159)
 *
 * @分类 [结算信息↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_80)
 * @标签 `结算信息`
 * @请求头 `GET /settle/list/team/pay`
 * @更新时间 `2024-08-23 16:22:12`
 */

/* **请求query类型** */
export interface GetSettleListTeamPayQuery {
  /**
   * idcard
   */
  idcard?: string
  /**
   * mobile
   */
  mobile?: string
  /**
   * pageNum
   */
  pageNum?: number
  /**
   * pageSize
   */
  pageSize?: number
  /**
   * teamId
   */
  teamId: string
  /**
   * userName
   */
  userName?: string
}

/* **返回类型** */
export interface GetSettleListTeamPayResponse {
  data: {
    /**
     * 实发工资
     */
    actualPay: number
    /**
     * 头像
     */
    avatar: string
    /**
     * 计件
     */
    piece: number
    /**
     * 应发工资
     */
    shouldPay: number
    /**
     * 总天数
     */
    totalDayCount: number
    userId: number
    /**
     * 用户名称
     */
    userName: string
    /**
     * 待发工资
     */
    waitPay: number
    /**
     * 工时
     */
    workHour: string
  }[]
  total: number
}

/* **请求函数** */
export async function getSettleListTeamPay(query: GetSettleListTeamPayQuery): Promise<any> {
  return request(`/settle/list/team/pay`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [23.发薪列表-详情-人员维度↗](http://fe.jiuworker.com:3000/project/29/interface/api/1161)
 *
 * @分类 [结算信息↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_80)
 * @标签 `结算信息`
 * @请求头 `GET /settle/list/user/pay`
 * @更新时间 `2024-08-23 16:22:12`
 */

/* **请求query类型** */
export interface GetSettleListUserPayQuery {
  /**
   * pageNum
   */
  pageNum?: number
  /**
   * pageSize
   */
  pageSize?: number
  /**
   * userId
   */
  userId: string
}

/* **返回类型** */
export interface GetSettleListUserPayResponse {
  data: {
    /**
     * 结算结束日期
     */
    endDate: string
    gmtCreate: string
    gmtModified: string
    id: number
    /**
     * 劳务公司id
     */
    laborCompanyId: number
    /**
     * 结算出来的待发金额
     */
    oldSalary: number
    /**
     * 付款账户
     */
    payAccount: string
    /**
     * 备注
     */
    remark: string
    /**
     * 支付的薪资
     */
    salary: number
    /**
     * 手续费
     */
    serviceFee: number
    /**
     * 结算开始日期
     */
    startDate: string
    /**
     * 异常状态  1:正常 2:确认中 3:失败
     */
    status: number
    /**
     * 到账时间
     */
    successTime: string
    /**
     * 班组id
     */
    teamId: number
    /**
     * 班组名称
     */
    teamName: string
    /**
     * 交易流水号
     */
    transactionSerialNumber: string
    /**
     * 收款账户
     */
    userAccount: string
    /**
     * 用户id
     */
    userId: number
    /**
     * 用户名称
     */
    userName: string
  }[]
  total: number
}

/* **请求函数** */
export async function getSettleListUserPay(query: GetSettleListUserPayQuery): Promise<any> {
  return request(`/settle/list/user/pay`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [1.用工结算概览↗](http://fe.jiuworker.com:3000/project/29/interface/api/1163)
 *
 * @分类 [结算信息↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_80)
 * @标签 `结算信息`
 * @请求头 `GET /settle/overview`
 * @更新时间 `2024-08-23 16:22:12`
 */

/* **返回类型** */
export interface GetSettleOverviewResponse {
  /**
   * 账户余额-根据当前渠道返回
   */
  balance: string
  balanceAllVo: {
    /**
     * 支付宝账户余额
     */
    aliAmount: number
    /**
     * 支付宝签约状态 0否1是
     */
    aliSign: number
    /**
     * 支付渠道 WECHAT ALIPAY
     */
    payChannel: string
    /**
     * 微信账户余额
     */
    wechatAmount: number
    /**
     * 微工卡签约状态 0否1是
     */
    wechatSign: number
  }
  /**
   * 今日预估收入待发
   */
  exceptedIncomeToday: number
  /**
   * 全部待发
   */
  waitPay: number
  /**
   * 今日待发
   */
  waitPayToday: number
}

/* **请求函数** */
export async function getSettleOverview(): Promise<any> {
  return request(`/settle/overview`, {
    method: Method.GET,
  })
}

/**
 * 接口 [11.发薪↗](http://fe.jiuworker.com:3000/project/29/interface/api/1165)
 *
 * @分类 [结算信息↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_80)
 * @标签 `结算信息`
 * @请求头 `POST /settle/pay`
 * @更新时间 `2024-08-23 16:22:12`
 */

/* **请求body类型** */
export interface PostSettlePayBody {
  /**
   * 是否垫资 0否1是 默认否
   */
  advanceType?: number
  /**
   * 结算员工列表
   */
  employeePays?: {
    /**
     * 付款金额
     */
    remark?: string
    /**
     * 付款金额
     */
    salary?: number
    /**
     * 结算id列表
     */
    settleIds?: number[]
    /**
     * 用户id
     */
    userId?: number
  }[]
  /**
   * 结束时间
   */
  endDate?: string
  /**
   * 支付密码
   */
  payPassword?: string
  /**
   * 开始时间
   */
  startDate?: string
  /**
   * 班组id
   */
  teamId?: number
}

/* **返回类型** */
export interface PostSettlePayResponse {
  /**
   * 失败次数
   */
  failTime: number
  /**
   * 冻结时间/秒
   */
  second: number
}

/* **请求函数** */
export async function postSettlePay(body: PostSettlePayBody): Promise<any> {
  return request(`/settle/pay`, {
    method: Method.POST,

    data: body,
  })
}

/**
 * 接口 [11.发薪失败记录-重试发薪↗](http://fe.jiuworker.com:3000/project/29/interface/api/1167)
 *
 * @分类 [结算信息↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_80)
 * @标签 `结算信息`
 * @请求头 `POST /settle/payAgain`
 * @更新时间 `2023-12-20 15:28:09`
 */

/* **请求body类型** */
export interface PostSettlePayAgainBody {
  id?: number
}

/* **返回类型** */
export type PostSettlePayAgainResponse = boolean

/* **请求函数** */
export async function postSettlePayAgain(body: PostSettlePayAgainBody): Promise<any> {
  return request(`/settle/payAgain`, {
    method: Method.POST,

    data: body,
  })
}

/**
 * 接口 [21.发薪列表-班组概览↗](http://fe.jiuworker.com:3000/project/29/interface/api/1169)
 *
 * @分类 [结算信息↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_80)
 * @标签 `结算信息`
 * @请求头 `GET /settle/team/pay/overview`
 * @更新时间 `2024-08-23 16:22:12`
 */

/* **请求query类型** */
export interface GetSettleTeamPayOverviewQuery {
  /**
   * teamId
   */
  teamId: string
}

/* **返回类型** */
export interface GetSettleTeamPayOverviewResponse {
  /**
   * 实发工资
   */
  actualPay: number
  /**
   * 今日预计收入
   */
  incomeToday: number
  /**
   * 今日工资
   */
  payToday: number
  /**
   * 计件
   */
  piece: number
  /**
   * 自建班组手续费，null不展示
   */
  serviceFee: number
  /**
   * 应发工资
   */
  shouldPay: number
  teamId: number
  /**
   * 班组名称
   */
  teamName: string
  /**
   * 总天数
   */
  totalDayCount: number
  /**
   * 计价方式 0:按小时 1:按天 2:按次
   */
  valuationType: number
  /**
   * 已发工资
   */
  waitPay: number
  /**
   * 工时
   */
  workHour: string
}

/* **请求函数** */
export async function getSettleTeamPayOverview(query: GetSettleTeamPayOverviewQuery): Promise<any> {
  return request(`/settle/team/pay/overview`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [22.发薪列表-详情-概览↗](http://fe.jiuworker.com:3000/project/29/interface/api/1171)
 *
 * @分类 [结算信息↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_80)
 * @标签 `结算信息`
 * @请求头 `GET /settle/user/pay/overview`
 * @更新时间 `2024-08-23 16:22:12`
 */

/* **请求query类型** */
export interface GetSettleUserPayOverviewQuery {
  /**
   * userId
   */
  userId: string
}

/* **返回类型** */
export interface GetSettleUserPayOverviewResponse {
  /**
   * 账户名称
   */
  accountName: string
  /**
   * 实/已发工资
   */
  actualPay: number
  /**
   * 头像
   */
  avatar: string
  id: number
  /**
   * 计件
   */
  piece: number
  /**
   * 应发工资
   */
  shouldPay: number
  userId: number
  /**
   * 用户名称
   */
  userName: string
  /**
   * 待发工资
   */
  waitPay: number
  /**
   * 工时
   */
  workHour: string
}

/* **请求函数** */
export async function getSettleUserPayOverview(query: GetSettleUserPayOverviewQuery): Promise<any> {
  return request(`/settle/user/pay/overview`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [13.付款详情↗](http://fe.jiuworker.com:3000/project/29/interface/api/1981)
 *
 * @分类 [结算信息↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_80)
 * @标签 `结算信息`
 * @请求头 `GET /settle/detail/pay`
 * @更新时间 `2024-08-23 16:22:12`
 */

/* **请求query类型** */
export interface GetSettleDetailPayQuery {
  /**
   * payId
   */
  payId: string
}

/* **返回类型** */
export interface GetSettleDetailPayResponse {
  /**
   * 结算结束日期
   */
  endDate: string
  gmtCreate: string
  gmtModified: string
  id: number
  /**
   * 劳务公司id
   */
  laborCompanyId: number
  /**
   * 结算出来的待发金额
   */
  oldSalary: number
  /**
   * 付款账户
   */
  payAccount: string
  /**
   * 备注
   */
  remark: string
  /**
   * 支付的薪资
   */
  salary: number
  /**
   * 手续费
   */
  serviceFee: number
  /**
   * 结算开始日期
   */
  startDate: string
  /**
   * 异常状态  1:正常 2:确认中 3:失败
   */
  status: number
  /**
   * 到账时间
   */
  successTime: string
  /**
   * 班组id
   */
  teamId: number
  /**
   * 班组名称
   */
  teamName: string
  /**
   * 交易流水号
   */
  transactionSerialNumber: string
  /**
   * 收款账户
   */
  userAccount: string
  /**
   * 用户id
   */
  userId: number
  /**
   * 用户名称
   */
  userName: string
}

/* **请求函数** */
export async function getSettleDetailPay(query: GetSettleDetailPayQuery): Promise<any> {
  return request(`/settle/detail/pay`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [12.付款记录-excel导出↗](http://fe.jiuworker.com:3000/project/29/interface/api/1983)
 *
 * @分类 [结算信息↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_80)
 * @标签 `结算信息`
 * @请求头 `GET /settle/export/payRecord`
 * @更新时间 `2024-08-23 16:22:12`
 */

/* **请求query类型** */
export interface GetSettleExportPayRecordQuery {
  /**
   * accountName
   */
  accountName?: string
  /**
   * endDate
   */
  endDate?: string
  /**
   * startDate
   */
  startDate?: string
  /**
   * 状态  1:正常 2:确认中 3:失败
   */
  status?: string
  /**
   * teamId
   */
  teamId?: string
  /**
   * transactionSerialNumber
   */
  transactionSerialNumber?: string
  /**
   * userName
   */
  userName?: string
}

/* **返回类型** */
export type GetSettleExportPayRecordResponse = any

/* **请求函数** */
export async function getSettleExportPayRecord(query: GetSettleExportPayRecordQuery): Promise<any> {
  return request(`/settle/export/payRecord`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [1.新增角色↗](http://fe.jiuworker.com:3000/project/29/interface/api/1103)
 *
 * @分类 [角色管理↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_82)
 * @标签 `角色管理`
 * @请求头 `POST /role/add`
 * @更新时间 `2024-08-23 16:22:11`
 */

/* **请求body类型** */
export interface PostRoleAddBody {
  id?: number
  /**
   * 权限id集合
   */
  permissionIds?: number[]
  /**
   * 角色名称
   */
  roleName?: string
}

/* **返回类型** */
export type PostRoleAddResponse = number

/* **请求函数** */
export async function postRoleAdd(body: PostRoleAddBody): Promise<any> {
  return request(`/role/add`, {
    method: Method.POST,

    data: body,
  })
}

/**
 * 接口 [5.删除角色↗](http://fe.jiuworker.com:3000/project/29/interface/api/1105)
 *
 * @分类 [角色管理↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_82)
 * @标签 `角色管理`
 * @请求头 `DELETE /role/delete/{id}`
 * @更新时间 `2024-08-23 16:22:11`
 */

/* **请求params类型** */
export interface DeleteRoleDeleteByIdParams {
  /**
   * id
   */
  id: string
}

/* **返回类型** */
export type DeleteRoleDeleteByIdResponse = boolean

/* **请求函数** */
export async function deleteRoleDeleteById(params: DeleteRoleDeleteByIdParams): Promise<any> {
  return request(`/role/delete/${params.id}`, {
    method: Method.DELETE,
  })
}

/**
 * 接口 [2.编辑角色-权限↗](http://fe.jiuworker.com:3000/project/29/interface/api/1107)
 *
 * @分类 [角色管理↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_82)
 * @标签 `角色管理`
 * @请求头 `PUT /role/edit`
 * @更新时间 `2024-08-23 16:22:11`
 */

/* **请求body类型** */
export interface PutRoleEditBody {
  /**
   * 权限id集合
   */
  permissionIds?: number[]
  roleId?: number
}

/* **返回类型** */
export type PutRoleEditResponse = boolean

/* **请求函数** */
export async function putRoleEdit(body: PutRoleEditBody): Promise<any> {
  return request(`/role/edit`, {
    method: Method.PUT,

    data: body,
  })
}

/**
 * 接口 [2.编辑角色-名称↗](http://fe.jiuworker.com:3000/project/29/interface/api/1109)
 *
 * @分类 [角色管理↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_82)
 * @标签 `角色管理`
 * @请求头 `PUT /role/edit/name`
 * @更新时间 `2024-08-23 16:22:11`
 */

/* **请求body类型** */
export interface PutRoleEditNameBody {
  id?: number
  /**
   * 角色名称
   */
  roleName?: string
}

/* **返回类型** */
export type PutRoleEditNameResponse = boolean

/* **请求函数** */
export async function putRoleEditName(body: PutRoleEditNameBody): Promise<any> {
  return request(`/role/edit/name`, {
    method: Method.PUT,

    data: body,
  })
}

/**
 * 接口 [4.查询角色列表↗](http://fe.jiuworker.com:3000/project/29/interface/api/1111)
 *
 * @分类 [角色管理↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_82)
 * @标签 `角色管理`
 * @请求头 `GET /role/list`
 * @更新时间 `2024-08-23 16:22:11`
 */

/* **请求query类型** */
export interface GetRoleListQuery {
  /**
   * name
   */
  name: string
}

/* **返回类型** */
export type GetRoleListResponse = {
  companyId: number
  defaultAdmin: number
  gmtCreate: string
  gmtModified: string
  id: number
  name: string
}[]

/* **请求函数** */
export async function getRoleList(query: GetRoleListQuery): Promise<any> {
  return request(`/role/list`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [4.查询角色列表↗](http://fe.jiuworker.com:3000/project/29/interface/api/1113)
 *
 * @分类 [角色管理↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_82)
 * @标签 `角色管理`
 * @请求头 `GET /role/list/select`
 * @更新时间 `2024-08-23 16:22:11`
 */

/* **请求query类型** */
export interface GetRoleListSelectQuery {
  /**
   * name
   */
  name: string
}

/* **返回类型** */
export type GetRoleListSelectResponse = {
  id: number
  /**
   * 班组名称
   */
  name: string
}[]

/* **请求函数** */
export async function getRoleListSelect(query: GetRoleListSelectQuery): Promise<any> {
  return request(`/role/list/select`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [4.1 查询角色列表-含权限↗](http://fe.jiuworker.com:3000/project/29/interface/api/1115)
 *
 * @分类 [角色管理↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_82)
 * @标签 `角色管理`
 * @请求头 `GET /role/list/with/permission`
 * @更新时间 `2024-08-23 16:22:11`
 */

/* **请求query类型** */
export interface GetRoleListWithPermissionQuery {
  /**
   * name
   */
  name: string
}

/* **返回类型** */
export type GetRoleListWithPermissionResponse = {
  id: number
  /**
   * 权限id集合
   */
  permissions: {
    /**
     * 是否选中
     */
    checked: number
    /**
     * 用户权限
     */
    children: {
      /**
       * 用户权限
       */
      children: {}[]
      /**
       * 权限名称
       */
      name: string
      /**
       * 权限id
       */
      permissionId: number
    }[]
    /**
     * 权限名称
     */
    name: string
    /**
     * 权限id
     */
    permissionId: number
  }[]
  /**
   * 角色名称
   */
  roleName: string
}[]

/* **请求函数** */
export async function getRoleListWithPermission(query: GetRoleListWithPermissionQuery): Promise<any> {
  return request(`/role/list/with/permission`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [3.查询角色详情↗](http://fe.jiuworker.com:3000/project/29/interface/api/1117)
 *
 * @分类 [角色管理↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_82)
 * @标签 `角色管理`
 * @请求头 `GET /role/query/{id}`
 * @更新时间 `2024-08-23 16:22:11`
 */

/* **请求params类型** */
export interface GetRoleQueryByIdParams {
  /**
   * id
   */
  id: string
}

/* **返回类型** */
export interface GetRoleQueryByIdResponse {
  id: number
  /**
   * 权限id集合
   */
  permissions: {
    /**
     * 是否选中
     */
    checked: number
    /**
     * 用户权限
     */
    children: {
      /**
       * 用户权限
       */
      children: {}[]
      /**
       * 权限名称
       */
      name: string
      /**
       * 权限id
       */
      permissionId: number
    }[]
    /**
     * 权限名称
     */
    name: string
    /**
     * 权限id
     */
    permissionId: number
  }[]
  /**
   * 角色名称
   */
  roleName: string
}

/* **请求函数** */
export async function getRoleQueryById(params: GetRoleQueryByIdParams): Promise<any> {
  return request(`/role/query/${params.id}`, {
    method: Method.GET,
  })
}

/**
 * 接口 [2.新增账号↗](http://fe.jiuworker.com:3000/project/29/interface/api/695)
 *
 * @分类 [账户管理↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_83)
 * @标签 `账户管理`
 * @请求头 `POST /account/add`
 * @更新时间 `2024-08-23 16:22:01`
 */

/* **请求body类型** */
export interface PostAccountAddBody {
  id?: number
  /**
   * 手机号
   */
  mobile?: string
  /**
   * 账户名
   */
  name?: string
  /**
   * 密码
   */
  password?: string
  /**
   * 角色id
   */
  roleId?: number
  /**
   * 角色名称
   */
  roleName?: string
  /**
   * 1:启用；0禁用
   */
  status?: number
}

/* **返回类型** */
export type PostAccountAddResponse = boolean

/* **请求函数** */
export async function postAccountAdd(body: PostAccountAddBody): Promise<any> {
  return request(`/account/add`, {
    method: Method.POST,

    data: body,
  })
}

/**
 * 接口 [1.获取当前用户信息↗](http://fe.jiuworker.com:3000/project/29/interface/api/697)
 *
 * @分类 [账户管理↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_83)
 * @标签 `账户管理`
 * @请求头 `POST /account/current/info`
 * @更新时间 `2024-08-23 16:22:01`
 */

/* **返回类型** */
export interface PostAccountCurrentInfoResponse {
  appVersion: number
  companyUserId: number
  /**
   * 是否首次登陆
   */
  isFirstLogin: boolean
  laborCompanyId: number
  laborCompanyName: string
  mobile: string
  /**
   * 试用到期时间
   */
  probationPeriod: string
  roleId: number
  /**
   * 是否是班组长
   */
  teamLeader: boolean
  token: string
  userId: number
  userName: string
}

/* **请求函数** */
export async function postAccountCurrentInfo(): Promise<any> {
  return request(`/account/current/info`, {
    method: Method.POST,
  })
}

/**
 * 接口 [7.删除账号↗](http://fe.jiuworker.com:3000/project/29/interface/api/699)
 *
 * @分类 [账户管理↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_83)
 * @标签 `账户管理`
 * @请求头 `DELETE /account/delete/{id}`
 * @更新时间 `2024-08-23 16:22:01`
 */

/* **请求params类型** */
export interface DeleteAccountDeleteByIdParams {
  /**
   * id
   */
  id: string
}

/* **返回类型** */
export type DeleteAccountDeleteByIdResponse = boolean

/* **请求函数** */
export async function deleteAccountDeleteById(params: DeleteAccountDeleteByIdParams): Promise<any> {
  return request(`/account/delete/${params.id}`, {
    method: Method.DELETE,
  })
}

/**
 * 接口 [3.编辑账号↗](http://fe.jiuworker.com:3000/project/29/interface/api/701)
 *
 * @分类 [账户管理↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_83)
 * @标签 `账户管理`
 * @请求头 `PUT /account/edit`
 * @更新时间 `2024-08-23 16:22:01`
 */

/* **请求body类型** */
export interface PutAccountEditBody {
  id?: number
  /**
   * 手机号
   */
  mobile?: string
  /**
   * 账户名
   */
  name?: string
  /**
   * 密码
   */
  password?: string
  /**
   * 角色id
   */
  roleId?: number
  /**
   * 角色名称
   */
  roleName?: string
  /**
   * 1:启用；0禁用
   */
  status?: number
}

/* **返回类型** */
export type PutAccountEditResponse = boolean

/* **请求函数** */
export async function putAccountEdit(body: PutAccountEditBody): Promise<any> {
  return request(`/account/edit`, {
    method: Method.PUT,

    data: body,
  })
}

/**
 * 接口 [4.启用\/禁用账号↗](http://fe.jiuworker.com:3000/project/29/interface/api/703)
 *
 * @分类 [账户管理↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_83)
 * @标签 `账户管理`
 * @请求头 `PUT /account/enable`
 * @更新时间 `2024-08-23 16:22:01`
 */

/* **请求body类型** */
export interface PutAccountEnableBody {
  /**
   * 启用状态 1启用0禁用
   */
  enable?: number
  id?: number
}

/* **返回类型** */
export type PutAccountEnableResponse = boolean

/* **请求函数** */
export async function putAccountEnable(body: PutAccountEnableBody): Promise<any> {
  return request(`/account/enable`, {
    method: Method.PUT,

    data: body,
  })
}

/**
 * 接口 [8.第一次登陆设置新密码↗](http://fe.jiuworker.com:3000/project/29/interface/api/705)
 *
 * @分类 [账户管理↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_83)
 * @标签 `账户管理`
 * @请求头 `POST /account/init/password`
 * @更新时间 `2024-08-23 16:22:01`
 */

/* **请求body类型** */
export interface PostAccountInitPasswordBody {
  password?: string
}

/* **返回类型** */
export type PostAccountInitPasswordResponse = boolean

/* **请求函数** */
export async function postAccountInitPassword(body: PostAccountInitPasswordBody): Promise<any> {
  return request(`/account/init/password`, {
    method: Method.POST,

    data: body,
  })
}

/**
 * 接口 [6.查询账号列表↗](http://fe.jiuworker.com:3000/project/29/interface/api/707)
 *
 * @分类 [账户管理↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_83)
 * @标签 `账户管理`
 * @请求头 `GET /account/list`
 * @更新时间 `2024-08-23 16:22:01`
 */

/* **请求query类型** */
export interface GetAccountListQuery {
  /**
   * mobile
   */
  mobile?: string
  /**
   * name
   */
  name?: string
  /**
   * pageNum
   */
  pageNum?: number
  /**
   * pageSize
   */
  pageSize?: number
  /**
   * roleId
   */
  roleId?: string
}

/* **返回类型** */
export interface GetAccountListResponse {
  data: {
    accountCompanyId: number
    accountId: number
    id: number
    /**
     * 手机号
     */
    mobile: string
    /**
     * 账户名
     */
    name: string
    /**
     * 角色id
     */
    roleId: number
    /**
     * 角色名称
     */
    roleName: string
    /**
     * 1:启用；0禁用
     */
    status: number
  }[]
  total: number
}

/* **请求函数** */
export async function getAccountList(query: GetAccountListQuery): Promise<any> {
  return request(`/account/list`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [6.指定班组长-筛选↗](http://fe.jiuworker.com:3000/project/29/interface/api/709)
 *
 * @分类 [账户管理↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_83)
 * @标签 `账户管理`
 * @请求头 `GET /account/list/leader`
 * @更新时间 `2024-08-23 16:22:01`
 */

/* **请求query类型** */
export interface GetAccountListLeaderQuery {
  /**
   * name
   */
  name?: string
  /**
   * pageNum
   */
  pageNum?: number
  /**
   * pageSize
   */
  pageSize?: number
}

/* **返回类型** */
export interface GetAccountListLeaderResponse {
  data: {
    id: number
    /**
     * 班组名称
     */
    name: string
  }[]
  total: number
}

/* **请求函数** */
export async function getAccountListLeader(query: GetAccountListLeaderQuery): Promise<any> {
  return request(`/account/list/leader`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [9.修改密码↗](http://fe.jiuworker.com:3000/project/29/interface/api/711)
 *
 * @分类 [账户管理↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_83)
 * @标签 `账户管理`
 * @请求头 `POST /account/modify/password`
 * @更新时间 `2024-08-23 16:22:01`
 */

/* **请求body类型** */
export interface PostAccountModifyPasswordBody {
  /**
   * 旧密码
   */
  oldPassword?: string
  /**
   * 新密码
   */
  password?: string
}

/* **返回类型** */
export type PostAccountModifyPasswordResponse = boolean

/* **请求函数** */
export async function postAccountModifyPassword(body: PostAccountModifyPasswordBody): Promise<any> {
  return request(`/account/modify/password`, {
    method: Method.POST,

    data: body,
  })
}

/**
 * 接口 [11.报名用户-录用\/拒绝↗](http://fe.jiuworker.com:3000/project/29/interface/api/917)
 *
 * @分类 [雇员信息↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_84)
 * @标签 `雇员信息`
 * @请求头 `POST /employee/apply/accept`
 * @更新时间 `2024-08-23 16:22:07`
 */

/* **请求body类型** */
export interface PostEmployeeApplyAcceptBody {
  /**
   * 是否同意 1同意0拒绝
   */
  accept?: number
  /**
   * 用户id集合-teamEmployeeId
   */
  ids?: number[]
  /**
   * 拒绝原因
   */
  refuseReason?: string
}

/* **返回类型** */
export type PostEmployeeApplyAcceptResponse = boolean

/* **请求函数** */
export async function postEmployeeApplyAccept(body: PostEmployeeApplyAcceptBody): Promise<any> {
  return request(`/employee/apply/accept`, {
    method: Method.POST,

    data: body,
  })
}

/**
 * 接口 [10.报名列表↗](http://fe.jiuworker.com:3000/project/29/interface/api/919)
 *
 * @分类 [雇员信息↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_84)
 * @标签 `雇员信息`
 * @请求头 `GET /employee/apply/list`
 * @更新时间 `2024-08-23 16:22:07`
 */

/* **请求query类型** */
export interface GetEmployeeApplyListQuery {
  /**
   * gender
   */
  gender?: string
  /**
   * keyword
   */
  keyword?: string
  /**
   * maxAge
   */
  maxAge?: string
  /**
   * minAge
   */
  minAge?: string
  /**
   * pageNum
   */
  pageNum?: number
  /**
   * pageSize
   */
  pageSize?: number
  /**
   * 报名状态 1:待录用 2:已录用 3:已拒绝
   */
  status?: string
  /**
   * teamId
   */
  teamId?: string
  /**
   * 工作状态 0:待入职 1:在职 2:离职 3:已移除 4:已删除
   */
  workStatus?: string
}

/* **返回类型** */
export interface GetEmployeeApplyListResponse {
  data: {
    /**
     * 年龄
     */
    age: number
    /**
     * 报名时间
     */
    applyTime: string
    /**
     * 头像
     */
    avatar: string
    /**
     * 拉黑理由
     */
    blackRemark: string
    /**
     * 拉黑状态 0:未拉黑 1:已拉黑
     */
    blackStatus: number
    /**
     * 录用时间
     */
    employTime: string
    /**
     * 1:男，0:女
     */
    gender: number
    /**
     * 创建时间
     */
    gmtCreate: string
    gmtModified: string
    id: number
    /**
     * 身份证号码
     */
    idcard: string
    /**
     * 岗位id
     */
    jobId: number
    /**
     * 岗位名称
     */
    jobName: string
    /**
     * 离职时间
     */
    leaveTime: string
    /**
     * 手机号
     */
    mobile: string
    /**
     * 总计件
     */
    piece: number
    /**
     * 拒绝理由
     */
    refuseReason: string
    /**
     * 累计薪资
     */
    salary: number
    /**
     * 报名状态 1:待录用 2:已录用 3:已拒绝
     */
    status: number
    /**
     * 班组id
     */
    teamId: number
    /**
     * 班组名称
     */
    teamName: string
    /**
     * 用户id
     */
    userId: number
    /**
     * 用户名
     */
    userName: string
    /**
     * 总天数
     */
    workDays: number
    /**
     * 总工时
     */
    workHour: string
    /**
     * 工作状态 0:待入职 1:在职 2:离职 3:已移除 4:已删除
     */
    workStatus: number
    /**
     * 累计排班
     */
    workTimes: number
  }[]
  total: number
}

/* **请求函数** */
export async function getEmployeeApplyList(query: GetEmployeeApplyListQuery): Promise<any> {
  return request(`/employee/apply/list`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [21.拉黑求职者-基于信息-id传userId↗](http://fe.jiuworker.com:3000/project/29/interface/api/921)
 *
 * @分类 [雇员信息↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_84)
 * @标签 `雇员信息`
 * @请求头 `PUT /employee/block`
 * @更新时间 `2024-08-23 16:22:07`
 */

/* **请求body类型** */
export interface PutEmployeeBlockBody {
  id?: number
  remark?: string
}

/* **返回类型** */
export type PutEmployeeBlockResponse = boolean

/* **请求函数** */
export async function putEmployeeBlock(body: PutEmployeeBlockBody): Promise<any> {
  return request(`/employee/block`, {
    method: Method.PUT,

    data: body,
  })
}

/**
 * 接口 [21.拉黑求职者-基于身份证号↗](http://fe.jiuworker.com:3000/project/29/interface/api/923)
 *
 * @分类 [雇员信息↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_84)
 * @标签 `雇员信息`
 * @请求头 `PUT /employee/block/idcard`
 * @更新时间 `2024-08-23 16:22:07`
 */

/* **请求body类型** */
export interface PutEmployeeBlockIdcardBody {
  idcard?: string
  name?: string
  remark?: string
}

/* **返回类型** */
export type PutEmployeeBlockIdcardResponse = boolean

/* **请求函数** */
export async function putEmployeeBlockIdcard(body: PutEmployeeBlockIdcardBody): Promise<any> {
  return request(`/employee/block/idcard`, {
    method: Method.PUT,

    data: body,
  })
}

/**
 * 接口 [12.1用户详情-工作记录-汇总↗](http://fe.jiuworker.com:3000/project/29/interface/api/925)
 *
 * @分类 [雇员信息↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_84)
 * @标签 `雇员信息`
 * @请求头 `GET /employee/count/team/record`
 * @更新时间 `2024-08-23 16:22:07`
 */

/* **请求query类型** */
export interface GetEmployeeCountTeamRecordQuery {
  /**
   * teamEmployeeId
   */
  teamEmployeeId?: string
  /**
   * userId
   */
  userId?: string
}

/* **返回类型** */
export interface GetEmployeeCountTeamRecordResponse {
  /**
   * 总计件
   */
  piece: number
  /**
   * 累计薪资
   */
  salary: number
  userId: number
  /**
   * 总天数
   */
  workDays: number
  /**
   * 总工时
   */
  workHour: number
  /**
   * 累计排班
   */
  workTimes: number
}

/* **请求函数** */
export async function getEmployeeCountTeamRecord(query: GetEmployeeCountTeamRecordQuery): Promise<any> {
  return request(`/employee/count/team/record`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [12.1 用户详情-离职\/人才库\/黑名单↗](http://fe.jiuworker.com:3000/project/29/interface/api/927)
 *
 * @分类 [雇员信息↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_84)
 * @标签 `雇员信息`
 * @请求头 `GET /employee/detail/user/{userId}`
 * @更新时间 `2024-08-23 16:22:07`
 */

/* **请求params类型** */
export interface GetEmployeeDetailUserByUserIdParams {
  /**
   * userId
   */
  userId: string
}

/* **返回类型** */
export interface GetEmployeeDetailUserByUserIdResponse {
  /**
   * 年龄
   */
  age: number
  /**
   * 报名时间
   */
  applyTime: string
  /**
   * 头像
   */
  avatar: string
  /**
   * 拉黑理由
   */
  blackRemark: string
  /**
   * 拉黑状态 0:未拉黑 1:已拉黑
   */
  blackStatus: number
  /**
   * 录用时间
   */
  employTime: string
  /**
   * 1:男，0:女
   */
  gender: number
  /**
   * 创建时间
   */
  gmtCreate: string
  id: number
  /**
   * 身份证号码
   */
  idcard: string
  /**
   * 离职时间
   */
  leaveTime: string
  /**
   * 手机号
   */
  mobile: string
  /**
   * 总计件
   */
  piece: number
  /**
   * 累计薪资
   */
  salary: number
  /**
   * 用户id
   */
  userId: number
  /**
   * 用户名
   */
  userName: string
  /**
   * 总天数
   */
  workDays: number
  /**
   * 总工时
   */
  workHour: string
  /**
   * 工作状态 0:待入职 1:在职 2:离职 3:已移除 4:已删除
   */
  workStatus: number
  /**
   * 累计排班
   */
  workTimes: number
}

/* **请求函数** */
export async function getEmployeeDetailUserByUserId(params: GetEmployeeDetailUserByUserIdParams): Promise<any> {
  return request(`/employee/detail/user/${params.userId}`, {
    method: Method.GET,
  })
}

/**
 * 接口 [12.用户详情-报名\/在职↗](http://fe.jiuworker.com:3000/project/29/interface/api/929)
 *
 * @分类 [雇员信息↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_84)
 * @标签 `雇员信息`
 * @请求头 `GET /employee/detail/{teamEmployeeId}`
 * @更新时间 `2024-08-23 16:22:07`
 */

/* **请求params类型** */
export interface GetEmployeeDetailByTeamEmployeeIdParams {
  /**
   * teamEmployeeId
   */
  teamEmployeeId: string
}

/* **返回类型** */
export interface GetEmployeeDetailByTeamEmployeeIdResponse {
  /**
   * 年龄
   */
  age: number
  /**
   * 报名时间
   */
  applyTime: string
  /**
   * 头像
   */
  avatar: string
  /**
   * 拉黑理由
   */
  blackRemark: string
  /**
   * 拉黑状态 0:未拉黑 1:已拉黑
   */
  blackStatus: number
  /**
   * 录用时间
   */
  employTime: string
  /**
   * 1:男，0:女
   */
  gender: number
  /**
   * 创建时间
   */
  gmtCreate: string
  gmtModified: string
  id: number
  /**
   * 身份证号码
   */
  idcard: string
  /**
   * 岗位id
   */
  jobId: number
  /**
   * 岗位名称
   */
  jobName: string
  /**
   * 离职时间
   */
  leaveTime: string
  /**
   * 手机号
   */
  mobile: string
  /**
   * 总计件
   */
  piece: number
  /**
   * 拒绝理由
   */
  refuseReason: string
  /**
   * 累计薪资
   */
  salary: number
  /**
   * 报名状态 1:待录用 2:已录用 3:已拒绝
   */
  status: number
  /**
   * 班组id
   */
  teamId: number
  /**
   * 班组名称
   */
  teamName: string
  /**
   * 用户id
   */
  userId: number
  /**
   * 用户名
   */
  userName: string
  /**
   * 总天数
   */
  workDays: number
  /**
   * 总工时
   */
  workHour: string
  /**
   * 工作状态 0:待入职 1:在职 2:离职 3:已移除 4:已删除
   */
  workStatus: number
  /**
   * 累计排班
   */
  workTimes: number
}

/* **请求函数** */
export async function getEmployeeDetailByTeamEmployeeId(params: GetEmployeeDetailByTeamEmployeeIdParams): Promise<any> {
  return request(`/employee/detail/${params.teamEmployeeId}`, {
    method: Method.GET,
  })
}

/**
 * 接口 [14.离职-排班↗](http://fe.jiuworker.com:3000/project/29/interface/api/931)
 *
 * @分类 [雇员信息↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_84)
 * @标签 `雇员信息`
 * @请求头 `PUT /employee/dimission`
 * @更新时间 `2024-08-23 16:22:07`
 */

/* **请求body类型** */
export interface PutEmployeeDimissionBody {
  id?: number
  remark?: string
}

/* **返回类型** */
export type PutEmployeeDimissionResponse = boolean

/* **请求函数** */
export async function putEmployeeDimission(body: PutEmployeeDimissionBody): Promise<any> {
  return request(`/employee/dimission`, {
    method: Method.PUT,

    data: body,
  })
}

/**
 * 接口 [13.灵活用工列表-↗](http://fe.jiuworker.com:3000/project/29/interface/api/933)
 *
 * @分类 [雇员信息↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_84)
 * @标签 `雇员信息`
 * @请求头 `GET /employee/list`
 * @更新时间 `2024-08-23 16:22:07`
 */

/* **请求query类型** */
export interface GetEmployeeListQuery {
  /**
   * gender
   */
  gender?: string
  /**
   * keyword
   */
  keyword?: string
  /**
   * maxAge
   */
  maxAge?: string
  /**
   * minAge
   */
  minAge?: string
  /**
   * pageNum
   */
  pageNum?: number
  /**
   * pageSize
   */
  pageSize?: number
  /**
   * 状态 1:在职人员 2:离职人员 3:黑名单
   */
  status?: string
  /**
   * teamId
   */
  teamId?: string
}

/* **返回类型** */
export interface GetEmployeeListResponse {
  data: {
    /**
     * 年龄
     */
    age: number
    /**
     * 报名时间
     */
    applyTime: string
    /**
     * 头像
     */
    avatar: string
    /**
     * 拉黑理由
     */
    blackRemark: string
    /**
     * 拉黑状态 0:未拉黑 1:已拉黑
     */
    blackStatus: number
    /**
     * 录用时间
     */
    employTime: string
    /**
     * 1:男，0:女
     */
    gender: number
    /**
     * 创建时间
     */
    gmtCreate: string
    gmtModified: string
    id: number
    /**
     * 身份证号码
     */
    idcard: string
    /**
     * 岗位id
     */
    jobId: number
    /**
     * 岗位名称
     */
    jobName: string
    /**
     * 离职时间
     */
    leaveTime: string
    /**
     * 手机号
     */
    mobile: string
    /**
     * 总计件
     */
    piece: number
    /**
     * 拒绝理由
     */
    refuseReason: string
    /**
     * 累计薪资
     */
    salary: number
    /**
     * 报名状态 1:待录用 2:已录用 3:已拒绝
     */
    status: number
    /**
     * 班组id
     */
    teamId: number
    /**
     * 班组名称
     */
    teamName: string
    /**
     * 用户id
     */
    userId: number
    /**
     * 用户名
     */
    userName: string
    /**
     * 总天数
     */
    workDays: number
    /**
     * 总工时
     */
    workHour: string
    /**
     * 工作状态 0:待入职 1:在职 2:离职 3:已移除 4:已删除
     */
    workStatus: number
    /**
     * 累计排班
     */
    workTimes: number
  }[]
  total: number
}

/* **请求函数** */
export async function getEmployeeList(query: GetEmployeeListQuery): Promise<any> {
  return request(`/employee/list`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [40.下拉筛选↗](http://fe.jiuworker.com:3000/project/29/interface/api/935)
 *
 * @分类 [雇员信息↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_84)
 * @标签 `雇员信息`
 * @请求头 `GET /employee/list/select`
 * @更新时间 `2024-08-23 16:22:07`
 */

/* **请求query类型** */
export interface GetEmployeeListSelectQuery {
  /**
   * name
   */
  name?: string
  /**
   * pageNum
   */
  pageNum?: number
  /**
   * pageSize
   */
  pageSize?: number
  /**
   * 1:正常 2:离职 3:黑名单 4:已删除
   */
  stats?: string
  /**
   * teamId
   */
  teamId?: string
}

/* **返回类型** */
export interface GetEmployeeListSelectResponse {
  data: {
    id: number
    /**
     * 班组名称
     */
    name: string
  }[]
  total: number
}

/* **请求函数** */
export async function getEmployeeListSelect(query: GetEmployeeListSelectQuery): Promise<any> {
  return request(`/employee/list/select`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [12.用户详情-工作记录↗](http://fe.jiuworker.com:3000/project/29/interface/api/937)
 *
 * @分类 [雇员信息↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_84)
 * @标签 `雇员信息`
 * @请求头 `GET /employee/list/team/record`
 * @更新时间 `2024-08-23 16:22:07`
 */

/* **请求query类型** */
export interface GetEmployeeListTeamRecordQuery {
  /**
   * pageNum
   */
  pageNum?: number
  /**
   * pageSize
   */
  pageSize?: number
  /**
   * teamEmployeeId
   */
  teamEmployeeId?: string
  /**
   * userId
   */
  userId?: string
}

/* **返回类型** */
export interface GetEmployeeListTeamRecordResponse {
  data: {
    /**
     * 年龄
     */
    age: number
    /**
     * 头像
     */
    avatar: string
    /**
     * 结束时间
     */
    endDate: string
    /**
     * 1:男，0:女
     */
    gender: number
    /**
     * 创建（报名）时间
     */
    gmtCreate: string
    gmtModified: string
    id: number
    /**
     * 身份证号码
     */
    idcard: string
    /**
     * 岗位id
     */
    jobId: number
    /**
     * 岗位名称
     */
    jobName: string
    /**
     * 手机号
     */
    mobile: string
    /**
     * 总计件
     */
    piece: number
    /**
     * 累计薪资
     */
    salary: number
    /**
     * 开始时间
     */
    startDate: string
    /**
     * 报名状态 1:待录用 2:已录用 3:已拒绝
     */
    status: number
    teamEmployId: number
    /**
     * 班组id
     */
    teamId: number
    /**
     * 班组名称
     */
    teamName: string
    /**
     * 用户id
     */
    userId: number
    /**
     * 用户名
     */
    userName: string
    /**
     * 总天数
     */
    workDays: number
    /**
     * 总工时
     */
    workHour: number
    /**
     * 工作状态 0:待入职 1:在职 2:离职 3:已移除 4:已删除
     */
    workStatus: number
    /**
     * 累计排班
     */
    workTimes: number
  }[]
  total: number
}

/* **请求函数** */
export async function getEmployeeListTeamRecord(query: GetEmployeeListTeamRecordQuery): Promise<any> {
  return request(`/employee/list/team/record`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [15.删除用户-排班列表↗](http://fe.jiuworker.com:3000/project/29/interface/api/939)
 *
 * @分类 [雇员信息↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_84)
 * @标签 `雇员信息`
 * @请求头 `PUT /employee/remove`
 * @更新时间 `2024-08-23 16:22:07`
 */

/* **请求body类型** */
export interface PutEmployeeRemoveBody {
  id?: number
}

/* **返回类型** */
export type PutEmployeeRemoveResponse = boolean

/* **请求函数** */
export async function putEmployeeRemove(body: PutEmployeeRemoveBody): Promise<any> {
  return request(`/employee/remove`, {
    method: Method.PUT,

    data: body,
  })
}

/**
 * 接口 [20.人才库↗](http://fe.jiuworker.com:3000/project/29/interface/api/941)
 *
 * @分类 [雇员信息↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_84)
 * @标签 `雇员信息`
 * @请求头 `GET /employee/talent/list`
 * @更新时间 `2024-08-23 16:22:07`
 */

/* **请求query类型** */
export interface GetEmployeeTalentListQuery {
  /**
   * gender
   */
  gender?: string
  /**
   * keyword
   */
  keyword?: string
  /**
   * maxAge
   */
  maxAge?: string
  /**
   * minAge
   */
  minAge?: string
  /**
   * pageNum
   */
  pageNum?: number
  /**
   * pageSize
   */
  pageSize?: number
  /**
   * teamId
   */
  teamId?: string
}

/* **返回类型** */
export interface GetEmployeeTalentListResponse {
  data: {
    /**
     * 年龄
     */
    age: number
    /**
     * 头像
     */
    avatar: string
    /**
     * 劳务公司id
     */
    companyId: number
    /**
     * 1:男，0:女
     */
    gender: number
    id: number
    /**
     * 身份证号码
     */
    idcard: string
    /**
     * 手机号
     */
    mobile: string
    /**
     * 计件
     */
    piece: string
    /**
     * 累计薪资
     */
    salary: number
    /**
     * 1:正常 2:离职 3:黑名单 4:已删除
     */
    status: number
    /**
     * 用户id
     */
    userId: number
    /**
     * 用户名
     */
    userName: string
    /**
     * 总天数
     */
    workDays: string
    /**
     * 总工时
     */
    workHour: string
    /**
     * 累计排班
     */
    workTimes: string
  }[]
  total: number
}

/* **请求函数** */
export async function getEmployeeTalentList(query: GetEmployeeTalentListQuery): Promise<any> {
  return request(`/employee/talent/list`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [1.排班-添加人员↗](http://fe.jiuworker.com:3000/project/29/interface/api/943)
 *
 * @分类 [雇员信息↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_84)
 * @标签 `雇员信息`
 * @请求头 `POST /employee/team/add`
 * @更新时间 `2024-08-23 16:22:07`
 */

/* **请求body类型** */
export interface PostEmployeeTeamAddBody {
  /**
   * 头像
   */
  avatar?: string
  /**
   * 身份证号码
   */
  idcard?: string
  /**
   * 岗位id
   */
  jobId?: number
  /**
   * 手机号
   */
  mobile?: string
  /**
   * 是否跳过风险校验 0否1是
   */
  skip?: number
  /**
   * 不再核验 0否1是
   */
  skipNotConfirm?: number
  /**
   * 班组id
   */
  teamId?: number
  /**
   * 用户名
   */
  userName?: string
}

/* **返回类型** */
export interface PostEmployeeTeamAddResponse {
  /**
   * 0:成功 1:账户余额不足 2:跳过风险校验
   */
  riskStatus: number
}

/* **请求函数** */
export async function postEmployeeTeamAdd(body: PostEmployeeTeamAddBody): Promise<any> {
  return request(`/employee/team/add`, {
    method: Method.POST,

    data: body,
  })
}

/**
 * 接口 [2.排班-人才库导入↗](http://fe.jiuworker.com:3000/project/29/interface/api/945)
 *
 * @分类 [雇员信息↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_84)
 * @标签 `雇员信息`
 * @请求头 `POST /employee/team/import`
 * @更新时间 `2024-08-23 16:22:07`
 */

/* **请求body类型** */
export interface PostEmployeeTeamImportBody {
  /**
   * 岗位id
   */
  jobId?: number
  /**
   * 是否跳过风险校验 0否1是
   */
  skip?: number
  /**
   * 不再核验 0否1是
   */
  skipNotConfirm?: number
  /**
   * 用户id
   */
  userIds?: number[]
}

/* **返回类型** */
export interface PostEmployeeTeamImportResponse {
  /**
   * 总人数
   */
  count: number
  /**
   * 失败人数
   */
  failCount: number
  /**
   * 失败列表
   */
  list: {
    /**
     * 身份证号
     */
    idcard: string
    /**
     * 姓名
     */
    name: string
    /**
     * 失败原因
     */
    reason: string
  }[]
  /**
   * 0:成功 1:账户余额不足 2:跳过风险校验
   */
  riskStatus: number
  /**
   * 成功人数
   */
  successCount: number
}

/* **请求函数** */
export async function postEmployeeTeamImport(body: PostEmployeeTeamImportBody): Promise<any> {
  return request(`/employee/team/import`, {
    method: Method.POST,

    data: body,
  })
}

/**
 * 接口 [4.排班-班组排班情况↗](http://fe.jiuworker.com:3000/project/29/interface/api/947)
 *
 * @分类 [雇员信息↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_84)
 * @标签 `雇员信息`
 * @请求头 `GET /employee/team/list`
 * @更新时间 `2024-08-23 16:22:07`
 */

/* **请求query类型** */
export interface GetEmployeeTeamListQuery {
  /**
   * pageNum
   */
  pageNum?: number
  /**
   * pageSize
   */
  pageSize?: number
  /**
   * teamId
   */
  teamId?: string
  /**
   * workStatus
   */
  workStatus?: string
}

/* **返回类型** */
export interface GetEmployeeTeamListResponse {
  data: {
    /**
     * 年龄
     */
    age: number
    /**
     * 报名时间
     */
    applyTime: string
    /**
     * 头像
     */
    avatar: string
    /**
     * 拉黑理由
     */
    blackRemark: string
    /**
     * 拉黑状态 0:未拉黑 1:已拉黑
     */
    blackStatus: number
    /**
     * 录用时间
     */
    employTime: string
    /**
     * 1:男，0:女
     */
    gender: number
    /**
     * 创建时间
     */
    gmtCreate: string
    gmtModified: string
    id: number
    /**
     * 身份证号码
     */
    idcard: string
    /**
     * 岗位id
     */
    jobId: number
    /**
     * 岗位名称
     */
    jobName: string
    /**
     * 离职时间
     */
    leaveTime: string
    /**
     * 手机号
     */
    mobile: string
    /**
     * 总计件
     */
    piece: number
    /**
     * 拒绝理由
     */
    refuseReason: string
    /**
     * 累计薪资
     */
    salary: number
    /**
     * 报名状态 1:待录用 2:已录用 3:已拒绝
     */
    status: number
    /**
     * 班组id
     */
    teamId: number
    /**
     * 班组名称
     */
    teamName: string
    /**
     * 用户id
     */
    userId: number
    /**
     * 用户名
     */
    userName: string
    /**
     * 总天数
     */
    workDays: number
    /**
     * 总工时
     */
    workHour: string
    /**
     * 工作状态 0:待入职 1:在职 2:离职 3:已移除 4:已删除
     */
    workStatus: number
    /**
     * 累计排班
     */
    workTimes: number
  }[]
  total: number
}

/* **请求函数** */
export async function getEmployeeTeamList(query: GetEmployeeTeamListQuery): Promise<any> {
  return request(`/employee/team/list`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [30.结算-班组人员列表↗](http://fe.jiuworker.com:3000/project/29/interface/api/949)
 *
 * @分类 [雇员信息↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_84)
 * @标签 `雇员信息`
 * @请求头 `GET /employee/team/list/settle`
 * @更新时间 `2024-08-23 16:22:07`
 */

/* **请求query类型** */
export interface GetEmployeeTeamListSettleQuery {
  /**
   * pageNum
   */
  pageNum?: number
  /**
   * pageSize
   */
  pageSize?: number
  /**
   * teamId
   */
  teamId: string
}

/* **返回类型** */
export interface GetEmployeeTeamListSettleResponse {
  data: {
    /**
     * 年龄
     */
    age: number
    /**
     * 报名时间
     */
    applyTime: string
    /**
     * 头像
     */
    avatar: string
    /**
     * 拉黑理由
     */
    blackRemark: string
    /**
     * 拉黑状态 0:未拉黑 1:已拉黑
     */
    blackStatus: number
    /**
     * 录用时间
     */
    employTime: string
    /**
     * 1:男，0:女
     */
    gender: number
    /**
     * 创建时间
     */
    gmtCreate: string
    gmtModified: string
    id: number
    /**
     * 身份证号码
     */
    idcard: string
    /**
     * 岗位id
     */
    jobId: number
    /**
     * 岗位名称
     */
    jobName: string
    /**
     * 离职时间
     */
    leaveTime: string
    /**
     * 手机号
     */
    mobile: string
    /**
     * 总计件
     */
    piece: number
    /**
     * 拒绝理由
     */
    refuseReason: string
    /**
     * 累计薪资
     */
    salary: number
    /**
     * 报名状态 1:待录用 2:已录用 3:已拒绝
     */
    status: number
    /**
     * 班组id
     */
    teamId: number
    /**
     * 班组名称
     */
    teamName: string
    /**
     * 用户id
     */
    userId: number
    /**
     * 用户名
     */
    userName: string
    /**
     * 总天数
     */
    workDays: number
    /**
     * 总工时
     */
    workHour: string
    /**
     * 工作状态 0:待入职 1:在职 2:离职 3:已移除 4:已删除
     */
    workStatus: number
    /**
     * 累计排班
     */
    workTimes: number
  }[]
  total: number
}

/* **请求函数** */
export async function getEmployeeTeamListSettle(query: GetEmployeeTeamListSettleQuery): Promise<any> {
  return request(`/employee/team/list/settle`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [3.排班-进度↗](http://fe.jiuworker.com:3000/project/29/interface/api/951)
 *
 * @分类 [雇员信息↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_84)
 * @标签 `雇员信息`
 * @请求头 `GET /employee/team/overview/{teamId}`
 * @更新时间 `2024-08-23 16:22:07`
 */

/* **请求params类型** */
export interface GetEmployeeTeamOverviewByTeamIdParams {
  /**
   * teamId
   */
  teamId: string
}

/* **返回类型** */
export interface GetEmployeeTeamOverviewByTeamIdResponse {
  /**
   * 已排人员人数
   */
  employeeNum: number
  /**
   * 招聘进度
   */
  jobProcess: {
    /**
     * 已招人数
     */
    jobHiredNum: number
    jobId: number
    /**
     * 岗位名称
     */
    jobName: string
    /**
     * 招聘需求人数
     */
    jobNum: number
  }[]
  /**
   * 排班进度 0-100
   */
  recruitProgress: number
}

/* **请求函数** */
export async function getEmployeeTeamOverviewByTeamId(params: GetEmployeeTeamOverviewByTeamIdParams): Promise<any> {
  return request(`/employee/team/overview/${params.teamId}`, {
    method: Method.GET,
  })
}

/**
 * 接口 [22.从黑名单中移除-id传userId↗](http://fe.jiuworker.com:3000/project/29/interface/api/953)
 *
 * @分类 [雇员信息↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_84)
 * @标签 `雇员信息`
 * @请求头 `PUT /employee/unblock`
 * @更新时间 `2024-08-23 16:22:07`
 */

/* **请求body类型** */
export interface PutEmployeeUnblockBody {
  id?: number
}

/* **返回类型** */
export type PutEmployeeUnblockResponse = boolean

/* **请求函数** */
export async function putEmployeeUnblock(body: PutEmployeeUnblockBody): Promise<any> {
  return request(`/employee/unblock`, {
    method: Method.PUT,

    data: body,
  })
}

/**
 * 接口 [报名接口↗](http://fe.jiuworker.com:3000/project/29/interface/api/1059)
 *
 * @分类 [雇员接口-对外↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_85)
 * @标签 `雇员接口-对外`
 * @请求头 `POST /out/employee/apply`
 * @更新时间 `2024-08-23 16:22:09`
 */

/* **请求body类型** */
export interface PostOutEmployeeApplyBody {
  idcard?: string
  jobId?: number
}

/* **返回类型** */
export interface PostOutEmployeeApplyResponse {
  idcard: string
  jobName: string
  teamId: number
  teamName: string
  userName: string
}

/* **请求函数** */
export async function postOutEmployeeApply(body: PostOutEmployeeApplyBody): Promise<any> {
  return request(`/out/employee/apply`, {
    method: Method.POST,

    data: body,
  })
}

/**
 * 接口 [11.打卡记录-指定日期↗](http://fe.jiuworker.com:3000/project/29/interface/api/1061)
 *
 * @分类 [雇员接口-对外↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_85)
 * @标签 `雇员接口-对外`
 * @请求头 `GET /out/employee/attendance/info`
 * @更新时间 `2024-08-23 16:22:10`
 */

/* **请求query类型** */
export interface GetOutEmployeeAttendanceInfoQuery {
  /**
   * date
   */
  date: string
  /**
   * idcard
   */
  idcard: string
  /**
   * teamId
   */
  teamId: string
}

/* **返回类型** */
export type GetOutEmployeeAttendanceInfoResponse = {
  /**
   * 打卡地点
   */
  devicePlace: string
  /**
   * 类型 1：进门，2：出门
   */
  doorType: number
  /**
   * 头像
   */
  headImage: string
  /**
   * 人员名称
   */
  name: string
  /**
   * 健康码状态 00.绿码(低风险) 10.红码(高风险) 01.黄码(中风险)
   */
  qrCodeStatus: {}
  /**
   * 打卡时间
   */
  recognitionTime: string
  /**
   * 人脸
   */
  registerImage: string
  /**
   * 项目名称
   */
  teamName: string
  /**
   * 体温
   */
  temperature: {}
}[]

/* **请求函数** */
export async function getOutEmployeeAttendanceInfo(query: GetOutEmployeeAttendanceInfoQuery): Promise<any> {
  return request(`/out/employee/attendance/info`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [10.考勤日历↗](http://fe.jiuworker.com:3000/project/29/interface/api/1063)
 *
 * @分类 [雇员接口-对外↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_85)
 * @标签 `雇员接口-对外`
 * @请求头 `GET /out/employee/attendance/month`
 * @更新时间 `2024-08-23 16:22:10`
 */

/* **请求query类型** */
export interface GetOutEmployeeAttendanceMonthQuery {
  /**
   * date
   */
  date: string
  /**
   * idcard
   */
  idcard: string
  /**
   * teamId
   */
  teamId: string
}

/* **返回类型** */
export interface GetOutEmployeeAttendanceMonthResponse {
  /**
   * 异常天数
   */
  abnormalDayCount: number
  /**
   * 出勤天数
   */
  attendanceDayCount: number
  /**
   * 记工统计-无效
   */
  attendanceNum: number
  /**
   * 工时统计-单位小时
   */
  attendanceTimeCount: string
  endSplitTime: string
  /**
   * 用工公司id
   */
  enterId: number
  /**
   * 头像
   */
  headImage: string
  /**
   * 劳务公司id
   */
  laborId: number
  monthAttendances: {
    /**
     * 考勤状态 1:正常，2：异常
     */
    attendanceStatus: number
    /**
     * 考勤时间
     */
    attendanceTime: string
    /**
     * 工时统计-单位小时
     */
    attendanceTimeCount: string
    /**
     * 考勤数量（工）
     */
    num: number
    /**
     * 计件
     */
    piece: number
    /**
     * 薪资
     */
    salary: number
    /**
     * 劳务端状态 忽略
     */
    status: number
    valuationType: number
  }[]
  /**
   * 用户名称
   */
  name: string
  /**
   * 蓝色荣耀用户id
   */
  personId: number
  /**
   * 计件
   */
  piece: number
  /**
   * 用工计划id
   */
  planId: number
  /**
   * 项目名称-班组名称
   */
  projectName: string
  startSplitTime: string
  /**
   * 班组id
   */
  teamId: number
  /**
   * 班组名称
   */
  teamName: string
  valuationType: number
  /**
   * 工作状态 0:待入职 1:在职 2:离职 3:已移除 4:已删除
   */
  workStatus: number
}

/* **请求函数** */
export async function getOutEmployeeAttendanceMonth(query: GetOutEmployeeAttendanceMonthQuery): Promise<any> {
  return request(`/out/employee/attendance/month`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [离职↗](http://fe.jiuworker.com:3000/project/29/interface/api/1065)
 *
 * @分类 [雇员接口-对外↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_85)
 * @标签 `雇员接口-对外`
 * @请求头 `POST /out/employee/dismission`
 * @更新时间 `2024-08-23 16:22:10`
 */

/* **请求body类型** */
export interface PostOutEmployeeDismissionBody {
  enterId?: number
  idcard?: string
  laborId?: number
  planId?: number
}

/* **返回类型** */
export type PostOutEmployeeDismissionResponse = boolean

/* **请求函数** */
export async function postOutEmployeeDismission(body: PostOutEmployeeDismissionBody): Promise<any> {
  return request(`/out/employee/dismission`, {
    method: Method.POST,

    data: body,
  })
}

/**
 * 接口 [21.收入明细-指定月份↗](http://fe.jiuworker.com:3000/project/29/interface/api/1067)
 *
 * @分类 [雇员接口-对外↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_85)
 * @标签 `雇员接口-对外`
 * @请求头 `GET /out/employee/income/list`
 * @更新时间 `2024-08-23 16:22:10`
 */

/* **请求query类型** */
export interface GetOutEmployeeIncomeListQuery {
  /**
   * date
   */
  date: string
  /**
   * idcard
   */
  idcard: string
}

/* **返回类型** */
export interface GetOutEmployeeIncomeListResponse {
  /**
   * 支付记录
   */
  list: {
    /**
     * 结算结束时间
     */
    endDate: string
    /**
     * 结算收入
     */
    oldSalary: number
    /**
     * 支付时间
     */
    payTime: string
    /**
     * 备注
     */
    remark: string
    /**
     * 收入
     */
    salary: number
    /**
     * 结算开始时间
     */
    startDate: string
    /**
     * 状态  1:正常 2:确认中 3:失败
     */
    status: number
    teamId: number
    teamName: string
    /**
     * 交易流水号
     */
    transactionSerialNumber: string
  }[]
  /**
   * 月收入
   */
  monthSalary: number
}

/* **请求函数** */
export async function getOutEmployeeIncomeList(query: GetOutEmployeeIncomeListQuery): Promise<any> {
  return request(`/out/employee/income/list`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [20.用户概览↗](http://fe.jiuworker.com:3000/project/29/interface/api/1069)
 *
 * @分类 [雇员接口-对外↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_85)
 * @标签 `雇员接口-对外`
 * @请求头 `GET /out/employee/income/overview`
 * @更新时间 `2024-08-23 16:22:10`
 */

/* **请求query类型** */
export interface GetOutEmployeeIncomeOverviewQuery {
  /**
   * idcard
   */
  idcard: string
}

/* **返回类型** */
export interface GetOutEmployeeIncomeOverviewResponse {
  /**
   * 本月收入
   */
  monthSalary: number
  /**
   * 今日收入
   */
  todaySalary: number
  /**
   * 总收入
   */
  totalSalary: number
  /**
   * 待发放
   */
  waitingSalary: number
}

/* **请求函数** */
export async function getOutEmployeeIncomeOverview(query: GetOutEmployeeIncomeOverviewQuery): Promise<any> {
  return request(`/out/employee/income/overview`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [3.用户详情↗](http://fe.jiuworker.com:3000/project/29/interface/api/1073)
 *
 * @分类 [雇员接口-对外↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_85)
 * @标签 `雇员接口-对外`
 * @请求头 `POST /out/employee/receive/customer`
 * @更新时间 `2023-12-11 19:30:08`
 */

/* **请求body类型** */
export interface PostOutEmployeeReceiveCustomerBody {
  /**
   * 年龄
   */
  age?: number
  /**
   * 支付宝账户id
   */
  alipayUserId?: string
  /**
   * 头像
   */
  avatar?: string
  bizData?: string
  /**
   * 邮箱
   */
  email?: string
  /**
   * 1:男，0:女
   */
  gender?: number
  id?: number
  /**
   * 身份证
   */
  idcard?: string
  /**
   * 手机号
   */
  mobile?: string
  /**
   * 昵称
   */
  nickname?: string
  /**
   * 用户名
   */
  userName?: string
  validAlipay?: boolean
  validWechat?: boolean
  /**
   * 微信openId
   */
  wechatOpenid?: string
}

/* **返回类型** */
export type PostOutEmployeeReceiveCustomerResponse = boolean

/* **请求函数** */
export async function postOutEmployeeReceiveCustomer(body: PostOutEmployeeReceiveCustomerBody): Promise<any> {
  return request(`/out/employee/receive/customer`, {
    method: Method.POST,

    data: body,
  })
}

/**
 * 接口 [移除用工计划人员↗](http://fe.jiuworker.com:3000/project/29/interface/api/1075)
 *
 * @分类 [雇员接口-对外↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_85)
 * @标签 `雇员接口-对外`
 * @请求头 `POST /out/employee/remove`
 * @更新时间 `2024-08-23 16:22:10`
 */

/* **请求body类型** */
export interface PostOutEmployeeRemoveBody {
  enterId?: number
  idcard?: string
  laborId?: number
  planId?: number
  remark?: string
}

/* **返回类型** */
export type PostOutEmployeeRemoveResponse = boolean

/* **请求函数** */
export async function postOutEmployeeRemove(body: PostOutEmployeeRemoveBody): Promise<any> {
  return request(`/out/employee/remove`, {
    method: Method.POST,

    data: body,
  })
}

/**
 * 接口 [22.薪资记录↗](http://fe.jiuworker.com:3000/project/29/interface/api/1077)
 *
 * @分类 [雇员接口-对外↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_85)
 * @标签 `雇员接口-对外`
 * @请求头 `GET /out/employee/salary/list`
 * @更新时间 `2024-08-23 16:22:10`
 */

/* **请求query类型** */
export interface GetOutEmployeeSalaryListQuery {
  /**
   * date
   */
  date?: string
  /**
   * idcard
   */
  idcard: string
  /**
   * pageNum
   */
  pageNum?: number
  /**
   * pageSize
   */
  pageSize?: number
  /**
   * 状态0待结算 1已结算（付款）
   */
  status?: string
  /**
   * teamId
   */
  teamId?: string
}

/* **返回类型** */
export interface GetOutEmployeeSalaryListResponse {
  /**
   * 结算记录
   */
  list: {
    /**
     * 计件
     */
    piece: number
    /**
     * 收入
     */
    salary: number
    /**
     * 状态  0待结算1已结算
     */
    status: number
    teamId: number
    teamName: string
    /**
     * 计价方式 0:按小时 1:按天 2:按次
     */
    valuationType: number
    /**
     * 工作日期
     */
    workDate: string
    /**
     * 工时
     */
    workHour: string
  }[]
  /**
   * 月结算收入
   */
  monthSalary: number
}

/* **请求函数** */
export async function getOutEmployeeSalaryList(query: GetOutEmployeeSalaryListQuery): Promise<any> {
  return request(`/out/employee/salary/list`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [23.薪资记录↗](http://fe.jiuworker.com:3000/project/29/interface/api/1079)
 *
 * @分类 [雇员接口-对外↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_85)
 * @标签 `雇员接口-对外`
 * @请求头 `GET /out/employee/salary/team/list`
 * @更新时间 `2024-08-23 16:22:10`
 */

/* **请求query类型** */
export interface GetOutEmployeeSalaryTeamListQuery {
  /**
   * idcard
   */
  idcard: string
  /**
   * teamIds
   */
  teamIds?: string
}

/* **返回类型** */
export type GetOutEmployeeSalaryTeamListResponse = {
  idcard: string
  /**
   * 当月收入
   */
  monthSalary: number
  teamId: number
  /**
   * 总收入
   */
  totalSalary: number
  /**
   * 待发放工资
   */
  waitSalary: number
}[]

/* **请求函数** */
export async function getOutEmployeeSalaryTeamList(query: GetOutEmployeeSalaryTeamListQuery): Promise<any> {
  return request(`/out/employee/salary/team/list`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [22.薪资记录-班组列表↗](http://fe.jiuworker.com:3000/project/29/interface/api/1081)
 *
 * @分类 [雇员接口-对外↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_85)
 * @标签 `雇员接口-对外`
 * @请求头 `GET /out/employee/salary/team/select`
 * @更新时间 `2024-08-23 16:22:10`
 */

/* **请求query类型** */
export interface GetOutEmployeeSalaryTeamSelectQuery {
  /**
   * idcard
   */
  idcard: string
  /**
   * pageNum
   */
  pageNum?: number
  /**
   * pageSize
   */
  pageSize?: number
}

/* **返回类型** */
export interface GetOutEmployeeSalaryTeamSelectResponse {
  data: {
    id: number
    /**
     * 班组名称
     */
    name: string
  }[]
  total: number
}

/* **请求函数** */
export async function getOutEmployeeSalaryTeamSelect(query: GetOutEmployeeSalaryTeamSelectQuery): Promise<any> {
  return request(`/out/employee/salary/team/select`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [2.班组详情↗](http://fe.jiuworker.com:3000/project/29/interface/api/1083)
 *
 * @分类 [雇员接口-对外↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_85)
 * @标签 `雇员接口-对外`
 * @请求头 `POST /out/employee/team/detail/{teamId}`
 * @更新时间 `2024-08-23 16:22:10`
 */

/* **请求params类型** */
export interface PostOutEmployeeTeamDetailByTeamIdParams {
  /**
   * teamId
   */
  teamId: string
}

/* **返回类型** */
export interface PostOutEmployeeTeamDetailByTeamIdResponse {
  companyId: number
  /**
   *  报名截止时间
   */
  deadline: string
  /**
   * 用工单位id
   */
  employerCompanyId: number
  /**
   * 用工单位名称
   */
  employerCompanyName: string
  endDate: string
  /**
   * 班组可以结束打卡时间
   */
  endSplitDateTime: string
  endTime: string
  excessRatio: number
  id: number
  /**
   * 岗位列表
   */
  jobs: {
    /**
     * 最大年龄
     */
    ageMax: number
    /**
     * 最小年龄
     */
    ageMin: number
    /**
     * 已报名人数
     */
    appliedNum: number
    /**
     * 报名已满 0未满 1已满
     */
    applyFull: number
    femaleNum: number
    id: number
    /**
     * 岗位id-用工计划中
     */
    jobId: number
    maleNum: number
    /**
     * 派单-最大单价
     */
    maxPrice: number
    /**
     * 派单-最小单价
     */
    minPrice: number
    /**
     * 岗位名称
     */
    name: string
    /**
     * 用工计划id
     */
    num: number
    /**
     * 其它要求-录用确认规则引擎
     */
    other: string
    /**
     * 单价
     */
    price: string
    /**
     * 班组id
     */
    teamId: number
    /**
     * 计价方式 0:按小时 1:按天 2:按次
     */
    valuationType: number
  }[]
  /**
   * 领班手机号
   */
  leaderMobile: string
  location: {
    address: string
    latitude: string
    longitude: string
    region: string[]
  }
  /**
   * 班组名称
   */
  name: string
  /**
   * 用工计划id
   */
  orderId: number
  /**
   * 休息时长 小时
   */
  restTime: number
  source: string
  startDate: string
  /**
   * 班组可以开始打卡时间
   */
  startSplitDateTime: string
  startTime: string
  /**
   * 计价方式 0:按小时 1:按天 2:按次
   */
  valuationType: number
}

/* **请求函数** */
export async function postOutEmployeeTeamDetailByTeamId(params: PostOutEmployeeTeamDetailByTeamIdParams): Promise<any> {
  return request(`/out/employee/team/detail/${params.teamId}`, {
    method: Method.POST,
  })
}

/**
 * 接口 [12.工作详情↗](http://fe.jiuworker.com:3000/project/29/interface/api/1085)
 *
 * @分类 [雇员接口-对外↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_85)
 * @标签 `雇员接口-对外`
 * @请求头 `GET /out/employee/work/info`
 * @更新时间 `2024-08-23 16:22:10`
 */

/* **请求query类型** */
export interface GetOutEmployeeWorkInfoQuery {
  /**
   * idcard
   */
  idcard: string
  /**
   * teamIds
   */
  teamIds: string
}

/* **返回类型** */
export type GetOutEmployeeWorkInfoResponse = {
  companyId: number
  /**
   * 用工单位id
   */
  employerCompanyId: number
  /**
   * 用工单位名称
   */
  employerCompanyName: string
  /**
   * 班组结束日期
   */
  endDate: string
  endTime: string
  /**
   * 员工离职时间-可能提前离职
   */
  endWorkDate: string
  id: number
  /**
   * 岗位id-用工计划中
   */
  jobId: number
  /**
   * 岗位名称
   */
  jobName: string
  /**
   * 状态 0报名中 1已录用 2已拒绝 3已作废
   */
  laborStatus: number
  /**
   * 领班手机号
   */
  leaderMobile: string
  location: {
    address: string
    latitude: string
    longitude: string
    region: string[]
  }
  /**
   * 班组名称
   */
  name: string
  /**
   * 用工计划id
   */
  orderId: number
  /**
   * 单价
   */
  price: number
  /**
   * 拒绝理由
   */
  refuseReason: string
  /**
   * 休息时长 小时
   */
  restTime: number
  /**
   * 班组开始日期
   */
  startDate: string
  startTime: string
  /**
   * 员工入职时间-可能后期补充
   */
  startWorkDate: string
  teamId: number
  /**
   * 计价方式 0:按小时 1:按天 2:按次
   */
  valuationType: number
}[]

/* **请求函数** */
export async function getOutEmployeeWorkInfo(query: GetOutEmployeeWorkInfoQuery): Promise<any> {
  return request(`/out/employee/work/info`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [人脸检测↗](http://fe.jiuworker.com:3000/project/29/interface/api/2425)
 *
 * @分类 [雇员接口-对外↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_85)
 * @标签 `雇员接口-对外`
 * @请求头 `POST /out/employee/face/check`
 * @更新时间 `2024-08-23 16:22:10`
 */

/* **请求body类型** */
export interface PostOutEmployeeFaceCheckBody {
  idcard?: string
  jobId?: number
}

/* **返回类型** */
export type PostOutEmployeeFaceCheckResponse = boolean

/* **请求函数** */
export async function postOutEmployeeFaceCheck(body: PostOutEmployeeFaceCheckBody): Promise<any> {
  return request(`/out/employee/face/check`, {
    method: Method.POST,

    data: body,
  })
}

/**
 * 接口 [2.查询考勤列表↗](http://fe.jiuworker.com:3000/project/29/interface/api/963)
 *
 * @分类 [首页管理↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_86)
 * @标签 `首页管理`
 * @请求头 `GET /homepage/attendance`
 * @更新时间 `2024-08-23 16:22:08`
 */

/* **请求query类型** */
export interface GetHomepageAttendanceQuery {
  /**
   * date
   */
  date?: string
  /**
   * teamId
   */
  teamId?: string
}

/* **返回类型** */
export interface GetHomepageAttendanceResponse {
  /**
   * 旷工
   */
  absenteeism: number
  /**
   * 出勤人次
   */
  attendance: number
  /**
   * 早退
   */
  early: number
  /**
   * 缺卡
   */
  lackCard: number
  /**
   * 迟到
   */
  late: number
  /**
   * 补卡
   */
  makeUpCard: number
  /**
   * 正常
   */
  normal: number
  /**
   * 计件
   */
  piece: number
  /**
   * 排班人次
   */
  scheduled: number
  /**
   * 工时
   */
  workHour: string
}

/* **请求函数** */
export async function getHomepageAttendance(query: GetHomepageAttendanceQuery): Promise<any> {
  return request(`/homepage/attendance`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [6.保险列表↗](http://fe.jiuworker.com:3000/project/29/interface/api/965)
 *
 * @分类 [首页管理↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_86)
 * @标签 `首页管理`
 * @请求头 `GET /homepage/insurance`
 * @更新时间 `2024-08-23 16:22:08`
 */

/* **请求query类型** */
export interface GetHomepageInsuranceQuery {
  /**
   * pageNum
   */
  pageNum?: number
  /**
   * pageSize
   */
  pageSize?: number
  /**
   * 0未投保1已投保
   */
  type?: string
}

/* **返回类型** */
export interface GetHomepageInsuranceResponse {
  data: {
    /**
     * 头像
     */
    avatar: string
    id: number
    /**
     * 投保时间
     */
    insuranceTime: string
    /**
     * 班组id
     */
    teamId: number
    /**
     * 班组名称
     */
    teamName: string
    userId: number
    /**
     * 用户名
     */
    userName: string
  }[]
  total: number
}

/* **请求函数** */
export async function getHomepageInsurance(query: GetHomepageInsuranceQuery): Promise<any> {
  return request(`/homepage/insurance`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [5.保险图表↗](http://fe.jiuworker.com:3000/project/29/interface/api/967)
 *
 * @分类 [首页管理↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_86)
 * @标签 `首页管理`
 * @请求头 `GET /homepage/insurance/chart`
 * @更新时间 `2024-08-23 16:22:08`
 */

/* **返回类型** */
export type GetHomepageInsuranceChartResponse = {
  date: string
  /**
   * 类型 income:收入 settle:工资
   */
  type: string
  /**
   * 预估收入
   */
  value: number
}[]

/* **请求函数** */
export async function getHomepageInsuranceChart(): Promise<any> {
  return request(`/homepage/insurance/chart`, {
    method: Method.GET,
  })
}

/**
 * 接口 [4.查询账号列表↗](http://fe.jiuworker.com:3000/project/29/interface/api/969)
 *
 * @分类 [首页管理↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_86)
 * @标签 `首页管理`
 * @请求头 `GET /homepage/list/apply`
 * @更新时间 `2024-08-23 16:22:08`
 */

/* **请求query类型** */
export interface GetHomepageListApplyQuery {
  /**
   * pageNum
   */
  pageNum?: number
  /**
   * pageSize
   */
  pageSize?: number
  /**
   * teamId
   */
  teamId?: string
}

/* **返回类型** */
export interface GetHomepageListApplyResponse {
  data: {
    /**
     * 头像
     */
    avatar: string
    /**
     * 1:男，0:女
     */
    gender: number
    /**
     * 创建（报名）时间
     */
    gmtCreate: string
    gmtModified: string
    id: number
    /**
     * 岗位id
     */
    jobId: number
    /**
     * 岗位名称
     */
    jobName: string
    teamEmployeeId: number
    /**
     * 班组id
     */
    teamId: number
    /**
     * 班组名称
     */
    teamName: string
    /**
     * 用户id
     */
    userId: number
    /**
     * 用户名
     */
    userName: string
  }[]
  total: number
}

/* **请求函数** */
export async function getHomepageListApply(query: GetHomepageListApplyQuery): Promise<any> {
  return request(`/homepage/list/apply`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [3.查询结算列表↗](http://fe.jiuworker.com:3000/project/29/interface/api/971)
 *
 * @分类 [首页管理↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_86)
 * @标签 `首页管理`
 * @请求头 `GET /homepage/settle`
 * @更新时间 `2024-08-23 16:22:08`
 */

/* **返回类型** */
export interface GetHomepageSettleResponse {
  /**
   * 全部待发
   */
  allSettle: number
  /**
   * 柱状图 income:收入 settle:工资
   */
  chart: {
    date: string
    /**
     * 类型 income:收入 settle:工资
     */
    type: string
    /**
     * 预估收入
     */
    value: number
  }[]
  /**
   * 今日预估收入
   */
  todayIncome: number
  /**
   * 今日待发
   */
  todaySettle: number
}

/* **请求函数** */
export async function getHomepageSettle(): Promise<any> {
  return request(`/homepage/settle`, {
    method: Method.GET,
  })
}

/**
 * 接口 [1.查询顶部统计详情↗](http://fe.jiuworker.com:3000/project/29/interface/api/973)
 *
 * @分类 [首页管理↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_86)
 * @标签 `首页管理`
 * @请求头 `GET /homepage/top/bar`
 * @更新时间 `2024-08-23 16:22:08`
 */

/* **返回类型** */
export interface GetHomepageTopBarResponse {
  /**
   * 进行中数量
   */
  doingNum: number
  /**
   * 待录用人数
   */
  waitEmployNum: number
  /**
   * 待接单数量
   */
  waitOrderNum: number
  /**
   * 待发工资
   */
  waitSalary: number
  /**
   * 待结算收入
   */
  waitSettle: number
}

/* **请求函数** */
export async function getHomepageTopBar(): Promise<any> {
  return request(`/homepage/top/bar`, {
    method: Method.GET,
  })
}

/**
 * 接口 [10.保险回调接口↗](http://fe.jiuworker.com:3000/project/29/interface/api/1193)
 *
 * @分类 [回调接口↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_87)
 * @标签 `回调接口`
 * @请求头 `POST /out/insurance/callback`
 * @更新时间 `2024-08-23 16:22:10`
 */

/* **请求body类型** */
export interface PostOutInsuranceCallbackBody {
  /**
   * 结束时间
   */
  endTime?: string
  /**
   * 保单号码-outBizNo
   */
  insuranceNo?: string
  /**
   * 保险价格
   */
  priceCent?: number
  /**
   * 失败原因
   */
  reason?: string
  /**
   * 开始时间
   */
  startTime?: string
  /**
   * 状态 1:正常，2:失败
   */
  status?: number
}

/* **返回类型** */
export interface PostOutInsuranceCallbackResponse {}

/* **请求函数** */
export async function postOutInsuranceCallback(body: PostOutInsuranceCallbackBody): Promise<any> {
  return request(`/out/insurance/callback`, {
    method: Method.POST,

    data: body,
  })
}

/**
 * 接口 [20.设备数据回掉↗](http://fe.jiuworker.com:3000/project/29/interface/api/1571)
 *
 * @分类 [回调接口↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_87)
 * @标签 `回调接口`
 * @请求头 `POST /out/iot/callback`
 * @更新时间 `2024-08-23 16:22:10`
 */

/* **请求query类型** */
export interface PostOutIotCallbackQuery {
  /**
   * eventCode
   */
  eventCode?: string
  /**
   * eventGuid
   */
  eventGuid?: string
  /**
   * eventMsg
   */
  eventMsg?: string
}

/* **返回类型** */
export interface PostOutIotCallbackResponse {}

/* **请求函数** */
export async function postOutIotCallback(query: PostOutIotCallbackQuery): Promise<any> {
  return request(`/out/iot/callback`, {
    method: Method.POST,
    params: query,
  })
}

/**
 * 接口 [10.考勤回调接口↗](http://fe.jiuworker.com:3000/project/29/interface/api/1713)
 *
 * @分类 [回调接口↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_87)
 * @标签 `回调接口`
 * @请求头 `POST /out/attend/callback`
 * @更新时间 `2024-08-23 16:22:09`
 */

/* **请求body类型** */
export type PostOutAttendCallbackBody = {
  attendId?: string
  attendScene?: string
  certName?: string
  certNo?: string
  certType?: string
  employeeCardNo?: string
  eventPlace?: string
  eventTime?: string
  jobCode?: string
  jobLevel?: string
  outBizNo?: string
}[]

/* **返回类型** */
export interface PostOutAttendCallbackResponse {}

/* **请求函数** */
export async function postOutAttendCallback(body: PostOutAttendCallbackBody): Promise<any> {
  return request(`/out/attend/callback`, {
    method: Method.POST,

    data: body,
  })
}

/**
 * 接口 [30.↗](http://fe.jiuworker.com:3000/project/29/interface/api/1959)
 *
 * @分类 [回调接口↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_87)
 * @标签 `回调接口`
 * @请求头 `POST /out/callback/test`
 * @更新时间 `2024-08-23 16:22:09`
 */

/* **返回类型** */
export interface PostOutCallbackTestResponse {}

/* **请求函数** */
export async function postOutCallbackTest(): Promise<any> {
  return request(`/out/callback/test`, {
    method: Method.POST,
  })
}

/**
 * 接口 [2.服务端生成授权token 供前端绑定微工卡↗](http://fe.jiuworker.com:3000/project/29/interface/api/1437)
 *
 * @分类 [微工卡相关对外接口↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_100)
 * @标签 `微工卡相关对外接口`
 * @请求头 `GET /out/wechat/pay/create/token`
 * @更新时间 `2024-08-23 16:22:10`
 */

/* **请求query类型** */
export interface GetOutWechatPayCreateTokenQuery {
  /**
   * idcard
   */
  idcard: string
  /**
   * openid
   */
  openid: string
  /**
   * teamId
   */
  teamId: string
  /**
   * username
   */
  username: string
}

/* **返回类型** */
export interface GetOutWechatPayCreateTokenResponse {
  appid: string
  expiresIn: number
  mchid: string
  openid: string
  subMchid: string
  token: string
}

/* **请求函数** */
export async function getOutWechatPayCreateToken(query: GetOutWechatPayCreateTokenQuery): Promise<any> {
  return request(`/out/wechat/pay/create/token`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [1.报名时获取用户openId 判断是否存在 需要考虑用户授权取消的情况↗](http://fe.jiuworker.com:3000/project/29/interface/api/1439)
 *
 * @分类 [微工卡相关对外接口↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_100)
 * @标签 `微工卡相关对外接口`
 * @请求头 `GET /out/wechat/pay/payroll/auth`
 * @更新时间 `2024-08-23 16:22:10`
 */

/* **请求query类型** */
export interface GetOutWechatPayPayrollAuthQuery {
  /**
   * openid
   */
  openid: string
  /**
   * teamId
   */
  teamId: string
}

/* **返回类型** */
export interface GetOutWechatPayPayrollAuthResponse {
  /**
   * 授权状态 0未授权 1已授权
   */
  authStatus: number
}

/* **请求函数** */
export async function getOutWechatPayPayrollAuth(query: GetOutWechatPayPayrollAuthQuery): Promise<any> {
  return request(`/out/wechat/pay/payroll/auth`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [职工考勤统计↗](http://fe.jiuworker.com:3000/project/29/interface/api/1519)
 *
 * @分类 [职工部门模块/app端↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_104)
 * @标签 `职工部门模块/app端`
 * @请求头 `GET /dept/attendance/app/query`
 * @更新时间 `2024-08-23 16:22:06`
 */

/* **请求query类型** */
export interface GetDeptAttendanceAppQueryQuery {
  /**
   * deptId
   */
  deptId?: string
  /**
   * queryDate
   */
  queryDate: string
}

/* **返回类型** */
export interface GetDeptAttendanceAppQueryResponse {
  /**
   * 考勤异常
   */
  abnormalAmount: number
  /**
   * 异常人员
   */
  abnormalAttendancePeopleList: {
    /**
     * 下午打卡时间
     */
    afterDate: string
    /**
     * 下午打卡状态
     */
    afterStatus: number
    /**
     * 人脸照片
     */
    faceUrl: string
    /**
     * 计件数量
     */
    itemAmount: number
    /**
     * 早上打卡时间
     */
    moonDate: string
    /**
     * 早上打卡状态
     */
    moonStatus: number
    /**
     * 电话
     */
    phone: string
    /**
     * 今日上班工时
     */
    todayHour: number
    /**
     * 人员id
     */
    userId: number
    /**
     * 人员名称
     */
    userName: string
  }[]
  /**
   * 今日出勤
   */
  attendanceAmount: number
  /**
   * 已出勤人员
   */
  hadAttendancePeopleList: {
    /**
     * 下午打卡时间
     */
    afterDate: string
    /**
     * 下午打卡状态
     */
    afterStatus: number
    /**
     * 人脸照片
     */
    faceUrl: string
    /**
     * 计件数量
     */
    itemAmount: number
    /**
     * 早上打卡时间
     */
    moonDate: string
    /**
     * 早上打卡状态
     */
    moonStatus: number
    /**
     * 电话
     */
    phone: string
    /**
     * 今日上班工时
     */
    todayHour: number
    /**
     * 人员id
     */
    userId: number
    /**
     * 人员名称
     */
    userName: string
  }[]
  /**
   * 未出勤人员
   */
  notAttendancePeopleList: {
    /**
     * 下午打卡时间
     */
    afterDate: string
    /**
     * 下午打卡状态
     */
    afterStatus: number
    /**
     * 人脸照片
     */
    faceUrl: string
    /**
     * 计件数量
     */
    itemAmount: number
    /**
     * 早上打卡时间
     */
    moonDate: string
    /**
     * 早上打卡状态
     */
    moonStatus: number
    /**
     * 电话
     */
    phone: string
    /**
     * 今日上班工时
     */
    todayHour: number
    /**
     * 人员id
     */
    userId: number
    /**
     * 人员名称
     */
    userName: string
  }[]
  /**
   * 用工计划名称
   */
  planName: string
  /**
   * 累计工时
   */
  totalHour: number
  /**
   * 今天排班
   */
  totalPeople: number
}

/* **请求函数** */
export async function getDeptAttendanceAppQuery(query: GetDeptAttendanceAppQueryQuery): Promise<any> {
  return request(`/dept/attendance/app/query`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [获取个人考勤详情↗](http://fe.jiuworker.com:3000/project/29/interface/api/1521)
 *
 * @分类 [职工部门模块/app端↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_104)
 * @标签 `职工部门模块/app端`
 * @请求头 `GET /dept/attendance/app/user`
 * @更新时间 `2024-08-23 16:22:06`
 */

/* **请求query类型** */
export interface GetDeptAttendanceAppUserQuery {
  /**
   * 月份yyyy-MM-dd
   */
  month?: string
  /**
   * userId
   */
  userId: string
}

/* **返回类型** */
export interface GetDeptAttendanceAppUserResponse {
  /**
   * 人员月度考勤详情
   */
  attendanceUserMonthVOList: {
    /**
     * 是否异常：true 是；false 否
     */
    abnormal: boolean
    /**
     * 下班打卡时间
     */
    afterDate: string
    /**
     * 下班打卡状态
     */
    afterStatus: number
    /**
     * 打卡日期：yyyy-MM-dd
     */
    dayDate: string
    /**
     * 计件数量
     */
    itemAmount: number
    /**
     * 上班打卡时间
     */
    moonDate: string
    /**
     * 上班打卡状态
     */
    moonStatus: number
    /**
     * 工作工时
     */
    workHour: number
  }[]
  /**
   * 部门
   */
  deptName: string
  /**
   * 人脸
   */
  faceUrl: string
  name: string
  /**
   * 异常
   */
  totalAbnormal: number
  /**
   * 累计天数
   */
  totalDays: number
  /**
   * 累计工时
   */
  totalHour: number
  /**
   * 计件
   */
  totalItem: number
}

/* **请求函数** */
export async function getDeptAttendanceAppUser(query: GetDeptAttendanceAppUserQuery): Promise<any> {
  return request(`/dept/attendance/app/user`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [添加考勤记录↗](http://fe.jiuworker.com:3000/project/29/interface/api/1517)
 *
 * @分类 [职工部门模块/每日考勤、月度汇总↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_106)
 * @标签 `职工部门模块/每日考勤、月度汇总`
 * @请求头 `POST /dept/attendance/add`
 * @更新时间 `2024-08-23 16:22:06`
 */

/* **请求body类型** */
export interface PostDeptAttendanceAddBody {
  /**
   * 考勤时间
   */
  attendanceDate?: string
  /**
   * 查询日期时间
   */
  dayDate?: string
  enterId?: number
  /**
   * 备注
   */
  remark?: string
  /**
   * 10:酒店；20劳务
   */
  sourceType?: number
  /**
   * 员工ID
   */
  userIdList?: number[]
}

/* **返回类型** */
export type PostDeptAttendanceAddResponse = boolean

/* **请求函数** */
export async function postDeptAttendanceAdd(body: PostDeptAttendanceAddBody): Promise<any> {
  return request(`/dept/attendance/add`, {
    method: Method.POST,

    data: body,
  })
}

/**
 * 接口 [每日考勤↗](http://fe.jiuworker.com:3000/project/29/interface/api/1523)
 *
 * @分类 [职工部门模块/每日考勤、月度汇总↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_106)
 * @标签 `职工部门模块/每日考勤、月度汇总`
 * @请求头 `GET /dept/attendance/day`
 * @更新时间 `2024-08-23 16:22:06`
 */

/* **请求query类型** */
export interface GetDeptAttendanceDayQuery {
  /**
   * dayDate
   */
  dayDate: string
  /**
   * 部门ID
   */
  deptId?: string
  /**
   * 是否展示子部门成员
   */
  subPerson?: string
}

/* **返回类型** */
export interface GetDeptAttendanceDayResponse {
  /**
   * 旷工人数
   */
  abstainedAmount: number
  /**
   * 早退人数
   */
  backAmount: number
  /**
   * 迟到人数
   */
  delayAmount: number
  /**
   * 人员信息
   */
  enterAttendanceDayDetailVOList: {
    /**
     * 下午打卡状态：10:正常；20迟到；30早退；40缺卡 50旷工；60(正常补)
     */
    afterStatus: number
    /**
     * 下班打开时间
     */
    afternoonDate: string
    /**
     * 打卡日
     */
    dayDate: string
    /**
     * 部门
     */
    deptName: string
    id: number
    /**
     * 岗位
     */
    jobName: string
    /**
     * 上班打卡时间
     */
    moonDate: string
    /**
     * 早上打卡状态：10:正常；20迟到；30早退；40缺卡 50旷工；60(正常补)
     */
    moonStatus: number
    /**
     * 姓名
     */
    name: string
    /**
     * 班次信息
     */
    teamName: string
    /**
     * 实际工时
     */
    totalHour: number
    /**
     * 总件数
     */
    totalTimeAmount: number
    userId: number
  }[]
  /**
   * 缺卡人数
   */
  missAmount: number
  /**
   * 补卡
   */
  repairAmount: number
}

/* **请求函数** */
export async function getDeptAttendanceDay(query: GetDeptAttendanceDayQuery): Promise<any> {
  return request(`/dept/attendance/day`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [修改日志↗](http://fe.jiuworker.com:3000/project/29/interface/api/1525)
 *
 * @分类 [职工部门模块/每日考勤、月度汇总↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_106)
 * @标签 `职工部门模块/每日考勤、月度汇总`
 * @请求头 `GET /dept/attendance/day/log/{id}`
 * @更新时间 `2024-08-23 16:22:06`
 */

/* **请求params类型** */
export interface GetDeptAttendanceDayLogByIdParams {
  /**
   * id
   */
  id: string
}

/* **返回类型** */
export type GetDeptAttendanceDayLogByIdResponse = {
  /**
   * 部门
   */
  deptName: string
  id: number
  /**
   * 岗位
   */
  jobName: string
  /**
   * 姓名
   */
  name: string
  /**
   * 原记录
   */
  oldData: string
  /**
   * 备注
   */
  remark: string
  /**
   * 修改后记录
   */
  repairData: string
}[]

/* **请求函数** */
export async function getDeptAttendanceDayLogById(params: GetDeptAttendanceDayLogByIdParams): Promise<any> {
  return request(`/dept/attendance/day/log/${params.id}`, {
    method: Method.GET,
  })
}

/**
 * 接口 [月度汇总↗](http://fe.jiuworker.com:3000/project/29/interface/api/1527)
 *
 * @分类 [职工部门模块/每日考勤、月度汇总↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_106)
 * @标签 `职工部门模块/每日考勤、月度汇总`
 * @请求头 `GET /dept/attendance/month`
 * @更新时间 `2024-08-23 16:22:06`
 */

/* **请求query类型** */
export interface GetDeptAttendanceMonthQuery {
  /**
   * 部门ID
   */
  deptId?: string
  /**
   * 月份yyyy-MM-dd
   */
  month?: string
  /**
   * 是否展示子部门成员
   */
  subPerson?: string
}

/* **返回类型** */
export interface GetDeptAttendanceMonthResponse {
  /**
   * 旷工人数
   */
  abstainedAmount: number
  /**
   * 早退人数
   */
  backAmount: number
  /**
   * 迟到人数
   */
  delayAmount: number
  enterAttendancePlanMonthDetailVOList: {
    /**
     * 旷工
     */
    abstainedAmount: number
    /**
     * 早退
     */
    backAmount: number
    /**
     * 迟到
     */
    delayAmount: number
    /**
     * 部门
     */
    deptName: string
    /**
     * 岗位
     */
    jobName: string
    /**
     * 缺卡
     */
    missAmount: number
    /**
     * 人员
     */
    name: string
    personId: number
    /**
     * 应该出勤天数
     */
    shouldDay: number
    /**
     * 应该出勤时长
     */
    shouldHour: number
    /**
     * 天数
     */
    totalDay: number
    /**
     * 工时
     */
    totalHour: number
  }[]
  /**
   * 缺卡人数
   */
  missAmount: number
}

/* **请求函数** */
export async function getDeptAttendanceMonth(query: GetDeptAttendanceMonthQuery): Promise<any> {
  return request(`/dept/attendance/month`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [职工考勤详情↗](http://fe.jiuworker.com:3000/project/29/interface/api/1529)
 *
 * @分类 [职工部门模块/每日考勤、月度汇总↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_106)
 * @标签 `职工部门模块/每日考勤、月度汇总`
 * @请求头 `GET /dept/attendance/person/detail`
 * @更新时间 `2024-08-23 16:22:06`
 */

/* **请求query类型** */
export interface GetDeptAttendancePersonDetailQuery {
  /**
   * 月份yyyy-MM-dd
   */
  month?: string
  /**
   * userId
   */
  userId: string
}

/* **返回类型** */
export interface GetDeptAttendancePersonDetailResponse {
  /**
   * 旷工
   */
  abstainedAmount: number
  /**
   * 早退
   */
  backAmount: number
  /**
   * 迟到
   */
  delayAmount: number
  /**
   * 部门
   */
  deptName: string
  /**
   * 人员详情
   */
  enterAttendanceDayDetailVOList: {
    /**
     * 下午打卡状态：10:正常；20迟到；30早退；40缺卡 50旷工；60(正常补)
     */
    afterStatus: number
    /**
     * 下班打开时间
     */
    afternoonDate: string
    /**
     * 打卡日
     */
    dayDate: string
    /**
     * 部门
     */
    deptName: string
    id: number
    /**
     * 岗位
     */
    jobName: string
    /**
     * 上班打卡时间
     */
    moonDate: string
    /**
     * 早上打卡状态：10:正常；20迟到；30早退；40缺卡 50旷工；60(正常补)
     */
    moonStatus: number
    /**
     * 姓名
     */
    name: string
    /**
     * 班次信息
     */
    teamName: string
    /**
     * 实际工时
     */
    totalHour: number
    /**
     * 总件数
     */
    totalTimeAmount: number
    userId: number
  }[]
  /**
   * 人脸
   */
  faceUrl: string
  /**
   * 岗位
   */
  jobName: string
  /**
   * 缺卡
   */
  missAmount: number
  /**
   * 姓名
   */
  name: string
  /**
   * 补卡
   */
  repairAmount: number
  /**
   * 应该出勤天数
   */
  shouldDay: number
  /**
   * 应该出勤时长
   */
  shouldHour: number
  /**
   * 天数
   */
  totalDay: number
  /**
   * 工时
   */
  totalHour: number
}

/* **请求函数** */
export async function getDeptAttendancePersonDetail(query: GetDeptAttendancePersonDetailQuery): Promise<any> {
  return request(`/dept/attendance/person/detail`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [分页查询补卡记录↗](http://fe.jiuworker.com:3000/project/29/interface/api/1531)
 *
 * @分类 [职工部门模块/每日考勤、月度汇总↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_106)
 * @标签 `职工部门模块/每日考勤、月度汇总`
 * @请求头 `GET /dept/attendance/repair`
 * @更新时间 `2024-08-23 16:22:06`
 */

/* **请求query类型** */
export interface GetDeptAttendanceRepairQuery {
  /**
   * dayDate
   */
  dayDate?: string
  /**
   * name
   */
  name?: string
  /**
   * pageNum
   */
  pageNum?: number
  /**
   * pageSize
   */
  pageSize?: number
  /**
   * personId
   */
  personId?: string
}

/* **返回类型** */
export interface GetDeptAttendanceRepairResponse {
  data: {
    /**
     * 部门
     */
    deptName: string
    id: number
    /**
     * 岗位
     */
    jobName: string
    /**
     * 姓名
     */
    name: string
    /**
     * 原记录
     */
    oldData: string
    /**
     * 备注
     */
    remark: string
    /**
     * 修改后记录
     */
    repairData: string
  }[]
  total: number
}

/* **请求函数** */
export async function getDeptAttendanceRepair(query: GetDeptAttendanceRepairQuery): Promise<any> {
  return request(`/dept/attendance/repair`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [每日考勤-导出↗](http://fe.jiuworker.com:3000/project/29/interface/api/2003)
 *
 * @分类 [职工部门模块/每日考勤、月度汇总↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_106)
 * @标签 `职工部门模块/每日考勤、月度汇总`
 * @请求头 `GET /dept/attendance/day/export`
 * @更新时间 `2024-08-23 16:22:06`
 */

/* **请求query类型** */
export interface GetDeptAttendanceDayExportQuery {
  /**
   * dayDate
   */
  dayDate: string
  /**
   * 部门ID
   */
  deptId?: string
  /**
   * 是否展示子部门成员
   */
  subPerson?: string
}

/* **返回类型** */
export type GetDeptAttendanceDayExportResponse = any

/* **请求函数** */
export async function getDeptAttendanceDayExport(query: GetDeptAttendanceDayExportQuery): Promise<any> {
  return request(`/dept/attendance/day/export`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [每月考勤-导出↗](http://fe.jiuworker.com:3000/project/29/interface/api/2085)
 *
 * @分类 [职工部门模块/每日考勤、月度汇总↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_106)
 * @标签 `职工部门模块/每日考勤、月度汇总`
 * @请求头 `GET /dept/attendance/month/export`
 * @更新时间 `2024-08-23 16:22:06`
 */

/* **请求query类型** */
export interface GetDeptAttendanceMonthExportQuery {
  /**
   * yyyy-MM-dd
   */
  date?: string
  /**
   * 部门ID
   */
  deptId?: string
  /**
   * 是否展示子部门成员
   */
  subPerson?: string
}

/* **返回类型** */
export type GetDeptAttendanceMonthExportResponse = any

/* **请求函数** */
export async function getDeptAttendanceMonthExport(query: GetDeptAttendanceMonthExportQuery): Promise<any> {
  return request(`/dept/attendance/month/export`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [新增班次↗](http://fe.jiuworker.com:3000/project/29/interface/api/1553)
 *
 * @分类 [职工部门模块/班次↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_107)
 * @标签 `职工部门模块/班次`
 * @请求头 `POST /dept/team/add`
 * @更新时间 `2024-08-23 16:22:07`
 */

/* **请求body类型** */
export interface PostDeptTeamAddBody {
  arrangeId?: number
  /**
   * 考勤下班时间
   */
  endHour?: string
  enterId?: number
  id?: number
  /**
   * 是否为默认班次
   */
  isDefault?: boolean
  name?: string
  /**
   * 休息结束时间
   */
  relaxEndHour?: string
  /**
   * 休息开始时间
   */
  relaxStartHour?: string
  /**
   * 是否有休息时间
   */
  relaxTime?: boolean
  /**
   * 10:酒店；20劳务
   */
  sourceType?: number
  /**
   * 考勤上班时间
   */
  startHour?: string
}

/* **返回类型** */
export type PostDeptTeamAddResponse = boolean

/* **请求函数** */
export async function postDeptTeamAdd(body: PostDeptTeamAddBody): Promise<any> {
  return request(`/dept/team/add`, {
    method: Method.POST,

    data: body,
  })
}

/**
 * 接口 [删除班次↗](http://fe.jiuworker.com:3000/project/29/interface/api/1555)
 *
 * @分类 [职工部门模块/班次↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_107)
 * @标签 `职工部门模块/班次`
 * @请求头 `DELETE /dept/team/delete/{id}`
 * @更新时间 `2024-08-23 16:22:07`
 */

/* **请求params类型** */
export interface DeleteDeptTeamDeleteByIdParams {
  /**
   * id
   */
  id: string
}

/* **返回类型** */
export type DeleteDeptTeamDeleteByIdResponse = boolean

/* **请求函数** */
export async function deleteDeptTeamDeleteById(params: DeleteDeptTeamDeleteByIdParams): Promise<any> {
  return request(`/dept/team/delete/${params.id}`, {
    method: Method.DELETE,
  })
}

/**
 * 接口 [编辑班次↗](http://fe.jiuworker.com:3000/project/29/interface/api/1557)
 *
 * @分类 [职工部门模块/班次↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_107)
 * @标签 `职工部门模块/班次`
 * @请求头 `PUT /dept/team/edit`
 * @更新时间 `2024-08-23 16:22:07`
 */

/* **请求body类型** */
export interface PutDeptTeamEditBody {
  arrangeId?: number
  /**
   * 考勤下班时间
   */
  endHour?: string
  enterId?: number
  id?: number
  /**
   * 是否为默认班次
   */
  isDefault?: boolean
  name?: string
  /**
   * 休息结束时间
   */
  relaxEndHour?: string
  /**
   * 休息开始时间
   */
  relaxStartHour?: string
  /**
   * 是否有休息时间
   */
  relaxTime?: boolean
  /**
   * 10:酒店；20劳务
   */
  sourceType?: number
  /**
   * 考勤上班时间
   */
  startHour?: string
}

/* **返回类型** */
export type PutDeptTeamEditResponse = boolean

/* **请求函数** */
export async function putDeptTeamEdit(body: PutDeptTeamEditBody): Promise<any> {
  return request(`/dept/team/edit`, {
    method: Method.PUT,

    data: body,
  })
}

/**
 * 接口 [查询班次列表↗](http://fe.jiuworker.com:3000/project/29/interface/api/1559)
 *
 * @分类 [职工部门模块/班次↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_107)
 * @标签 `职工部门模块/班次`
 * @请求头 `GET /dept/team/query`
 * @更新时间 `2024-08-23 16:22:07`
 */

/* **返回类型** */
export type GetDeptTeamQueryResponse = {
  bizData: string
  enterId: number
  gmtCreate: string
  gmtModified: string
  id: number
  name: string
}[]

/* **请求函数** */
export async function getDeptTeamQuery(): Promise<any> {
  return request(`/dept/team/query`, {
    method: Method.GET,
  })
}

/**
 * 接口 [查询班次详情↗](http://fe.jiuworker.com:3000/project/29/interface/api/1561)
 *
 * @分类 [职工部门模块/班次↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_107)
 * @标签 `职工部门模块/班次`
 * @请求头 `GET /dept/team/query/detail/{id}`
 * @更新时间 `2024-08-23 16:22:07`
 */

/* **请求params类型** */
export interface GetDeptTeamQueryDetailByIdParams {
  /**
   * id
   */
  id: string
}

/* **返回类型** */
export interface GetDeptTeamQueryDetailByIdResponse {
  bizData: string
  enterId: number
  gmtCreate: string
  gmtModified: string
  id: number
  name: string
}

/* **请求函数** */
export async function getDeptTeamQueryDetailById(params: GetDeptTeamQueryDetailByIdParams): Promise<any> {
  return request(`/dept/team/query/detail/${params.id}`, {
    method: Method.GET,
  })
}

/**
 * 接口 [新增部门↗](http://fe.jiuworker.com:3000/project/29/interface/api/1473)
 *
 * @分类 [职工部门模块/职工管理↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_108)
 * @标签 `职工部门模块/职工管理`
 * @请求头 `POST /dept/add`
 * @更新时间 `2024-08-23 16:22:05`
 */

/* **请求body类型** */
export interface PostDeptAddBody {
  enterId?: number
  id?: number
  leaderPersonId?: number
  name?: string
  parentId?: number
  phone?: string
  /**
   * 10:酒店；20劳务
   */
  sourceType?: number
}

/* **返回类型** */
export type PostDeptAddResponse = boolean

/* **请求函数** */
export async function postDeptAdd(body: PostDeptAddBody): Promise<any> {
  return request(`/dept/add`, {
    method: Method.POST,

    data: body,
  })
}

/**
 * 接口 [新增人员↗](http://fe.jiuworker.com:3000/project/29/interface/api/1475)
 *
 * @分类 [职工部门模块/职工管理↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_108)
 * @标签 `职工部门模块/职工管理`
 * @请求头 `POST /dept/add/person`
 * @更新时间 `2024-08-23 16:22:05`
 */

/* **请求body类型** */
export interface PostDeptAddPersonBody {
  deptIdList?: number[]
  email?: string
  enterId?: number
  faceUrl?: string
  id?: number
  job?: string
  mainDeptId?: number
  name?: string
  phone?: string
  sex?: string
  /**
   * 10:酒店；20劳务
   */
  sourceType?: number
  workNo?: string
}

/* **返回类型** */
export type PostDeptAddPersonResponse = boolean

/* **请求函数** */
export async function postDeptAddPerson(body: PostDeptAddPersonBody): Promise<any> {
  return request(`/dept/add/person`, {
    method: Method.POST,

    data: body,
  })
}

/**
 * 接口 [批量调整部门↗](http://fe.jiuworker.com:3000/project/29/interface/api/1477)
 *
 * @分类 [职工部门模块/职工管理↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_108)
 * @标签 `职工部门模块/职工管理`
 * @请求头 `PUT /dept/adjust/person`
 * @更新时间 `2024-08-23 16:22:05`
 */

/* **请求body类型** */
export interface PutDeptAdjustPersonBody {
  deptIdList?: number[]
  enterId?: number
  mainDeptId?: number
  personIdList?: number[]
  /**
   * 10:酒店；20劳务
   */
  sourceType?: number
}

/* **返回类型** */
export type PutDeptAdjustPersonResponse = boolean

/* **请求函数** */
export async function putDeptAdjustPerson(body: PutDeptAdjustPersonBody): Promise<any> {
  return request(`/dept/adjust/person`, {
    method: Method.PUT,

    data: body,
  })
}

/**
 * 接口 [删除人员↗](http://fe.jiuworker.com:3000/project/29/interface/api/1533)
 *
 * @分类 [职工部门模块/职工管理↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_108)
 * @标签 `职工部门模块/职工管理`
 * @请求头 `DELETE /dept/delete/person`
 * @更新时间 `2024-08-23 16:22:06`
 */

/* **请求query类型** */
export interface DeleteDeptDeletePersonQuery {
  /**
   * 多个逗号隔开
   */
  ids?: string
}

/* **返回类型** */
export type DeleteDeptDeletePersonResponse = boolean

/* **请求函数** */
export async function deleteDeptDeletePerson(query: DeleteDeptDeletePersonQuery): Promise<any> {
  return request(`/dept/delete/person`, {
    method: Method.DELETE,
    params: query,
  })
}

/**
 * 接口 [删除部门↗](http://fe.jiuworker.com:3000/project/29/interface/api/1535)
 *
 * @分类 [职工部门模块/职工管理↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_108)
 * @标签 `职工部门模块/职工管理`
 * @请求头 `DELETE /dept/delete/{id}`
 * @更新时间 `2024-08-23 16:22:06`
 */

/* **请求params类型** */
export interface DeleteDeptDeleteByIdParams {
  /**
   * id
   */
  id: string
}

/* **返回类型** */
export type DeleteDeptDeleteByIdResponse = boolean

/* **请求函数** */
export async function deleteDeptDeleteById(params: DeleteDeptDeleteByIdParams): Promise<any> {
  return request(`/dept/delete/${params.id}`, {
    method: Method.DELETE,
  })
}

/**
 * 接口 [部门列表↗](http://fe.jiuworker.com:3000/project/29/interface/api/1537)
 *
 * @分类 [职工部门模块/职工管理↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_108)
 * @标签 `职工部门模块/职工管理`
 * @请求头 `GET /dept/dept/list`
 * @更新时间 `2024-08-23 16:22:06`
 */

/* **返回类型** */
export type GetDeptDeptListResponse = {
  bizData: string
  enterId: number
  fullName: string
  gmtCreate: string
  gmtModified: string
  id: number
  name: string
  parentId: number
  rootId: number
}[]

/* **请求函数** */
export async function getDeptDeptList(): Promise<any> {
  return request(`/dept/dept/list`, {
    method: Method.GET,
  })
}

/**
 * 接口 [编辑部门↗](http://fe.jiuworker.com:3000/project/29/interface/api/1539)
 *
 * @分类 [职工部门模块/职工管理↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_108)
 * @标签 `职工部门模块/职工管理`
 * @请求头 `POST /dept/edit`
 * @更新时间 `2024-08-23 16:22:06`
 */

/* **请求body类型** */
export interface PostDeptEditBody {
  enterId?: number
  id?: number
  leaderPersonId?: number
  name?: string
  parentId?: number
  phone?: string
  /**
   * 10:酒店；20劳务
   */
  sourceType?: number
}

/* **返回类型** */
export type PostDeptEditResponse = boolean

/* **请求函数** */
export async function postDeptEdit(body: PostDeptEditBody): Promise<any> {
  return request(`/dept/edit`, {
    method: Method.POST,

    data: body,
  })
}

/**
 * 接口 [编辑人员↗](http://fe.jiuworker.com:3000/project/29/interface/api/1541)
 *
 * @分类 [职工部门模块/职工管理↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_108)
 * @标签 `职工部门模块/职工管理`
 * @请求头 `PUT /dept/edit/person`
 * @更新时间 `2024-08-23 16:22:06`
 */

/* **请求body类型** */
export interface PutDeptEditPersonBody {
  deptIdList?: number[]
  email?: string
  enterId?: number
  faceUrl?: string
  id?: number
  job?: string
  mainDeptId?: number
  name?: string
  phone?: string
  sex?: string
  /**
   * 10:酒店；20劳务
   */
  sourceType?: number
  workNo?: string
}

/* **返回类型** */
export type PutDeptEditPersonResponse = boolean

/* **请求函数** */
export async function putDeptEditPerson(body: PutDeptEditPersonBody): Promise<any> {
  return request(`/dept/edit/person`, {
    method: Method.PUT,

    data: body,
  })
}

/**
 * 接口 [查询人员详情↗](http://fe.jiuworker.com:3000/project/29/interface/api/1543)
 *
 * @分类 [职工部门模块/职工管理↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_108)
 * @标签 `职工部门模块/职工管理`
 * @请求头 `GET /dept/person/detail/{id}`
 * @更新时间 `2024-08-23 16:22:06`
 */

/* **请求params类型** */
export interface GetDeptPersonDetailByIdParams {
  /**
   * id
   */
  id: string
}

/* **返回类型** */
export interface GetDeptPersonDetailByIdResponse {
  deptIdList: number[]
  deptName: string
  email: string
  faceUrl: string
  id: number
  job: string
  mainDeptId: number
  mainDeptName: string
  name: string
  phone: string
  sex: string
  workNo: string
}

/* **请求函数** */
export async function getDeptPersonDetailById(params: GetDeptPersonDetailByIdParams): Promise<any> {
  return request(`/dept/person/detail/${params.id}`, {
    method: Method.GET,
  })
}

/**
 * 接口 [人员列表↗](http://fe.jiuworker.com:3000/project/29/interface/api/1545)
 *
 * @分类 [职工部门模块/职工管理↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_108)
 * @标签 `职工部门模块/职工管理`
 * @请求头 `GET /dept/person/list`
 * @更新时间 `2024-08-23 16:22:06`
 */

/* **返回类型** */
export type GetDeptPersonListResponse = {
  aiotId: string
  bizData: string
  enterId: number
  gmtCreate: string
  gmtModified: string
  id: number
  name: string
}[]

/* **请求函数** */
export async function getDeptPersonList(): Promise<any> {
  return request(`/dept/person/list`, {
    method: Method.GET,
  })
}

/**
 * 接口 [分页查询人员↗](http://fe.jiuworker.com:3000/project/29/interface/api/1547)
 *
 * @分类 [职工部门模块/职工管理↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_108)
 * @标签 `职工部门模块/职工管理`
 * @请求头 `GET /dept/person/page`
 * @更新时间 `2024-08-23 16:22:07`
 */

/* **请求query类型** */
export interface GetDeptPersonPageQuery {
  /**
   * deptId
   */
  deptId?: string
  /**
   * name
   */
  name?: string
  /**
   * pageNum
   */
  pageNum?: number
  /**
   * pageSize
   */
  pageSize?: number
  /**
   * 是否展示子部门成员
   */
  subPerson?: string
}

/* **返回类型** */
export interface GetDeptPersonPageResponse {
  data: {
    /**
     * 考勤
     */
    attendanceName: string
    /**
     * 所属部门
     */
    deptName: string
    /**
     * 邮箱
     */
    email: string
    /**
     * 人脸照
     */
    faceUrl: string
    id: number
    /**
     * 岗位
     */
    job: string
    /**
     * 名称
     */
    name: string
    /**
     * 手机号
     */
    phone: string
    /**
     * 性别
     */
    sex: string
    /**
     * 工号
     */
    workNo: string
  }[]
  total: number
}

/* **请求函数** */
export async function getDeptPersonPage(query: GetDeptPersonPageQuery): Promise<any> {
  return request(`/dept/person/page`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [查询部门列表↗](http://fe.jiuworker.com:3000/project/29/interface/api/1549)
 *
 * @分类 [职工部门模块/职工管理↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_108)
 * @标签 `职工部门模块/职工管理`
 * @请求头 `GET /dept/query`
 * @更新时间 `2024-08-23 16:22:07`
 */

/* **返回类型** */
export type GetDeptQueryResponse = {
  id: number
  /**
   * 关键字
   */
  key: string
  menuVOList: {}[]
  /**
   * 名称
   */
  name: string
  /**
   * 上级ID
   */
  parentId: number
  /**
   * 人员数量
   */
  personCount: number
  /**
   * 人员列表
   */
  personPOList: {
    aiotId: string
    bizData: string
    enterId: number
    gmtCreate: string
    gmtModified: string
    id: number
    name: string
  }[]
}[]

/* **请求函数** */
export async function getDeptQuery(): Promise<any> {
  return request(`/dept/query`, {
    method: Method.GET,
  })
}

/**
 * 接口 [查询部门详情↗](http://fe.jiuworker.com:3000/project/29/interface/api/1551)
 *
 * @分类 [职工部门模块/职工管理↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_108)
 * @标签 `职工部门模块/职工管理`
 * @请求头 `GET /dept/query/detail/{id}`
 * @更新时间 `2024-08-23 16:22:07`
 */

/* **请求params类型** */
export interface GetDeptQueryDetailByIdParams {
  /**
   * id
   */
  id: string
}

/* **返回类型** */
export interface GetDeptQueryDetailByIdResponse {
  id: number
  /**
   * 部门负责人
   */
  leaderPersonId: number
  /**
   * 部门负责人名称
   */
  leaderPersonIdName: string
  /**
   * 名称
   */
  name: string
  /**
   * 上级部门ID,没有则默认为-1
   */
  parentId: number
  /**
   * 父级菜单名称
   */
  parentName: string
  personCount: number
  /**
   * 联系电话
   */
  phone: string
}

/* **请求函数** */
export async function getDeptQueryDetailById(params: GetDeptQueryDetailByIdParams): Promise<any> {
  return request(`/dept/query/detail/${params.id}`, {
    method: Method.GET,
  })
}

/**
 * 接口 [分页查询日志↗](http://fe.jiuworker.com:3000/project/29/interface/api/1599)
 *
 * @分类 [操作日志管理↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_111)
 * @标签 `操作日志管理`
 * @请求头 `GET /log/query`
 * @更新时间 `2024-08-23 16:22:09`
 */

/* **请求query类型** */
export interface GetLogQueryQuery {
  /**
   * endDate
   */
  endDate?: string
  /**
   * logType
   */
  logType?: string
  /**
   * pageNum
   */
  pageNum?: number
  /**
   * pageSize
   */
  pageSize?: number
  /**
   * startDate
   */
  startDate?: string
}

/* **返回类型** */
export interface GetLogQueryResponse {
  data: {
    enterName: string
    ip: string
    logContent: string
    logTime: string
    logType: string
    requestContent: string
    systemName: string
    userName: string
  }[]
  total: number
}

/* **请求函数** */
export async function getLogQuery(query: GetLogQueryQuery): Promise<any> {
  return request(`/log/query`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [查询日志类型↗](http://fe.jiuworker.com:3000/project/29/interface/api/1601)
 *
 * @分类 [操作日志管理↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_111)
 * @标签 `操作日志管理`
 * @请求头 `GET /log/type`
 * @更新时间 `2024-08-23 16:22:09`
 */

/* **返回类型** */
export type GetLogTypeResponse = {
  key: string
  value: string
}[]

/* **请求函数** */
export async function getLogType(): Promise<any> {
  return request(`/log/type`, {
    method: Method.GET,
  })
}

/**
 * 接口 [1.创建用户↗](http://fe.jiuworker.com:3000/project/29/interface/api/1715)
 *
 * @分类 [外部调用接口-xiaoM↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_115)
 * @标签 `外部调用接口-xiaoM`
 * @请求头 `POST /out/man/create/user`
 * @更新时间 `2024-08-23 16:22:10`
 */

/* **请求body类型** */
export interface PostOutManCreateUserBody {
  /**
   * 加密数据
   */
  data?: string
}

/* **返回类型** */
export interface PostOutManCreateUserResponse {
  id: number
}

/* **请求函数** */
export async function postOutManCreateUser(body: PostOutManCreateUserBody): Promise<any> {
  return request(`/out/man/create/user`, {
    method: Method.POST,

    data: body,
  })
}

/**
 * 接口 [10.投保链接↗](http://fe.jiuworker.com:3000/project/29/interface/api/1717)
 *
 * @分类 [外部调用接口-xiaoM↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_115)
 * @标签 `外部调用接口-xiaoM`
 * @请求头 `POST /out/man/insure/url`
 * @更新时间 `2024-08-23 16:22:10`
 */

/* **请求body类型** */
export interface PostOutManInsureUrlBody {
  /**
   * 加密数据
   */
  data?: string
}

/* **返回类型** */
export interface PostOutManInsureUrlResponse {
  /**
   * 状态 0未投保 1已投保/无需投保
   */
  status: number
  /**
   * 考勤跳转链接
   */
  url: string
}

/* **请求函数** */
export async function postOutManInsureUrl(body: PostOutManInsureUrlBody): Promise<any> {
  return request(`/out/man/insure/url`, {
    method: Method.POST,

    data: body,
  })
}

/**
 * 接口 [2.发薪↗](http://fe.jiuworker.com:3000/project/29/interface/api/1719)
 *
 * @分类 [外部调用接口-xiaoM↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_115)
 * @标签 `外部调用接口-xiaoM`
 * @请求头 `POST /out/man/pay`
 * @更新时间 `2024-08-23 16:22:10`
 */

/* **请求body类型** */
export interface PostOutManPayBody {
  /**
   * 加密数据
   */
  data?: string
}

/* **返回类型** */
export interface PostOutManPayResponse {
  /**
   * 业务编码-须确保唯一
   */
  bizNo: string
  /**
   * 失败原因
   */
  reason: number
  /**
   * 支付金额
   */
  salary: number
  /**
   * 支付状态 0失败1成功
   */
  status: number
  /**
   * 用户id
   */
  userId: number
}

/* **请求函数** */
export async function postOutManPay(body: PostOutManPayBody): Promise<any> {
  return request(`/out/man/pay`, {
    method: Method.POST,

    data: body,
  })
}

/**
 * 接口 [3.获取项目二维码↗](http://fe.jiuworker.com:3000/project/29/interface/api/1721)
 *
 * @分类 [外部调用接口-xiaoM↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_115)
 * @标签 `外部调用接口-xiaoM`
 * @请求头 `POST /out/man/qrcode`
 * @更新时间 `2024-08-23 16:22:10`
 */

/* **请求body类型** */
export interface PostOutManQrcodeBody {
  /**
   * 加密数据
   */
  data?: string
}

/* **返回类型** */
export interface PostOutManQrcodeResponse {
  /**
   * 链接地址
   */
  url: string
}

/* **请求函数** */
export async function postOutManQrcode(body: PostOutManQrcodeBody): Promise<any> {
  return request(`/out/man/qrcode`, {
    method: Method.POST,

    data: body,
  })
}

/**
 * 接口 [1.发票抬头↗](http://fe.jiuworker.com:3000/project/29/interface/api/2063)
 *
 * @分类 [发票管理↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_137)
 * @标签 `发票管理`
 * @请求头 `POST /invoice/create/title`
 * @更新时间 `2024-08-23 16:22:08`
 */

/* **请求body类型** */
export interface PostInvoiceCreateTitleBody {
  /**
   * 考勤链接
   */
  attendUrl?: string
  email?: string
  gmtCreate?: string
  gmtModified?: string
  id?: number
  insureUrl?: string
  /**
   * 发票公司地址
   */
  invoiceAddress?: string
  /**
   * 发票开户行账号
   */
  invoiceBankAccount?: string
  /**
   * 发票开户行名称
   */
  invoiceBankName?: string
}

/* **返回类型** */
export type PostInvoiceCreateTitleResponse = boolean

/* **请求函数** */
export async function postInvoiceCreateTitle(body: PostInvoiceCreateTitleBody): Promise<any> {
  return request(`/invoice/create/title`, {
    method: Method.POST,

    data: body,
  })
}

/**
 * 接口 [21.开票历史-关联订单↗](http://fe.jiuworker.com:3000/project/29/interface/api/2065)
 *
 * @分类 [发票管理↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_137)
 * @标签 `发票管理`
 * @请求头 `GET /invoice/detail/order`
 * @更新时间 `2024-08-23 16:22:08`
 */

/* **请求query类型** */
export interface GetInvoiceDetailOrderQuery {
  /**
   * id
   */
  id: string
}

/* **返回类型** */
export type GetInvoiceDetailOrderResponse = {
  /**
   * 结算结束日期
   */
  endDate: string
  id: number
  /**
   * 手续费
   */
  serviceFee: number
  /**
   * 结算开始日期
   */
  startDate: string
  /**
   * 状态 0未开票1开票中2已开票
   */
  status: number
  /**
   * 班组名称
   */
  teamName: string
  /**
   * 交易流水号
   */
  transactionSerialNumber: string
  /**
   * 收款账户
   */
  userAccount: string
  /**
   * 用户名称
   */
  userName: string
}[]

/* **请求函数** */
export async function getInvoiceDetailOrder(query: GetInvoiceDetailOrderQuery): Promise<any> {
  return request(`/invoice/detail/order`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [2.发票抬头详情↗](http://fe.jiuworker.com:3000/project/29/interface/api/2067)
 *
 * @分类 [发票管理↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_137)
 * @标签 `发票管理`
 * @请求头 `GET /invoice/detail/title`
 * @更新时间 `2024-08-23 16:22:08`
 */

/* **返回类型** */
export interface GetInvoiceDetailTitleResponse {
  /**
   * 公司税号
   */
  certNo: string
  companyName: string
  /**
   * 邮箱
   */
  email: string
  gmtCreate: string
  gmtModified: string
  id: number
  /**
   * 发票公司地址
   */
  invoiceAddress: string
  /**
   * 发票开户行账号
   */
  invoiceBankAccount: string
  /**
   * 发票开户行名称
   */
  invoiceBankName: string
}

/* **请求函数** */
export async function getInvoiceDetailTitle(): Promise<any> {
  return request(`/invoice/detail/title`, {
    method: Method.GET,
  })
}

/**
 * 接口 [20.开票历史↗](http://fe.jiuworker.com:3000/project/29/interface/api/2069)
 *
 * @分类 [发票管理↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_137)
 * @标签 `发票管理`
 * @请求头 `GET /invoice/list`
 * @更新时间 `2024-08-23 16:22:08`
 */

/* **请求query类型** */
export interface GetInvoiceListQuery {
  /**
   * pageNum
   */
  pageNum?: number
  /**
   * pageSize
   */
  pageSize?: number
}

/* **返回类型** */
export interface GetInvoiceListResponse {
  data: {
    /**
     * 发票公司地址
     */
    address: string
    amount: number
    /**
     * 发票开户行账号
     */
    bankAccount: string
    /**
     * 发票开户行名称
     */
    bankName: string
    /**
     * 公司税号
     */
    certNo: string
    /**
     * 公司名称
     */
    companyName: string
    /**
     * 申请开票时间
     */
    gmtCreate: string
    gmtModified: string
    id: number
    /**
     * 邮箱
     */
    mail: string
    /**
     * 备注
     */
    remark: string
    /**
     * 1开票中 2开票成功
     */
    status: number
    /**
     * 开票时间
     */
    successTime: string
    url: {
      /**
       * 附件名称
       */
      name: string
      /**
       * 附件大小
       */
      size: string
      /**
       * 附件地址
       */
      url: string
    }
  }[]
  total: number
}

/* **请求函数** */
export async function getInvoiceList(query: GetInvoiceListQuery): Promise<any> {
  return request(`/invoice/list`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [10.发薪服务费发票↗](http://fe.jiuworker.com:3000/project/29/interface/api/2071)
 *
 * @分类 [发票管理↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_137)
 * @标签 `发票管理`
 * @请求头 `POST /invoice/pay`
 * @更新时间 `2024-08-23 16:22:08`
 */

/* **请求body类型** */
export interface PostInvoicePayBody {
  /**
   * 公司税号
   */
  certNo?: string
  companyName?: string
  email?: string
  ids?: number[]
  /**
   * 发票公司地址
   */
  invoiceAddress?: string
  /**
   * 发票开户行账号
   */
  invoiceBankAccount?: string
  /**
   * 发票开户行名称
   */
  invoiceBankName?: string
  remark?: string
}

/* **返回类型** */
export type PostInvoicePayResponse = boolean

/* **请求函数** */
export async function postInvoicePay(body: PostInvoicePayBody): Promise<any> {
  return request(`/invoice/pay`, {
    method: Method.POST,

    data: body,
  })
}

/**
 * 接口 [13.发薪服务费列表-代开发票列表↗](http://fe.jiuworker.com:3000/project/29/interface/api/2073)
 *
 * @分类 [发票管理↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_137)
 * @标签 `发票管理`
 * @请求头 `GET /invoice/pay/list`
 * @更新时间 `2024-08-23 16:22:08`
 */

/* **请求query类型** */
export interface GetInvoicePayListQuery {
  /**
   * pageNum
   */
  pageNum?: number
  /**
   * pageSize
   */
  pageSize?: number
}

/* **返回类型** */
export interface GetInvoicePayListResponse {
  data: {
    /**
     * 结算结束日期
     */
    endDate: string
    id: number
    /**
     * 手续费
     */
    serviceFee: number
    /**
     * 结算开始日期
     */
    startDate: string
    /**
     * 状态 0未开票1开票中2已开票
     */
    status: number
    /**
     * 班组名称
     */
    teamName: string
    /**
     * 交易流水号
     */
    transactionSerialNumber: string
    /**
     * 收款账户
     */
    userAccount: string
    /**
     * 用户名称
     */
    userName: string
  }[]
  total: number
}

/* **请求函数** */
export async function getInvoicePayList(query: GetInvoicePayListQuery): Promise<any> {
  return request(`/invoice/pay/list`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [22.重发发票↗](http://fe.jiuworker.com:3000/project/29/interface/api/2075)
 *
 * @分类 [发票管理↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_137)
 * @标签 `发票管理`
 * @请求头 `GET /invoice/resend`
 * @更新时间 `2024-08-23 16:22:08`
 */

/* **请求query类型** */
export interface GetInvoiceResendQuery {
  /**
   * email
   */
  email: string
  /**
   * id
   */
  id: string
}

/* **返回类型** */
export type GetInvoiceResendResponse = boolean

/* **请求函数** */
export async function getInvoiceResend(query: GetInvoiceResendQuery): Promise<any> {
  return request(`/invoice/resend`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [11.酒店结算发票↗](http://fe.jiuworker.com:3000/project/29/interface/api/2077)
 *
 * @分类 [发票管理↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_137)
 * @标签 `发票管理`
 * @请求头 `POST /invoice/settle`
 * @更新时间 `2024-08-23 16:22:08`
 */

/* **请求body类型** */
export interface PostInvoiceSettleBody {
  /**
   * 公司税号
   */
  certNo?: string
  companyName?: string
  email?: string
  ids?: number[]
  /**
   * 发票公司地址
   */
  invoiceAddress?: string
  /**
   * 发票开户行账号
   */
  invoiceBankAccount?: string
  /**
   * 发票开户行名称
   */
  invoiceBankName?: string
  remark?: string
}

/* **返回类型** */
export interface PostInvoiceSettleResponse {
  /**
   * 计划取消原因
   */
  cancelReason: string
  /**
   * 取消状态 1正常 2用工方取消 3劳务方取消 4用工方拒绝 5劳务方拒绝
   */
  cancelStatus: number
  /**
   * 联系人
   */
  contractPerson: string
  /**
   * 联系人电话
   */
  contractPhone: string
  day: number
  /**
   * 单日费用
   */
  dayPrice: number
  /**
   * 用工企业id
   */
  employCompanyId: number
  /**
   * 用工企业名称
   */
  employCompanyName: string
  endDate: string
  endDateTime: string
  endTime: string
  gmtCreate: string
  gmtModified: string
  /**
   * 工作时长
   */
  hour: number
  id: number
  /**
   * 岗位需求json
   */
  jobs: {
    ageMax: number
    ageMin: number
    femaleNum: number
    maleNum: number
    maxAge: number
    minAge: number
    name: string
    num: number
    other: string
    price: number
    rooms: {
      /**
       * 房间id
       */
      id: number
      name: string
      num: number
      originalPrice: number
      price: number
    }[]
  }[]
  /**
   * 劳务公司id
   */
  laborCompanyId: number
  location: {
    address: string
    latitude: string
    longitude: string
    region: string[]
  }
  /**
   * 需求名称
   */
  name: string
  /**
   * 订单编号
   */
  orderNo: string
  /**
   * 用工计划id
   */
  planId: number
  /**
   * 拒绝计划取消原因
   */
  refuseCancelReason: string
  /**
   * 拒绝原因
   */
  refuseReason: string
  /**
   * 休息时长 小时
   */
  restTime: number
  /**
   * 排班进度
   */
  scheduleProgress: number
  source: string
  startDate: string
  startTime: string
  /**
   * 订单状态 1待接单 2接单中  11进行中 3待客户确认考勤 4待考勤确认 5已完成 6 已拒绝 7已取消-用工端 8已取消-劳务端 9已取消-未接单 10待结算 11进行中
   */
  status: number
  /**
   * 班组id
   */
  teamId: number
  /**
   * 班组名称
   */
  teamName: string
  /**
   * 天数汇总
   */
  totalDay: number
  /**
   * 总费用
   */
  totalPrice: number
  /**
   * 工时汇总
   */
  totalWorkHour: number
  userNum: number
  /**
   * 计价方式 0:按小时 1:按天 2:按次
   */
  valuationType: number
}

/* **请求函数** */
export async function postInvoiceSettle(body: PostInvoiceSettleBody): Promise<any> {
  return request(`/invoice/settle`, {
    method: Method.POST,

    data: body,
  })
}

/**
 * 接口 [1.创建一个新公司↗](http://fe.jiuworker.com:3000/project/29/interface/api/1045)
 *
 * @分类 [运营端调用接口↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_138)
 * @标签 `运营端调用接口`
 * @请求头 `PUT /out/company/create`
 * @更新时间 `2024-08-23 16:22:09`
 */

/* **请求body类型** */
export interface PutOutCompanyCreateBody {
  certNo?: string
  /**
   * 电子邮件
   */
  email?: string
  id?: number
  location?: {
    address?: string
    latitude?: string
    longitude?: string
    region?: string[]
  }
  /**
   * 手机号
   */
  mobile?: string
  /**
   * 劳务公司名称
   */
  name?: string
  /**
   * 支付渠道
   */
  payChannel?: string
  /**
   * 联系人
   */
  userName?: string
}

/* **返回类型** */
export interface PutOutCompanyCreateResponse {
  /**
   * 公司id
   */
  companyId: number
  /**
   * 默认密码
   */
  defaultPassword: string
}

/* **请求函数** */
export async function putOutCompanyCreate(body: PutOutCompanyCreateBody): Promise<any> {
  return request(`/out/company/create`, {
    method: Method.PUT,

    data: body,
  })
}

/**
 * 接口 [3.工人统计列表↗](http://fe.jiuworker.com:3000/project/29/interface/api/1071)
 *
 * @分类 [运营端调用接口↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_138)
 * @标签 `运营端调用接口`
 * @请求头 `GET /out/employee/list`
 * @更新时间 `2024-08-23 16:22:10`
 */

/* **请求query类型** */
export interface GetOutEmployeeListQuery {
  /**
   * name
   */
  name?: string
  /**
   * pageNum
   */
  pageNum?: number
  /**
   * pageSize
   */
  pageSize?: number
}

/* **返回类型** */
export interface GetOutEmployeeListResponse {
  data: {
    /**
     * 年龄
     */
    age: number
    /**
     * 照片
     */
    avatar: string
    /**
     * 总天数
     */
    days: number
    /**
     * 性别
     */
    gender: number
    id: number
    /**
     * 身份证号
     */
    idcard: string
    /**
     * 手机号
     */
    mobile: string
    /**
     * 用户名称
     */
    name: string
    /**
     * 总计件
     */
    piece: number
    /**
     * 总薪资
     */
    salary: number
    /**
     * 总排班
     */
    schedule: number
    /**
     * 状态 0禁用1启用
     */
    status: number
    /**
     * 总工时
     */
    workHour: string
  }[]
  total: number
}

/* **请求函数** */
export async function getOutEmployeeList(query: GetOutEmployeeListQuery): Promise<any> {
  return request(`/out/employee/list`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [2.创建一个管理员↗](http://fe.jiuworker.com:3000/project/29/interface/api/1087)
 *
 * @分类 [运营端调用接口↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_138)
 * @标签 `运营端调用接口`
 * @请求头 `PUT /out/manager/create`
 * @更新时间 `2024-08-23 16:22:10`
 */

/* **请求body类型** */
export interface PutOutManagerCreateBody {
  companyId?: number
  /**
   * 电子邮件
   */
  email?: string
  /**
   * 手机号
   */
  mobile?: string
  /**
   * 用户名称
   */
  name?: string
  /**
   * 明文密码-非必要
   */
  password?: string
}

/* **返回类型** */
export interface PutOutManagerCreateResponse {
  /**
   * 公司id
   */
  companyId: number
  /**
   * 默认密码
   */
  defaultPassword: string
}

/* **请求函数** */
export async function putOutManagerCreate(body: PutOutManagerCreateBody): Promise<any> {
  return request(`/out/manager/create`, {
    method: Method.PUT,

    data: body,
  })
}

/**
 * 接口 [21.开票历史-关联订单↗](http://fe.jiuworker.com:3000/project/29/interface/api/2037)
 *
 * @分类 [运营端调用接口↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_138)
 * @标签 `运营端调用接口`
 * @请求头 `GET /out/invoice/detail/order`
 * @更新时间 `2024-08-23 16:22:10`
 */

/* **请求query类型** */
export interface GetOutInvoiceDetailOrderQuery {
  /**
   * id
   */
  id: string
}

/* **返回类型** */
export type GetOutInvoiceDetailOrderResponse = {
  /**
   * 结算结束日期
   */
  endDate: string
  id: number
  /**
   * 手续费
   */
  serviceFee: number
  /**
   * 结算开始日期
   */
  startDate: string
  /**
   * 状态 0未开票1开票中2已开票
   */
  status: number
  /**
   * 班组名称
   */
  teamName: string
  /**
   * 交易流水号
   */
  transactionSerialNumber: string
  /**
   * 收款账户
   */
  userAccount: string
  /**
   * 用户名称
   */
  userName: string
}[]

/* **请求函数** */
export async function getOutInvoiceDetailOrder(query: GetOutInvoiceDetailOrderQuery): Promise<any> {
  return request(`/out/invoice/detail/order`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [23.上传发票↗](http://fe.jiuworker.com:3000/project/29/interface/api/2039)
 *
 * @分类 [运营端调用接口↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_138)
 * @标签 `运营端调用接口`
 * @请求头 `POST /out/invoice/file`
 * @更新时间 `2024-08-23 16:22:10`
 */

/* **请求body类型** */
export interface PostOutInvoiceFileBody {
  file?: {
    /**
     * 附件名称
     */
    name?: string
    /**
     * 附件大小
     */
    size?: string
    /**
     * 附件地址
     */
    url?: string
  }
  id?: string
  /**
   * 开票时间
   */
  time?: string
}

/* **返回类型** */
export type PostOutInvoiceFileResponse = boolean

/* **请求函数** */
export async function postOutInvoiceFile(body: PostOutInvoiceFileBody): Promise<any> {
  return request(`/out/invoice/file`, {
    method: Method.POST,

    data: body,
  })
}

/**
 * 接口 [20.发票列表 1代开发票 2开票完成↗](http://fe.jiuworker.com:3000/project/29/interface/api/2041)
 *
 * @分类 [运营端调用接口↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_138)
 * @标签 `运营端调用接口`
 * @请求头 `GET /out/invoice/list`
 * @更新时间 `2024-08-23 16:22:10`
 */

/* **请求query类型** */
export interface GetOutInvoiceListQuery {
  /**
   * laborName
   */
  laborName?: string
  /**
   * pageNum
   */
  pageNum?: number
  /**
   * pageSize
   */
  pageSize?: number
  /**
   * status
   */
  status?: string
}

/* **返回类型** */
export interface GetOutInvoiceListResponse {
  data: {
    /**
     * 发票公司地址
     */
    address: string
    amount: number
    /**
     * 发票开户行账号
     */
    bankAccount: string
    /**
     * 发票开户行名称
     */
    bankName: string
    /**
     * 公司税号
     */
    certNo: string
    /**
     * 公司名称
     */
    companyName: string
    /**
     * 申请开票时间
     */
    gmtCreate: string
    gmtModified: string
    id: number
    /**
     * 邮箱
     */
    mail: string
    /**
     * 备注
     */
    remark: string
    /**
     * 1开票中 2开票成功
     */
    status: number
    /**
     * 开票时间
     */
    successTime: string
    url: {
      /**
       * 附件名称
       */
      name: string
      /**
       * 附件大小
       */
      size: string
      /**
       * 附件地址
       */
      url: string
    }
  }[]
  total: number
}

/* **请求函数** */
export async function getOutInvoiceList(query: GetOutInvoiceListQuery): Promise<any> {
  return request(`/out/invoice/list`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [22.重发发票↗](http://fe.jiuworker.com:3000/project/29/interface/api/2043)
 *
 * @分类 [运营端调用接口↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_138)
 * @标签 `运营端调用接口`
 * @请求头 `GET /out/invoice/resend`
 * @更新时间 `2024-08-23 16:22:10`
 */

/* **请求query类型** */
export interface GetOutInvoiceResendQuery {
  /**
   * id
   */
  id: string
}

/* **返回类型** */
export type GetOutInvoiceResendResponse = boolean

/* **请求函数** */
export async function getOutInvoiceResend(query: GetOutInvoiceResendQuery): Promise<any> {
  return request(`/out/invoice/resend`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [30.开票历史↗](http://fe.jiuworker.com:3000/project/29/interface/api/2089)
 *
 * @分类 [运营端调用接口↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_138)
 * @标签 `运营端调用接口`
 * @请求头 `GET /out/invoice/list/resend`
 * @更新时间 `2024-08-23 16:22:10`
 */

/* **请求query类型** */
export interface GetOutInvoiceListResendQuery {
  /**
   * laborName
   */
  laborName?: string
  /**
   * pageNum
   */
  pageNum?: number
  /**
   * pageSize
   */
  pageSize?: number
}

/* **返回类型** */
export interface GetOutInvoiceListResendResponse {
  data: {
    /**
     * 发票公司地址
     */
    address: string
    amount: number
    /**
     * 发票开户行账号
     */
    bankAccount: string
    /**
     * 发票开户行名称
     */
    bankName: string
    /**
     * 公司税号
     */
    certNo: string
    /**
     * 公司名称
     */
    companyName: string
    /**
     * 申请开票实践
     */
    gmtCreate: string
    gmtModified: string
    id: number
    invoiceId: number
    /**
     * 邮箱
     */
    mail: string
    /**
     * 备注
     */
    remark: string
    resendTime: string
    /**
     * 1开票中 2开票成功
     */
    status: number
    /**
     * 开票时间
     */
    successTime: string
    url: {
      /**
       * 附件名称
       */
      name: string
      /**
       * 附件大小
       */
      size: string
      /**
       * 附件地址
       */
      url: string
    }
  }[]
  total: number
}

/* **请求函数** */
export async function getOutInvoiceListResend(query: GetOutInvoiceListResendQuery): Promise<any> {
  return request(`/out/invoice/list/resend`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [体验账号↗](http://fe.jiuworker.com:3000/project/29/interface/api/2093)
 *
 * @分类 [运营端调用接口↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_138)
 * @标签 `运营端调用接口`
 * @请求头 `POST /out/account/try`
 * @更新时间 `2024-08-23 16:22:09`
 */

/* **请求body类型** */
export interface PostOutAccountTryBody {
  name?: string
  phone?: string
}

/* **返回类型** */
export interface PostOutAccountTryResponse {
  /**
   * 小程序token
   */
  accessToken: string
  /**
   * 公司id
   */
  companyId: number
  /**
   * 默认密码
   */
  defaultPassword: string
  /**
   * 小程序跳转位置
   */
  miniWechatPath: string
}

/* **请求函数** */
export async function postOutAccountTry(body: PostOutAccountTryBody): Promise<any> {
  return request(`/out/account/try`, {
    method: Method.POST,

    data: body,
  })
}

/**
 * 接口 [31.酒店端关联的劳务派单列表↗](http://fe.jiuworker.com:3000/project/29/interface/api/2141)
 *
 * @分类 [运营端调用接口↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_138)
 * @标签 `运营端调用接口`
 * @请求头 `GET /out/delivery/enter/bind`
 * @更新时间 `2024-08-23 16:22:09`
 */

/* **请求query类型** */
export interface GetOutDeliveryEnterBindQuery {
  /**
   * enterId
   */
  enterId: string
}

/* **返回类型** */
export type GetOutDeliveryEnterBindResponse = {
  companyId: number
  companyName: string
}[]

/* **请求函数** */
export async function getOutDeliveryEnterBind(query: GetOutDeliveryEnterBindQuery): Promise<any> {
  return request(`/out/delivery/enter/bind`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [33.酒店端关联的劳务派单列表↗](http://fe.jiuworker.com:3000/project/29/interface/api/2143)
 *
 * @分类 [运营端调用接口↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_138)
 * @标签 `运营端调用接口`
 * @请求头 `POST /out/delivery/enter/bind`
 * @更新时间 `2024-08-23 16:22:09`
 */

/* **请求body类型** */
export interface PostOutDeliveryEnterBindBody {
  id?: number
  ids?: number[]
}

/* **返回类型** */
export type PostOutDeliveryEnterBindResponse = boolean

/* **请求函数** */
export async function postOutDeliveryEnterBind(body: PostOutDeliveryEnterBindBody): Promise<any> {
  return request(`/out/delivery/enter/bind`, {
    method: Method.POST,

    data: body,
  })
}

/**
 * 接口 [30.劳务端关联的酒店派单列表↗](http://fe.jiuworker.com:3000/project/29/interface/api/2145)
 *
 * @分类 [运营端调用接口↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_138)
 * @标签 `运营端调用接口`
 * @请求头 `GET /out/delivery/labor/bind`
 * @更新时间 `2024-08-23 16:22:09`
 */

/* **请求query类型** */
export interface GetOutDeliveryLaborBindQuery {
  /**
   * laborId
   */
  laborId: string
}

/* **返回类型** */
export type GetOutDeliveryLaborBindResponse = {
  companyId: number
  companyName: string
}[]

/* **请求函数** */
export async function getOutDeliveryLaborBind(query: GetOutDeliveryLaborBindQuery): Promise<any> {
  return request(`/out/delivery/labor/bind`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [32.酒店端关联的劳务派单列表↗](http://fe.jiuworker.com:3000/project/29/interface/api/2147)
 *
 * @分类 [运营端调用接口↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_138)
 * @标签 `运营端调用接口`
 * @请求头 `POST /out/delivery/labor/bind`
 * @更新时间 `2024-08-23 16:22:09`
 */

/* **请求body类型** */
export interface PostOutDeliveryLaborBindBody {
  id?: number
  ids?: number[]
}

/* **返回类型** */
export type PostOutDeliveryLaborBindResponse = boolean

/* **请求函数** */
export async function postOutDeliveryLaborBind(body: PostOutDeliveryLaborBindBody): Promise<any> {
  return request(`/out/delivery/labor/bind`, {
    method: Method.POST,

    data: body,
  })
}

/**
 * 接口 [40.保单注册↗](http://fe.jiuworker.com:3000/project/29/interface/api/2549)
 *
 * @分类 [运营端调用接口↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_138)
 * @标签 `运营端调用接口`
 * @请求头 `POST /out/insure/add`
 * @更新时间 `2024-08-23 16:22:10`
 */

/* **请求body类型** */
export interface PostOutInsureAddBody {
  /**
   * 是否自动投保  默认0否1是
   */
  auto?: number
  /**
   * 保险结束时间
   */
  endDate?: string
  insuranceBaseId?: number
  /**
   * 用户id
   */
  laborId?: number
  /**
   * 投保人信息
   */
  personList?: {
    /**
     * 投保人身份证号
     */
    idcard?: string
    /**
     * 投保人姓名
     */
    name?: string
    /**
     * 投保人电话
     */
    phone?: string
  }[]
  /**
   * 保险价格-成本
   */
  premium?: number
  /**
   * 保险价格-使用价格
   */
  price?: number
  /**
   * 产品方案id
   */
  productPlanId?: string
  /**
   * 保险开始时间
   */
  startDate?: string
}

/* **返回类型** */
export type PostOutInsureAddResponse = boolean

/* **请求函数** */
export async function postOutInsureAdd(body: PostOutInsureAddBody): Promise<any> {
  return request(`/out/insure/add`, {
    method: Method.POST,

    data: body,
  })
}

/**
 * 接口 [33.酒店端关联的劳务派单列表↗](http://fe.jiuworker.com:3000/project/29/interface/api/2581)
 *
 * @分类 [运营端调用接口↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_138)
 * @标签 `运营端调用接口`
 * @请求头 `POST /out/delivery/enter/bind/rate`
 * @更新时间 `2024-08-23 16:22:09`
 */

/* **请求body类型** */
export interface PostOutDeliveryEnterBindRateBody {
  deliveryList?: {
    deliveryRate?: number
    id?: number
  }[]
  id?: number
}

/* **返回类型** */
export type PostOutDeliveryEnterBindRateResponse = boolean

/* **请求函数** */
export async function postOutDeliveryEnterBindRate(body: PostOutDeliveryEnterBindRateBody): Promise<any> {
  return request(`/out/delivery/enter/bind/rate`, {
    method: Method.POST,

    data: body,
  })
}

/**
 * 接口 [32.酒店端关联的劳务派单列表↗](http://fe.jiuworker.com:3000/project/29/interface/api/2583)
 *
 * @分类 [运营端调用接口↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_138)
 * @标签 `运营端调用接口`
 * @请求头 `POST /out/delivery/labor/bind/rate`
 * @更新时间 `2024-08-23 16:22:09`
 */

/* **请求body类型** */
export interface PostOutDeliveryLaborBindRateBody {
  deliveryList?: {
    deliveryRate?: number
    id?: number
  }[]
  id?: number
}

/* **返回类型** */
export type PostOutDeliveryLaborBindRateResponse = boolean

/* **请求函数** */
export async function postOutDeliveryLaborBindRate(body: PostOutDeliveryLaborBindRateBody): Promise<any> {
  return request(`/out/delivery/labor/bind/rate`, {
    method: Method.POST,

    data: body,
  })
}

/**
 * 接口 [2.劳务市场列表↗](http://fe.jiuworker.com:3000/project/29/interface/api/2097)
 *
 * @分类 [对外接口-用工端小程序↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_139)
 * @标签 `对外接口-用工端小程序`
 * @请求头 `GET /out/working/team/market`
 * @更新时间 `2024-08-23 16:22:11`
 */

/* **请求query类型** */
export interface GetOutWorkingTeamMarketQuery {
  /**
   * adcode
   */
  adcode?: string
  /**
   * 人员未满 0存在未满(勾选) 1已满(未勾选/默认)
   */
  full?: string
  /**
   * 岗位名称
   */
  name?: string
  /**
   * 排序方式 0时间优先 1价格有限
   */
  order?: string
  /**
   * pageNum
   */
  pageNum?: number
  /**
   * pageSize
   */
  pageSize?: number
  /**
   * 计价方式
   */
  valuationType?: string
}

/* **返回类型** */
export interface GetOutWorkingTeamMarketResponse {
  data: {
    companyId: number
    /**
     *  报名截止时间
     */
    deadline: string
    /**
     * 用工单位id
     */
    employerCompanyId: number
    /**
     * 用工单位名称
     */
    employerCompanyName: string
    endDate: string
    /**
     * 班组可以结束打卡时间
     */
    endSplitDateTime: string
    endTime: string
    excessRatio: number
    id: number
    /**
     * 岗位列表
     */
    jobs: {
      /**
       * 最大年龄
       */
      ageMax: number
      /**
       * 最小年龄
       */
      ageMin: number
      /**
       * 已报名人数
       */
      appliedNum: number
      /**
       * 报名已满 0未满 1已满
       */
      applyFull: number
      femaleNum: number
      id: number
      /**
       * 岗位id-用工计划中
       */
      jobId: number
      maleNum: number
      /**
       * 派单-最大单价
       */
      maxPrice: number
      /**
       * 派单-最小单价
       */
      minPrice: number
      /**
       * 岗位名称
       */
      name: string
      /**
       * 用工计划id
       */
      num: number
      /**
       * 其它要求-录用确认规则引擎
       */
      other: string
      /**
       * 单价
       */
      price: string
      /**
       * 班组id
       */
      teamId: number
      /**
       * 计价方式 0:按小时 1:按天 2:按次
       */
      valuationType: number
    }[]
    /**
     * 领班手机号
     */
    leaderMobile: string
    location: {
      address: string
      latitude: string
      longitude: string
      region: string[]
    }
    /**
     * 班组名称
     */
    name: string
    /**
     * 用工计划id
     */
    orderId: number
    /**
     * 休息时长 小时
     */
    restTime: number
    source: string
    startDate: string
    /**
     * 班组可以开始打卡时间
     */
    startSplitDateTime: string
    startTime: string
    /**
     * 计价方式 0:按小时 1:按天 2:按次
     */
    valuationType: number
  }[]
  total: number
}

/* **请求函数** */
export async function getOutWorkingTeamMarket(query: GetOutWorkingTeamMarketQuery): Promise<any> {
  return request(`/out/working/team/market`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [listDelivery↗](http://fe.jiuworker.com:3000/project/29/interface/api/2237)
 *
 * @分类 [对外接口-用工端小程序↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_139)
 * @标签 `对外接口-用工端小程序`
 * @请求头 `GET /out/working/team/source/list`
 * @更新时间 `2024-08-23 16:22:11`
 */

/* **请求query类型** */
export interface GetOutWorkingTeamSourceListQuery {
  /**
   * teamIds
   */
  teamIds: string
}

/* **返回类型** */
export type GetOutWorkingTeamSourceListResponse = {
  /**
   * 是否酒店派单 0否1是
   */
  delivery: number
  id: number
  planId: number
}[]

/* **请求函数** */
export async function getOutWorkingTeamSourceList(query: GetOutWorkingTeamSourceListQuery): Promise<any> {
  return request(`/out/working/team/source/list`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [getScheduleByTeamId↗](http://fe.jiuworker.com:3000/project/29/interface/api/2261)
 *
 * @分类 [对外接口-用工端小程序↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_139)
 * @标签 `对外接口-用工端小程序`
 * @请求头 `GET /out/working/team/room/schedule`
 * @更新时间 `2024-08-23 16:22:11`
 */

/* **请求query类型** */
export interface GetOutWorkingTeamRoomScheduleQuery {
  /**
   * teamId
   */
  teamId: string
}

/* **返回类型** */
export type GetOutWorkingTeamRoomScheduleResponse = {
  /**
   * 房间信息
   */
  enterRoomDetailInfoVO: {
    /**
     * 反馈信息
     */
    enterRoomDetailInfoRemarkVOList: {
      /**
       * 内容
       */
      infoContent: string
      /**
       * 时间
       */
      infoDate: string
      /**
       * 10:反馈；20回复
       */
      infoType: number
      pictureList: string[]
    }[]
    enterRoomOrderPersonVO: {
      attendanceDate: string
      faceUrl: string
      personId: number
      personName: string
    }
    id: string
    /**
     * 图片列表
     */
    pictureList: string[]
    /**
     * 房间备注
     */
    remark: string
    /**
     * 房间名称/房间号
     */
    room: string
    /**
     * 打扫完成时间
     */
    roomDate: string
    /**
     * 10:未确认；20已确认
     */
    status: number
  }[]
  /**
   * 人员信息
   */
  enterRoomOrderPersonVOList: {
    attendanceDate: string
    faceUrl: string
    personId: number
    personName: string
  }[]
  /**
   * 时间
   */
  itemDate: string
  /**
   * 名称
   */
  itemName: string
}[]

/* **请求函数** */
export async function getOutWorkingTeamRoomSchedule(query: GetOutWorkingTeamRoomScheduleQuery): Promise<any> {
  return request(`/out/working/team/room/schedule`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [insureUrl↗](http://fe.jiuworker.com:3000/project/29/interface/api/2355)
 *
 * @分类 [对外接口-用工端小程序↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_139)
 * @标签 `对外接口-用工端小程序`
 * @请求头 `GET /out/working/insure/alipay/url`
 * @更新时间 `2024-08-23 16:22:11`
 */

/* **请求query类型** */
export interface GetOutWorkingInsureAlipayUrlQuery {
  /**
   * clockTime
   */
  clockTime: string
  /**
   * idcard
   */
  idcard: string
  /**
   * teamId
   */
  teamId: string
}

/* **返回类型** */
export interface GetOutWorkingInsureAlipayUrlResponse {
  /**
   * 状态 0未投保 1已投保/无需投保
   */
  status: number
  /**
   * 考勤跳转链接
   */
  url: string
}

/* **请求函数** */
export async function getOutWorkingInsureAlipayUrl(query: GetOutWorkingInsureAlipayUrlQuery): Promise<any> {
  return request(`/out/working/insure/alipay/url`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [getRoomInfoByTeamId↗](http://fe.jiuworker.com:3000/project/29/interface/api/2389)
 *
 * @分类 [对外接口-用工端小程序↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_139)
 * @标签 `对外接口-用工端小程序`
 * @请求头 `GET /out/working/team/room/info`
 * @更新时间 `2024-08-23 16:22:11`
 */

/* **请求query类型** */
export interface GetOutWorkingTeamRoomInfoQuery {
  /**
   * teamId
   */
  teamId: string
}

/* **返回类型** */
export type GetOutWorkingTeamRoomInfoResponse = {
  /**
   * 房间id
   */
  id: number
  name: string
  num: number
  originalPrice: number
  price: number
}[]

/* **请求函数** */
export async function getOutWorkingTeamRoomInfo(query: GetOutWorkingTeamRoomInfoQuery): Promise<any> {
  return request(`/out/working/team/room/info`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [10.用户综合薪资↗](http://fe.jiuworker.com:3000/project/29/interface/api/2455)
 *
 * @分类 [对外接口-用工端小程序↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_139)
 * @标签 `对外接口-用工端小程序`
 * @请求头 `GET /out/working/team/salary`
 * @更新时间 `2024-08-23 16:22:11`
 */

/* **请求query类型** */
export interface GetOutWorkingTeamSalaryQuery {
  /**
   * idcard
   */
  idcard: string
  /**
   * teamId
   */
  teamId: string
}

/* **返回类型** */
export interface GetOutWorkingTeamSalaryResponse {
  /**
   * 计件
   */
  piece: number
  /**
   * 薪资
   */
  salary: number
}

/* **请求函数** */
export async function getOutWorkingTeamSalary(query: GetOutWorkingTeamSalaryQuery): Promise<any> {
  return request(`/out/working/team/salary`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [1.工人端通知用户信息更新↗](http://fe.jiuworker.com:3000/project/29/interface/api/2595)
 *
 * @分类 [对外接口-用工端小程序↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_139)
 * @标签 `对外接口-用工端小程序`
 * @请求头 `POST /out/working/update/user`
 * @更新时间 `2024-08-23 16:22:11`
 */

/* **请求body类型** */
export interface PostOutWorkingUpdateUserBody {
  idcard?: string
}

/* **返回类型** */
export type PostOutWorkingUpdateUserResponse = string

/* **请求函数** */
export async function postOutWorkingUpdateUser(body: PostOutWorkingUpdateUserBody): Promise<any> {
  return request(`/out/working/update/user`, {
    method: Method.POST,

    data: body,
  })
}

/**
 * 接口 [1.接受或拒绝用工单 planId↗](http://fe.jiuworker.com:3000/project/29/interface/api/2133)
 *
 * @分类 [客房派单↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_142)
 * @标签 `客房派单`
 * @请求头 `POST /delivery/order/accept`
 * @更新时间 `2024-08-23 16:22:05`
 */

/* **请求body类型** */
export interface PostDeliveryOrderAcceptBody {
  /**
   * 是否同意 1同意0拒绝
   */
  accept?: number
  id?: number
  /**
   * 拒绝原因
   */
  refuseReason?: string
}

/* **返回类型** */
export interface PostDeliveryOrderAcceptResponse {
  id: number
}

/* **请求函数** */
export async function postDeliveryOrderAccept(body: PostDeliveryOrderAcceptBody): Promise<any> {
  return request(`/delivery/order/accept`, {
    method: Method.POST,

    data: body,
  })
}

/**
 * 接口 [1.取消接单 planId↗](http://fe.jiuworker.com:3000/project/29/interface/api/2135)
 *
 * @分类 [客房派单↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_142)
 * @标签 `客房派单`
 * @请求头 `POST /delivery/order/cancel`
 * @更新时间 `2024-08-23 16:22:05`
 */

/* **请求body类型** */
export interface PostDeliveryOrderCancelBody {
  /**
   * 是否同意 1同意0拒绝
   */
  accept?: number
  id?: number
  /**
   * 拒绝原因
   */
  refuseReason?: string
}

/* **返回类型** */
export type PostDeliveryOrderCancelResponse = boolean

/* **请求函数** */
export async function postDeliveryOrderCancel(body: PostDeliveryOrderCancelBody): Promise<any> {
  return request(`/delivery/order/cancel`, {
    method: Method.POST,

    data: body,
  })
}

/**
 * 接口 [2.详情-planId↗](http://fe.jiuworker.com:3000/project/29/interface/api/2137)
 *
 * @分类 [客房派单↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_142)
 * @标签 `客房派单`
 * @请求头 `GET /delivery/order/detail/{id}`
 * @更新时间 `2024-08-23 16:22:05`
 */

/* **请求params类型** */
export interface GetDeliveryOrderDetailByIdParams {
  /**
   * id
   */
  id: string
}

/* **返回类型** */
export interface GetDeliveryOrderDetailByIdResponse {
  /**
   * 取消理由
   */
  cancelReason: string
  /**
   * 联系人
   */
  contractPerson: string
  /**
   * 联系人电话
   */
  contractPhone: string
  /**
   * 用工企业id
   */
  employCompanyId: number
  /**
   * 用工企业名称
   */
  employCompanyName: string
  /**
   * 抢单截止时间
   */
  endDateOrderHall: string
  endTime: string
  gmtCreate: string
  gmtModified: string
  /**
   * planId
   */
  id: number
  /**
   * 保险id
   */
  insuranceId: number
  /**
   * 保险名称
   */
  insuranceName: string
  jobName: string
  /**
   * 岗位信息
   */
  jobs: {
    /**
     * 最大年龄
     */
    ageMax: number
    /**
     * 最小年龄
     */
    ageMin: number
    /**
     * 计划用工数量/女
     */
    femaleNum: number
    id: number
    /**
     * 岗位id
     */
    jobId: number
    /**
     * 计划用工数量/男
     */
    maleNum: number
    /**
     * 岗位名称
     */
    name: string
    /**
     * 计划用工数量
     */
    num: number
    /**
     * 其它要求 json
     */
    other: string
    /**
     * 用工/单价
     */
    price: number
    rooms: {
      /**
       * 房间id
       */
      id: number
      name: string
      num: number
      originalPrice: number
      price: number
    }[]
    /**
     * 班组id
     */
    teamId: number
    /**
     * 计价方式 0:按小时 1:按天 2:按次
     */
    valuationType: number
  }[]
  /**
   * 劳务公司id
   */
  laborCompanyId: number
  location: {
    address: string
    latitude: string
    longitude: string
    region: string[]
  }
  /**
   * 需求名称
   */
  name: string
  /**
   * 房间数量
   */
  num: number
  orderId: number
  /**
   * 订单编号
   */
  orderNo: string
  /**
   * 用工计划id
   */
  planId: number
  /**
   * 房间单价
   */
  price: number
  /**
   * 休息时长 小时
   */
  restTime: number
  startDate: string
  startTime: string
  /**
   * 订单状态 10待接单 20接单中  30进行中 60待结算  80已完成 90已取消
   */
  status: number
  /**
   * 班组id
   */
  teamId: number
  /**
   * 岗位信息
   */
  teamJobs: {
    /**
     * 最大年龄
     */
    ageMax: number
    /**
     * 最小年龄
     */
    ageMin: number
    /**
     * 计划用工数量/女
     */
    femaleNum: number
    id: number
    /**
     * 岗位id
     */
    jobId: number
    /**
     * 计划用工数量/男
     */
    maleNum: number
    /**
     * 岗位名称
     */
    name: string
    /**
     * 计划用工数量
     */
    num: number
    /**
     * 其它要求 json
     */
    other: string
    /**
     * 用工/单价
     */
    price: number
    rooms: {
      /**
       * 房间id
       */
      id: number
      name: string
      num: number
      originalPrice: number
      price: number
    }[]
    /**
     * 班组id
     */
    teamId: number
    /**
     * 计价方式 0:按小时 1:按天 2:按次
     */
    valuationType: number
  }[]
  /**
   * 班组名称
   */
  teamName: string
  /**
   * 客房总价
   */
  totalPrice: number
  users: {
    avatar: string
    userName: string
  }[]
  /**
   * 计价方式 0:按小时 1:按天 2:按次
   */
  valuationType: number
}

/* **请求函数** */
export async function getDeliveryOrderDetailById(params: GetDeliveryOrderDetailByIdParams): Promise<any> {
  return request(`/delivery/order/detail/${params.id}`, {
    method: Method.GET,
  })
}

/**
 * 接口 [3.列表↗](http://fe.jiuworker.com:3000/project/29/interface/api/2139)
 *
 * @分类 [客房派单↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_142)
 * @标签 `客房派单`
 * @请求头 `GET /delivery/order/list`
 * @更新时间 `2024-08-23 16:22:05`
 */

/* **请求query类型** */
export interface GetDeliveryOrderListQuery {
  /**
   * employCompanyId
   */
  employCompanyId?: string
  /**
   * endDate
   */
  endDate?: string
  /**
   * name
   */
  name?: string
  /**
   * orderNo
   */
  orderNo?: string
  /**
   * pageNum
   */
  pageNum?: number
  /**
   * pageSize
   */
  pageSize?: number
  /**
   * startDate
   */
  startDate?: string
  /**
   * 订单状态 10待接单 20接单中  30进行中 60待结算  80已完成 90已取消
   */
  statusList?: string
}

/* **返回类型** */
export interface GetDeliveryOrderListResponse {
  data: {
    /**
     * 取消理由
     */
    cancelReason: string
    /**
     * 联系人
     */
    contractPerson: string
    /**
     * 联系人电话
     */
    contractPhone: string
    /**
     * 用工企业id
     */
    employCompanyId: number
    /**
     * 用工企业名称
     */
    employCompanyName: string
    /**
     * 抢单截止时间
     */
    endDateOrderHall: string
    endTime: string
    gmtCreate: string
    gmtModified: string
    /**
     * planId
     */
    id: number
    /**
     * 保险id
     */
    insuranceId: number
    /**
     * 保险名称
     */
    insuranceName: string
    jobName: string
    /**
     * 岗位信息
     */
    jobs: {
      /**
       * 最大年龄
       */
      ageMax: number
      /**
       * 最小年龄
       */
      ageMin: number
      /**
       * 计划用工数量/女
       */
      femaleNum: number
      id: number
      /**
       * 岗位id
       */
      jobId: number
      /**
       * 计划用工数量/男
       */
      maleNum: number
      /**
       * 岗位名称
       */
      name: string
      /**
       * 计划用工数量
       */
      num: number
      /**
       * 其它要求 json
       */
      other: string
      /**
       * 用工/单价
       */
      price: number
      rooms: {
        /**
         * 房间id
         */
        id: number
        name: string
        num: number
        originalPrice: number
        price: number
      }[]
      /**
       * 班组id
       */
      teamId: number
      /**
       * 计价方式 0:按小时 1:按天 2:按次
       */
      valuationType: number
    }[]
    /**
     * 劳务公司id
     */
    laborCompanyId: number
    location: {
      address: string
      latitude: string
      longitude: string
      region: string[]
    }
    /**
     * 需求名称
     */
    name: string
    /**
     * 房间数量
     */
    num: number
    orderId: number
    /**
     * 订单编号
     */
    orderNo: string
    /**
     * 用工计划id
     */
    planId: number
    /**
     * 房间单价
     */
    price: number
    /**
     * 休息时长 小时
     */
    restTime: number
    startDate: string
    startTime: string
    /**
     * 订单状态 10待接单 20接单中  30进行中 60待结算  80已完成 90已取消
     */
    status: number
    /**
     * 班组id
     */
    teamId: number
    /**
     * 岗位信息
     */
    teamJobs: {
      /**
       * 最大年龄
       */
      ageMax: number
      /**
       * 最小年龄
       */
      ageMin: number
      /**
       * 计划用工数量/女
       */
      femaleNum: number
      id: number
      /**
       * 岗位id
       */
      jobId: number
      /**
       * 计划用工数量/男
       */
      maleNum: number
      /**
       * 岗位名称
       */
      name: string
      /**
       * 计划用工数量
       */
      num: number
      /**
       * 其它要求 json
       */
      other: string
      /**
       * 用工/单价
       */
      price: number
      rooms: {
        /**
         * 房间id
         */
        id: number
        name: string
        num: number
        originalPrice: number
        price: number
      }[]
      /**
       * 班组id
       */
      teamId: number
      /**
       * 计价方式 0:按小时 1:按天 2:按次
       */
      valuationType: number
    }[]
    /**
     * 班组名称
     */
    teamName: string
    /**
     * 客房总价
     */
    totalPrice: number
    users: {
      avatar: string
      userName: string
    }[]
    /**
     * 计价方式 0:按小时 1:按天 2:按次
     */
    valuationType: number
  }[]
  total: number
}

/* **请求函数** */
export async function getDeliveryOrderList(query: GetDeliveryOrderListQuery): Promise<any> {
  return request(`/delivery/order/list`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [2.详情-orderId↗](http://fe.jiuworker.com:3000/project/29/interface/api/2241)
 *
 * @分类 [客房派单↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_142)
 * @标签 `客房派单`
 * @请求头 `GET /delivery/order/detail/order/{id}`
 * @更新时间 `2024-03-19 15:10:06`
 */

/* **请求params类型** */
export interface GetDeliveryOrderDetailOrderByIdParams {
  /**
   * id
   */
  id: string
}

/* **返回类型** */
export interface GetDeliveryOrderDetailOrderByIdResponse {
  /**
   * 用工企业id
   */
  employCompanyId: number
  /**
   * 用工企业名称
   */
  employCompanyName: string
  endTime: string
  gmtCreate: string
  gmtModified: string
  /**
   * planId
   */
  id: number
  jobName: string
  /**
   * 劳务公司id
   */
  laborCompanyId: number
  location: {
    address: string
    latitude: string
    longitude: string
    region: string[]
  }
  /**
   * 需求名称
   */
  name: string
  /**
   * 房间数量
   */
  num: number
  orderId: number
  /**
   * 订单编号
   */
  orderNo: string
  /**
   * 用工计划id
   */
  planId: number
  /**
   * 价格
   */
  price: number
  /**
   * 休息时长 小时
   */
  restTime: number
  startDate: string
  startTime: string
  /**
   * 订单状态 10待接单 20接单中  30进行中 70已拒绝 80已完成 90已取消
   */
  status: number
  /**
   * 班组id
   */
  teamId: number
  /**
   * 班组名称
   */
  teamName: string
  /**
   * 计价方式 0:按小时 1:按天 2:按次
   */
  valuationType: number
}

/* **请求函数** */
export async function getDeliveryOrderDetailOrderById(params: GetDeliveryOrderDetailOrderByIdParams): Promise<any> {
  return request(`/delivery/order/detail/order/${params.id}`, {
    method: Method.GET,
  })
}

/**
 * 接口 [getScheduleByTeamId↗](http://fe.jiuworker.com:3000/project/29/interface/api/2267)
 *
 * @分类 [客房派单↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_142)
 * @标签 `客房派单`
 * @请求头 `GET /delivery/order/team/room/schedule`
 * @更新时间 `2024-03-21 10:56:05`
 */

/* **请求query类型** */
export interface GetDeliveryOrderTeamRoomScheduleQuery {
  /**
   * teamId
   */
  teamId: string
}

/* **返回类型** */
export type GetDeliveryOrderTeamRoomScheduleResponse = {
  /**
   * 房间信息
   */
  enterRoomDetailInfoVO: {
    /**
     * 反馈信息
     */
    enterRoomDetailInfoRemarkVOList: {
      /**
       * 内容
       */
      infoContent: string
      /**
       * 时间
       */
      infoDate: string
      /**
       * 10:反馈；20回复
       */
      infoType: number
    }[]
    enterRoomOrderPersonVO: {
      attendanceDate: string
      faceUrl: string
      personId: number
      personName: string
    }
    id: string
    /**
     * 图片列表
     */
    pictureList: string[]
    /**
     * 房间备注
     */
    remark: string
    /**
     * 房间名称/房间号
     */
    room: string
    /**
     * 打扫完成时间
     */
    roomDate: string
    /**
     * 10:未确认；20已确认
     */
    status: number
  }[]
  /**
   * 人员信息
   */
  enterRoomOrderPersonVOList: {
    attendanceDate: string
    faceUrl: string
    personId: number
    personName: string
  }[]
  /**
   * 时间
   */
  itemDate: string
  /**
   * 名称
   */
  itemName: string
}[]

/* **请求函数** */
export async function getDeliveryOrderTeamRoomSchedule(query: GetDeliveryOrderTeamRoomScheduleQuery): Promise<any> {
  return request(`/delivery/order/team/room/schedule`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [getScheduleByTeamId↗](http://fe.jiuworker.com:3000/project/29/interface/api/2269)
 *
 * @分类 [客房派单↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_142)
 * @标签 `客房派单`
 * @请求头 `GET /delivery/order/room/schedule`
 * @更新时间 `2024-08-23 16:22:05`
 */

/* **请求query类型** */
export interface GetDeliveryOrderRoomScheduleQuery {
  /**
   * planId
   */
  planId: string
}

/* **返回类型** */
export type GetDeliveryOrderRoomScheduleResponse = {
  /**
   * 房间信息
   */
  enterRoomDetailInfoVO: {
    /**
     * 反馈信息
     */
    enterRoomDetailInfoRemarkVOList: {
      /**
       * 内容
       */
      infoContent: string
      /**
       * 时间
       */
      infoDate: string
      /**
       * 10:反馈；20回复
       */
      infoType: number
      pictureList: string[]
    }[]
    enterRoomOrderPersonVO: {
      attendanceDate: string
      faceUrl: string
      personId: number
      personName: string
    }
    id: string
    /**
     * 图片列表
     */
    pictureList: string[]
    /**
     * 房间备注
     */
    remark: string
    /**
     * 房间名称/房间号
     */
    room: string
    /**
     * 打扫完成时间
     */
    roomDate: string
    /**
     * 10:未确认；20已确认
     */
    status: number
  }[]
  /**
   * 人员信息
   */
  enterRoomOrderPersonVOList: {
    attendanceDate: string
    faceUrl: string
    personId: number
    personName: string
  }[]
  /**
   * 时间
   */
  itemDate: string
  /**
   * 名称
   */
  itemName: string
}[]

/* **请求函数** */
export async function getDeliveryOrderRoomSchedule(query: GetDeliveryOrderRoomScheduleQuery): Promise<any> {
  return request(`/delivery/order/room/schedule`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [3.2需求单-数据统计↗](http://fe.jiuworker.com:3000/project/29/interface/api/2289)
 *
 * @分类 [客房派单↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_142)
 * @标签 `客房派单`
 * @请求头 `GET /delivery/order/list/count`
 * @更新时间 `2024-08-23 16:22:05`
 */

/* **返回类型** */
export type GetDeliveryOrderListCountResponse = {
  count: number
  /**
   * 订单状态 10待接单 20接单中  30进行中 60待结算  80已完成 90已取消
   */
  status: number
}[]

/* **请求函数** */
export async function getDeliveryOrderListCount(): Promise<any> {
  return request(`/delivery/order/list/count`, {
    method: Method.GET,
  })
}

/**
 * 接口 [1.统计报表-班组统计↗](http://fe.jiuworker.com:3000/project/29/interface/api/2275)
 *
 * @分类 [报表统计管理↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_153)
 * @标签 `报表统计管理`
 * @请求头 `GET /report/list`
 * @更新时间 `2024-08-23 16:22:11`
 */

/* **请求query类型** */
export interface GetReportListQuery {
  /**
   * pageNum
   */
  pageNum?: number
  /**
   * pageSize
   */
  pageSize?: number
  /**
   * teamId
   */
  teamId?: string
}

/* **返回类型** */
export interface GetReportListResponse {
  data: {
    /**
     * 实际收入
     */
    actualIncome: number
    /**
     * 实际保险支出
     */
    actualInsure: number
    /**
     * 实发工资
     */
    actualPay: number
    /**
     * 实际利润
     */
    actualProfit: number
    name: string
    /**
     * 应结收入
     */
    projectedIncome: number
    /**
     * 预估利润
     */
    projectedProfit: number
    /**
     * 应发工资
     */
    shouldPay: number
  }[]
  total: number
}

/* **请求函数** */
export async function getReportList(query: GetReportListQuery): Promise<any> {
  return request(`/report/list`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [3.统计报表-日统计↗](http://fe.jiuworker.com:3000/project/29/interface/api/2291)
 *
 * @分类 [报表统计管理↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_153)
 * @标签 `报表统计管理`
 * @请求头 `GET /report/listByDay`
 * @更新时间 `2024-08-23 16:22:11`
 */

/* **请求query类型** */
export interface GetReportListByDayQuery {
  /**
   * 月份 yyyy-MM-dd
   */
  month?: string
  /**
   * pageNum
   */
  pageNum?: number
  /**
   * pageSize
   */
  pageSize?: number
}

/* **返回类型** */
export type GetReportListByDayResponse = {
  /**
   * 实际收入
   */
  actualIncome: number
  /**
   * 实际保险支出
   */
  actualInsure: number
  /**
   * 实发工资
   */
  actualPay: number
  /**
   * 实际利润
   */
  actualProfit: number
  name: string
  /**
   * 应结收入
   */
  projectedIncome: number
  /**
   * 预估利润
   */
  projectedProfit: number
  /**
   * 应发工资
   */
  shouldPay: number
}[]

/* **请求函数** */
export async function getReportListByDay(query: GetReportListByDayQuery): Promise<any> {
  return request(`/report/listByDay`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [2.统计报表-月统计↗](http://fe.jiuworker.com:3000/project/29/interface/api/2293)
 *
 * @分类 [报表统计管理↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_153)
 * @标签 `报表统计管理`
 * @请求头 `GET /report/listByMonth`
 * @更新时间 `2024-08-23 16:22:11`
 */

/* **请求query类型** */
export interface GetReportListByMonthQuery {
  /**
   * pageNum
   */
  pageNum?: number
  /**
   * pageSize
   */
  pageSize?: number
  /**
   * 年份 yyyy
   */
  year?: string
}

/* **返回类型** */
export type GetReportListByMonthResponse = {
  /**
   * 实际收入
   */
  actualIncome: number
  /**
   * 实际保险支出
   */
  actualInsure: number
  /**
   * 实发工资
   */
  actualPay: number
  /**
   * 实际利润
   */
  actualProfit: number
  name: string
  /**
   * 应结收入
   */
  projectedIncome: number
  /**
   * 预估利润
   */
  projectedProfit: number
  /**
   * 应发工资
   */
  shouldPay: number
}[]

/* **请求函数** */
export async function getReportListByMonth(query: GetReportListByMonthQuery): Promise<any> {
  return request(`/report/listByMonth`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [1.统计报表-班组统计-导出↗](http://fe.jiuworker.com:3000/project/29/interface/api/2297)
 *
 * @分类 [报表统计管理↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_153)
 * @标签 `报表统计管理`
 * @请求头 `GET /report/list/export`
 * @更新时间 `2024-08-23 16:22:11`
 */

/* **返回类型** */
export type GetReportListExportResponse = any

/* **请求函数** */
export async function getReportListExport(): Promise<any> {
  return request(`/report/list/export`, {
    method: Method.GET,
  })
}

/**
 * 接口 [3.统计报表-日统计-导出↗](http://fe.jiuworker.com:3000/project/29/interface/api/2299)
 *
 * @分类 [报表统计管理↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_153)
 * @标签 `报表统计管理`
 * @请求头 `GET /report/listByDay/export`
 * @更新时间 `2024-08-23 16:22:11`
 */

/* **请求query类型** */
export interface GetReportListByDayExportQuery {
  /**
   * 月份 yyyy-MM-dd
   */
  month?: string
  /**
   * pageNum
   */
  pageNum?: number
  /**
   * pageSize
   */
  pageSize?: number
}

/* **返回类型** */
export type GetReportListByDayExportResponse = any

/* **请求函数** */
export async function getReportListByDayExport(query: GetReportListByDayExportQuery): Promise<any> {
  return request(`/report/listByDay/export`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [2.统计报表-月统计-导出↗](http://fe.jiuworker.com:3000/project/29/interface/api/2301)
 *
 * @分类 [报表统计管理↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_153)
 * @标签 `报表统计管理`
 * @请求头 `GET /report/listByMonth/export`
 * @更新时间 `2024-08-23 16:22:11`
 */

/* **请求query类型** */
export interface GetReportListByMonthExportQuery {
  /**
   * pageNum
   */
  pageNum?: number
  /**
   * pageSize
   */
  pageSize?: number
  /**
   * 年份 yyyy
   */
  year?: string
}

/* **返回类型** */
export type GetReportListByMonthExportResponse = any

/* **请求函数** */
export async function getReportListByMonthExport(query: GetReportListByMonthExportQuery): Promise<any> {
  return request(`/report/listByMonth/export`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [3.查询移动端用户详情↗](http://fe.jiuworker.com:3000/project/29/interface/api/2503)
 *
 * @分类 [App权限管理↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_164)
 * @标签 `App权限管理`
 * @请求头 `GET /app/permission/query/{id}`
 * @更新时间 `2024-05-31 14:26:04`
 */

/* **请求params类型** */
export interface GetAppPermissionQueryByIdParams {
  /**
   * accountId
   */
  accountId: string
  id: string
}

/* **返回类型** */
export interface GetAppPermissionQueryByIdResponse {
  id: number
  /**
   * 权限id集合
   */
  permissions: {
    /**
     * 是否选中
     */
    checked: number
    /**
     * 用户权限
     */
    children: {
      /**
       * 用户权限
       */
      children: {}[]
      /**
       * 权限名称
       */
      name: string
      /**
       * 权限id
       */
      permissionId: number
    }[]
    /**
     * 权限名称
     */
    name: string
    /**
     * 权限id
     */
    permissionId: number
  }[]
  /**
   * 角色名称
   */
  roleName: string
}

/* **请求函数** */
export async function getAppPermissionQueryById(params: GetAppPermissionQueryByIdParams): Promise<any> {
  return request(`/app/permission/query/${params.id}`, {
    method: Method.GET,
  })
}

/**
 * 接口 [1.app端添加用户↗](http://fe.jiuworker.com:3000/project/29/interface/api/2507)
 *
 * @分类 [App权限管理↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_164)
 * @标签 `App权限管理`
 * @请求头 `PUT /app/permission/add/account`
 * @更新时间 `2024-08-23 16:22:03`
 */

/* **请求body类型** */
export interface PutAppPermissionAddAccountBody {
  mobile?: string
  name?: string
  /**
   * 权限id集合
   */
  permissionIds?: number[]
}

/* **返回类型** */
export type PutAppPermissionAddAccountResponse = boolean

/* **请求函数** */
export async function putAppPermissionAddAccount(body: PutAppPermissionAddAccountBody): Promise<any> {
  return request(`/app/permission/add/account`, {
    method: Method.PUT,

    data: body,
  })
}

/**
 * 接口 [2.编辑用户权限↗](http://fe.jiuworker.com:3000/project/29/interface/api/2509)
 *
 * @分类 [App权限管理↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_164)
 * @标签 `App权限管理`
 * @请求头 `PUT /app/permission/edit`
 * @更新时间 `2024-08-23 16:22:03`
 */

/* **请求body类型** */
export interface PutAppPermissionEditBody {
  accountCompanyId?: number
  name?: string
  /**
   * 权限id集合
   */
  permissionIds?: number[]
}

/* **返回类型** */
export type PutAppPermissionEditResponse = boolean

/* **请求函数** */
export async function putAppPermissionEdit(body: PutAppPermissionEditBody): Promise<any> {
  return request(`/app/permission/edit`, {
    method: Method.PUT,

    data: body,
  })
}

/**
 * 接口 [3.查询移动端用户详情-用户管理↗](http://fe.jiuworker.com:3000/project/29/interface/api/2511)
 *
 * @分类 [App权限管理↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_164)
 * @标签 `App权限管理`
 * @请求头 `GET /app/permission/query/{accountCompanyId}`
 * @更新时间 `2024-08-23 16:22:03`
 */

/* **请求params类型** */
export interface GetAppPermissionQueryByAccountCompanyIdParams {
  /**
   * accountCompanyId
   */
  accountCompanyId: string
}

/* **返回类型** */
export interface GetAppPermissionQueryByAccountCompanyIdResponse {
  accountCompanyId: number
  accountId: number
  id: number
  mobile: string
  /**
   * 权限id集合
   */
  permissions: {
    /**
     * 是否选中
     */
    checked: number
    /**
     * 用户权限
     */
    children: {
      /**
       * 用户权限
       */
      children: {}[]
      /**
       * 权限名称
       */
      name: string
      /**
       * 权限id
       */
      permissionId: number
    }[]
    /**
     * 权限名称
     */
    name: string
    /**
     * 权限id
     */
    permissionId: number
  }[]
  /**
   * 角色名称
   */
  userName: string
}

/* **请求函数** */
export async function getAppPermissionQueryByAccountCompanyId(
  params: GetAppPermissionQueryByAccountCompanyIdParams,
): Promise<any> {
  return request(`/app/permission/query/${params.accountCompanyId}`, {
    method: Method.GET,
  })
}

/**
 * 接口 [5.查询所有权限↗](http://fe.jiuworker.com:3000/project/29/interface/api/2513)
 *
 * @分类 [App权限管理↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_164)
 * @标签 `App权限管理`
 * @请求头 `GET /app/permission/all`
 * @更新时间 `2024-08-23 16:22:03`
 */

/* **返回类型** */
export type GetAppPermissionAllResponse = {
  /**
   * 是否选中
   */
  checked: number
  /**
   * 用户权限
   */
  children: {
    /**
     * 用户权限
     */
    children: {}[]
    /**
     * 权限名称
     */
    name: string
    /**
     * 权限id
     */
    permissionId: number
  }[]
  /**
   * 权限名称
   */
  name: string
  /**
   * 权限id
   */
  permissionId: number
}[]

/* **请求函数** */
export async function getAppPermissionAll(): Promise<any> {
  return request(`/app/permission/all`, {
    method: Method.GET,
  })
}

/**
 * 接口 [4.查询移动端用户详情-用户管理↗](http://fe.jiuworker.com:3000/project/29/interface/api/2515)
 *
 * @分类 [App权限管理↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_164)
 * @标签 `App权限管理`
 * @请求头 `GET /app/permission/current`
 * @更新时间 `2024-08-23 16:22:03`
 */

/* **返回类型** */
export type GetAppPermissionCurrentResponse = number[]

/* **请求函数** */
export async function getAppPermissionCurrent(): Promise<any> {
  return request(`/app/permission/current`, {
    method: Method.GET,
  })
}

/**
 * 接口 [6.酒店端考勤确认↗](http://fe.jiuworker.com:3000/project/29/interface/api/1089)
 *
 * @分类 [用工单接口-对外↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_77)
 * @标签 `用工单接口-对外`
 * @请求头 `POST /out/order/attendance/confirm`
 * @更新时间 `2024-08-23 16:22:10`
 */

/* **请求body类型** */
export interface PostOutOrderAttendanceConfirmBody {
  enterId?: number
  laborId?: number
  planId?: number
}

/* **返回类型** */
export type PostOutOrderAttendanceConfirmResponse = boolean

/* **请求函数** */
export async function postOutOrderAttendanceConfirm(body: PostOutOrderAttendanceConfirmBody): Promise<any> {
  return request(`/out/order/attendance/confirm`, {
    method: Method.POST,

    data: body,
  })
}

/**
 * 接口 [3.未接单取消用工计划↗](http://fe.jiuworker.com:3000/project/29/interface/api/1091)
 *
 * @分类 [用工单接口-对外↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_77)
 * @标签 `用工单接口-对外`
 * @请求头 `POST /out/order/cancel`
 * @更新时间 `2024-08-23 16:22:10`
 */

/* **请求body类型** */
export interface PostOutOrderCancelBody {
  enterId?: number
  laborId?: number
  planId?: number
}

/* **返回类型** */
export type PostOutOrderCancelResponse = boolean

/* **请求函数** */
export async function postOutOrderCancel(body: PostOutOrderCancelBody): Promise<any> {
  return request(`/out/order/cancel`, {
    method: Method.POST,

    data: body,
  })
}

/**
 * 接口 [5.同意劳务端提出的取消用工计划↗](http://fe.jiuworker.com:3000/project/29/interface/api/1093)
 *
 * @分类 [用工单接口-对外↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_77)
 * @标签 `用工单接口-对外`
 * @请求头 `POST /out/order/cancel/accept`
 * @更新时间 `2024-08-23 16:22:10`
 */

/* **请求body类型** */
export interface PostOutOrderCancelAcceptBody {
  /**
   * 是否接受 0-拒绝 1-接受
   */
  accept?: number
  enterId?: number
  laborId?: number
  planId?: number
  /**
   * 拒绝原因
   */
  reason?: string
}

/* **返回类型** */
export type PostOutOrderCancelAcceptResponse = boolean

/* **请求函数** */
export async function postOutOrderCancelAccept(body: PostOutOrderCancelAcceptBody): Promise<any> {
  return request(`/out/order/cancel/accept`, {
    method: Method.POST,

    data: body,
  })
}

/**
 * 接口 [2.收到一个用工单变更↗](http://fe.jiuworker.com:3000/project/29/interface/api/1095)
 *
 * @分类 [用工单接口-对外↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_77)
 * @标签 `用工单接口-对外`
 * @请求头 `POST /out/order/modify`
 * @更新时间 `2024-08-23 16:22:10`
 */

/* **请求body类型** */
export interface PostOutOrderModifyBody {
  bizData?: string
  createId?: number
  enterId?: number
  gmtCreate?: string
  gmtModified?: string
  id?: number
  planEndDate?: string
  planName?: string
  planNo?: string
  planStartDate?: string
  relateWorkId?: number
  status?: number
  workLeaderId?: number
}

/* **返回类型** */
export type PostOutOrderModifyResponse = boolean

/* **请求函数** */
export async function postOutOrderModify(body: PostOutOrderModifyBody): Promise<any> {
  return request(`/out/order/modify`, {
    method: Method.POST,

    data: body,
  })
}

/**
 * 接口 [1.收到一个用工单↗](http://fe.jiuworker.com:3000/project/29/interface/api/1097)
 *
 * @分类 [用工单接口-对外↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_77)
 * @标签 `用工单接口-对外`
 * @请求头 `POST /out/order/receive`
 * @更新时间 `2024-08-23 16:22:10`
 */

/* **请求body类型** */
export interface PostOutOrderReceiveBody {
  bizData?: string
  createId?: number
  enterId?: number
  gmtCreate?: string
  gmtModified?: string
  id?: number
  planEndDate?: string
  planName?: string
  planNo?: string
  planStartDate?: string
  relateWorkId?: number
  status?: number
  workLeaderId?: number
}

/* **返回类型** */
export type PostOutOrderReceiveResponse = boolean

/* **请求函数** */
export async function postOutOrderReceive(body: PostOutOrderReceiveBody): Promise<any> {
  return request(`/out/order/receive`, {
    method: Method.POST,

    data: body,
  })
}

/**
 * 接口 [4.接单中取消用工计划↗](http://fe.jiuworker.com:3000/project/29/interface/api/1099)
 *
 * @分类 [用工单接口-对外↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_77)
 * @标签 `用工单接口-对外`
 * @请求头 `POST /out/order/underway/cancel`
 * @更新时间 `2024-08-23 16:22:10`
 */

/* **请求body类型** */
export interface PostOutOrderUnderwayCancelBody {
  enterId?: number
  laborId?: number
  planId?: number
  reason?: string
}

/* **返回类型** */
export type PostOutOrderUnderwayCancelResponse = boolean

/* **请求函数** */
export async function postOutOrderUnderwayCancel(body: PostOutOrderUnderwayCancelBody): Promise<any> {
  return request(`/out/order/underway/cancel`, {
    method: Method.POST,

    data: body,
  })
}

/**
 * 接口 [11.用工计划详情↗](http://fe.jiuworker.com:3000/project/29/interface/api/1971)
 *
 * @分类 [用工单接口-对外↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_77)
 * @标签 `用工单接口-对外`
 * @请求头 `GET /out/order/grab/detail/{id}`
 * @更新时间 `2024-08-23 16:22:10`
 */

/* **请求query类型** */
export interface GetOutOrderGrabDetailByIdQuery {
  /**
   * isFade
   */
  isFade?: string
}

/* **请求params类型** */
export interface GetOutOrderGrabDetailByIdParams {
  /**
   * id
   */
  id: string
}

/* **返回类型** */
export interface GetOutOrderGrabDetailByIdResponse {
  /**
   * 计划取消原因
   */
  cancelReason: string
  /**
   * 取消状态 1正常 2用工方取消 3劳务方取消 4用工方拒绝 5劳务方拒绝
   */
  cancelStatus: number
  /**
   * 联系人
   */
  contractPerson: string
  /**
   * 联系人电话
   */
  contractPhone: string
  day: number
  /**
   * 单日费用
   */
  dayPrice: number
  /**
   * 用工企业id
   */
  employCompanyId: number
  /**
   * 用工企业名称
   */
  employCompanyName: string
  endDate: string
  endDateTime: string
  endTime: string
  gmtCreate: string
  gmtModified: string
  /**
   * 工作时长
   */
  hour: number
  id: number
  /**
   * 岗位需求json
   */
  jobs: {
    ageMax: number
    ageMin: number
    femaleNum: number
    maleNum: number
    maxAge: number
    minAge: number
    name: string
    num: number
    other: string
    price: number
    rooms: {
      /**
       * 房间id
       */
      id: number
      name: string
      num: number
      originalPrice: number
      price: number
    }[]
  }[]
  /**
   * 劳务公司id
   */
  laborCompanyId: number
  location: {
    address: string
    latitude: string
    longitude: string
    region: string[]
  }
  /**
   * 需求名称
   */
  name: string
  /**
   * 订单编号
   */
  orderNo: string
  /**
   * 用工计划id
   */
  planId: number
  /**
   * 拒绝计划取消原因
   */
  refuseCancelReason: string
  /**
   * 拒绝原因
   */
  refuseReason: string
  /**
   * 休息时长 小时
   */
  restTime: number
  /**
   * 排班进度
   */
  scheduleProgress: number
  source: string
  startDate: string
  startTime: string
  /**
   * 订单状态 1待接单 2接单中  11进行中 3待客户确认考勤 4待考勤确认 5已完成 6 已拒绝 7已取消-用工端 8已取消-劳务端 9已取消-未接单 10待结算 11进行中
   */
  status: number
  /**
   * 班组id
   */
  teamId: number
  /**
   * 班组名称
   */
  teamName: string
  /**
   * 天数汇总
   */
  totalDay: number
  /**
   * 总费用
   */
  totalPrice: number
  /**
   * 工时汇总
   */
  totalWorkHour: number
  userNum: number
  /**
   * 计价方式 0:按小时 1:按天 2:按次
   */
  valuationType: number
}

/* **请求函数** */
export async function getOutOrderGrabDetailById(
  query: GetOutOrderGrabDetailByIdQuery,
  params: GetOutOrderGrabDetailByIdParams,
): Promise<any> {
  return request(`/out/order/grab/detail/${params.id}`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [10.计划单分页查询↗](http://fe.jiuworker.com:3000/project/29/interface/api/1973)
 *
 * @分类 [用工单接口-对外↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_77)
 * @标签 `用工单接口-对外`
 * @请求头 `GET /out/order/grab/query`
 * @更新时间 `2024-08-23 16:22:10`
 */

/* **请求query类型** */
export interface GetOutOrderGrabQueryQuery {
  /**
   * 市
   */
  city?: string
  /**
   * 区县
   */
  country?: string
  /**
   * 用工结束时间
   */
  endDate?: string
  isFade?: string
  laborEnterIdList?: string
  /**
   * 最多工时
   */
  maxHourNum?: string
  /**
   * 最多费用
   */
  maxMoneyNum?: string
  /**
   * 最多人数
   */
  maxPeopleNum?: string
  /**
   * 最少工时
   */
  minHourNum?: string
  /**
   * 最少费用
   */
  minMoneyNum?: string
  /**
   * 最少人数
   */
  minPeopleNum?: string
  /**
   * 排序类型：10：近期发布; 20 费用优先；30 工时优先
   */
  orderType?: string
  pageNum?: number
  pageSize?: number
  /**
   * 省份
   */
  province?: string
  /**
   * 用工开始时间
   */
  startDate?: string
}

/* **返回类型** */
export interface GetOutOrderGrabQueryResponse {
  data: {
    /**
     * 计划取消原因
     */
    cancelReason: string
    /**
     * 取消状态 1正常 2用工方取消 3劳务方取消 4用工方拒绝 5劳务方拒绝
     */
    cancelStatus: number
    /**
     * 联系人
     */
    contractPerson: string
    /**
     * 联系人电话
     */
    contractPhone: string
    day: number
    /**
     * 单日费用
     */
    dayPrice: number
    /**
     * 用工企业id
     */
    employCompanyId: number
    /**
     * 用工企业名称
     */
    employCompanyName: string
    endDate: string
    endDateTime: string
    endTime: string
    gmtCreate: string
    gmtModified: string
    /**
     * 工作时长
     */
    hour: number
    id: number
    /**
     * 岗位需求json
     */
    jobs: {
      ageMax: number
      ageMin: number
      femaleNum: number
      maleNum: number
      maxAge: number
      minAge: number
      name: string
      num: number
      other: string
      price: number
      rooms: {
        /**
         * 房间id
         */
        id: number
        name: string
        num: number
        originalPrice: number
        price: number
      }[]
    }[]
    /**
     * 劳务公司id
     */
    laborCompanyId: number
    location: {
      address: string
      latitude: string
      longitude: string
      region: string[]
    }
    /**
     * 需求名称
     */
    name: string
    /**
     * 订单编号
     */
    orderNo: string
    /**
     * 用工计划id
     */
    planId: number
    /**
     * 拒绝计划取消原因
     */
    refuseCancelReason: string
    /**
     * 拒绝原因
     */
    refuseReason: string
    /**
     * 休息时长 小时
     */
    restTime: number
    /**
     * 排班进度
     */
    scheduleProgress: number
    source: string
    startDate: string
    startTime: string
    /**
     * 订单状态 1待接单 2接单中  11进行中 3待客户确认考勤 4待考勤确认 5已完成 6 已拒绝 7已取消-用工端 8已取消-劳务端 9已取消-未接单 10待结算 11进行中
     */
    status: number
    /**
     * 班组id
     */
    teamId: number
    /**
     * 班组名称
     */
    teamName: string
    /**
     * 天数汇总
     */
    totalDay: number
    /**
     * 总费用
     */
    totalPrice: number
    /**
     * 工时汇总
     */
    totalWorkHour: number
    userNum: number
    /**
     * 计价方式 0:按小时 1:按天 2:按次
     */
    valuationType: number
  }[]
  total: number
}

/* **请求函数** */
export async function getOutOrderGrabQuery(query: GetOutOrderGrabQueryQuery): Promise<any> {
  return request(`/out/order/grab/query`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [21.客房变更↗](http://fe.jiuworker.com:3000/project/29/interface/api/2149)
 *
 * @分类 [用工单接口-对外↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_77)
 * @标签 `用工单接口-对外`
 * @请求头 `GET /out/order/delivery/modify`
 * @更新时间 `2024-08-23 16:22:10`
 */

/* **请求query类型** */
export interface GetOutOrderDeliveryModifyQuery {
  /**
   * planId
   */
  planId: string
}

/* **返回类型** */
export type GetOutOrderDeliveryModifyResponse = boolean

/* **请求函数** */
export async function getOutOrderDeliveryModify(query: GetOutOrderDeliveryModifyQuery): Promise<any> {
  return request(`/out/order/delivery/modify`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [20.新派单-确保事务提交↗](http://fe.jiuworker.com:3000/project/29/interface/api/2151)
 *
 * @分类 [用工单接口-对外↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_77)
 * @标签 `用工单接口-对外`
 * @请求头 `GET /out/order/delivery/new`
 * @更新时间 `2024-08-23 16:22:10`
 */

/* **请求query类型** */
export interface GetOutOrderDeliveryNewQuery {
  /**
   * planId
   */
  planId: string
}

/* **返回类型** */
export type GetOutOrderDeliveryNewResponse = boolean

/* **请求函数** */
export async function getOutOrderDeliveryNew(query: GetOutOrderDeliveryNewQuery): Promise<any> {
  return request(`/out/order/delivery/new`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [1.考勤每日概览↗](http://fe.jiuworker.com:3000/project/29/interface/api/855)
 *
 * @分类 [考勤管理↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_81)
 * @标签 `考勤管理`
 * @请求头 `GET /attendance/daily/overview`
 * @更新时间 `2024-08-23 16:22:04`
 */

/* **请求query类型** */
export interface GetAttendanceDailyOverviewQuery {
  /**
   * date
   */
  date: string
  /**
   * 1:正常 2:迟到 3:早退 4:缺卡 5:旷工
   */
  status?: string
  /**
   * teamId
   */
  teamId: string
}

/* **返回类型** */
export interface GetAttendanceDailyOverviewResponse {
  /**
   * 旷工
   */
  absenteeismCount: number
  /**
   * 出勤人次
   */
  attendanceCount: number
  /**
   * 早退
   */
  earlyCount: number
  /**
   * 缺卡
   */
  lackCardCount: number
  /**
   * 迟到
   */
  lateCount: number
  /**
   * 补卡
   */
  makeUpCardCount: number
  /**
   * 计件
   */
  piece: number
  /**
   * 人次
   */
  userCount: number
  /**
   * 工时
   */
  workHour: string
}

/* **请求函数** */
export async function getAttendanceDailyOverview(query: GetAttendanceDailyOverviewQuery): Promise<any> {
  return request(`/attendance/daily/overview`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [12.员工考勤-点击详情-概览↗](http://fe.jiuworker.com:3000/project/29/interface/api/857)
 *
 * @分类 [考勤管理↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_81)
 * @标签 `考勤管理`
 * @请求头 `GET /attendance/detail/employee/{teamEmployeeId}`
 * @更新时间 `2024-08-23 16:22:04`
 */

/* **请求params类型** */
export interface GetAttendanceDetailEmployeeByTeamEmployeeIdParams {
  /**
   * teamEmployeeId
   */
  teamEmployeeId: string
}

/* **返回类型** */
export interface GetAttendanceDetailEmployeeByTeamEmployeeIdResponse {
  /**
   * 旷工
   */
  absenteeismCount: number
  /**
   * 头像
   */
  avatar: string
  /**
   * 出勤天数
   */
  dayCount: number
  /**
   * 早退
   */
  earlyCount: number
  /**
   * 1:男，0:女
   */
  gender: number
  /**
   * 岗位名称
   */
  jobName: string
  /**
   * 缺卡
   */
  lackCardCount: number
  /**
   * 迟到
   */
  lateCount: number
  /**
   * 补卡
   */
  makeUpCardCount: number
  /**
   * 计件
   */
  piece: number
  teamEmployeeId: number
  teamId: number
  /**
   * 班组名称
   */
  teamName: string
  /**
   * 用户id
   */
  userId: number
  /**
   * 用户名
   */
  userName: string
  /**
   * 工时
   */
  workHour: string
}

/* **请求函数** */
export async function getAttendanceDetailEmployeeByTeamEmployeeId(
  params: GetAttendanceDetailEmployeeByTeamEmployeeIdParams,
): Promise<any> {
  return request(`/attendance/detail/employee/${params.teamEmployeeId}`, {
    method: Method.GET,
  })
}

/**
 * 接口 [20.1班组考勤列表-点击详情-概览↗](http://fe.jiuworker.com:3000/project/29/interface/api/859)
 *
 * @分类 [考勤管理↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_81)
 * @标签 `考勤管理`
 * @请求头 `GET /attendance/detail/team/{teamId}`
 * @更新时间 `2024-08-23 16:22:04`
 */

/* **请求params类型** */
export interface GetAttendanceDetailTeamByTeamIdParams {
  /**
   * teamId
   */
  teamId: string
}

/* **返回类型** */
export interface GetAttendanceDetailTeamByTeamIdResponse {
  /**
   * 旷工
   */
  absenteeismCount: number
  /**
   * 出勤人次
   */
  dayCount: number
  /**
   * 早退
   */
  earlyCount: number
  /**
   * 工时
   */
  employeeCount: number
  /**
   * 缺卡
   */
  lackCardCount: number
  /**
   * 迟到
   */
  lateCount: number
  /**
   * 补卡
   */
  makeUpCardCount: number
  /**
   * 计件
   */
  piece: number
  teamId: number
  /**
   * 班组名称
   */
  teamName: string
  /**
   * 总天数
   */
  totalDayCount: number
  /**
   * 工时
   */
  workHour: string
}

/* **请求函数** */
export async function getAttendanceDetailTeamByTeamId(params: GetAttendanceDetailTeamByTeamIdParams): Promise<any> {
  return request(`/attendance/detail/team/${params.teamId}`, {
    method: Method.GET,
  })
}

/**
 * 接口 [41.考勤调整记录↗](http://fe.jiuworker.com:3000/project/29/interface/api/861)
 *
 * @分类 [考勤管理↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_81)
 * @标签 `考勤管理`
 * @请求头 `GET /attendance/list/adjust/record`
 * @更新时间 `2024-08-23 16:22:04`
 */

/* **请求query类型** */
export interface GetAttendanceListAdjustRecordQuery {
  /**
   * endDate
   */
  endDate?: string
  /**
   * pageNum
   */
  pageNum?: number
  /**
   * pageSize
   */
  pageSize?: number
  /**
   * startDate
   */
  startDate?: string
  /**
   * teamId
   */
  teamId?: string
}

/* **返回类型** */
export interface GetAttendanceListAdjustRecordResponse {
  data: {
    adjustAmount: number
    adjustDesc: string
    /**
     * 调整时间
     */
    adjustTime: string
    id: number
    /**
     * 岗位名称
     */
    jobName: string
    /**
     * 备注
     */
    remark: string
    /**
     * 班组名称
     */
    teamName: string
    /**
     * 用户id
     */
    userId: number
    /**
     * 用户名称
     */
    userName: string
    valuationType: number
    /**
     * 工作日期
     */
    workDate: string
  }[]
  total: number
}

/* **请求函数** */
export async function getAttendanceListAdjustRecord(query: GetAttendanceListAdjustRecordQuery): Promise<any> {
  return request(`/attendance/list/adjust/record`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [13.员工考勤-点击详情-打卡记录列表↗](http://fe.jiuworker.com:3000/project/29/interface/api/863)
 *
 * @分类 [考勤管理↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_81)
 * @标签 `考勤管理`
 * @请求头 `GET /attendance/list/employee/record`
 * @更新时间 `2024-08-23 16:22:04`
 */

/* **请求query类型** */
export interface GetAttendanceListEmployeeRecordQuery {
  /**
   * pageNum
   */
  pageNum?: number
  /**
   * pageSize
   */
  pageSize?: number
  /**
   * teamEmployeeId
   */
  teamEmployeeId: string
}

/* **返回类型** */
export interface GetAttendanceListEmployeeRecordResponse {
  data: {
    /**
     * 工作时长/秒
     */
    attendanceTime: number
    /**
     * 工作日期
     */
    date: string
    /**
     * 下班时间
     */
    endTime: string
    id: number
    /**
     * 补卡状态 0未补卡 1早上补卡 2晚上补卡 3两次补卡
     */
    makeUpClock: number
    /**
     * 计件
     */
    piece: number
    /**
     * 上班时间
     */
    startTime: string
    /**
     * 状态 1:正常 2:迟到 3:早退 4:缺卡 5:旷工 6:迟到/缺卡 7:早退/缺卡 8:迟到/早退
     */
    status: number
    /**
     * 工作时长/小时
     */
    workHourStr: string
  }[]
  total: number
}

/* **请求函数** */
export async function getAttendanceListEmployeeRecord(query: GetAttendanceListEmployeeRecordQuery): Promise<any> {
  return request(`/attendance/list/employee/record`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [30.考勤月统计列表↗](http://fe.jiuworker.com:3000/project/29/interface/api/865)
 *
 * @分类 [考勤管理↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_81)
 * @标签 `考勤管理`
 * @请求头 `GET /attendance/list/month`
 * @更新时间 `2024-08-23 16:22:04`
 */

/* **请求query类型** */
export interface GetAttendanceListMonthQuery {
  /**
   * date
   */
  date: string
  /**
   * teamId
   */
  teamId?: string
}

/* **返回类型** */
export type GetAttendanceListMonthResponse = {
  /**
   * 旷工
   */
  absenteeismCount: number
  /**
   * 出勤人次
   */
  attendanceNum: number
  /**
   * 用户id
   */
  date: string
  /**
   * 早退
   */
  earlyCount: number
  /**
   * 缺卡
   */
  lackCardCount: number
  /**
   * 迟到
   */
  lateCount: number
  /**
   * 补卡
   */
  makeUpCardCount: number
  /**
   * 计件
   */
  piece: number
  teamId: string
  teamName: string
  /**
   * 工时
   */
  workHour: string
}[]

/* **请求函数** */
export async function getAttendanceListMonth(query: GetAttendanceListMonthQuery): Promise<any> {
  return request(`/attendance/list/month`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [40.考勤记录↗](http://fe.jiuworker.com:3000/project/29/interface/api/867)
 *
 * @分类 [考勤管理↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_81)
 * @标签 `考勤管理`
 * @请求头 `GET /attendance/list/record`
 * @更新时间 `2024-08-23 16:22:04`
 */

/* **请求query类型** */
export interface GetAttendanceListRecordQuery {
  /**
   * endDate
   */
  endDate?: string
  /**
   * pageNum
   */
  pageNum?: number
  /**
   * pageSize
   */
  pageSize?: number
  /**
   * startDate
   */
  startDate?: string
  /**
   * teamId
   */
  teamId?: string
  /**
   * userId
   */
  userId?: string
}

/* **返回类型** */
export interface GetAttendanceListRecordResponse {
  data: {
    /**
     * 打卡地址
     */
    address: string
    /**
     * 打卡时间
     */
    clockTime: string
    id: number
    /**
     * 班组人员id
     */
    teamEmployeeId: number
    /**
     * 班组id
     */
    teamId: number
    /**
     * 班组名称
     */
    teamName: string
    /**
     * 用户id
     */
    userId: number
    /**
     * 用户名称
     */
    userName: string
  }[]
  total: number
}

/* **请求函数** */
export async function getAttendanceListRecord(query: GetAttendanceListRecordQuery): Promise<any> {
  return request(`/attendance/list/record`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [20.班组考勤列表-班组维度统计↗](http://fe.jiuworker.com:3000/project/29/interface/api/869)
 *
 * @分类 [考勤管理↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_81)
 * @标签 `考勤管理`
 * @请求头 `GET /attendance/list/team`
 * @更新时间 `2024-08-23 16:22:04`
 */

/* **请求query类型** */
export interface GetAttendanceListTeamQuery {
  /**
   * endDate
   */
  endDate?: string
  /**
   * pageNum
   */
  pageNum?: number
  /**
   * pageSize
   */
  pageSize?: number
  /**
   * startDate
   */
  startDate?: string
  /**
   * 订单确认状态  10待客户确认考勤-暂不存在 0待考勤确认 1已确认
   */
  status?: string
  /**
   * teamId
   */
  teamId?: string
}

/* **返回类型** */
export interface GetAttendanceListTeamResponse {
  data: {
    /**
     * 旷工
     */
    absenteeismCount: number
    /**
     * 出勤人次
     */
    attendanceNum: number
    /**
     * 早退
     */
    earlyCount: number
    /**
     * 缺卡
     */
    lackCardCount: number
    /**
     * 迟到
     */
    lateCount: number
    /**
     * 补卡
     */
    makeUpCardCount: number
    orderId: number
    /**
     * 订单确认状态  10待客户确认考勤-暂不存在 0待考勤确认 1已确认
     */
    orderStatus: number
    /**
     * 计件
     */
    piece: number
    /**
     * 班组状态 1待开始 2进行中 3已结束 4已作废
     */
    status: number
    teamId: number
    /**
     * 班组名称
     */
    teamName: string
    /**
     * 总天数
     */
    totalDayCount: number
    /**
     * 工时
     */
    workHour: string
  }[]
  total: number
}

/* **请求函数** */
export async function getAttendanceListTeam(query: GetAttendanceListTeamQuery): Promise<any> {
  return request(`/attendance/list/team`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [2.每日考勤列表↗](http://fe.jiuworker.com:3000/project/29/interface/api/871)
 *
 * @分类 [考勤管理↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_81)
 * @标签 `考勤管理`
 * @请求头 `GET /attendance/list/team/daily`
 * @更新时间 `2024-08-23 16:22:04`
 */

/* **请求query类型** */
export interface GetAttendanceListTeamDailyQuery {
  /**
   * date
   */
  date: string
  /**
   * pageNum
   */
  pageNum?: number
  /**
   * pageSize
   */
  pageSize?: number
  /**
   * 1:正常 2:迟到 3:早退 4:缺卡 5:旷工
   */
  status?: string
  /**
   * teamId
   */
  teamId: string
}

/* **返回类型** */
export interface GetAttendanceListTeamDailyResponse {
  data: {
    attendanceId: number
    /**
     * 考勤状态 1:正常 2:迟到 3:早退 4:缺卡 5:旷工 6:迟到/缺卡 7:早退/缺卡 8:迟到/早退 9:未打卡
     */
    attendanceStatus: number
    /**
     * 头像
     */
    avatar: string
    /**
     * 下班打卡时间
     */
    endTime: string
    /**
     * 岗位名称
     */
    jobName: string
    /**
     * 是否补卡 1是0否
     */
    makeUpCard: number
    /**
     * 手机号
     */
    mobile: string
    /**
     * 计件
     */
    piece: number
    /**
     * 上班打卡时间
     */
    startTime: string
    /**
     * 雇员id
     */
    teamEmployeeId: number
    teamId: number
    /**
     * 用户id
     */
    userId: number
    /**
     * 用户名称
     */
    userName: string
    /**
     * 工作时长
     */
    workHour: string
  }[]
  total: number
}

/* **请求函数** */
export async function getAttendanceListTeamDaily(query: GetAttendanceListTeamDailyQuery): Promise<any> {
  return request(`/attendance/list/team/daily`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [10.员工考勤列表↗](http://fe.jiuworker.com:3000/project/29/interface/api/873)
 *
 * @分类 [考勤管理↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_81)
 * @标签 `考勤管理`
 * @请求头 `GET /attendance/list/team/employee`
 * @更新时间 `2024-08-23 16:22:05`
 */

/* **请求query类型** */
export interface GetAttendanceListTeamEmployeeQuery {
  /**
   * endDate
   */
  endDate?: string
  /**
   * jobId
   */
  jobId?: string
  /**
   * pageNum
   */
  pageNum?: number
  /**
   * pageSize
   */
  pageSize?: number
  /**
   * startDate
   */
  startDate?: string
  /**
   * teamId
   */
  teamId: string
}

/* **返回类型** */
export interface GetAttendanceListTeamEmployeeResponse {
  data: {
    /**
     * 旷工
     */
    absenteeismCount: number
    /**
     * 用户头像
     */
    avatar: string
    /**
     * 出勤天数
     */
    dayCount: number
    /**
     * 早退
     */
    earlyCount: number
    /**
     * teamEmployeeId
     */
    id: number
    jobName: string
    /**
     * 缺卡
     */
    lackCardCount: number
    /**
     * 迟到
     */
    lateCount: number
    /**
     * 补卡
     */
    makeUpCardCount: number
    mobile: string
    /**
     * 计件
     */
    piece: number
    teamEmployeeId: number
    /**
     * 总天数
     */
    totalDayCount: number
    userId: number
    userName: string
    /**
     * 工时
     */
    workHour: string
  }[]
  total: number
}

/* **请求函数** */
export async function getAttendanceListTeamEmployee(query: GetAttendanceListTeamEmployeeQuery): Promise<any> {
  return request(`/attendance/list/team/employee`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [11.员工考勤-班组岗位列表-筛选下拉↗](http://fe.jiuworker.com:3000/project/29/interface/api/875)
 *
 * @分类 [考勤管理↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_81)
 * @标签 `考勤管理`
 * @请求头 `GET /attendance/list/team/job`
 * @更新时间 `2024-08-23 16:22:05`
 */

/* **请求query类型** */
export interface GetAttendanceListTeamJobQuery {
  /**
   * teamId
   */
  teamId: string
}

/* **返回类型** */
export type GetAttendanceListTeamJobResponse = {
  /**
   * 最大年龄
   */
  ageMax: number
  /**
   * 最小年龄
   */
  ageMin: number
  femaleNum: number
  id: number
  /**
   * 岗位id-用工计划中
   */
  jobId: number
  maleNum: number
  /**
   * 岗位名称
   */
  name: string
  /**
   * 用工计划id
   */
  num: number
  /**
   * 其它要求-录用确认规则引擎
   */
  other: string
  /**
   * 单价
   */
  price: number
  rooms: {
    /**
     * 房间id
     */
    id: number
    name: string
    num: number
    originalPrice: number
    price: number
  }[]
  /**
   * 班组id
   */
  teamId: number
  /**
   * 计价方式 0:按小时 1:按天 2:按次
   */
  valuationType: number
}[]

/* **请求函数** */
export async function getAttendanceListTeamJob(query: GetAttendanceListTeamJobQuery): Promise<any> {
  return request(`/attendance/list/team/job`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [21.班组考勤列表-点击详情-指定班组列表↗](http://fe.jiuworker.com:3000/project/29/interface/api/877)
 *
 * @分类 [考勤管理↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_81)
 * @标签 `考勤管理`
 * @请求头 `GET /attendance/list/team/{teamId}`
 * @更新时间 `2024-08-23 16:22:05`
 */

/* **请求query类型** */
export interface GetAttendanceListTeamByTeamIdQuery {
  /**
   * pageNum
   */
  pageNum?: number
  /**
   * pageSize
   */
  pageSize?: number
}

/* **请求params类型** */
export interface GetAttendanceListTeamByTeamIdParams {
  /**
   * teamId
   */
  teamId: string
}

/* **返回类型** */
export interface GetAttendanceListTeamByTeamIdResponse {
  data: {
    /**
     * 旷工
     */
    absenteeismCount: number
    /**
     * 用户头像
     */
    avatar: string
    /**
     * 出勤天数
     */
    dayCount: number
    /**
     * 早退
     */
    earlyCount: number
    /**
     * teamEmployeeId
     */
    id: number
    jobName: string
    /**
     * 缺卡
     */
    lackCardCount: number
    /**
     * 迟到
     */
    lateCount: number
    /**
     * 补卡
     */
    makeUpCardCount: number
    mobile: string
    /**
     * 计件
     */
    piece: number
    teamEmployeeId: number
    /**
     * 总天数
     */
    totalDayCount: number
    userId: number
    userName: string
    /**
     * 工时
     */
    workHour: string
  }[]
  total: number
}

/* **请求函数** */
export async function getAttendanceListTeamByTeamId(
  query: GetAttendanceListTeamByTeamIdQuery,
  params: GetAttendanceListTeamByTeamIdParams,
): Promise<any> {
  return request(`/attendance/list/team/${params.teamId}`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [31.考勤月统计概览↗](http://fe.jiuworker.com:3000/project/29/interface/api/879)
 *
 * @分类 [考勤管理↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_81)
 * @标签 `考勤管理`
 * @请求头 `GET /attendance/month/overview`
 * @更新时间 `2024-08-23 16:22:05`
 */

/* **请求query类型** */
export interface GetAttendanceMonthOverviewQuery {
  /**
   * month
   */
  month: string
  /**
   * teamId
   */
  teamId?: string
}

/* **返回类型** */
export interface GetAttendanceMonthOverviewResponse {
  /**
   * 旷工
   */
  absenteeismCount: number
  /**
   * 出勤人次
   */
  attendanceNum: number
  /**
   * 早退
   */
  earlyCount: number
  /**
   * 缺卡
   */
  lackCardCount: number
  /**
   * 迟到
   */
  lateCount: number
  /**
   * 补卡
   */
  makeUpCardCount: number
  /**
   * 计件
   */
  piece: number
  /**
   * 工时
   */
  workHour: string
}

/* **请求函数** */
export async function getAttendanceMonthOverview(query: GetAttendanceMonthOverviewQuery): Promise<any> {
  return request(`/attendance/month/overview`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [2.每日考勤列表-修改记录↗](http://fe.jiuworker.com:3000/project/29/interface/api/881)
 *
 * @分类 [考勤管理↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_81)
 * @标签 `考勤管理`
 * @请求头 `GET /attendance/team/employee/modify`
 * @更新时间 `2024-08-23 16:22:05`
 */

/* **请求query类型** */
export interface GetAttendanceTeamEmployeeModifyQuery {
  /**
   * date
   */
  date: string
  /**
   * teamId
   */
  teamId: string
  /**
   * 1:考勤 2:计件
   */
  type?: string
  /**
   * userId
   */
  userId: string
}

/* **返回类型** */
export type GetAttendanceTeamEmployeeModifyResponse = {
  id: number
  /**
   * 补卡记录
   */
  modifyRecord: string
  /**
   * 原始记录
   */
  originalRecord: string
  /**
   * 备注
   */
  remark: string
}[]

/* **请求函数** */
export async function getAttendanceTeamEmployeeModify(query: GetAttendanceTeamEmployeeModifyQuery): Promise<any> {
  return request(`/attendance/team/employee/modify`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [100.添加考勤记录↗](http://fe.jiuworker.com:3000/project/29/interface/api/1591)
 *
 * @分类 [考勤管理↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_81)
 * @标签 `考勤管理`
 * @请求头 `POST /attendance/add`
 * @更新时间 `2024-08-23 16:22:04`
 */

/* **请求body类型** */
export interface PostAttendanceAddBody {
  /**
   * 考勤时间
   */
  attendanceDate?: string
  /**
   * 查询日期时间
   */
  dayDate?: string
  enterId?: number
  /**
   * 计件数量
   */
  itemCount?: number
  /**
   * 备注
   */
  remark?: string
  /**
   * 工作计划ID
   */
  teamId?: number
  /**
   * 员工ID
   */
  userIdList?: number[]
  workDay?: string
}

/* **返回类型** */
export type PostAttendanceAddResponse = boolean

/* **请求函数** */
export async function postAttendanceAdd(body: PostAttendanceAddBody): Promise<any> {
  return request(`/attendance/add`, {
    method: Method.POST,

    data: body,
  })
}

/**
 * 接口 [101.计件↗](http://fe.jiuworker.com:3000/project/29/interface/api/1593)
 *
 * @分类 [考勤管理↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_81)
 * @标签 `考勤管理`
 * @请求头 `POST /attendance/item/add`
 * @更新时间 `2024-08-23 16:22:04`
 */

/* **请求body类型** */
export interface PostAttendanceItemAddBody {
  /**
   * 考勤时间
   */
  attendanceDate?: string
  /**
   * 查询日期时间
   */
  dayDate?: string
  enterId?: number
  /**
   * 计件数量
   */
  itemCount?: number
  /**
   * 备注
   */
  remark?: string
  /**
   * 工作计划ID
   */
  teamId?: number
  /**
   * 员工ID
   */
  userIdList?: number[]
  workDay?: string
}

/* **返回类型** */
export type PostAttendanceItemAddResponse = boolean

/* **请求函数** */
export async function postAttendanceItemAdd(body: PostAttendanceItemAddBody): Promise<any> {
  return request(`/attendance/item/add`, {
    method: Method.POST,

    data: body,
  })
}

/**
 * 接口 [102.考勤调整↗](http://fe.jiuworker.com:3000/project/29/interface/api/1605)
 *
 * @分类 [考勤管理↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_81)
 * @标签 `考勤管理`
 * @请求头 `POST /attendance/adjust`
 * @更新时间 `2024-08-23 16:22:04`
 */

/* **请求body类型** */
export interface PostAttendanceAdjustBody {
  /**
   * 调整类型:10增加；20减少
   */
  adjustType?: number
  /**
   * 调整工时量/计件
   */
  amount?: number
  jobName?: string
  /**
   * 调整日前
   */
  modifyDate?: string
  /**
   * 备注
   */
  remark?: string
  /**
   * 班组ID
   */
  teamId?: number
  /**
   * 调整人员ID
   */
  userId?: number
  workDate?: string
}

/* **返回类型** */
export type PostAttendanceAdjustResponse = boolean

/* **请求函数** */
export async function postAttendanceAdjust(body: PostAttendanceAdjustBody): Promise<any> {
  return request(`/attendance/adjust`, {
    method: Method.POST,

    data: body,
  })
}

/**
 * 接口 [103.补卡记录↗](http://fe.jiuworker.com:3000/project/29/interface/api/1607)
 *
 * @分类 [考勤管理↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_81)
 * @标签 `考勤管理`
 * @请求头 `GET /attendance/repair/{teamId}`
 * @更新时间 `2024-08-23 16:22:05`
 */

/* **请求query类型** */
export interface GetAttendanceRepairByTeamIdQuery {
  /**
   * dayDate
   */
  dayDate?: string
  /**
   * name
   */
  name?: string
}

/* **请求params类型** */
export interface GetAttendanceRepairByTeamIdParams {
  /**
   * teamId
   */
  teamId: string
}

/* **返回类型** */
export type GetAttendanceRepairByTeamIdResponse = {
  /**
   * 部门
   */
  deptName: string
  id: number
  /**
   * 岗位
   */
  jobName: string
  /**
   * 姓名
   */
  name: string
  /**
   * 原记录
   */
  oldData: string
  /**
   * 备注
   */
  remark: string
  /**
   * 修改后记录
   */
  repairData: string
}[]

/* **请求函数** */
export async function getAttendanceRepairByTeamId(
  query: GetAttendanceRepairByTeamIdQuery,
  params: GetAttendanceRepairByTeamIdParams,
): Promise<any> {
  return request(`/attendance/repair/${params.teamId}`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [2.1.每日考勤列表-导出↗](http://fe.jiuworker.com:3000/project/29/interface/api/2001)
 *
 * @分类 [考勤管理↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_81)
 * @标签 `考勤管理`
 * @请求头 `GET /attendance/list/team/daily/export`
 * @更新时间 `2024-08-23 16:22:04`
 */

/* **请求query类型** */
export interface GetAttendanceListTeamDailyExportQuery {
  /**
   * date
   */
  date: string
  /**
   * 1:正常 2:迟到 3:早退 4:缺卡 5:旷工
   */
  status?: string
  /**
   * teamId
   */
  teamId: string
}

/* **返回类型** */
export type GetAttendanceListTeamDailyExportResponse = any

/* **请求函数** */
export async function getAttendanceListTeamDailyExport(query: GetAttendanceListTeamDailyExportQuery): Promise<any> {
  return request(`/attendance/list/team/daily/export`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [2.2.每月考勤列表-导出↗](http://fe.jiuworker.com:3000/project/29/interface/api/2083)
 *
 * @分类 [考勤管理↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_81)
 * @标签 `考勤管理`
 * @请求头 `GET /attendance/list/team/month/export`
 * @更新时间 `2024-08-23 16:22:05`
 */

/* **请求query类型** */
export interface GetAttendanceListTeamMonthExportQuery {
  /**
   * yyyy-MM-dd
   */
  date?: string
  /**
   * teamId
   */
  teamId: string
}

/* **返回类型** */
export type GetAttendanceListTeamMonthExportResponse = any

/* **请求函数** */
export async function getAttendanceListTeamMonthExport(query: GetAttendanceListTeamMonthExportQuery): Promise<any> {
  return request(`/attendance/list/team/month/export`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [新增排班↗](http://fe.jiuworker.com:3000/project/29/interface/api/1479)
 *
 * @分类 [职工部门模块/排班、节假日、打卡记录↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_105)
 * @标签 `职工部门模块/排班、节假日、打卡记录`
 * @请求头 `POST /dept/arrange/add`
 * @更新时间 `2024-08-23 16:22:05`
 */

/* **请求body类型** */
export interface PostDeptArrangeAddBody {
  /**
   * 打卡设备
   */
  deviceIdList?: number[]
  /**
   * 生效：10立即生效；20明日生效
   */
  effectType?: number
  /**
   * 结束时间
   */
  endDate?: string
  /**
   * 考勤对象
   */
  enterDeptArrangeTargetDTOList?: {
    /**
     * 考勤对象类型：10部门；20人员
     */
    arrangeType?: number
    /**
     * 如果对象是人员:人员所属部门ID
     */
    deptIdList?: number[]
    /**
     * 考勤对象
     */
    id?: number
    /**
     * 是否需要覆盖
     */
    isNeedOver?: boolean
  }[]
  /**
   * 班次设置
   */
  enterDeptArrangeTimeDTOList?: {
    /**
     * 班次ID，不传代表休息
     */
    deptTeamId?: number
    name?: string
    /**
     * 如果是固定班：1代表周一，2代表周二....；如果是轮班：1代表第一天啊，2代表第二天......
     */
    orderNo?: number
  }[]
  enterId?: number
  id?: number
  /**
   * 排班名称
   */
  name?: string
  /**
   * 10:酒店；20劳务
   */
  sourceType?: number
  /**
   * 开始时间
   */
  startDate?: string
  /**
   * 考勤类型：10固定；20轮班
   */
  type?: number
}

/* **返回类型** */
export type PostDeptArrangeAddResponse = boolean

/* **请求函数** */
export async function postDeptArrangeAdd(body: PostDeptArrangeAddBody): Promise<any> {
  return request(`/dept/arrange/add`, {
    method: Method.POST,

    data: body,
  })
}

/**
 * 接口 [主动新增考勤↗](http://fe.jiuworker.com:3000/project/29/interface/api/1481)
 *
 * @分类 [职工部门模块/排班、节假日、打卡记录↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_105)
 * @标签 `职工部门模块/排班、节假日、打卡记录`
 * @请求头 `PUT /dept/arrange/again/auth`
 * @更新时间 `2024-08-23 16:22:05`
 */

/* **请求body类型** */
export interface PutDeptArrangeAgainAuthBody {
  /**
   * 设备列表
   */
  deviceIdList?: number[]
  /**
   * 授权对象
   */
  enterDeptArrangeTargetDTOList?: {
    /**
     * 考勤对象类型：10部门；20人员
     */
    arrangeType?: number
    /**
     * 如果对象是人员:人员所属部门ID
     */
    deptIdList?: number[]
    /**
     * 考勤对象
     */
    id?: number
    /**
     * 是否需要覆盖
     */
    isNeedOver?: boolean
  }[]
  enterId?: number
  /**
   * 10:酒店；20劳务
   */
  sourceType?: number
}

/* **返回类型** */
export type PutDeptArrangeAgainAuthResponse = boolean

/* **请求函数** */
export async function putDeptArrangeAgainAuth(body: PutDeptArrangeAgainAuthBody): Promise<any> {
  return request(`/dept/arrange/again/auth`, {
    method: Method.PUT,

    data: body,
  })
}

/**
 * 接口 [重新授权↗](http://fe.jiuworker.com:3000/project/29/interface/api/1483)
 *
 * @分类 [职工部门模块/排班、节假日、打卡记录↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_105)
 * @标签 `职工部门模块/排班、节假日、打卡记录`
 * @请求头 `PUT /dept/arrange/again/auth/{id}`
 * @更新时间 `2024-08-23 16:22:06`
 */

/* **请求params类型** */
export interface PutDeptArrangeAgainAuthByIdParams {
  /**
   * id
   */
  id: string
}

/* **返回类型** */
export type PutDeptArrangeAgainAuthByIdResponse = boolean

/* **请求函数** */
export async function putDeptArrangeAgainAuthById(params: PutDeptArrangeAgainAuthByIdParams): Promise<any> {
  return request(`/dept/arrange/again/auth/${params.id}`, {
    method: Method.PUT,
  })
}

/**
 * 接口 [排班下的人员↗](http://fe.jiuworker.com:3000/project/29/interface/api/1485)
 *
 * @分类 [职工部门模块/排班、节假日、打卡记录↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_105)
 * @标签 `职工部门模块/排班、节假日、打卡记录`
 * @请求头 `GET /dept/arrange/attendance/person/{id}`
 * @更新时间 `2024-08-23 16:22:06`
 */

/* **请求query类型** */
export interface GetDeptArrangeAttendancePersonByIdQuery {
  /**
   * name
   */
  name?: string
  /**
   * pageNum
   */
  pageNum?: number
  /**
   * pageSize
   */
  pageSize?: number
}

/* **请求params类型** */
export interface GetDeptArrangeAttendancePersonByIdParams {
  /**
   * id
   */
  id: string
}

/* **返回类型** */
export interface GetDeptArrangeAttendancePersonByIdResponse {
  data: {
    /**
     * 考勤
     */
    attendanceName: string
    /**
     * 所属部门
     */
    deptName: string
    /**
     * 邮箱
     */
    email: string
    /**
     * 人脸照
     */
    faceUrl: string
    id: number
    /**
     * 岗位
     */
    job: string
    /**
     * 名称
     */
    name: string
    /**
     * 手机号
     */
    phone: string
    /**
     * 性别
     */
    sex: string
    /**
     * 工号
     */
    workNo: string
  }[]
  total: number
}

/* **请求函数** */
export async function getDeptArrangeAttendancePersonById(
  query: GetDeptArrangeAttendancePersonByIdQuery,
  params: GetDeptArrangeAttendancePersonByIdParams,
): Promise<any> {
  return request(`/dept/arrange/attendance/person/${params.id}`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [职工授权列表↗](http://fe.jiuworker.com:3000/project/29/interface/api/1487)
 *
 * @分类 [职工部门模块/排班、节假日、打卡记录↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_105)
 * @标签 `职工部门模块/排班、节假日、打卡记录`
 * @请求头 `GET /dept/arrange/auth/person`
 * @更新时间 `2024-08-23 16:22:06`
 */

/* **请求query类型** */
export interface GetDeptArrangeAuthPersonQuery {
  /**
   * deviceName
   */
  deviceName?: string
  /**
   * deviceNo
   */
  deviceNo?: string
  /**
   * name
   */
  name?: string
  /**
   * pageNum
   */
  pageNum?: number
  /**
   * pageSize
   */
  pageSize?: number
}

/* **返回类型** */
export interface GetDeptArrangeAuthPersonResponse {
  data: {
    /**
     * 设备名称
     */
    deviceName: string
    /**
     * 性别
     */
    deviceNo: string
    /**
     * 人脸照
     */
    faceUrl: string
    /**
     * 操作时间
     */
    gmtModified: string
    id: number
    /**
     * 姓名
     */
    name: string
    /**
     * 状态：10:授权中；20授权成功；30授权失败
     */
    status: number
  }[]
  total: number
}

/* **请求函数** */
export async function getDeptArrangeAuthPerson(query: GetDeptArrangeAuthPersonQuery): Promise<any> {
  return request(`/dept/arrange/auth/person`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [取消授权↗](http://fe.jiuworker.com:3000/project/29/interface/api/1489)
 *
 * @分类 [职工部门模块/排班、节假日、打卡记录↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_105)
 * @标签 `职工部门模块/排班、节假日、打卡记录`
 * @请求头 `PUT /dept/arrange/cancel/auth`
 * @更新时间 `2024-08-23 16:22:06`
 */

/* **请求query类型** */
export interface PutDeptArrangeCancelAuthQuery {
  /**
   * idList
   */
  idList: string
}

/* **返回类型** */
export type PutDeptArrangeCancelAuthResponse = boolean

/* **请求函数** */
export async function putDeptArrangeCancelAuth(query: PutDeptArrangeCancelAuthQuery): Promise<any> {
  return request(`/dept/arrange/cancel/auth`, {
    method: Method.PUT,
    params: query,
  })
}

/**
 * 接口 [校验勾选的部门或人员是否有其他考勤组↗](http://fe.jiuworker.com:3000/project/29/interface/api/1491)
 *
 * @分类 [职工部门模块/排班、节假日、打卡记录↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_105)
 * @标签 `职工部门模块/排班、节假日、打卡记录`
 * @请求头 `POST /dept/arrange/check`
 * @更新时间 `2024-08-23 16:22:06`
 */

/* **请求body类型** */
export interface PostDeptArrangeCheckBody {
  /**
   * 考勤对象
   */
  enterDeptArrangeTargetDTOList?: {
    /**
     * 考勤对象类型：10部门；20人员
     */
    arrangeType?: number
    /**
     * 如果对象是人员:人员所属部门ID
     */
    deptIdList?: number[]
    /**
     * 考勤对象
     */
    id?: number
    /**
     * 是否需要覆盖
     */
    isNeedOver?: boolean
  }[]
  enterId?: number
  id?: number
  /**
   * 10:酒店；20劳务
   */
  sourceType?: number
}

/* **返回类型** */
export type PostDeptArrangeCheckResponse = {
  arrangeType: number
  /**
   * 当前考勤排班
   */
  attendanceName: string
  /**
   * 所属部门
   */
  deptName: string
  id: number
  /**
   * 人员或部门
   */
  name: string
  orderNo: number
}[]

/* **请求函数** */
export async function postDeptArrangeCheck(body: PostDeptArrangeCheckBody): Promise<any> {
  return request(`/dept/arrange/check`, {
    method: Method.POST,

    data: body,
  })
}

/**
 * 接口 [排班删除↗](http://fe.jiuworker.com:3000/project/29/interface/api/1493)
 *
 * @分类 [职工部门模块/排班、节假日、打卡记录↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_105)
 * @标签 `职工部门模块/排班、节假日、打卡记录`
 * @请求头 `DELETE /dept/arrange/delete/{id}`
 * @更新时间 `2024-08-23 16:22:06`
 */

/* **请求params类型** */
export interface DeleteDeptArrangeDeleteByIdParams {
  /**
   * id
   */
  id: string
}

/* **返回类型** */
export type DeleteDeptArrangeDeleteByIdResponse = boolean

/* **请求函数** */
export async function deleteDeptArrangeDeleteById(params: DeleteDeptArrangeDeleteByIdParams): Promise<any> {
  return request(`/dept/arrange/delete/${params.id}`, {
    method: Method.DELETE,
  })
}

/**
 * 接口 [编辑排班↗](http://fe.jiuworker.com:3000/project/29/interface/api/1495)
 *
 * @分类 [职工部门模块/排班、节假日、打卡记录↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_105)
 * @标签 `职工部门模块/排班、节假日、打卡记录`
 * @请求头 `PUT /dept/arrange/edit`
 * @更新时间 `2024-08-23 16:22:06`
 */

/* **请求body类型** */
export interface PutDeptArrangeEditBody {
  /**
   * 打卡设备
   */
  deviceIdList?: number[]
  /**
   * 生效：10立即生效；20明日生效
   */
  effectType?: number
  /**
   * 结束时间
   */
  endDate?: string
  /**
   * 考勤对象
   */
  enterDeptArrangeTargetDTOList?: {
    /**
     * 考勤对象类型：10部门；20人员
     */
    arrangeType?: number
    /**
     * 如果对象是人员:人员所属部门ID
     */
    deptIdList?: number[]
    /**
     * 考勤对象
     */
    id?: number
    /**
     * 是否需要覆盖
     */
    isNeedOver?: boolean
  }[]
  /**
   * 班次设置
   */
  enterDeptArrangeTimeDTOList?: {
    /**
     * 班次ID，不传代表休息
     */
    deptTeamId?: number
    name?: string
    /**
     * 如果是固定班：1代表周一，2代表周二....；如果是轮班：1代表第一天啊，2代表第二天......
     */
    orderNo?: number
  }[]
  enterId?: number
  id?: number
  /**
   * 排班名称
   */
  name?: string
  /**
   * 10:酒店；20劳务
   */
  sourceType?: number
  /**
   * 开始时间
   */
  startDate?: string
  /**
   * 考勤类型：10固定；20轮班
   */
  type?: number
}

/* **返回类型** */
export type PutDeptArrangeEditResponse = boolean

/* **请求函数** */
export async function putDeptArrangeEdit(body: PutDeptArrangeEditBody): Promise<any> {
  return request(`/dept/arrange/edit`, {
    method: Method.PUT,

    data: body,
  })
}

/**
 * 接口 [新增假日↗](http://fe.jiuworker.com:3000/project/29/interface/api/1497)
 *
 * @分类 [职工部门模块/排班、节假日、打卡记录↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_105)
 * @标签 `职工部门模块/排班、节假日、打卡记录`
 * @请求头 `POST /dept/arrange/holiday/add`
 * @更新时间 `2024-08-23 16:22:06`
 */

/* **请求body类型** */
export interface PostDeptArrangeHolidayAddBody {
  /**
   * 结束时间
   */
  endDate?: string
  enterId?: number
  id?: number
  /**
   * 假期名称
   */
  name?: string
  /**
   * 10:酒店；20劳务
   */
  sourceType?: number
  /**
   * 开始时间
   */
  startDate?: string
}

/* **返回类型** */
export type PostDeptArrangeHolidayAddResponse = boolean

/* **请求函数** */
export async function postDeptArrangeHolidayAdd(body: PostDeptArrangeHolidayAddBody): Promise<any> {
  return request(`/dept/arrange/holiday/add`, {
    method: Method.POST,

    data: body,
  })
}

/**
 * 接口 [假期删除↗](http://fe.jiuworker.com:3000/project/29/interface/api/1499)
 *
 * @分类 [职工部门模块/排班、节假日、打卡记录↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_105)
 * @标签 `职工部门模块/排班、节假日、打卡记录`
 * @请求头 `DELETE /dept/arrange/holiday/delete/{id}`
 * @更新时间 `2024-08-23 16:22:06`
 */

/* **请求params类型** */
export interface DeleteDeptArrangeHolidayDeleteByIdParams {
  /**
   * id
   */
  id: string
}

/* **返回类型** */
export type DeleteDeptArrangeHolidayDeleteByIdResponse = boolean

/* **请求函数** */
export async function deleteDeptArrangeHolidayDeleteById(
  params: DeleteDeptArrangeHolidayDeleteByIdParams,
): Promise<any> {
  return request(`/dept/arrange/holiday/delete/${params.id}`, {
    method: Method.DELETE,
  })
}

/**
 * 接口 [假期查询↗](http://fe.jiuworker.com:3000/project/29/interface/api/1501)
 *
 * @分类 [职工部门模块/排班、节假日、打卡记录↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_105)
 * @标签 `职工部门模块/排班、节假日、打卡记录`
 * @请求头 `GET /dept/arrange/holiday/detail/{id}`
 * @更新时间 `2024-08-23 16:22:06`
 */

/* **请求params类型** */
export interface GetDeptArrangeHolidayDetailByIdParams {
  /**
   * id
   */
  id: string
}

/* **返回类型** */
export interface GetDeptArrangeHolidayDetailByIdResponse {
  bizData: string
  enterId: number
  gmtCreate: string
  gmtModified: string
  id: number
  name: string
  status: number
}

/* **请求函数** */
export async function getDeptArrangeHolidayDetailById(params: GetDeptArrangeHolidayDetailByIdParams): Promise<any> {
  return request(`/dept/arrange/holiday/detail/${params.id}`, {
    method: Method.GET,
  })
}

/**
 * 接口 [编辑假期↗](http://fe.jiuworker.com:3000/project/29/interface/api/1503)
 *
 * @分类 [职工部门模块/排班、节假日、打卡记录↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_105)
 * @标签 `职工部门模块/排班、节假日、打卡记录`
 * @请求头 `PUT /dept/arrange/holiday/edit`
 * @更新时间 `2024-08-23 16:22:06`
 */

/* **请求body类型** */
export interface PutDeptArrangeHolidayEditBody {
  /**
   * 结束时间
   */
  endDate?: string
  enterId?: number
  id?: number
  /**
   * 假期名称
   */
  name?: string
  /**
   * 10:酒店；20劳务
   */
  sourceType?: number
  /**
   * 开始时间
   */
  startDate?: string
}

/* **返回类型** */
export type PutDeptArrangeHolidayEditResponse = boolean

/* **请求函数** */
export async function putDeptArrangeHolidayEdit(body: PutDeptArrangeHolidayEditBody): Promise<any> {
  return request(`/dept/arrange/holiday/edit`, {
    method: Method.PUT,

    data: body,
  })
}

/**
 * 接口 [分页查询记录↗](http://fe.jiuworker.com:3000/project/29/interface/api/1505)
 *
 * @分类 [职工部门模块/排班、节假日、打卡记录↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_105)
 * @标签 `职工部门模块/排班、节假日、打卡记录`
 * @请求头 `GET /dept/arrange/holiday/page`
 * @更新时间 `2024-08-23 16:22:06`
 */

/* **请求query类型** */
export interface GetDeptArrangeHolidayPageQuery {
  /**
   * 部门
   */
  deptId?: string
  /**
   * endDate
   */
  endDate?: string
  /**
   * 姓名
   */
  name?: string
  /**
   * pageNum
   */
  pageNum?: number
  /**
   * pageSize
   */
  pageSize?: number
  /**
   * startDate
   */
  startDate?: string
  /**
   * 是否展示子部门成员
   */
  subPerson?: string
}

/* **返回类型** */
export interface GetDeptArrangeHolidayPageResponse {
  data: {
    /**
     * 部门
     */
    deptName: string
    /**
     * 打卡时间
     */
    gmtCreate: string
    id: number
    /**
     * 岗位
     */
    job: string
    /**
     * 姓名
     */
    name: string
    /**
     * 打卡地点
     */
    placeName: string
  }[]
  total: number
}

/* **请求函数** */
export async function getDeptArrangeHolidayPage(query: GetDeptArrangeHolidayPageQuery): Promise<any> {
  return request(`/dept/arrange/holiday/page`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [假期查询↗](http://fe.jiuworker.com:3000/project/29/interface/api/1507)
 *
 * @分类 [职工部门模块/排班、节假日、打卡记录↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_105)
 * @标签 `职工部门模块/排班、节假日、打卡记录`
 * @请求头 `GET /dept/arrange/holiday/query`
 * @更新时间 `2024-08-23 16:22:06`
 */

/* **返回类型** */
export type GetDeptArrangeHolidayQueryResponse = {
  bizData: string
  enterId: number
  gmtCreate: string
  gmtModified: string
  id: number
  name: string
  status: number
}[]

/* **请求函数** */
export async function getDeptArrangeHolidayQuery(): Promise<any> {
  return request(`/dept/arrange/holiday/query`, {
    method: Method.GET,
  })
}

/**
 * 接口 [假期启用禁用↗](http://fe.jiuworker.com:3000/project/29/interface/api/1509)
 *
 * @分类 [职工部门模块/排班、节假日、打卡记录↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_105)
 * @标签 `职工部门模块/排班、节假日、打卡记录`
 * @请求头 `PUT /dept/arrange/holiday/status/{id}`
 * @更新时间 `2024-08-23 16:22:06`
 */

/* **请求query类型** */
export interface PutDeptArrangeHolidayStatusByIdQuery {
  /**
   * 10:启用；20禁用
   */
  status?: string
}

/* **请求params类型** */
export interface PutDeptArrangeHolidayStatusByIdParams {
  /**
   * id
   */
  id: string
}

/* **返回类型** */
export type PutDeptArrangeHolidayStatusByIdResponse = boolean

/* **请求函数** */
export async function putDeptArrangeHolidayStatusById(
  query: PutDeptArrangeHolidayStatusByIdQuery,
  params: PutDeptArrangeHolidayStatusByIdParams,
): Promise<any> {
  return request(`/dept/arrange/holiday/status/${params.id}`, {
    method: Method.PUT,
    params: query,
  })
}

/**
 * 接口 [排班查询↗](http://fe.jiuworker.com:3000/project/29/interface/api/1511)
 *
 * @分类 [职工部门模块/排班、节假日、打卡记录↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_105)
 * @标签 `职工部门模块/排班、节假日、打卡记录`
 * @请求头 `GET /dept/arrange/query`
 * @更新时间 `2024-08-23 16:22:06`
 */

/* **返回类型** */
export type GetDeptArrangeQueryResponse = {
  /**
   * 考情规则
   */
  enterDeptArrangeTimeDTOList: {
    /**
     * 班次ID，不传代表休息
     */
    deptTeamId: number
    name: string
    /**
     * 如果是固定班：1代表周一，2代表周二....；如果是轮班：1代表第一天啊，2代表第二天......
     */
    orderNo: number
  }[]
  id: number
  /**
   * 排班名称
   */
  name: string
  /**
   * 人数
   */
  personCount: number
}[]

/* **请求函数** */
export async function getDeptArrangeQuery(): Promise<any> {
  return request(`/dept/arrange/query`, {
    method: Method.GET,
  })
}

/**
 * 接口 [排班详情↗](http://fe.jiuworker.com:3000/project/29/interface/api/1513)
 *
 * @分类 [职工部门模块/排班、节假日、打卡记录↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_105)
 * @标签 `职工部门模块/排班、节假日、打卡记录`
 * @请求头 `GET /dept/arrange/query/detail/{id}`
 * @更新时间 `2024-08-23 16:22:06`
 */

/* **请求params类型** */
export interface GetDeptArrangeQueryDetailByIdParams {
  /**
   * id
   */
  id: string
}

/* **返回类型** */
export interface GetDeptArrangeQueryDetailByIdResponse {
  /**
   * 打卡设备
   */
  deviceIdList: number[]
  /**
   * 生效：10立即生效；20明日生效
   */
  effectType: number
  /**
   * 结束时间
   */
  endDate: string
  /**
   * 考勤对象
   */
  enterDeptArrangeTargetDTOList: {
    /**
     * 考勤对象类型：10部门；20人员
     */
    arrangeType: number
    /**
     * 如果对象是人员:人员所属部门ID
     */
    deptIdList: number[]
    /**
     * 考勤对象
     */
    id: number
    /**
     * 是否需要覆盖
     */
    isNeedOver: boolean
  }[]
  /**
   * 班次设置
   */
  enterDeptArrangeTimeDTOList: {
    /**
     * 班次ID，不传代表休息
     */
    deptTeamId: number
    name: string
    /**
     * 如果是固定班：1代表周一，2代表周二....；如果是轮班：1代表第一天啊，2代表第二天......
     */
    orderNo: number
  }[]
  id: number
  /**
   * 排班名称
   */
  name: string
  /**
   * 开始时间
   */
  startDate: string
  /**
   * 考勤类型：10固定；20轮班
   */
  type: number
}

/* **请求函数** */
export async function getDeptArrangeQueryDetailById(params: GetDeptArrangeQueryDetailByIdParams): Promise<any> {
  return request(`/dept/arrange/query/detail/${params.id}`, {
    method: Method.GET,
  })
}

/**
 * 接口 [部门列表(包含人员)↗](http://fe.jiuworker.com:3000/project/29/interface/api/1515)
 *
 * @分类 [职工部门模块/排班、节假日、打卡记录↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_105)
 * @标签 `职工部门模块/排班、节假日、打卡记录`
 * @请求头 `GET /dept/arrange/query/person`
 * @更新时间 `2024-08-23 16:22:06`
 */

/* **返回类型** */
export type GetDeptArrangeQueryPersonResponse = {
  id: number
  /**
   * 关键字
   */
  key: string
  menuVOList: {}[]
  /**
   * 名称
   */
  name: string
  /**
   * 上级ID
   */
  parentId: number
  /**
   * 人员数量
   */
  personCount: number
  /**
   * 人员列表
   */
  personPOList: {
    aiotId: string
    bizData: string
    enterId: number
    gmtCreate: string
    gmtModified: string
    id: number
    name: string
  }[]
}[]

/* **请求函数** */
export async function getDeptArrangeQueryPerson(): Promise<any> {
  return request(`/dept/arrange/query/person`, {
    method: Method.GET,
  })
}

/**
 * 接口 [添加设备↗](http://fe.jiuworker.com:3000/project/29/interface/api/1563)
 *
 * @分类 [设备管理↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_109)
 * @标签 `设备管理`
 * @请求头 `POST /device/add`
 * @更新时间 `2024-08-23 16:22:07`
 */

/* **请求body类型** */
export interface PostDeviceAddBody {
  deviceName?: string
  deviceNo?: string
  enterId?: number
  /**
   * 10:酒店；20劳务
   */
  sourceType?: number
}

/* **返回类型** */
export type PostDeviceAddResponse = boolean

/* **请求函数** */
export async function postDeviceAdd(body: PostDeviceAddBody): Promise<any> {
  return request(`/device/add`, {
    method: Method.POST,

    data: body,
  })
}

/**
 * 接口 [删除设备↗](http://fe.jiuworker.com:3000/project/29/interface/api/1565)
 *
 * @分类 [设备管理↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_109)
 * @标签 `设备管理`
 * @请求头 `DELETE /device/delete/{id}`
 * @更新时间 `2024-08-23 16:22:07`
 */

/* **请求params类型** */
export interface DeleteDeviceDeleteByIdParams {
  /**
   * id
   */
  id: string
}

/* **返回类型** */
export type DeleteDeviceDeleteByIdResponse = boolean

/* **请求函数** */
export async function deleteDeviceDeleteById(params: DeleteDeviceDeleteByIdParams): Promise<any> {
  return request(`/device/delete/${params.id}`, {
    method: Method.DELETE,
  })
}

/**
 * 接口 [编辑设备↗](http://fe.jiuworker.com:3000/project/29/interface/api/1567)
 *
 * @分类 [设备管理↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_109)
 * @标签 `设备管理`
 * @请求头 `GET /device/edit/{id}`
 * @更新时间 `2024-08-23 16:22:07`
 */

/* **请求query类型** */
export interface GetDeviceEditByIdQuery {
  /**
   * deviceName
   */
  deviceName: string
}

/* **请求params类型** */
export interface GetDeviceEditByIdParams {
  /**
   * id
   */
  id: string
}

/* **返回类型** */
export type GetDeviceEditByIdResponse = boolean

/* **请求函数** */
export async function getDeviceEditById(query: GetDeviceEditByIdQuery, params: GetDeviceEditByIdParams): Promise<any> {
  return request(`/device/edit/${params.id}`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [分页查询设备↗](http://fe.jiuworker.com:3000/project/29/interface/api/1569)
 *
 * @分类 [设备管理↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_109)
 * @标签 `设备管理`
 * @请求头 `GET /device/query`
 * @更新时间 `2024-08-23 16:22:07`
 */

/* **请求query类型** */
export interface GetDeviceQueryQuery {
  /**
   * deviceName
   */
  deviceName?: string
  /**
   * deviceNo
   */
  deviceNo?: string
  /**
   * pageNum
   */
  pageNum?: number
  /**
   * pageSize
   */
  pageSize?: number
}

/* **返回类型** */
export interface GetDeviceQueryResponse {
  data: {
    /**
     * 设备名称
     */
    deviceName: string
    /**
     * 设备序列号
     */
    deviceNo: string
    id: number
    /**
     * 状态:10在线；20不在线
     */
    status: number
  }[]
  total: number
}

/* **请求函数** */
export async function getDeviceQuery(query: GetDeviceQueryQuery): Promise<any> {
  return request(`/device/query`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [新增授权↗](http://fe.jiuworker.com:3000/project/29/interface/api/1575)
 *
 * @分类 [设备管理↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_109)
 * @标签 `设备管理`
 * @请求头 `POST /device/add/auth`
 * @更新时间 `2024-08-23 16:22:07`
 */

/* **请求body类型** */
export interface PostDeviceAddAuthBody {
  /**
   * 设备列表
   */
  deviceIdList?: number[]
  /**
   * 授权数据列表
   */
  enterDeviceAuthDetailDTOList?: {
    /**
     * 数据类型：10:人员；20用工计划
     */
    dataType?: number
    /**
     * 数据id
     */
    id?: number
  }[]
}

/* **返回类型** */
export type PostDeviceAddAuthResponse = boolean

/* **请求函数** */
export async function postDeviceAddAuth(body: PostDeviceAddAuthBody): Promise<any> {
  return request(`/device/add/auth`, {
    method: Method.POST,

    data: body,
  })
}

/**
 * 接口 [重新授权↗](http://fe.jiuworker.com:3000/project/29/interface/api/1577)
 *
 * @分类 [设备管理↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_109)
 * @标签 `设备管理`
 * @请求头 `PUT /device/again/auth/{id}`
 * @更新时间 `2024-08-23 16:22:07`
 */

/* **请求params类型** */
export interface PutDeviceAgainAuthByIdParams {
  /**
   * id
   */
  id: string
}

/* **返回类型** */
export type PutDeviceAgainAuthByIdResponse = boolean

/* **请求函数** */
export async function putDeviceAgainAuthById(params: PutDeviceAgainAuthByIdParams): Promise<any> {
  return request(`/device/again/auth/${params.id}`, {
    method: Method.PUT,
  })
}

/**
 * 接口 [用工计划列表↗](http://fe.jiuworker.com:3000/project/29/interface/api/1579)
 *
 * @分类 [设备管理↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_109)
 * @标签 `设备管理`
 * @请求头 `GET /device/auth/plan`
 * @更新时间 `2024-08-23 16:22:07`
 */

/* **请求query类型** */
export interface GetDeviceAuthPlanQuery {
  /**
   * pageNum
   */
  pageNum?: number
  /**
   * pageSize
   */
  pageSize?: number
  /**
   * planName
   */
  planName?: string
}

/* **返回类型** */
export interface GetDeviceAuthPlanResponse {
  data: {
    /**
     * 联系人
     */
    contractPerson: string
    /**
     * 联系人电话
     */
    contractPhone: string
    /**
     * 自动授权的设备列表
     */
    deviceIdList: {
      deviceId: number
      deviceName: string
    }[]
    /**
     * 结束时间
     */
    endDate: string
    /**
     * 抢单截止时间
     */
    endDateOrderHall: string
    /**
     * 结束用工时段
     */
    endHour: string
    /**
     * 岗位需求
     */
    enterWorkApplyJobDTOList: {
      /**
       * 数量
       */
      amount: number
      /**
       * 其他业务字段
       */
      bizData: string
      /**
       * 假期岗位单价
       */
      holidayMoney: number
      /**
       * 岗位id
       */
      jobId: number
      /**
       * 岗位名称
       */
      jobName: string
      /**
       * 男数量
       */
      manNumber: number
      maxAge: string
      /**
       * 岗位单价
       */
      money: number
      /**
       * 客房量
       */
      roomInfoList: {
        currentMoney: number
        holidayMoney: number
        money: number
        roomAmount: number
        roomTypeName: string
        unitMoney: string
        weekendMoney: number
      }[]
      /**
       * 单位
       */
      unit: string
      /**
       * 女数量
       */
      womanNumber: number
    }[]
    /**
     * 附件
     */
    fileUrlList: string[]
    /**
     * 是否为节假日标准计价:true是；false否
     */
    holidayType: boolean
    id: number
    /**
     * 是否自动授权:true是；false否
     */
    isAutoAuth: boolean
    isOver: boolean
    isOverNight: boolean
    /**
     * 是否发送至抢单大厅
     */
    isSendOrderHall: boolean
    /**
     * 劳务公司
     */
    laborId: number
    /**
     * 劳务状态：10计划申请取消中；20申请取消已被拒绝
     */
    laborStatus: number
    /**
     * 计价方式：10小时；20按天；30按计件
     */
    moneyType: number
    oldRoomAccount: number
    /**
     * 计划名称
     */
    planName: string
    relateWorkId: number
    /**
     * 休息时长
     */
    relaxTime: number
    /**
     * 取消原因
     */
    remark: string
    /**
     * 开始时间
     */
    startDate: string
    /**
     * 开始用工时段
     */
    startHour: string
    /**
     * 用工负责人
     */
    workPlanLeader: number
  }[]
  total: number
}

/* **请求函数** */
export async function getDeviceAuthPlan(query: GetDeviceAuthPlanQuery): Promise<any> {
  return request(`/device/auth/plan`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [授权人员列表↗](http://fe.jiuworker.com:3000/project/29/interface/api/1581)
 *
 * @分类 [设备管理↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_109)
 * @标签 `设备管理`
 * @请求头 `GET /device/auth/user`
 * @更新时间 `2024-08-23 16:22:07`
 */

/* **请求query类型** */
export interface GetDeviceAuthUserQuery {
  /**
   * pageNum
   */
  pageNum?: number
  /**
   * pageSize
   */
  pageSize?: number
  /**
   * userName
   */
  userName?: string
}

/* **返回类型** */
export interface GetDeviceAuthUserResponse {
  data: {
    /**
     * 年龄
     */
    age: string
    /**
     * 身份证
     */
    cardNo: string
    /**
     * 人脸照
     */
    faceUrl: string
    id: number
    /**
     * 岗位
     */
    jobName: string
    /**
     * 所属劳务
     */
    laborName: string
    /**
     * 姓名
     */
    name: string
    /**
     * 电话
     */
    phone: string
    /**
     * 性别
     */
    sex: string
  }[]
  total: number
}

/* **请求函数** */
export async function getDeviceAuthUser(query: GetDeviceAuthUserQuery): Promise<any> {
  return request(`/device/auth/user`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [取消授权↗](http://fe.jiuworker.com:3000/project/29/interface/api/1583)
 *
 * @分类 [设备管理↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_109)
 * @标签 `设备管理`
 * @请求头 `PUT /device/cancel/auth`
 * @更新时间 `2024-08-23 16:22:07`
 */

/* **请求query类型** */
export interface PutDeviceCancelAuthQuery {
  /**
   * idList
   */
  idList: string
}

/* **返回类型** */
export type PutDeviceCancelAuthResponse = boolean

/* **请求函数** */
export async function putDeviceCancelAuth(query: PutDeviceCancelAuthQuery): Promise<any> {
  return request(`/device/cancel/auth`, {
    method: Method.PUT,
    params: query,
  })
}

/**
 * 接口 [设备授权列表查询↗](http://fe.jiuworker.com:3000/project/29/interface/api/1585)
 *
 * @分类 [设备管理↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_109)
 * @标签 `设备管理`
 * @请求头 `GET /device/query/auth`
 * @更新时间 `2024-08-23 16:22:07`
 */

/* **请求query类型** */
export interface GetDeviceQueryAuthQuery {
  /**
   * deviceName
   */
  deviceName?: string
  /**
   * deviceNo
   */
  deviceNo?: string
  /**
   * name
   */
  name?: string
  /**
   * pageNum
   */
  pageNum?: number
  /**
   * pageSize
   */
  pageSize?: number
}

/* **返回类型** */
export interface GetDeviceQueryAuthResponse {
  data: {
    /**
     * 设备名称
     */
    deviceName: string
    /**
     * 性别
     */
    deviceNo: string
    /**
     * 人脸照
     */
    faceUrl: string
    /**
     * 操作时间
     */
    gmtModified: string
    id: number
    /**
     * 姓名
     */
    name: string
    /**
     * 状态：10:授权中；20授权成功；30授权失败
     */
    status: number
  }[]
  total: number
}

/* **请求函数** */
export async function getDeviceQueryAuth(query: GetDeviceQueryAuthQuery): Promise<any> {
  return request(`/device/query/auth`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [分页查询↗](http://fe.jiuworker.com:3000/project/29/interface/api/1589)
 *
 * @分类 [设备管理↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_109)
 * @标签 `设备管理`
 * @请求头 `GET /plan/query`
 * @更新时间 `2024-08-23 16:22:11`
 */

/* **请求query类型** */
export interface GetPlanQueryQuery {
  /**
   * 用工结束时间
   */
  endDate?: string
  enterId?: string
  pageNum?: number
  pageSize?: number
  /**
   * 计划单名称
   */
  planName?: string
  /**
   * 计划单号
   */
  planNo?: string
  /**
   * 用工开始时间
   */
  startDate?: string
  /**
   * 状态
   */
  status?: string
  userId?: string
}

/* **返回类型** */
export interface GetPlanQueryResponse {
  data: {
    /**
     * 用工时间(天)
     */
    dateDay: number
    /**
     * 用工时段(小时)
     */
    dateHour: number
    /**
     * 酒店名称
     */
    enterName: string
    enterWorkPlanDTO: {
      /**
       * 联系人
       */
      contractPerson: string
      /**
       * 联系人电话
       */
      contractPhone: string
      /**
       * 自动授权的设备列表
       */
      deviceIdList: {
        deviceId: number
        deviceName: string
      }[]
      /**
       * 结束时间
       */
      endDate: string
      /**
       * 抢单截止时间
       */
      endDateOrderHall: string
      /**
       * 结束用工时段
       */
      endHour: string
      /**
       * 岗位需求
       */
      enterWorkApplyJobDTOList: {
        /**
         * 数量
         */
        amount: number
        /**
         * 其他业务字段
         */
        bizData: string
        /**
         * 假期岗位单价
         */
        holidayMoney: number
        /**
         * 岗位id
         */
        jobId: number
        /**
         * 岗位名称
         */
        jobName: string
        /**
         * 男数量
         */
        manNumber: number
        maxAge: string
        /**
         * 岗位单价
         */
        money: number
        /**
         * 客房量
         */
        roomInfoList: {
          currentMoney: number
          holidayMoney: number
          money: number
          roomAmount: number
          roomTypeName: string
          unitMoney: string
          weekendMoney: number
        }[]
        /**
         * 单位
         */
        unit: string
        /**
         * 女数量
         */
        womanNumber: number
      }[]
      /**
       * 附件
       */
      fileUrlList: string[]
      /**
       * 是否为节假日标准计价:true是；false否
       */
      holidayType: boolean
      id: number
      /**
       * 是否自动授权:true是；false否
       */
      isAutoAuth: boolean
      isOver: boolean
      isOverNight: boolean
      /**
       * 是否发送至抢单大厅
       */
      isSendOrderHall: boolean
      /**
       * 劳务公司
       */
      laborId: number
      /**
       * 劳务状态：10计划申请取消中；20申请取消已被拒绝
       */
      laborStatus: number
      /**
       * 计价方式：10小时；20按天；30按计件
       */
      moneyType: number
      oldRoomAccount: number
      /**
       * 计划名称
       */
      planName: string
      relateWorkId: number
      /**
       * 休息时长
       */
      relaxTime: number
      /**
       * 取消原因
       */
      remark: string
      /**
       * 开始时间
       */
      startDate: string
      /**
       * 开始用工时段
       */
      startHour: string
      /**
       * 用工负责人
       */
      workPlanLeader: number
    }
    gmtCreate: string
    /**
     * 主键ID
     */
    id: number
    /**
     * 劳务公司
     */
    laborName: string
    /**
     * 招工进度
     */
    percent: string
    /**
     * 工单号
     */
    planNo: string
    /**
     * 状态10:待接单；20接单中；30进行中；40待考勤确认；50待劳务确认；60待劳务结算；70：已拒绝；80 已完成；90：已取消
     */
    status: number
    /**
     * 总工时
     */
    totalHour: number
    /**
     * 总费用
     */
    totalMoney: number
    /**
     * 10用工计划 20客房派单
     */
    workPlanType: number
  }[]
  total: number
}

/* **请求函数** */
export async function getPlanQuery(query: GetPlanQueryQuery): Promise<any> {
  return request(`/plan/query`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [获取房态数量↗](http://fe.jiuworker.com:3000/project/29/interface/api/2495)
 *
 * @分类 [APP-房态信息↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_163)
 * @标签 `APP-房态信息`
 * @请求头 `GET /app/room/amount`
 * @更新时间 `2024-08-23 16:22:03`
 */

/* **请求query类型** */
export interface GetAppRoomAmountQuery {
  /**
   * enterId
   */
  enterId: string
}

/* **返回类型** */
export interface GetAppRoomAmountResponse {
  hadClean: number
  todayClean: number
  waitingClean: number
}

/* **请求函数** */
export async function getAppRoomAmount(query: GetAppRoomAmountQuery): Promise<any> {
  return request(`/app/room/amount`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [房态查询↗](http://fe.jiuworker.com:3000/project/29/interface/api/2497)
 *
 * @分类 [APP-房态信息↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_163)
 * @标签 `APP-房态信息`
 * @请求头 `GET /app/room/query/status`
 * @更新时间 `2024-08-23 16:22:03`
 */

/* **请求query类型** */
export interface GetAppRoomQueryStatusQuery {
  /**
   * planId
   */
  planId: string
}

/* **返回类型** */
export type GetAppRoomQueryStatusResponse = {
  roomPOList: {
    enterId: number
    enterRoomPersonVOList: {
      attendanceId: number
      enterId: number
      gmtCreate: string
      gmtModified: string
      id: number
      money: number
      planId: number
      roomId: number
      roomType: string
      userId: number
      userName: string
    }[]
    floorId: number
    gmtCreate: string
    gmtModified: string
    id: number
    regionId: number
    roomName: string
    roomType: string
    status: number
  }[]
  roomType: string
}[]

/* **请求函数** */
export async function getAppRoomQueryStatus(query: GetAppRoomQueryStatusQuery): Promise<any> {
  return request(`/app/room/query/status`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [获取保洁数据↗](http://fe.jiuworker.com:3000/project/29/interface/api/2499)
 *
 * @分类 [APP-房态信息↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_163)
 * @标签 `APP-房态信息`
 * @请求头 `GET /app/room/user/list`
 * @更新时间 `2024-08-23 16:22:03`
 */

/* **请求query类型** */
export interface GetAppRoomUserListQuery {
  /**
   * enterId
   */
  enterId: string
  /**
   * planId
   */
  planId: string
  /**
   * roomType
   */
  roomType: string
}

/* **返回类型** */
export type GetAppRoomUserListResponse = {
  planId: number
  userId: number
  userName: string
}[]

/* **请求函数** */
export async function getAppRoomUserList(query: GetAppRoomUserListQuery): Promise<any> {
  return request(`/app/room/user/list`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [选择保洁↗](http://fe.jiuworker.com:3000/project/29/interface/api/2501)
 *
 * @分类 [APP-房态信息↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_163)
 * @标签 `APP-房态信息`
 * @请求头 `GET /app/room/user/{id}`
 * @更新时间 `2024-08-23 16:22:04`
 */

/* **请求query类型** */
export interface GetAppRoomUserByIdQuery {
  /**
   * planId
   */
  planId: string
  /**
   * userId
   */
  userId: string
}

/* **请求params类型** */
export interface GetAppRoomUserByIdParams {
  /**
   * 房间ID
   */
  id: string
}

/* **返回类型** */
export type GetAppRoomUserByIdResponse = boolean

/* **请求函数** */
export async function getAppRoomUserById(
  query: GetAppRoomUserByIdQuery,
  params: GetAppRoomUserByIdParams,
): Promise<any> {
  return request(`/app/room/user/${params.id}`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [撤回(删除房间记录)↗](http://fe.jiuworker.com:3000/project/29/interface/api/2541)
 *
 * @分类 [APP-房态信息↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_163)
 * @标签 `APP-房态信息`
 * @请求头 `GET /app/room/app/out/attendance/room/reset`
 * @更新时间 `2024-06-28 14:02:04`
 */

/* **请求query类型** */
export interface GetAppRoomAppOutAttendanceRoomResetQuery {
  /**
   * planId
   */
  planId: string
  /**
   * roomId
   */
  roomId: string
  /**
   * userId
   */
  userId: string
}

/* **返回类型** */
export type GetAppRoomAppOutAttendanceRoomResetResponse = boolean

/* **请求函数** */
export async function getAppRoomAppOutAttendanceRoomReset(
  query: GetAppRoomAppOutAttendanceRoomResetQuery,
): Promise<any> {
  return request(`/app/room/app/out/attendance/room/reset`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [撤回(删除房间记录)↗](http://fe.jiuworker.com:3000/project/29/interface/api/2543)
 *
 * @分类 [APP-房态信息↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_163)
 * @标签 `APP-房态信息`
 * @请求头 `GET /app/room/attendance/reset`
 * @更新时间 `2024-08-23 16:22:03`
 */

/* **请求query类型** */
export interface GetAppRoomAttendanceResetQuery {
  /**
   * planId
   */
  planId: string
  /**
   * roomId
   */
  roomId: string
  /**
   * userId
   */
  userId: string
}

/* **返回类型** */
export type GetAppRoomAttendanceResetResponse = boolean

/* **请求函数** */
export async function getAppRoomAttendanceReset(query: GetAppRoomAttendanceResetQuery): Promise<any> {
  return request(`/app/room/attendance/reset`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [查询区域和楼层(结合一起)↗](http://fe.jiuworker.com:3000/project/29/interface/api/2761)
 *
 * @分类 [APP-房态信息↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_163)
 * @标签 `APP-房态信息`
 * @请求头 `GET /app/room/query/room/all`
 * @更新时间 `2024-08-23 16:22:03`
 */

/* **请求query类型** */
export interface GetAppRoomQueryRoomAllQuery {
  /**
   * planId
   */
  planId: string
}

/* **返回类型** */
export type GetAppRoomQueryRoomAllResponse = {
  id: number
  isDefault: number
  name: string
  regionId: number
  roomFloorPOList: {
    enterId: number
    gmtCreate: string
    gmtModified: string
    id: number
    isDefault: number
    name: string
    regionId: number
  }[]
}[]

/* **请求函数** */
export async function getAppRoomQueryRoomAll(query: GetAppRoomQueryRoomAllQuery): Promise<any> {
  return request(`/app/room/query/room/all`, {
    method: Method.GET,
    params: query,
  })
}

/**
 * 接口 [房态查询↗](http://fe.jiuworker.com:3000/project/29/interface/api/2763)
 *
 * @分类 [APP-房态信息↗](http://fe.jiuworker.com:3000/project/29/interface/api/cat_163)
 * @标签 `APP-房态信息`
 * @请求头 `GET /app/room/query/room/status`
 * @更新时间 `2024-08-23 16:22:03`
 */

/* **请求query类型** */
export interface GetAppRoomQueryRoomStatusQuery {
  /**
   * floorId
   */
  floorId?: string
  /**
   * planId
   */
  planId: string
  /**
   * regionId
   */
  regionId?: string
  /**
   * roomName
   */
  roomName?: string
}

/* **返回类型** */
export type GetAppRoomQueryRoomStatusResponse = {
  enterId: number
  enterRoomPersonVOList: {
    attendanceId: number
    enterId: number
    gmtCreate: string
    gmtModified: string
    id: number
    money: number
    planId: number
    roomId: number
    roomType: string
    userId: number
    userName: string
  }[]
  floorId: number
  gmtCreate: string
  gmtModified: string
  id: number
  regionId: number
  roomName: string
  roomType: string
  status: number
}[]

/* **请求函数** */
export async function getAppRoomQueryRoomStatus(query: GetAppRoomQueryRoomStatusQuery): Promise<any> {
  return request(`/app/room/query/room/status`, {
    method: Method.GET,
    params: query,
  })
}

/* prettier-ignore-end */
