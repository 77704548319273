export default {
  path: 'attendance',
  name: '灵工考勤',
  icon: 'TaskFilled',
  accessKey: '/attendance',
  routes: [
    { path: '', redirect: 'daily-attendance' },
    {
      path: 'daily-attendance',
      component: '@/pages/attendance/daily-attendance',
      name: '每日考勤',
      accessKey: '/attendance/daily-attendance',
    },
    {
      path: 'repair-record',
      component: '@/pages/attendance/repair-record',
      name: '补卡记录',
      hideInMenu: true,
      accessKey: '/attendance/daily-attendance',
      selectedKey: '/attendance/daily-attendance',
    },
    {
      path: 'employee-attendance',
      component: '@/pages/attendance/employee-attendance',
      name: '员工考勤',
      accessKey: '/attendance/employee-attendance',
    },
    {
      path: 'adjust-record',
      component: '@/pages/attendance/adjust-record',
      name: '调整记录',
      hideInMenu: true,
      accessKey: '/attendance/employee-attendance',
      selectedKey: '/attendance/team-attendance',
    },
    {
      path: 'employee-attendance-detail',
      component: '@/pages/attendance/employee-attendance-detail',
      name: '考勤详情',
      hideInMenu: true,
      accessKey: '/attendance/employee-attendance',
      selectedKey: '/attendance/employee-attendance',
    },
    {
      path: 'team-attendance',
      component: '@/pages/attendance/team-attendance',
      name: '班组考勤',
      accessKey: '/attendance/team-attendance',
    },
    {
      path: 'team-attendance-detail',
      component: '@/pages/attendance/team-attendance-detail',
      name: '班组详情',
      hideInMenu: true,
      accessKey: '/attendance/team-attendance',
      selectedKey: '/attendance/team-attendance',
    },
    {
      path: 'monthly-summary',
      component: '@/pages/attendance/monthly-summary',
      name: '月度汇总',
      accessKey: '/attendance/monthly-summary',
    },
    {
      path: 'attendance-record',
      component: '@/pages/attendance/attendance-record',
      name: '打卡记录',
      accessKey: '/attendance/attendance-record',
    },
  ],
};
