import { useRequest } from '@umijs/max';

import {
  getAppAccountFirstLogin,
  getBasicMenuAccountList,
  getLaborCompanyQuery,
  postAccountCurrentInfo,
} from '@/api';
import { BaseResponse } from '@/interfaces/base';

import type {
  GetAppAccountFirstLoginResponse,
  GetBasicInfoAccountResponse,
  GetBasicMenuAccountListResponse,
  GetLaborCompanyQueryResponse,
} from '@/api';

export type UserInfo = GetBasicInfoAccountResponse;
export type AccessItem = GetBasicMenuAccountListResponse[number];

export default function useUserModel() {
  // 用户信息
  const { data: info, run: fetchUserInfo } = useRequest<BaseResponse<UserInfo>>(
    postAccountCurrentInfo,
    { manual: true },
  );

  // 用户是否首次登录
  const { data: isFirstLogin, run: fetchUserIsFirstLogin } = useRequest<
    BaseResponse<GetAppAccountFirstLoginResponse>
  >(getAppAccountFirstLogin, { manual: true });

  // 用户权限
  const { data: access = [], run: fetchUserAccess } = useRequest<
    BaseResponse<AccessItem[]>
  >(getBasicMenuAccountList, { manual: true });

  // 劳务公司信息
  const { data: laborInfo, run: fetchLaborInfo } = useRequest<
    BaseResponse<GetLaborCompanyQueryResponse>
  >(getLaborCompanyQuery, { manual: true });

  return {
    info,
    fetchUserInfo,
    access,
    fetchUserAccess,
    laborInfo,
    fetchLaborInfo,
    isFirstLogin,
    fetchUserIsFirstLogin,
  };
}
