import { ProTableProps } from '@pangu/materials';
import { RequestData } from '@pangu/materials/dist/ProTable/interfaces';
import dayjs, { Dayjs } from 'dayjs';

import type { OptionItem, PlainObject } from '@/interfaces/base';

export default class ProTableUtils {
  /**
   * @desc 生成proTable 请求函数
   * @param requestFun
   * @param options
   */
  static generateRequest<Record = any>(
    requestFun: Function,
    options?: {
      formatReqParams?: (params: PlainObject) => PlainObject[];
      formatTableResponse?: (resp: any) => any;
      formatReqOptions?: Parameters<typeof ProTableUtils.formatReqParams>[1];
    },
  ): ProTableProps<Record>['request'] {
    return async function (params, sort, filter) {
      const {
        formatReqParams = ProTableUtils.formatReqParams,
        formatTableResponse = ProTableUtils.formatTableResponse,
        formatReqOptions,
      } = options ?? {};

      const resp = await requestFun(
        ...formatReqParams(
          {
            ...params,
            ...sort,
            ...filter,
          },
          formatReqOptions,
        ),
      );
      return formatTableResponse(resp);
    };
  }

  static formatReqParams(
    params: PlainObject,
    options?: {
      time?: Array<{
        field: string;
      }>;
      timeRange?: Array<{
        originField: string;
        startTimeField: string;
        endTimeField: string;
        autoDeleteOriginField?: boolean;
      }>;
    },
  ) {
    const { time, timeRange } = options ?? {};

    if (timeRange?.length) {
      timeRange.forEach((item) => {
        [params[item.startTimeField], params[item.endTimeField]] =
          ProTableUtils.formatTimeRange(params[item.originField]);
        (item.autoDeleteOriginField ?? true) && delete params[item.originField];
      });
    }

    if (time?.length) {
      time.forEach((item) => {
        params[item.field] = params[item.field]
          ? dayjs(params[item.field]).format('YYYY-MM-DD 00:00:00')
          : undefined;
      });
    }

    return [ProTableUtils.formatPaginationParams(params)];
  }

  /**
   * @desc 格式化请求
   * @param resp 请求返回
   * @param options 配置 dataFiled 返回data的字段
   */
  static formatTableResponse<ResponseData = any>(
    resp: any,
    options?: {
      dataFiled?: string;
    },
  ): RequestData & {
    data: ResponseData;
  } {
    const { dataFiled = 'data' } = options ?? {};
    return {
      success: resp?.success,
      data: resp?.data?.[dataFiled] ?? [],
      total: resp.data?.total || 0,
    };
  }

  static formatTimeRange(
    times: [Dayjs | string | number, Dayjs | string | number],
  ) {
    if (!Array.isArray(times) || !times.length) {
      return [];
    }

    const [startTimeField, endTimeField] = times ?? [];
    return [
      dayjs(startTimeField)?.format('YYYY-MM-DD 00:00:00'),
      dayjs(endTimeField)?.format('YYYY-MM-DD 23:59:59'),
    ];
  }

  /**
   * @desc 格式化请求分数数据
   * @param params 分页 + 自定义数据
   * @returns 后端接口需要的数据
   */
  static formatPaginationParams(
    params: {
      current?: number;
      pageSize?: number;
    } & PlainObject = { current: 1, pageSize: 10 },
  ) {
    const { current, ...rest } = params;
    return {
      ...ProTableUtils.removeEmptyKey(rest),
      pageNum: current,
    };
  }

  static removeEmptyKey(obj: PlainObject) {
    const ret: PlainObject = {};
    Object.keys(obj).forEach((key) => {
      const val = obj[key];
      if (val !== undefined && val !== null && val !== '') {
        ret[key] = val;
      }
    });
    return ret;
  }

  static getActionsLength(
    actions: PlainObject[],
    stringField: string,
    options?: {
      fontSize: number;
      padding: number;
      reserved: number;
    },
  ): number {
    const { fontSize = 14, padding = 12, reserved = 20 } = options ?? {};
    return (
      actions.reduce((sum, action) => {
        return sum + action[stringField]?.length * fontSize;
      }, 0) +
      actions.length * padding +
      reserved
    );
  }

  /**
   * 把数组转换成选项 Map
   * @param data 选项数组
   * @returns
   */
  static formatOptions<T>(data: Array<OptionItem<T>>) {
    return data?.reduce((map, item) => {
      map.set(item.value, item.label);
      return map;
    }, new Map<T, string>());
  }
}
