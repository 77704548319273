import { useLocation, useNavigate } from '@umijs/max';
import { Button, Result } from 'antd';
import { useEffect } from 'react';

import { MenuItem, SessionStorageKey } from '@/utils';

const Page403 = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    const historyStack = sessionStorage.getItem(SessionStorageKey.HistoryStack);
    const historyStackArr = historyStack ? JSON.parse(historyStack) : [];
    // 删掉当前路由对应的历史记录
    const newHistoryStackArr = historyStackArr.filter(
      (item: MenuItem) => item.path !== pathname,
    );

    sessionStorage.setItem(
      SessionStorageKey.HistoryStack,
      JSON.stringify(newHistoryStackArr),
    );
  }, [pathname]);

  return (
    <Result
      status="403"
      title="403"
      subTitle="没有权限"
      extra={
        <Button type="primary" onClick={() => navigate('/')}>
          返回
        </Button>
      }
    />
  );
};

export default Page403;
