export default {
  path: 'labor',
  name: '用工管理',
  icon: 'PangZhanFilled',
  accessKey: '/labor',
  routes: [
    { path: '', redirect: 'demand' },
    {
      path: 'hall',
      component: '@/pages/labor/hall',
      name: '抢单大厅',
      accessKey: '/labor/hall',
    },
    {
      path: 'hall/detail',
      component: '@/pages/labor/hall-detail',
      name: '详情',
      hideInMenu: true,
      accessKey: '/labor/hall',
      selectedKey: '/labor/hall',
    },
    {
      path: 'demand',
      component: '@/pages/labor/demand',
      name: '用工计划',
      accessKey: '/labor/demand',
    },
    {
      path: 'order-detail',
      component: '@/pages/labor/order-detail',
      name: '需求详情',
      hideInMenu: true,
      selectedKey: '/labor/demand',
    },
    {
      path: 'dispatch',
      component: '@/pages/labor/dispatch',
      name: '客房派单',
      accessKey: '/labor/dispatch',
    },
    {
      path: 'dispatch-detail',
      component: '@/pages/labor/dispatch-detail',
      name: '客房派单详情',
      accessKey: '/labor/dispatch',
      hideInMenu: true,
      selectedKey: '/labor/dispatch',
    },
    {
      path: 'dispatch-room-status',
      component: '@/pages/labor/dispatch-room-status',
      name: '房态',
      accessKey: '/labor/dispatch',
      hideInMenu: true,
      selectedKey: '/labor/dispatch',
    },
    {
      path: 'dispatch-team-add',
      component: '@/pages/labor/dispatch-team-add',
      name: '派单创建班组',
      accessKey: '/labor/dispatch',
      hideInMenu: true,
      selectedKey: '/labor/dispatch',
    },
    {
      path: 'team',
      component: '@/pages/labor/team',
      name: '班组管理',
      accessKey: '/labor/team',
    },
    {
      path: 'team-add',
      component: '@/pages/labor/team-add',
      name: '创建班组',
      hideInMenu: true,
      accessKey: '/labor/team',
      selectedKey: '/labor/team',
    },
    {
      path: 'team-detail',
      component: '@/pages/labor/team-detail',
      name: '班组详情',
      hideInMenu: true,
      accessKey: '/labor/team',
      selectedKey: '/labor/team',
    },
    {
      path: 'team-detail-dispatch',
      component: '@/pages/labor/team-detail-dispatch',
      name: '客房派单班组详情',
      hideInMenu: true,
      accessKey: '/labor/dispatch',
      selectedKey: '/labor/dispatch',
    },
    {
      path: 'team-scheduling',
      component: '@/pages/labor/team-scheduling',
      name: '排班',
      hideInMenu: true,
      accessKey: '/labor/team',
      selectedKey: '/labor/team',
    },
    {
      path: 'registration',
      component: '@/pages/labor/registration',
      name: '报名管理',
      accessKey: '/labor/registration',
    },
    {
      path: 'workers-management',
      component: '@/pages/labor/workers-management',
      name: '灵工管理',
      accessKey: '/labor/workers-management',
    },
    {
      path: 'worker-detail',
      component: '@/pages/labor/worker-detail',
      name: '人员详情',
      hideInMenu: true,
      accessKey: '/labor/workers-management',
      selectedKey: '/labor/workers-management',
    },
    {
      path: 'worker-detail-apply',
      component: '@/pages/labor/worker-detail-apply',
      name: '报名人员详情',
      hideInMenu: true,
      accessKey: '/labor/workers-management',
      selectedKey: '/labor/workers-management',
    },
    {
      path: 'worker-detail-departed',
      component: '@/pages/labor/worker-detail-departed',
      name: '离职人员详情',
      hideInMenu: true,
      accessKey: '/labor/workers-management',
      selectedKey: '/labor/workers-management',
    },
    {
      path: 'worker-detail-black',
      component: '@/pages/labor/worker-detail-black',
      name: '黑名单人员详情',
      hideInMenu: true,
      accessKey: '/labor/workers-management',
      selectedKey: '/labor/workers-management',
    },
    {
      path: 'talent-pool',
      component: '@/pages/labor/talent-pool',
      name: '人才库',
      accessKey: '/labor/talent-pool',
    },
    {
      path: 'worker-detail-pool',
      component: '@/pages/labor/worker-detail-pool',
      name: '人才详情',
      hideInMenu: true,
      accessKey: '/labor/talent-pool',
      selectedKey: '/labor/talent-pool',
    },
  ],
};
