import attendance from './modules/attendance';
import customerManagement from './modules/customer-management';
import device from './modules/device';
import employee from './modules/employee';
import insurance from './modules/insurance';
import labor from './modules/labor';
import overview from './modules/overview';
import setting from './modules/setting';
import settlement from './modules/settlement';
import statistics from './modules/statistics';

import type { Route } from '@/interfaces/route';

const routes: Route[] = [
  {
    path: '/user',
    component: '@/layouts/LoginLayout',
    routes: [
      {
        path: 'login',
        component: '@/pages/user/login',
        name: '登录',
      },
      {
        path: 'password',
        component: '@/pages/user/password',
        name: '找回密码',
      },
      {
        path: '/user',
        exact: true,
        redirect: '/user/login',
      },
    ],
  },
  {
    path: '/403',
    component: '@/pages/403',
  },
  {
    path: '/',
    component: '@/layouts/BasicLayout',
    wrappers: ['@/wrappers/SecurityWrapper', '@/wrappers/RedirectWrapper'],
    routes: [
      overview,
      labor,
      attendance,
      employee,
      settlement,
      insurance,
      statistics,
      device,
      customerManagement,
      setting,
    ],
  },
];

export default routes;
