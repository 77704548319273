export default {
  path: 'setting',
  name: '设置',
  icon: 'SettingsFilled',
  accessKey: '/setting',
  routes: [
    { path: '', redirect: 'account' },
    {
      path: 'account',
      component: '@/pages/setting/account',
      name: '账户管理',
      accessKey: '/setting/account',
    },
    {
      path: 'base',
      component: '@/pages/setting/base',
      name: '基本设置',
      accessKey: '/setting/base',
    },
    {
      path: 'role',
      component: '@/pages/setting/role',
      name: '角色管理',
      accessKey: '/setting/role',
    },
    {
      path: 'log',
      component: '@/pages/setting/log',
      name: '操作日志',
      accessKey: '/setting/log',
    },
  ],
};
