import { ProTableUtil } from '@pangu/materials';
import { Checker } from '@pangu/utils';
import CryptoJS from 'crypto-js';
import dayjs from 'dayjs';
import _ from 'lodash';

import emitter, { EventEnum } from './emitter';
import StorageUtils, { CookieKeyEnum, SessionStorageKey } from './storage';

import type { Route } from '@/interfaces/route';
import type { ItemType } from 'antd/es/menu/hooks/useItems';
import type { ReactNode } from 'react';

export { default as DayjsUtils } from './dayjs';
export { default as EnumUtils } from './enum';
export { default as ProTableUtils } from './proTable';
export { CookieKeyEnum, SessionStorageKey, StorageUtils };
export { emitter, EventEnum };

export const proTableUtil = new ProTableUtil();

export interface MenuItem extends Omit<Route, 'icon'> {
  children?: MenuItem[];
  icon?: ReactNode | string | undefined;
  key?: string;
  label?: string;
  selectedKey?: string;
}

/**
 * 格式化路由，将路由对象转换成菜单组件可识别的格式
 * @param data 路由数据
 * @param layoutName 布局组件名
 * @returns 菜单项
 */
export const getMenu = (data: MenuItem[], layoutName: string) => {
  // 属于当前布局组件的菜单项
  const menuInLayout =
    data.filter((item) => item.component === `@/layouts/${layoutName}`)[0]
      ?.children ?? [];

  // 根据条件递归过滤路由
  const filterRoutes = (arr: MenuItem[]) => {
    return arr
      .filter((item: MenuItem) => {
        const { hideInMenu, redirect } = item;
        return !redirect && !hideInMenu;
      })
      .map((obj) => {
        const ret: MenuItem = { ...obj };
        if (Array.isArray(obj.children)) {
          ret.children = filterRoutes(ret.children ?? []);
        }
        return ret;
      });
  };

  // 过滤掉所有不该显示的菜单项
  const menu = filterRoutes(menuInLayout) as unknown as ItemType[];

  return menu;
};

/**
 * 退出登录
 */
export const logout = () => {
  StorageUtils.removeCookie(CookieKeyEnum.Token);
  window.location.href = '/user/login';
};

/**
 * 数据加密
 * @param type 加密方式
 * @param value 需要加密的值
 * @returns 加密后的值
 */
export const encode = (type: 'md5', value: any) => {
  if (type === 'md5') {
    return CryptoJS.MD5(value).toString();
  }
  return value;
};

/**
 * 判断字符串是否为url
 * @param str 字符串
 * @returns 是否为url
 */
export const isUrl = (str: string) => {
  const urlChecker = new Checker('url');
  return urlChecker.test(str);
};

/**
 * 计算时间差
 * @param start 开始时间
 * @param end 结束时间
 * @param restTime 休息时间
 * @returns 返回值
 */
export const formatTimeWithTimes = (
  start: string,
  end: string,
  restTime = 0,
) => {
  let times =
    dayjs(end, 'HH:mm:ss').diff(dayjs(start, 'HH:mm:ss'), 'minutes') / 60;
  // 如果时间小于0，说明跨天了
  if (times < 0) {
    times += 24;
  }

  const timeRange = `${formatTime(start)}-${formatTime(end)}(${
    times - (restTime || 0)
  })小时`;

  return timeRange;
};

export const formatTime = (time: string) => {
  if (!time) {
    return '';
  }

  return dayjs(time, 'HH:mm:ss').format('HH:mm');
};
export const formatDate = (time: string) => {
  return dayjs(time, 'YYYY-MM-DD').format('MM/DD');
};

export const formatDateWithDays = (start: string, end: string) => {
  const days = dayjs(end).diff(dayjs(start), 'days') + 1;

  const dateRange = `${formatDate(start)}-${formatDate(end)}(${days})天`;

  return dateRange;
};

// 输入数字，返回千分位格式，小数保留两位
export const formatAmount = (num: number) => {
  if (num === null || num === undefined) {
    return '';
  }
  const [int, d] = Number(num).toFixed(2).split('.');
  return `${Number(int).toLocaleString()}.${d}`;
};

// 浏览器访问链接，直接浏览pdf
export const previewPdf = async (pdfUrl: string) => {
  const res = await fetch(pdfUrl);
  const blob = await res.blob();
  const url = window.URL.createObjectURL(blob);
  window.open(url);
};

/**
 * 解析 json 字符串
 * @param str json 字符串
 * @param defaultValue 解析失败默认值
 * @returns json
 */
export const parseJson = <T>(str: string, defaultValue: T = [] as any): T => {
  try {
    return str ? JSON.parse(str) : defaultValue;
  } catch (err) {
    return defaultValue;
  }
};

export interface TreeItem {
  code: string;
  children?: TreeItem[];
  name: string;
}
/**
 * code不足6位的补0
 * @param data
 */
export const padZero = (data: TreeItem[]) => {
  const nData = _.cloneDeep(data);

  const format = (d: TreeItem[]) => {
    d.forEach((item: any) => {
      if (item.code.length < 6) {
        item.code = item.code.padEnd(6, '0');
      }
      if (item.children?.length) {
        format(item.children);
      }
    });
  };

  format(nData);

  return nData;
};
