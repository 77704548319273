import { EventEmitter } from 'events';

export enum EventEnum {
  RoleChange = 'role_change',
  DepartmentRefresh = 'department_refresh', // 职工管理页面更新部门数据
}

const emitter = new EventEmitter();

export default emitter;
