export default {
  path: 'device',
  name: '设备管理',
  icon: 'ConstructionSiteEquipmentFilled',
  accessKey: '/device/list',
  routes: [
    {
      path: 'list',
      component: '@/pages/device/list',
      name: '设备管理',
      accessKey: '/device/list',
    },
    {
      path: 'authorization',
      component: '@/pages/device/authorization',
      name: '授权管理',
      accessKey: '/device/authorization',
    },
  ],
};
