export default {
  path: 'insurance',
  name: '保险',
  icon: 'ShieldFilled',
  accessKey: '/insurance',
  routes: [
    { path: '', redirect: 'record' },
    {
      path: 'record',
      component: '@/pages/insurance/record',
      name: '投保记录',
      accessKey: '/insurance/record',
    },
    {
      path: 'introduction',
      component: '@/pages/insurance/introduction',
      name: '保险介绍',
      accessKey: '/insurance/introduction',
    },
  ],
};
